/** @format */

const state = {
  offers: [],
};
const getters = {
  getOffers: (state, name) => {
    return state.offers;
  },
};
const actions = {
  newOffer({ commit }, data) {
    commit('setNewOffer');
  },
};

const mutations = {
  setNewOffer: (state, data) => {
    if (typeof data === 'object') {
      let d = [data, ...state.offers];
      state.offers = d;
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
