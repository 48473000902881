<template>
  <v-dialog v-model="dialog" width="500" @keydown.esc="dialog = false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn color="primary" :disabled="disabled" class="px-2" :title="title" v-bind="attrs" v-on="on">
        <v-icon>{{ icon }}</v-icon>
        <btn-title v-if="$vuetify.breakpoint[`${size}AndUp`] && title && false">{{ title }}</btn-title>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="lighten-2">
        <span>
          {{ title }}
        </span>
        <v-btn style="position: absolute; right: 12px; top: 12px" fab dark small color="blue-grey" title="Закрыть окно" @click="dialog = false"
          ><v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-text-field label="ссылка на объявление" v-model="link"></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" :disabled="!link" @click.stop="makeOffer(link)">
          <btn-title v-if="$vuetify.breakpoint.smAndUp"> Загрузить</btn-title>
        </v-btn>
        <v-btn @click="dialog = false">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getForm, submitForm, removeEl } from "@/components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl],
  props: {
    size: { type: String, default: "sm" },
    title: { type: String, default: "Создать" },
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: "fas fa-link" },
  },
  data() {
    return {
      dialog: false,
      link: null,
      loading: false,
    };
  },
  created() {},
  watch: {
    dialog(v) {
      if (v) {
        this.link = null;
      }
    },
  },
  methods: {
    makeOffer(l = "") {
      //this.openOffer();
      //return;
      if (l.length < 10) return;
      if (l.includes("https://www.cian.ru/")) this.doCian(l);
      else if (l.includes("https://www.avito.ru/")) this.doAvito(l);
      else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка проверки ссылки",
        });
      }
    },
    async doAvito(l) {
      console.log("doAvito");
      l = l.replace("www.avito.ru", "m.avito.ru");
      if (l.includes("/rent/flat/") || l.includes("/sale/flat/") || true) {
        let category_id = l.includes("/rent/flat/") ? 1 : 2;
        console.log(category_id);
        let data = await this.getData("avito", l);
        console.log("doAvito data", data);
        data.category_id = category_id;
        data.vendor = "avito";
        if (data?.vendor_id) {
          this.openOffer(data);
        } else {
          console.log("----data?.vendor_id");
        }
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка. Не известная ссылка",
        });
      }
    },
    async doCian(l) {
      console.log("doCian");
      if (l.includes("/rent/flat/") || l.includes("/sale/flat/")) {
        let category_id = l.includes("/rent/flat/") ? 1 : 2;
        console.log(category_id);
        let data = await this.getData("cian", l);
        console.log("doCian data", data);
        data.category_id = category_id;
        data.vendor = "cian";
        if (data?.vendor_id) {
          this.openOffer(data);
        } else {
          console.log("----data?.vendor_id");
        }
      } else {
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка. Не известная ссылка",
        });
      }
    },
    async getData(vendor, url) {
      this.loading = true;
      try {
        let d = await this.$axios.post("/offer/makeOffer", { vendor, url });
        if (d.data.status == "ok") {
          let data = d.data.data;
          this.loading = !true;
          return data;
        }
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка. Получения данных",
        });
      } catch (error) {
        console.log(error);
        this.$root.$emit("show-info", {
          type: "error",
          text: "Ошибка. Получения данных",
        });
      }
      this.loading = !true;
      return null;
    },
    openOffer(d) {
      //console.log("open offer", d);
      let title = d.title;
      let data = {
        data: d?.obj || {},
        phone: d?.phone,
        category_id: d.category_id,
        user_id: this.$root.profile.id,
        url: d.url,
        addr: d.addr,
        vendor: d.vendor,
        vendor_id: d.vendor_id,
        description: d.description,
        title: title,
        price: d.price.match(/\d+/g).join(""),
        floor: d.floor,
        total_floor: d.total_floor,
        ads_info: {
          rulesParams: d.rulesParams,
          aboutHome: d.aboutHome,
          features: d.features,
          images: d.images,
        },
      };
      this.$emit("onData", data);
      this.dialog = false;
      console.log(data);
    },
  },
};
</script>
