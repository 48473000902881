var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-row',{staticClass:"fill-height ma-0 pl-0",staticStyle:{"overflow":"auto"},attrs:{"align":"center","justify":"center"}},[_vm._l((_vm.items),function(el,key){return _c('v-col',{key:key,staticClass:"pa-1",class:{
      'pl-0': _vm.$vuetify.breakpoint.smAndDown,
    },attrs:{"cols":"4","sm":"5","md":"4","lg":"3"}},[(el.includes('video#'))?_c('v-card',{staticClass:"ma-0",attrs:{"draggable":"true","active-class":"","width":"100%"}},[_c('a',{attrs:{"href":_vm.$root.config.videoUrl + el.split('#')[1],"target":"_blank"},on:{"click":function($event){$event.preventDefault();_vm.fullScr = el;
          _vm.currImage = key;
          _vm.fullImage = true;}}},[_c('v-img',{attrs:{"aspect-ratio":1 / 1,"src":_vm.$root.config.imageUrl + el.split('#')[1] + '?width=' + _vm.size + '&height=' + _vm.size}},[_c('s-video-icon',{attrs:{"id":el.split('#')[2]}})],1)],1)]):_c('v-card',{staticClass:"ma-0",attrs:{"draggable":"true","active-class":"","width":"100%"}},[_c('a',{attrs:{"href":_vm.$root.config.imageUrl + el,"target":"_blank"},on:{"click":function($event){$event.preventDefault();_vm.fullScr = el;
          _vm.currImage = key;
          _vm.fullImage = true;}}},[_c('v-img',{attrs:{"aspect-ratio":4 / 3,"src":_vm.$root.config.imageUrl + el + '?width=' + _vm.size + '&height=' + _vm.size}})],1)])],1)}),(_vm.fullImage)?_c('a-view-image-carousel',{attrs:{"src":_vm.fullScr,"items":_vm.items,"curr":_vm.currImage},model:{value:(_vm.fullImage),callback:function ($$v) {_vm.fullImage=$$v},expression:"fullImage"}}):_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }