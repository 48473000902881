<template>
  <span>
    <v-btn v-if="value" class="ma-1 mr-4" small @click.stop="onItemCall(value)"> <v-icon color="primary" small>fas fa-phone</v-icon> </v-btn>
    {{ val }}
    <span v-if="value && false" style="cursor: pointer" class="ma-1" title="Позвонить" @click="$root.$emit('global-call', value)">
      <v-icon color="primary" small>fas fa-phone</v-icon>
    </span>
  </span>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },
  computed: {
    val() {
      if (this.value?.length == 12) {
        let a = this.value;
        return `${a[0]}${a[1]}(${a[2]}${a[3]}${a[4]}) ${a[5]}${a[6]}${a[7]}-${a[8]}${a[9]}-${a[10]}${a[11]}`;
      }
      return this.value;
    },
  },
  methods: {
    onItemCall(v) {
      window.open("tel:" + v, "_self");
    },
  },
};
</script>
