/** @format */

const routes = [
  {
    path: '/index',
    name: 'index',
    meta: { auth: 1, title: 'Главная' },
    component: () => {
      return import('@/views/index.vue');
    },
  },
  {
    path: '/rent',
    name: 'rent',
    meta: { auth: 2, title: 'Аренда' },
    component: () => {
      return import('@/views/rent.vue');
    },
  },
  {
    path: '/buy',
    name: 'buy',
    meta: { auth: 2, title: 'Продажа' },
    component: () => {
      return import('@/views/buy.vue');
    },
  },

  {
    path: '/offers',
    name: 'offers',
    meta: { auth: 2, title: 'Предложения' },
    component: () => {
      return import('@/views/offers.vue');
    },
  },
  {
    path: '/objects',
    name: 'objects',
    meta: { auth: 2, title: 'Избранное' },
    component: () => {
      return import('@/views/objects.vue');
    },
  },
  {
    path: '/objects/:id',
    name: 'objects_view',
    meta: { auth: 10000, title: 'Избранное' },
    component: () => {
      return import('@/views/views/objectView.vue');
    },
  },
  {
    path: '/my-offers',
    name: 'myOffers',
    meta: { auth: 1, title: 'Мои предложения' },
    component: () => {
      return import('@/views/myOffers.vue');
    },
  },

  {
    path: '/info',
    name: 'info',
    meta: { auth: 1, title: 'Инфо' },
    component: () => {
      return import('@/views/info.vue');
    },
  },
  {
    path: '/info-admin',
    name: 'info-admin',
    meta: { auth: 10, title: 'Инфо' },
    component: () => {
      return import('@/views/infoAdmin.vue');
    },
  },
  {
    path: '/apiConfig',
    name: 'apiConfig',
    meta: { auth: 10, title: 'API' },
    component: () => {
      return import('@/views/apiConfig.vue');
    },
  },

  {
    path: '/logs-admin',
    name: 'logs-admin',
    meta: { auth: 10, title: 'Logs' },
    component: () => {
      return import('@/views/logsAdmin.vue');
    },
  },
  {
    path: '/users',
    name: 'users',
    meta: { auth: 10, title: 'Пользователи' },
    component: () => {
      return import('@/views/users.vue');
    },
  },
  {
    path: '/billing',
    name: 'billing',
    meta: { auth: 1000, title: 'Billing' },
    component: () => {
      return import('@/views/billing.vue');
    },
  },
  {
    path: '/dirs',
    name: 'dirs',
    meta: { auth: 10, title: 'Справочники' },
    props: (route) => ({ dir: route.query.dir, ...route.params }),
    component: () => {
      return import('@/views/dirs.vue');
    },
  },
  {
    path: '/settings',
    name: 'settings',
    meta: { auth: 10, title: 'Настройки' },
    component: () => {
      return import('@/views/settings.vue');
    },
  },
];

export default {
  routes: routes,
};
