<template>
  <div>
    <v-menu bottom min-width="200px" rounded offset-y>
      <template v-slot:activator="{ on }">
        <span v-if="!$vuetify.breakpoint.xsOnly">
          {{ $root.profile.name }}
        </span>
        <v-btn icon large v-on="on">
          <v-avatar size="32">
            <v-img :src="getImage()"></v-img>
          </v-avatar>
        </v-btn>
      </template>
      <v-card>
        <v-list-item v-if="$vuetify.breakpoint.xsOnly">
          {{ $root.profile.name }}
        </v-list-item>
        <v-list-item link @click="hardReload">
          <v-list-item-icon>
            <i class="fas fa-sync-alt"></i>
          </v-list-item-icon>
          <v-list-item-title> Обновить </v-list-item-title>
        </v-list-item>
        <v-list-item link @click="$root.logout">
          <v-list-item-icon>
            <i class="fas fa-sign-out-alt"></i>
          </v-list-item-icon>
          <v-list-item-title>Выйти</v-list-item-title>
        </v-list-item>
      </v-card>
    </v-menu>
  </div>
</template>



<script>
export default {
  data() {
    return {};
  },
  created() {},
  methods: {
    hardReload() {
      window.location.reload(true);
    },
    getImage() {
      let im = this.$store.getters["auth/profile"].image;
      let path;

      if (im) {
        path =
          this.$root.config.baseUrl +
          "/image/download/" +
          im +
          "?width=50&height=50";
      } else {
        path = "/img/man.png";
      }
      return path;
    },
  },
};
</script>