/** @format */

import axios from '../../libs/axios';
const state = {
  keys: {},
  badges: { tasks: { comments: 0, items: 0 }, tasksgoods: { comments: 0, items: 0 }, finance: { comments: 0, items: 0 } },
  newComment: 0,
};

const getters = {
  getKeys: (state) => state.keys,
  getBadges: (state) => (name, type) => {
    return state.badges?.[name]?.[type];
  },
  getNewComment: (state, name) => {
    return state.newComment;
  },
};

const actions = {
  keys: async ({ state, commit, dispatch }, data) => {
    let keys = state.keys;
    for (const [key, value] of Object.entries(data)) {
      keys[key] = value;
    }
    commit('setKeys', keys);
  },
  async badges({ state, commit, dispatch }, data) {
    console.log('dispatch badges');
    let badges = state.badges;
    let resp;
    let params = {};
    params = { filters: { type: { condition: '<>', value: 'construction-goods' } } };
    if (data == 'task_comments' || !data)
      axios.get('/7perfect/tasks/count_unviewed_comments', { params }).then((resp) => {
        if (resp.data.status === 'ok') {
          badges.tasks.comments = resp.data.data;
        }
      });
    if (data == 'task_items' || !data)
      axios.get('/7perfect/tasks/count_unviewed', { params }).then((resp) => {
        if (resp.data.status === 'ok') {
          badges.tasks.items = resp.data.data;
        }
      });


    if (data == 'finance_items' || !data)
      axios.get('/7perfect/finance/count_unviewed').then((resp) => {
        if (resp.data.status === 'ok') {
          badges.finance.items = resp.data.data;
        }
      });

    if (data == 'task_comments') commit('setNewComments');
    commit('setBadges', badges);
  },
  newComment({ commit }, data) {
    commit('setNewComment');
  },
};

const mutations = {
  setKeys: (state, keys) => {
    console.log('state', keys);
    state.keys = keys;
  },
  setBadges: (state, badges) => {
    state.badges = badges;
  },
  setNewComment: (state) => {
    state.newComment++;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
