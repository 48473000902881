<template>
  <v-dialog v-model="dialog" width="500" @keydown.esc="dialog = false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="type == 'button'" color="primary" :disabled="disabled" class="px-2" :title="title" v-bind="attrs" v-on="on">
        <v-icon>{{ icon }}</v-icon>
        <btn-title v-if="$vuetify.breakpoint[`${size}AndUp`] && title && false">{{ title }}</btn-title>
      </v-btn>
      <v-icon v-else-if="type == 'icon'" v-bind="attrs" v-on="on">{{ icon }}</v-icon>
      <btn-title v-if="$vuetify.breakpoint[`${size}AndUp`] && title && false">{{ title }}</btn-title>
    </template>
    <v-card>
      <v-card-title class="lighten-2">
        <span> Профиль </span>
        <v-btn style="position: absolute; right: 12px; top: 12px" fab dark small color="blue-grey" title="Закрыть окно" @click="dialog = false"
          ><v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0">
            <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
              <div v-for="name in el.name.split('+')" :key="name">
                <a-form-model
                  :ref="'field_' + name"
                  v-model="data"
                  :model="getFormModel([name], model)"
                  :errors="errors"
                  :config="{ dense: true, readonly }"
                  @validate="validate($event)"
                />
              </div>
            </v-col>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn :loading="loading" @click.stop="_submit()">
          <btn-title v-if="$vuetify.breakpoint.smAndUp"> Сохранить</btn-title>
        </v-btn>
        <v-btn @click="dialog = false">Закрыть</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { getForm, submitForm, removeEl, genModel } from "@/components/mixings";
export default {
  mixins: [getForm, submitForm, removeEl, genModel],
  props: {
    size: { type: String, default: "sm" },
    disabled: { type: Boolean, default: false },
    icon: { type: String, default: "fas fa-cog" },
    type: { type: String, default: "button" },
    title: { type: String, default: "" },
  },
  data() {
    return {
      dialog: false,
      link: null,
      loading: false,
      m: this.$store.getters["config/get"].models.profile,
      data: {},
      readonly: false,
    };
  },
  created() {},
  watch: {
    dialog(v) {
      if (v) {
        this.fetchData();
      }
    },
  },
  computed: {
    url() {
      return this.m.url;
    },
    api() {
      return this.m.url;
    },
    model() {
      let res = this.calcModelConfig(this.m.config.default);

      return res;
    },
    config() {
      return { dense: true, readonly: false, hideDetails: "auto" };
    },
    configForm() {
      return this.m.config.default;
    },
  },
  methods: {
    fetchData() {
      this.data = Object.assign({}, this.$root.profile);
      console.log(this.$root.profile);
    },
    _submit() {
      if (this.readonly) return;
      const fields = this.m.config.default.fieldsPost || "";
      this.submit(false, fields);
    },
    afterSave() {
      this.$store.dispatch("auth/updateProfile");
    },
  },
};
</script>
