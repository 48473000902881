<template>
  <div>
    <v-badge :value="badge" :content="badge" inline color="green">
      <div :style="background + style" v-html="getTextByValue(value, model)"></div>
    </v-badge>
  </div>
</template>

<script>
import { modelOptions } from "./../../../mixings";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    values: [Object],
    model: {
      type: Object,
      default: {},
    },
  },
  computed: {
    badge() {
      if (this.model.hasOwnProperty("badgeField") && this.model?.["badgeField"]) return this.values[this.model.badgeField];
      return false;
    },
    background() {
      let value = this.value;
      if (this.model?.aliace) {
        value = this.values?.[this.model.aliace] || this.value;
      }
      let text = this.getElementByValue(value, this.model);
      return text?.bgColor ? "background-color:" + text.bgColor : "";
    },
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },
  },
};
</script>
