/** @format */

const info = {
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'group', title: 'Раздел', type: 'string', sortable: true },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 450 },
  ],
  form: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
    { name: 'data.group', title: 'Раздел', type: 'string' },
    { name: 'data.role', title: 'Доступ для ролей', type: 'string' },
    { name: 'data.section', title: 'Назначение', type: 'string' },
    { name: 'content', title: 'Содержимое', type: 'editor', validator: ['req'] },
    { name: 'content', title: 'Содержимое', type: 'text', validator: ['req'] },
  ],
};
export default info;
