<template>
  <v-dialog :value="value" @input="$emit('input')" persistent scrollable @keydown.esc="$emit('input')" open-on-focus>
    <v-card style="position: absolute; top: 10px; left: 10px; right: 10px; bottom: 10px; width: auto; margin: auto" height="auto" :style="{ maxWidth: maxWidth }">
      <v-card-title
        v-if="$slots.title"
        style="position: absolute; top: 0; left: 0; right: 0; border-bottom: 1px solid #ddd; display: block; white-space: nowrap; text-overflow: clip"
      >
        <span style="overflow: hidden; padding-right: 36px; text-overflow: ellipsis; display: block"><slot name="title"></slot> </span>
        <v-btn style="position: absolute; right: 12px; top: 12px" fab dark small color="blue-grey" title="Закрыть окно" @click="$emit('input')">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text
        :class="{
          'py-1 px-3': $vuetify.breakpoint.smAndDown,
          'py-1 px-6': !$vuetify.breakpoint.smAndDown,
        }"
        :style="`position: absolute; top: ${$slots.title ? '67px' : '0'}; bottom: 52px; left: 0; right: 0; overflow: auto; overflow-x: hidden`"
      >
        <div class="text-center">
          <slot />
        </div>
      </v-card-text>

      <v-card-actions style="position: absolute; bottom: 0; left: 0; right: 0; border-top: 1px solid #ddd; overflow: auto">
        <slot name="actions">
          <v-spacer></v-spacer>
          <v-btn @click="$emit('input')">Закрыть</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: "800px",
    },
  },
};
</script>
