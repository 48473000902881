<template>
  <v-dialog :value="value" @input="$emit('input')" persistent scrollable @keydown.esc="$emit('input')" open-on-focus :width="maxWidth">
    <v-card height="auto">
      <v-card-title v-if="$slots.title">
        <slot name="title"></slot>
      </v-card-title>
      <v-card-text
        :class="{
          'py-1 px-3': $vuetify.breakpoint.smAndDown,
          'py-1 px-6': !$vuetify.breakpoint.smAndDown,
        }"
      >
        <div class="text-center">
          <slot />
        </div>
      </v-card-text>

      <v-card-actions>
        <slot name="actions">
          <v-spacer></v-spacer>
          <v-btn @click="$emit('input')">Закрыть</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    maxWidth: {
      type: String,
      default: "800px",
    },
  },
};
</script>
