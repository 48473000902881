<template>
  <div style="position: relative">
    <v-textarea
      v-if="!isDiv"
      outlined
      :value="value"
      :label="model.title"
      :error-messages="error"
      :hide-details="config.hideDetails || false"
      :readonly="readonly"
      :disabled="disabled"
      :rows="model.rows || 5"
      @input="$emit('input', $event)"
    ></v-textarea>
    <div v-else>{{ value }}</div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: Object,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  computed: {
    isDiv() {
      return this.model?.div || false;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    rating: {
      get() {
        let res = this.values.data?.rating?.[this.model.name] || "";
        return res;
      },
      set(v) {
        this.$emit("rating", { name: this.model.name, value: v });
      },
    },
  },
};
</script>
