<template>
  <div>
    <v-text-field
      outlined
      :value="value"
      :label="model.title"
      :error-messages="error"
      :disabled="disabled"
      :hide-details="config.hideDetails || false"
      :dense="config.dense || false"
      :readonly="readonly"
      @wheel.prevent="$event.target.blur()"
      @input="$emit(`input`, $event)"
      @keypress="filter($event)"
      :class="model.class || config.class"
    ></v-text-field>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    isDisable() {
      return this.model?.disabled || this.disabled;
    },
  },
  methods: {
    filter(e) {
      let regex = new RegExp("^[0-9]+$");
      var key = String.fromCharCode(!e.charCode ? e.which : e.charCode);
      if (!regex.test(key)) {
        e.preventDefault();
        return false;
      }
    },
  },
};
</script>
