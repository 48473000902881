<template>
  <v-menu offset-y v-if="statusItems.length">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-if="fab" color="primary" v-bind="attrs" v-on="on" :disabled="disabled" fab x-small>
        <v-icon>{{ icon }}</v-icon>
      </v-btn>
      <v-btn v-else color="primary" v-bind="attrs" v-on="on" :disabled="disabled">
        <v-icon>{{ icon }}</v-icon>
        <v-icon></v-icon>
        <btn-title>{{ title }}</btn-title>
      </v-btn>
    </template>
    <v-list>
      <v-list-item v-for="(item, index) in statusItems" :key="index" link @click="$emit('click', item)" :disabled="item.disabled">
        <v-list-item-title>{{ item.action }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],
  props: {
    title: { type: String, default: "Статус" },
    disabled: { type: Boolean, default: false },
    items: { type: Array, default: [] },
    icon: { type: String, default: "mdi-debug-step-over" },
    status: Number,
    fab: { type: Boolean, default: false },
  },
  computed: {
    statusCurrent() {
      if (this.status === undefined) return {};
      let statusCur = this.items.find((el) => el.value == this.status);
      if (!statusCur) {
        statusCur = {};
      }
      return JSON.parse(JSON.stringify(statusCur));
    },
    statusItems() {
      if (this.status === undefined) {
        let res = [...this.items];
        return res; 
      }
      let items = [];
      let res = [];
      const statuses = this.items;
      const statusCur = this.statusCurrent;
      if (statusCur && statusCur.next && statuses) {
        let arr = statusCur.next;
        res = statuses.filter((s) => {
          return arr.includes(s.value);
        });
      }
      items = JSON.parse(JSON.stringify(res));
      return items;
    },
  },
};
</script>
