/** @format */

const offer = {
  title1: 'Предложение',
  url: '/unrealty/offer',
  list: [
    { name: 'offer_time', title: 'Время', type: 'string', sortable: false, width: 50, class: '  title' },
    { name: 'parser_type', title: ' ', type: 'string', sortable: false, width: 50, class: '  title', hidden: true },
    { name: 'vendor_id', title: '#', type: 'id', sortable: false, width: 50, class: 'title', hidden: true },
    { name: 'icon', title: ' ', sortable: false, width: 50, class: '  title', hidden1: true },
    { name: 'title', title: 'Наименование', type: 'string', sortable: false, width: 100, class: '  title' },
    { name: 'price', title: 'Цена', type: 'string', sortable: false, width: 50, class: 'title' },
    { name: 'addr', title: 'Адрес', type: 'string', sortable: false, width: 200, class: '  title' },
    { name: 'phone', title: 'Телефон', sortable: false, width: 100, class: '  title' },
    { name: 'actions', title: 'Звонок', sortable: false, width: 155, class: '  title' },
  ],
  list2: [
    { name: 'parsedon', title: 'Парсинг', type: 'datetime', width: 120 },
    { name: 'offer_time', title: 'Время', type: 'string', sortable: false, width: 50 },
    { name: 'vendor', title: 'Источник', type: 'string' },
    { name: 'vendor_id', title: '#', type: 'id', sortable: false, width: 150 },
    { name: 'icon', title: ' ', sortable: false, width: 50, hidden: true },
    { name: 'total_floor', title: 'Всего этажей', type: 'number' },
    { name: 'total_area', title: 'Площадь', type: 'number' },
    { name: 'title', title: 'Наименование', type: 'string', sortable: false, width: 100 },
    { name: 'price', title: 'Цена', type: 'string', sortable: false, width: 50 },
    { name: 'addr', title: 'Адрес', type: 'string', sortable: false, width: 200 },
    { name: 'phone', title: 'Телефон', sortable: false, width: 100 },
    { name: 'actions', title: 'Действия', sortable: false, width: 155, hidden: true },
  ],
  form: [
    { name: 'title', title: 'Наименование', type: 'string', validator: ['req'] },
    { name: 'vendor', title: 'Источник', type: 'string', validator: ['req'] },
    { name: 'vendor_id', title: 'id объявления', type: 'string' },
    { name: 'rooms', title: 'Комнат', type: 'number' },
    { name: 'total_area', title: 'Площадь', type: 'number' },
    { name: 'floor', title: 'Этаж', type: 'number' },
    { name: 'floor2', title: 'Этаж', type: 'string', formula: '((d)=>{return `${d?.floor} / ${d?.total_floor}`;})', calculated: true },
    { name: 'total_floor', title: 'Всего этажей', type: 'number' },
    { name: 'price', title: 'Цена', type: 'number' },
    { name: 'price2', title: 'Цена', type: 'string', formula: '((d)=>{return (d?.price || 0).toLocaleString() + " руб";})', calculated: true },
    { name: 'city', title: 'Город', type: 'string' },
    { name: 'addr', title: 'Адрес', type: 'string' },
    { name: 'seller_name', title: 'Имя', type: 'string' },
    { name: 'phone', title: 'Телефон', type: 'phone' },
    { name: 'phone2', title: 'Телефон собственника', type: 'string' },
    { name: 'whatsapp', title: 'WhatsApp', type: 'string' },
    { name: 'seller_type', title: 'Тип контакта', type: 'select', options: ['собственник', 'представитель'] },
    { name: 'seller_want', title: 'Рассматривает', type: 'select', options: ['пара', 'с детьми', 'с животными'], chips: true },
    { name: 'description', title: 'Описание', type: 'text', rows: 3, readonly: true, div: true },
    { name: 'ads_info', title: 'Данные объявления', type: 'adsInfo' },
    { name: 'images', title: 'Фото без водяных знаков', type: 'images' },
    { name: 'url', title: 'Источник', type: 'link', icon: true },
    { name: 'data.note', title: 'Заметки', type: 'text', rows: 3 },
  ],
  viewForm1: {
    fields: 'url,rooms1,total_area1,floor2,price2,addr,phone',
  },
  viewForm2: {
    fields: 'seller_name,phone2,whatsapp,data.note',
  },
  detail1: {
    fields: 'description,ads_info',
  },
  config: {
    default: {
      fields:
        'vendor,vendor_id,rooms,total_area,floor,floor2,total_floor,price,price2,addr,seller_name,phone,phone2,whatsapp,seller_type,seller_want,description,ads_info,images',
      fieldsRO: 'vendor,vendor_id,rooms,total_area,floor,total_floor,price,addr,images,description',
      fieldsForm: 'vendor,vendor_id,rooms,total_area,floor,total_floor,price,addr,description',
    },
  },
};
export default offer;
