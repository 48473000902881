<template>
  <v-switch class="mt-0" v-model="v" inset :disabled="disabled" :readonly="config.readonly || false" :label="model.title" color="blue" />
</template>

<script>
export default {
  props: {
    value: [String, Number],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    config: { type: Object, default: {} },
  },
  computed: {
    v: {
      get() {
        return this.value == 1 ? true : false;
      },
      set(v) {
        return this.$emit("input", v ? 1 : 0);
      },
    },
  },
};
</script>
