<template>
  <span :style="style" >
    <span v-if="model.preText && value">
      {{ model.preText }}
    </span>
    {{ text || value }}
  </span>
</template>

<script>
import { modelOptions } from "@/components/mixings";

export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    values: [Object],
    model: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      data: [],
      text: null,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },
  },
  watch: {
    value(v) {
      if (v) this.fetchData();
      else this.text = "";
    },
  },
  methods: {
    async fetchData() {
      if (!this.value) {
        return;
      }
      let t = this.model?.itemField?.text || "text";
      let v = this.model?.itemField?.value || "value";
      let filters = {};
      if (this.model?.itemField) {
        filters = { [v]: this.value };
      }

      this.loading = true;
      let response = await this.$axios.get("/" + this.model.api, { params: { filters } });
      this.loading = false;
      this.data = response.data.data;
      this.text = this.data[0]?.[t];
    },
  },
};
</script>
