<template>
  <v-navigation-drawer v-model="$root.leftMenuDrawer" :right="$vuetify.rtl" mobile-break-point="960" app width="260" v-bind="$attrs">
    <v-divider class="mb-1" />
    <v-list>
      <v-list-item class="px-2 d-flex align-center justify-center">
        <v-list-item-avatar size="140">
          <v-img src="/img/icons/logo_home.png"></v-img>
        </v-list-item-avatar>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-h6 text-center"> {{ $root.profile.name }} </v-list-item-title>
        </v-list-item-content>
        <edit-Profile type="icon" />
      </v-list-item>
    </v-list>

    <v-divider></v-divider>

    <list-main-menu :value="mainMenu" :role="$root.profile.role" class="pl-3" />
    <template v-slot:append>
      <v-list-item link @click="$root.logout">
        <v-list-item-icon>
          <i class="fas fa-sign-out-alt"></i>
        </v-list-item-icon>
        <v-list-item-title>Выйти</v-list-item-title>
      </v-list-item>
    </template>
  </v-navigation-drawer>
</template>

<script>
export default {
  data() {
    return {
      mainMenu: this.$root.config.mainMenu,
      showProfileDialog: false,
    };
  },
  created() {},
  methods: {
    getImage() {
      let im = this.$store.getters["auth/profile"].image;
      let path;

      if (im) {
        path = this.$root.config.baseUrl + "/image/download/" + im + "?width=50&height=50";
      } else {
        path = "/img/man.png";
      }
      return path;
    },
  },
};
</script>
<style scoped>
.v-navigation-drawer {
  background-color: rgb(255, 255, 255) !important;
}
.v-navigation-drawer {
  background-color: lightgray; /* Измените цвет фона */
}
</style>
