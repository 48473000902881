<template>
  <v-list nav dense>
    <template v-for="(el, i) in value">
      <template v-if="el.auth <= role || isVisible(el)">
        <v-list-group v-if="el.sublist" :key="i" :value="false" :prepend-icon="el.icon">
          <template v-slot:activator>
            <v-list-item-title style="font-size: 1rem; font-weight: 300">
              {{ $t(el.name) }}
            </v-list-item-title>
          </template>

          <list-main-menu-item v-if="e.auth <= role || isVisible(e)" v-for="(e, i) in el.sublist" :key="i" :value="e" :sublist="true" />
        </v-list-group>
        <list-main-menu-add-menu v-else-if="el.menu" :el="el" :key="i" />
        <list-main-menu-item v-else :key="i" :value="el" />
      </template>
    </template>
  </v-list>
</template>

<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],

  props: {
    value: Array,
    role: {
      type: [Number, String],
      default: 0,
    },
  },
  computed: {},

  methods: {
    isVisible(el) {
      return this.getAccess("menu." + (el?.route?.name || el?.accessName || ""));
    },
  },
};
</script>

<style scoped>
.v-list-item--active {
  background-color: rgb(121, 181, 219) !important; /* Измените цвет фона для активных элементов */
}
.v-list-item:hover {
  background-color: #6496b5 !important; /* Измените цвет фона для активных элементов */
}
.v-application .primary--text {
  color: #181a1a !important;
  caret-color: #181a1a !important;
}
</style>
