<template>
  <v-dialog :value="value" @input="$emit('input')" fullscreen refs="carousel">
    <v-card style="background-color: #222" flat tile class="d-flex justify-center">
      <v-carousel hide-delimiters v-model="current" height="100vh">
        <v-btn style="position: absolute; right: 30px; top: 30px; z-index: 100" fab dark small color="blue-grey" title="Закрыть окно" @click="doClose()">
          <v-icon dark> mdi-close </v-icon>
        </v-btn>

        <v-carousel-item v-for="(item, i) of items" :key="i">
          <v-img contain :src="getSrc(item) + '?height=1080'" height="100%" />
        </v-carousel-item>
      </v-carousel>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    value: Boolean,
    curr: { type: Number, default: 0 },
    items: Array,
    src: String,
  },
  data() {
    return { current: 0, fullscreen: true };
  },
  watch: {
    value(v) {
      if (v) {
        this.current = this.curr;
      }
    },
  },
  created() {
    this.current = this.curr;
  },
  computed: {},
  methods: {
    getSrc(n) {
      if (n.includes("http")) return n;
      return this.$root.config.imageUrl + n;
    },
    doClose() {
      if (!this.fullscreen) {
        this.toggle();
      }
      this.$emit("input");
    },
    async toggle() {
      await this.$fullscreen.toggle();
      this.fullscreen = this.$fullscreen.isFullscreen;
    },
  },
};
</script>
<style lang="scss" scope></style>
