<template>
  <div class="f-table-col" :style="{ width: width + 'px' }">
    <div v-if="filter.searchable === false"></div>
    <a-input-table-search-string
      v-else-if="['id', 'chipId', 'string', 'ref-table', 'login', 'number', 'phones', 'text', 'sparams'].includes(filter.type)"
      :value="values[filter.name]"
      @change="onInputElement"
    />
    <a-input-table-search-selectbit v-else-if="['selectbit'].includes(filter.type)" :value="val" :model="filter" @change="onInputElement" />
    <a-input-table-search-select2 v-else-if="['select', 'selectvar'].includes(filter.type)" :value="values[filter.name]" :model="filter" @change="onInputElement" />
    <a-input-table-search-select v-else-if="['select'].includes(filter.type)" :value="values[filter.name]" :model="filter" @change="onInputElement" />
    <a-input-table-search-selectvar v-else-if="['selectvar'].includes(filter.type)" :value="values[filter.name]" :model="filter" @change="onInputElement" />
    <a-input-table-search-selectapi v-else-if="filter.type == 'selectapi'" :value="values[filter.name]" :model="filter" @change="onInputElement" />
    <a-input-table-search-selectsql v-else-if="filter.type == 'selectsql'" :value="values[filter.name]" :model="filter" @change="onInputElement" />
    <a-input-table-search-datetime v-else-if="['date', 'datetime', 'dateColor'].includes(filter.type)" :value="values[filter.name]" @input="onInputElement" />
    <slot></slot>
  </div>
</template>

<script>
export default {
  props: {
    color: String,
    model: Object,
    values: Object,
    width: [Number, String],
  },
  data() {
    return {
      val: null,
    };
  },
  computed: {
    value() {
      return this.values[this.model.name];
    },
    filter() {
      if (this.model.filter) return this.model.filter;
      return this.model;
    },
  },
  methods: {
    // onInputElement: lodash.debounce(function (event) {
    //   this.values[this.model.name] = event;
    //   this.$emit("input", this.values);
    // }, 500),
    onInputElement(event) {
      let e;
      if (typeof event === "string") {
        e = event.split("##");
        event = e[0];
      }
      let field = e?.[1] || this.filter.name;
      if (this.model.fieldFilter) {
        //очищаем все поля в мультифильтре
        this.model.fieldFilter.forEach((el) => {
          if (this.values?.[el]) this.values[el] = "";
        });
      }
      if (["string", "login", "phones", "text"].includes(this.filter.type)) {
        let r = "";
        if (event) {
          r = { condition: "LIKE", value: "%" + event + "%" };
        }
        this.values[field] = r;
      } else if (["date", "datetime", "dateColor"].includes(this.filter.type)) {
        let d = event.split(" - ");
        let r = "";
        if (d[0] && d[1]) {
          r = [
            { condition: ">=", value: d[0] + " 00:00:00" },
            { condition: "<=", value: d[1] + " 23:59:59" },
          ];
        }
        this.values[field] = r;
      } else if (["selectbit"].includes(this.filter.type)) {
        let r = "";
        console.log("serache set ", event);
        if (event) {
          r = { condition: `&${1 << (event - 1)}=`, value: 1 << (event - 1) };
        }
        this.values[field] = r;
        this.val = event;
      } else {
        if (event.includes(",")) this.values[field] = event.split(",");
        else this.values[field] = event;
      }
      //console.log("res", { event, field, val: this.values });
      this.$emit("input", this.values);
    },
    getOptions(field) {
      if (field.options) {
        return field.options;
      } else if (field.data && field.data.select && field.data.select.storage) {
        return this.options[field.data.select.storage];
      }
    },
  },
};
</script>
