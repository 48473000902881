/** @format */

const myOffer = {
  title: 'Предложение клиенту',
  url: '/unrealty/my-offer',
  api: '/unrealty/my-offer',
  statuses: [
    { value: 1, text: 'В работе', next: [2], action: 'Принять в работу' },
    { value: 2, text: 'Сделка', next: [], action: 'Совершить сделку' },
  ],
  list: [
    { name: 'createdon', title: 'Cоздано', type: 'datetime', sortable: false, width: 80, class1: 'h4' },
    { name: 'createdby_id', title: 'Пользователь', type: 'select', dir: 'user', sortable: true, width: 150, searchable: false, hidden: true },
    { name: 'title', title: 'Наименование', type: 'string', sortable: false, width: 100, class1: '  title' },
    { name: 'price', title: 'Цена', type: 'number', sortable: false, width: 50, class1: 'title' },
    { name: 'commission', title: 'Комиссия', type: 'number', sortable: false, width: 50, class1: 'title' },
    { name: 'addr', title: 'Адрес', type: 'string', sortable: false, width: 200, class1: '  title' },
    { name: 'client_name', title: 'Клиент', type: 'string', sortable: false, width: 200, class1: '  title' },
    { name: 'client_phone', title: 'Телефон', sortable: false, width: 100, class1: '  title' },
  ],

  form: [
    { name: 'title', title: 'Наименование', type: 'string', validator: ['req'] },
    { name: 'price', title: 'Цена', type: 'number' },
    { name: 'commission', title: 'Комиссия', type: 'string' },
    { name: 'addr', title: 'Адрес', type: 'string' },
    { name: 'client_name', title: 'Имя клиента', type: 'string', validator: ['req'] },
    { name: 'client_phone', title: 'Телефон клиента', type: 'phone' },
    { name: 'description', title: 'Описание', type: 'ckeditor5', rows: 3 },
    { name: 'data.images', title: 'Фото без водяных знаков', type: 'images' },
  ],
  viewForm2: {
    fields: 'client_name,client_phone,addr,price,commission',
  },
  detail1: {
    fields: 'description',
  },
  config: {
    create: {
      fields: 'title,price,commission,addr,client_name,client_phone,description',
      fieldsRO: '',
      fieldsPost: '',
      fieldsForm: 'client_name,client_phone,title,price,commission,addr,description',
    },
    default: {
      fields: 'title,price,commission,addr,client_name,client_phone,description,data.images',
      fieldsRO: '',
      fieldsPost: 'id,client_name,client_phone,commission,price,description,addr,data',
      fieldsForm: 'vendor,vendor_id,rooms,total_area,floor,total_floor,price,addr,description,',
    },
  },
  offer: {
    form: [
      { name: 'title', title: 'Наименование', type: 'string', validator: ['req'] },
      { name: 'vendor', title: 'Источник', type: 'string', validator: ['req'] },
      { name: 'vendor_id', title: 'id объявления', type: 'string' },
      { name: 'rooms', title: 'Комнат', type: 'number' },
      { name: 'total_area', title: 'Площадь', type: 'number' },
      { name: 'floor', title: 'Этаж', type: 'number' },
      { name: 'floor2', title: 'Этаж', type: 'string', formula: '((d)=>{return `${d?.floor} / ${d?.total_floor}`;})', calculated: true },
      { name: 'total_floor', title: 'Всего этажей', type: 'number' },
      { name: 'price', title: 'Цена', type: 'number' },
      { name: 'price2', title: 'Цена', type: 'string', formula: '((d)=>{return (d?.price || 0).toLocaleString() + " руб";})', calculated: true },
      { name: 'city', title: 'Город', type: 'string' },
      { name: 'addr', title: 'Адрес', type: 'string' },
      { name: 'seller_name', title: 'Имя', type: 'string' },
      { name: 'phone', title: 'Телефон', type: 'phone' },
      { name: 'phone2', title: 'Телефон собственника', type: 'string' },
      { name: 'whatsapp', title: 'WhatsApp', type: 'string' },
      { name: 'seller_type', title: 'Тип контакта', type: 'select', options: ['собственник', 'представитель'] },
      { name: 'seller_want', title: 'Рассматривает', type: 'select', options: ['пара', 'с детьми', 'с животными'], chips: true },
      { name: 'description', title: 'Описание', type: 'text', rows: 3, readonly: true, div: true },
      { name: 'ads_info', title: 'Данные объявления', type: 'adsInfo' },
      { name: 'images', title: 'Фото без водяных знаков', type: 'images' },
      { name: 'url', title: 'Источник', type: 'link', icon: true },
      { name: 'data.note', title: 'Заметки', type: 'ckeditor5', rows: 3 },
    ],
    viewForm1: {
      fields: 'url,rooms1,total_area1,floor2,price2,addr,phone',
    },
    detail1: {
      fields: 'data.note1,ads_info',
    },
    config: {
      default: {
        fields:
          'data.note,vendor,vendor_id,rooms,total_area,floor,floor2,total_floor,price,price2,addr,seller_name,phone,phone2,whatsapp,seller_type,seller_want,description,ads_info,images',
        fieldsRO: 'vendor,vendor_id,rooms,total_area,floor,total_floor,price,addr,images,description',
        fieldsForm: 'vendor,vendor_id,rooms,total_area,floor,total_floor,price,addr,description',
      },
    },
  },
};
export default myOffer;
/* 
console.log(
  window._cianConfig['mobile-search-frontend'].find((e) => {
    return e.key == 'mobile-search-frontend.card.entry.initialState';
  })
);
 */
