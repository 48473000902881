<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ title }}
      </template>
      <a-loader v-if="!loaded" />
      <v-row v-else class="mt-1 pt-2" style="overflow-y: auto; overflow-x: hidden">
        <v-col v-for="(el, i) in modelForm" :key="i" cols="12" :sm="el.size" class="py-0">
          <div v-for="name in el.name.split('+')" :key="name">
            <a-form-model
              :ref="'field_' + name"
              v-model="data"
              :model="getFormModel([name], model)"
              :errors="errors"
              :config="{ dense: true, readonly }"
              @validate="
                validate($event);
                doWatch($event);
              "
              @fieldData="setFieldData($event)"
            />
          </div>
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save :disabled="readonly" @click="submit()"></a-btn-save>
        <a-btn-delete v-if="canDelete && id" :disabled="readonly" @click="removeDialogShow = true"></a-btn-delete>
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
    <remove-dialog v-model="removeDialogShow" @remove="_remove(api, id)" :title="removeTitle" />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    m: Object,
    title: String,
    config: Object,
    showData: Object,
    hasData: { type: Boolean, default: false },
    initData: { type: Object, default: () => {} },
    opt: { type: String, default: "" },
  },
  data() {
    return {
      model: [],
      idEdit: 0,
      suEditShow: false,
      removeDialogShow: false,
      type: null,
      loaded: false,
      options: [],
      watchModel: null,
      fieldData: {},
    };
  },
  computed: {
    editObject___del() {
      return false;
    },
    canDelete() {
      return this.opt.includes("delete");
    },
    readonly() {
      return this.opt.includes("readonly");
    },
    removeTitle() {
      return this.m?.removeTitle || "Вы действительно хотите удалить это?";
    },
  },
  created() {
    this.loaded = false;
    this.updateData(this.id);
    if (!this.id) {
      this.loaded = true; ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  watch: {},
  methods: {
    updateData(id) {
      this.resetFrom();
      this.fillForm();
      this.data = Object.assign({}, this.showData);
      this.getModel();
      this.loaded = true; ///  this.type = 1;
    },

    getModel(t) {
      let config = this.config;
      if (t) config = JSON.parse(JSON.stringify(this.m?.config?.[t] || this.config));
      if (this.data.status == 2) config.fieldsRO = config.fields;
      this.watchModel = config?.watch || null;
      let model = this.calcModelConfig(config);
      if (true)
        model.forEach(el => {
          if (el.hiddenCreate && !this.data?.id) {
            el.hidden = true;
          }
        });
      this.fillFormFromModel(model);
      this.model = model;
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      let id = data.id;
      if (!this.id && this.m?.routeName) this.$router.push({ name: this.m.routeName, params: { id } });
    },
    doWatch(e) {
      if (e == this.m?.config?.watch) this.getModel(this.data?.[e]);
      console.log("doWatch", e, this.config, this.model);
    },
    async _remove(api, id) {
      if (!api || !id) return;
      await this.remove(api, id);
      this.$emit("input");
    },
  },
};
</script>
