<template>
  <v-menu v-model="show" :position-x="x" :position-y="y" absolute offset-y>
    <v-list v-if="items" class="py-0">
      <v-list-item
        v-for="(item, index) in items" 
        :key="index"
        dense
        @click="onClick(item)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>  

<script>
export default {
  props: {
    value: Boolean,
    position: {
      type: Object,
      default: () => {
        return {};
      },
    },
    name: String,
    items: Array,
  },
  data: function () {
    return {};
  },

  created() {},
  watch: {
    value(v) {
      if (v) {
      }
    },
  },
  computed: {
    x() {
      return this.position?.x || 0;
    },
    y() {
      return this.position?.y || 0;
    },
    show: {
      get() {
        return this.value;
      },
      set(v) {
        
        this.$emit("input");
      },
    },
  },
  methods: {
    onClick(item) {
      if (item.action) item.action();
    },
  },
};
</script>