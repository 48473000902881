<template>
  <v-autocomplete
    outlined
    :clearable="!readonly"
    :disabled="disabled"
    :items="data"
    :value="value"
    :label="model.title"
    :error-messages="error"
    :hide-details="config.hideDetails || false"
    :dense="config.dense || false"
    :readonly="readonly"
    item-text="text"
    item-value="user_id"
    @input="onInput($event)"
    :small-chips="model.chips || false"
    :multiple="model.chips || false"
    :deletable-chips="model.chips && !readonly"
    :hide-selected="model.chips || false"
    :return-object="model.chips || false"
  >
    <template v-slot:selection="data" v-if="model.chips || false">
      <v-chip v-bind="data.attrs" :input-value="data.selected" :close="config.readonly != true" small @click="data.select" @click:close="remove(data.item)" class="mt-1">
        {{ data.item.text }}
      </v-chip>
    </template>
  </v-autocomplete>
</template>

<script>
export default {
  // mixins: [modelOptions],
  props: {
    value: [Array, Number, Object],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
      default: "",
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      data: [],
      roles: this.$store.getters["config/get"].models.users.roles,
    };
  },
  created() {
    this.fetchData();
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    api() {
      return "7perfect/tasks/users/select";
    },
  },
  methods: {
    remove(item) {
      if (this.readonly) return;
      const index = this.value.findIndex((el) => {
        return el.user_id == item.user_id;
      });
      if (index >= 0) this.value.splice(index, 1);
    },
    onInput(e) {
      this.$emit("input", e);
    },
    async fetchData() {
      this.loading = true;
      let params = {};
      params.sort = { key: "name", order: "ASC" };
      let response = await this.$axios.get(this.api, { params });
      this.loading = false;
      //console.log("users", response.data);
      let data = [];
      for (let el of response.data.data) {
        data.push({
          user_id: el.value,
          text:
            el.text +
            "(" +
            this.roles.find((r) => {
              return r.value == el?.role;
            })?.text +
            ")",
        });
      }
      //response.data.data
      this.data = data;
    },
  },
};
</script>
<style lang="scss"></style>
