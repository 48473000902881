/** @format */

const dirs = {
  list: [
    { value: 2, text: 'Владелец канала', api: '/main/channel_owner', name: 'channel_owner', role: [1000] },
    { value: 1, text: 'Каналы', api: '/main/channel', name: 'channel', role: [1000] },
  ],
  channelList: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
    { name: 'channel_owner_id', title: 'Владелец канала', type: 'selectapi', api: 'main/channel_owner/select', validator: ['req'] },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 300 },
  ],
  channelForm: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
    { name: 'href', title: 'Ссылка', type: 'string', validator: ['req'] },
    { name: 'channel_owner_id', title: 'Владелец канала', type: 'selectapi', api: 'main/channel_owner/select', validator: ['req'] },
    { name: 'comments', title: 'Комментарий', type: 'text' },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 1, validator: ['req'] },
  ],
  channel_ownerList: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
    { name: 'name', title: 'Наименование', type: 'string', sortable: true, width: 300 },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', sortable: true, width: 300 },
  ],
  channel_ownerForm: [
    { name: 'name', title: 'Наименование', type: 'string', validator: ['req'] },
    { name: 'comments', title: 'Комментарий', type: 'text' },
    { name: 'status', title: 'Статус', type: 'select', options: 'status_active', default: 1, validator: ['req'] },
  ],
};
export default dirs;
