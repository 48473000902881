<template>
  <v-btn color="error" @click="$emit('click')" :disabled="disabled" class="px-2">
    <v-icon>fas fa-trash-alt</v-icon>
    <btn-title v-if="$vuetify.breakpoint.smAndUp && title">{{ title }}</btn-title>
  </v-btn>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Удалить" },
    disabled: { type: Boolean, default: false },
  },
};
</script>
