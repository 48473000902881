<template>
  <div>
    <v-menu offset-y>
      <template v-slot:activator="{ on, attrs }">
        <v-fab-transition>
          <v-btn v-bind="attrs" v-on="on" fab fixed1 absolute top1 x-small style1="top: -5px; right: -5px; background-color: rgba(129, 129, 129, 0.4)">
            {{ value }}
          </v-btn>
        </v-fab-transition>
        <!--  <v-btn color="primary" v-bind="attrs" v-on="on" class="pa-0" fab small>
        {{ value }}
      </v-btn> -->
      </template>

      <v-card width="110" style="overflow: hidden">
        <v-icon large v-for="i in items" :key="i" :color="'purple darken-4'" v-text="`mdi-numeric-${i}-box`" @click="$emit('input', i)"></v-icon>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  props: {
    value: String | Number,
    icon: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      items: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    };
  },
};
</script>
