<template>
  <div>
    <v-simple-table :dense="config.dense || false">
      <tbody>
        <tr v-for="(el, i) in data" :key="i">
          <td v-for="(f, ii) in model" :key="ii" class="text-left px-1" style="height: 38px; min-width: 30%">
            <span>{{ el[f.name] }}</span>
          </td>
        </tr>
      </tbody>
    </v-simple-table>
  </div>
</template>

<script>
export default {
  props: {
    value: [Object, Array],
    model: Array,
    config: {
      type: Object,
      default: { dense: false, hideDetails: false, hideNull: false },
    },
    cols: { type: Number, default: 1 },
  },
  data() {
    return {
      m: [],
      model1: [
        { name: "createdby_id", title: "Автор", type: "select", dir: "user" },
        { name: "createdon", title: "создан", type: "datetime" },
        { name: "updatedby_id", title: "Изменил", type: "select", dir: "user" },
        { name: "updatedon", title: "изменен", type: "datetime" },
      ],
    };
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
    },
  },
  created() {},
  watch: {},
  methods: {
    field(name) {
      return this.model.find((el) => el.name == name) || {};
    },
  },
};
</script>
