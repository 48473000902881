<template>
  <div class="py-1 mb-4">
    <v-card-text>
      <v-row style1="max-height: 400px; overflow: auto" @drop="dropItem" @dragover="(e) => e.preventDefault()">
        <v-card
          v-for="(el, key) in images"
          :key="key"
          :height="size"
          :width="size"
          class="ma-2"
          @dragover="(e) => onDragOver(el, key, e)"
          @dragend="(e) => finishDrag(el, key, e)"
          @dragstart="(e) => startDrag(el, key, e)"
          @dragleave="onDragLeave(el, key, $event)"
          :class="{ over: el === over.item && el !== dragFrom, [over.dir]: el === over.item && el !== dragFrom }"
        >
          <a :href="$root.config.imageUrl + el.split('#')[0]" target="_blank">
            <v-img :src="$root.config.imageUrl + el.split('#')[0] + '?width=' + size + '&height=' + size" />
            <div class="d-flex" style="position: absolute; right: 8px; top: 8px">
              <v-btn v-if="!readonly && actions.includes('delete')" class="mx-1" fab dark x-small color="error" title="Удалить" @click.prevent="remove(key)">
                <v-icon dark> mdi-close </v-icon>
              </v-btn>
              <v-btn v-if="!readonly && actions.includes('hide')" class="mx-1" fab dark x-small color="info" title="Скрыть" @click.prevent="toggleMarker(key, 'hide')">
                <v-icon dark> {{ el.split("#").includes("hide") ? "fas fa-eye-slash" : "fas fa-eye" }} </v-icon>
              </v-btn>
            </div>
          </a>
        </v-card>
      </v-row>
    </v-card-text>
    <div class="v-text-field__details" v-if="showError">
      <div class="v-messages theme--light" role="alert">
        <div class="v-messages__wrapper error--text">{{ err }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Array,
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      uploadsFiles: null,
      dialogVideoFile: false,
      dialogFile: false,
      fileLoading: false,
      showError: false,
      err: null,
      over: {},
      startLoc: 0,
      progressLoading: 0,
      dragging: false,
      dragFrom: {},
    };
  },
  watch: {
    error(e) {
      this.err = e;
      if (e) {
        this.showError = true;
        setTimeout(() => {
          this.showError = false;
          this.err = "";
        }, 2000);
      }
    },
  },
  computed: {
    size() {
      return this.model?.size || 150;
    },
    actions() {
      return (this.model?.actions || "").split(",");
    },
    isReverse() {
      return this.model?.reverce || false;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    images: {
      get() {
        let i = JSON.parse(JSON.stringify(this.items));
        return this.isReverse ? i.reverse() : i;
      },
      set(i) {
        // let i = JSON.parse(JSON.stringify(v));
        this.items = this.isReverse ? i.reverse() : i;
      },
    },
    items: {
      get() {
        let d = [];
        try {
          // d = JSON.parse(this.value);
          d = this.value;
          if (!d) d = [];
        } catch (error) {
          d = [];
        }
        return d;
      },
      set(v) {
        this.$emit("input", v);
        //    this.$emit("input", this.isReverse ? v.reverse() : v);
      },
    },
  },
  methods: {
    hasMarker(e, m) {
      let arr = e.split("#");
      return arr.includes(m);
    },
    dropItem: function (e) {
      if (this.dragFrom == this.model.name) return;
      let el = this.items;
      if (!Array.isArray(el)) el = [];
      //   el.unshift(e.dataTransfer.getData("text"));
      el.splice(this.over?.pos || 0, 0, e.dataTransfer.getData("text"));
      this.items = el;
    },
    startDrag(item, i, e) {
      e.dataTransfer.setData("text", item);
      e.dataTransfer.effectAllowed = "move";
      this.startLoc = e.clientY;
      this.dragging = true;
      this.dragFrom = this.model.name;
    },
    finishDrag(item, pos, e) {
      this.dragFrom = null;
      this.items.splice(pos, 1);
      this.items.splice(this.over.pos, 0, item);
      setTimeout(() => {
        this.over = {};
      }, 50);
    },

    onDragOver(item, pos, e) {
      if (this.over.pos == pos) return;
      const dir = this.startLoc < e.clientY ? "down" : "up";
      this.over = { item, pos, dir };
      //      setTimeout(() => {        this.over = { item, pos, dir };      }, 50);
    },
    onDragLeave(item, pos, e) {
      if (this.over.pos == pos) this.over = {};
    },
    remove(key) {
      let e = this.images;
      e.splice(key, 1);
      this.images = e;
    },
    toggleMarker(key, m) {
      let e = this.images;
      let el = e[key];
      let arr = el.split("#");
      if (arr.includes(m)) {
        arr = arr.filter((a) => a !== m);
      } else arr.push(m);
      e[key] = arr.join("#");
      console.log(arr.join("#"));
      this.images = e;
    },
    addImage() {
      this.uploadsFiles = null;
      this.dialogFile = true;
      this.fileLoading = false;
    },
    addVideo() {
      this.uploadsFiles = null;
      this.dialogVideoFile = true;
      this.fileLoading = false;
    },
  },
};
</script>
<style lang="scss">
.over {
  opacity: 0.6;
}
</style>
