<template>
  <div class="d-flex">
    <v-btn v-if="value && model.icon" class="ma-1 mr-4" small @click.stop="onClickLink(link)"> <v-icon color="primary" small>fas fa-link</v-icon> </v-btn>
    <div class="align-content-center">
      {{ link }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: Object,
    model: { type: Object, default: () => {} },
  },
  data: () => ({
    showMenu: false,
  }),
  computed: {
    link() {
      let res = this.value;
      if (this.values?.vendor == "avito" || this.values?.offer?.vendor == "avito") {
        res = "https://www.avito.ru/" + this.values.vendor_id || this.values?.offer?.vendor_id;
      }
      return res;
    },
  },
  methods: {
    onClickLink(e) {
      if (e) window.open(e);
    },
  },
};
</script>
