import { render, staticRenderFns } from "./adsInfo.vue?vue&type=template&id=cbf0ec0c&"
import script from "./adsInfo.vue?vue&type=script&lang=js&"
export * from "./adsInfo.vue?vue&type=script&lang=js&"
import style0 from "./adsInfo.vue?vue&type=style&index=0&id=cbf0ec0c&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardText,VCardTitle,VImg,VSpacer})
