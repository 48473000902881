/** @format */

const billing = {
  title: 'Информация об оплате',
  url: '/manager/billing',
  api: '/manager/billing',
  statuses: [
    { value: 1, text: 'В работе', next: [2], action: 'Принять в работу' },
    { value: 2, text: 'Сделка', next: [], action: 'Совершить сделку' },
  ],
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'createdon', title: 'Дата', type: 'datetime', sortable: false, width: 80, class1: 'h4' },
    { name: 'user_id', title: 'Пользователь', type: 'select', dir: 'user', sortable: true, width: 150, searchable: false },
    { name: 'price', title: 'Сумма', type: 'string', sortable: false, width: 50, class1: 'title' },
    { name: 'date_end', title: 'Дата конца', type: 'datetime', sortable: false, width: 80, class1: 'h4' },
    { name: 'note', title: 'Примечание', type: 'string', formula: '(d)=>{return d?.data?.note || ""}', calculated: true, sortable: false, width: 200 },
  ],
  form: [
    { name: 'user_id', title: 'Пользователь', type: 'select', dir: 'user', validator: ['req'] },
    { name: 'price', title: 'Сумма', type: 'string', validator: ['req'] },
    { name: 'date_end', title: 'Дата конца', type: 'datestring', validator: ['req'] },
    { name: 'createdon', title: 'Создано', type: 'datetimestring' },
    { name: 'createdby_id', title: 'Создал', type: 'select', dir: 'user' },
    { name: 'updatedon', title: 'Изменено', type: 'datetimestring' },
    { name: 'updatedby_id', title: 'Изменил', type: 'select', dir: 'user' },
    { name: 'data.note', title: 'Примечание', type: 'text' },
  ],
  config: {
    edit: {
      fields: 'updatedby_id,updatedon,createdby_id,createdon,user_id,price,date_end,data.note',
      fieldsRO: 'updatedby_id,updatedon,createdby_id,createdon',
      fieldsForm: 'createdon#6,createdby_id#6,updatedon#6,updatedby_id#6,user_id,price#6,date_end#6,data.note',
    },
    create: {
      fields: 'user_id,price,date_end,data.note',
      fieldsRO: '',
      fieldsForm: 'user_id,price#6,date_end#6,data.note',
    },
    fields: 'user_id,price,date_end',
    fieldsRO: '',
    fieldsForm: 'user_id,price,date_end',
  },
};
export default billing;
