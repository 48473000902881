<template>
  <span v-html="text"></span>
</template>

<script>
export default {
  props: {
    value: [Array],
  },
  computed: {
    text() {
      if (this.value) {
        let users = [];
        this.value.forEach((el) => {
          users.push(el.user_name);
        });
        return users.join(";");
      }
    },
  },
};
</script>