<template>
  <div class="py-1 mb-4">
    <v-card color="teal lighten-5">
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-text>
        <div class="d-flex flex-wrap card-columns" style="min-height: 150px; max-height1: 350px; overflow: auto">
          <v-card
            v-if="value?.[el.name]"
            v-for="(el, key) in cards"
            elevation="2"
            :key="`it-${key}`"
            class="ma-1 grow"
            style="max-height: 350px; overflow: auto; max-width1: 40%"
            :style="el.width == 100 ? 'width: 100%; ' : `max-width:${el.width}%`"
          >
            <v-card-text v-if="el.type == 'images'" class="pa-2 pt-1">
              <v-card-title color="secondary" class="pa-1">{{ el.title }}</v-card-title>
              <a-view-images-url :value="value?.[el.name]" :size="250" />
              <div v-if="!true" class="d-flex flex-wrap card-columns">
                <v-img v-for="(img, i) in value?.[el.name]" :src="img" :key="i"></v-img>
              </div>
            </v-card-text>
            <v-card-text v-else class="mb-2">
              <v-card-title color="secondary" class="pa-1">{{ el.title }}</v-card-title>
              <div class="pa-1">
                <a-form-info2 v-if="value?.[el.name]" color="teal lighten-5" :value="value?.[el.name]" :model="el.model" :config="{ dense: true, hideNull: !true }" />
              </div>
            </v-card-text>
          </v-card>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    model: Object,
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      loaded: true,
      cards: [
        {
          name: "images",
          title: "Фото",
          type: "images",
          width: 100,
        },
        {
          name: "parameters",
          title: "Параметры",
          width: 40,
          model: [
            { name: "name", type: "text" },
            { name: "value", type: "text" },
          ],
        },
        {
          name: "aboutHome",
          title: "О доме",
          width: 40,
          model: [
            { name: "name", type: "text" },
            { name: "value", type: "text" },
          ],
        },
        {
          name: "rulesParams",
          title: "Правила",
          width: 40,
          model: [
            { name: "name", type: "text" },
            { name: "value", type: "text" },
          ],
        },
        {
          name: "rentConditions",
          title: "Аренда",
          width: 40,
          model: [
            { name: "name", type: "text" },
            { name: "value", type: "text" },
          ],
        },
        {
          name: "metro",
          title: "Метро",
          width: 40,
          model: [
            { name: "name", type: "text" },
            { name: "range", type: "text" },
            { name: "time", type: "text" },
          ],
        },
      ],
    };
  },
  created() {
    console.log(this.value);
  },
  watch: {},
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
  },
  methods: {},
};
</script>
<style lang="scss">
.over {
  opacity: 0.6;
}
</style>
