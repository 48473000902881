/** @format */
import modelOptions from './modelOptions';

export default {
  mixins: [modelOptions],
  methods: {
    generateModel(type, viewName) {
      let r = [];
      let view = this.getConfigView(viewName);
      if (!view.model) return console.log('not find', viewName);
      let dataModel = this.getConfigModel(view.model);
      let dataView = view[type].data;
      for (let i = 0; i < dataView.length; i++) {
        let el = dataView[i];
        let a = { ...el, ...dataModel[el.name] };
        r.push(a);
      }
      return r;
    },
    getConfigModel(name) {
      return this.$root.config.meta.models[name].data;
    },
    getConfigView(name) {
      return this.$root.config.meta.views[name];
    },
    getModelList(m, list = 'list', filter = false) {
      if (!m) return [];
      const model = JSON.parse(JSON.stringify(m[list]));
      //const model = [...m[list]];
      model.forEach((el) => {
        if (!el.hasOwnProperty('style')) {
          el.style = 'white-space: normal;';
        }
        if (el?.isDisableFunc) {
          if (typeof el.isDisableFunc === 'string') {
            el.isDisableFunc = eval(el.isDisableFunc);
          }
        }

        if (el?.access) {
          let access = el.access;
          /** && !el.roleShow.includes(this.$root.profile.role))  */
          let res;
          let role = this.$root.profile.role;
          if (access?.role.length) {
            res = access.role.includes(role) || false;
            if (!res) {
              res = access.role.includes(-1) && !access.role.includes(-role) && !(access?.user && access?.user.includes(-user));
            }
          }
          if (!res) el.hidden = true;
        }
        if (el.options) el.options = this.getOptions(el);
        if (el?.sourceList) {
          if (filter) {
            el.options = this.m?.[el.sourceList]
              ? this.m[el.sourceList].filter((l) => {
                  return !l?.hidden;
                })
              : [];
          } else el.options = m[el.sourceList];
        }
      });
      return model;
    },
    calcModel(type, template = 'form', m = null) {
      if (!m) m = Object.assign({}, this.m);
      const mt = Object.assign({}, m);
      let modelT = this.getModelList(mt, template, true);
      const fields = (mt?.[type].fields || '').split(',');
      let fieldsRO = (mt?.[type].fieldsRO || '').split(',');
      if (this.data.status == 2) fieldsRO = fields;
      const fieldsForm = (mt?.[type].fieldsForm || '').split(',');
      let model = [];
      for (const f of fields) {
        if (
          modelT.find((m) => {
            return m.name == f;
          })
        )
          model.push(
            modelT.find((m) => {
              return m.name == f;
            })
          );
      }

      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
        if (el?.roleShow && !el.roleShow.includes(this.$root.profile.role)) {
          el.hidden = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split('#');
        mForm.push({ name: f[0], size: f[1] || 12 });
      });
      this[type] = mForm;
      return model;
    },
    calcModelConfig(config, m = null) {
      if (!m) m = Object.assign({}, this.m);
      this['modelForm'] = null;
      const mt = Object.assign({}, m);
      let modelT = this.getModelList(mt, 'form', true);

      let fields = (config.fields || '').split(',');
      let fieldsRO = (config.fieldsRO || '').split(',');
      if (this.$root.profile.role == 1000) {
        if (this.$route.query.root) fieldsRO = '';
      }
      let fieldsForm = (config.fieldsForm || '').split(',');
      let model = [];
      for (const f of fields) {
        let m = modelT.find((m) => {
          return m.name == f;
        });
        if (m) model.push(m);
      }
      if (config?.fieldsReplace) {
        Object.keys(config.fieldsReplace).forEach((f) => {
          let idx = model.findIndex((el) => {
            return el.name == f;
          });
          if (idx !== -1) model[idx] = config.fieldsReplace[f];
        });
      }
      model.forEach((el) => {
        if (fieldsRO.includes(el.name)) {
          el.readonly = true;
        }
        if (el?.roleShow && !el.roleShow.includes(this.$root.profile.role)) {
          el.hidden = true;
        }
        if (!this.id && el?.hiddenCreate) {
          el.hidden = true;
        }
      });
      let mForm = [];
      fieldsForm.forEach((field) => {
        let f = field.split('#');
        mForm.push({ name: f[0], size: f[1] || 12 });
      });

      this['modelForm'] = mForm;
      return model;
    },
  },
};
