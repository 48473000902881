<template>
  <div>
    <div v-for="(el, i) in model" :key="i">
      <component
        :ref="`input_${el.name}`"
        v-if="el.type && el.hidden !== true"
        :class="{ 'my-2': config.hideDetails || config.hideDetails == 'auto' }"
        :is="'a-input-' + el.type"
        :model="getModel(el)"
        :values="data"
        :disabled="el.disabled || false"
        :value="getValue(el)"
        :config="config"
        :readonly="readonly"
        @input="onInput($event, el)"
        @commit="$emit('commit', { event: $event, el })"
        @fieldData="$emit('fieldData', { name: el.name, value: $event })"
        :error="getError(el)"
      ></component>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    value: Object,
    model: Array,
    errors: Object,
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: false, disabled: false };
      },
    },
    readonly: { type: Boolean, default: false },
  },
  computed: {
    data: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        return this.value;
      },
    },
    rating: {
      set(v) {
        //this.$emit("input", v);
      },
      get() {
        let res = this.value.rating || {};
        return res;
      },
    },
  },

  methods: {
    getModel(el) {
      let res = Object.assign({}, el);
      if (this.readonly) res.readonly = true;
      return res;
    },
    getValue(el) {
      if (el.name.indexOf(".") !== -1) {
        let field = el.name.split(".")[0];
        let name = el.name.split(".")[1];
        return this.data?.[field]?.[name];
      }
      return el.json ? this.data.data[el.name] : this.data[el.name];
    },
    getError(el) {
      if (el.calculated === true) {
        let err = [];
        if (el.errorFrom)
          for (let e of el.errorFrom) {
            if (this.errors?.[e])
              err.push(
                this.model.find(m => {
                  return m.name == e;
                })?.title +
                  ": " +
                  this.errors?.[e],
              );
          }
        return err.join(" , ");
      }

      if (el.type == "ref") return this.errors[el.name + "_id"];
      return this.errors[el.name];
    },
    async onInput(e, el) {
      let parent = "";
      if (el.json) {
        let field = el?.jsonField || "data";
        let data = JSON.parse(JSON.stringify(this.data[field]));
        data[el.name] = e;
        this.data[field] = data;
      } else if (el.type == "filesdata" || el.type == "filesdatavideo") {
        let task = this.data["filesTask"] || [];
        let del = JSON.parse(JSON.stringify(this.data["filesRemove"] || []));
        let add = JSON.parse(JSON.stringify(this.data["filesAdd"] || []));
        let d = JSON.parse(JSON.stringify(this.data["filesData"] || []));
        for (const file of e) {
          if (file.type == "add") {
            d.push(file);
            add.push(file);
          } else if (file.type == "task") {
            task.push(file);
            // d.push(file);
          } else if (file.type == "del") {
            console.log("del", file);
            if (file.task) {
              task = task.filter(f => {
                return f.upload_id !== file.upload_id;
              });
            } else {
              d = d.filter(f => {
                return f.upload_id !== file.upload_id;
              });
              if (file?.id) {
                del.push({ upload_id: file.upload_id });
              } else {
                add = add.filter(f => {
                  return f.upload_id !== file.upload_id;
                });
              }
            }
          }
        }
        this.data[el.name] = e.filter(f => {
          return f.type !== "del";
        });
        this.data["filesData"] = d;
        this.data["filesTask"] = task;
        this.data["filesAdd"] = add;
        this.data["filesRemove"] = del;
      } else if (el.name.indexOf(".") !== -1) {
        let field = el.name.split(".")[0];
        parent = field;
        let name = el.name.split(".")[1];
        let data = JSON.parse(JSON.stringify(this.data[field])) || {};
        data[name] = e;
        this.data[field] = data;
      } else if (Array.isArray(e)) {
        this.data[el.name] = e;
      } else if (typeof e === "object" && e !== null) {
        if (this.data[el.name] !== null) {
          Object.assign(this.data, e);
        } else {
          this.data[el.name] = e;
        }
        //Object.assign(this.data, e);
      } else {
        this.data[el.name] = e;
      }
      if (el.calculated === true) {
        let err = [];
        if (el.errorFrom)
          for (let e of el.errorFrom) {
            this.$emit("validate", e);
          }
      }
      if (el.onChange) {
        for (const key in el.onChange) {
          if (el.onChange[key].validator) {
            let func = eval(el.onChange[key].validator);
            let model = [{ name: key, validator: func(this.data) }];
            this.$emit("changeValidator", model);
          }
          if (el.onChange[key].formula) {
            let func;
            if (typeof el.onChange[key].formula == "function") func = el.onChange[key].formula;
            if (typeof el.onChange[key].formula == "string") func = eval(el.onChange[key].formula);
            let res = func(this.data);
            this.data[key] = res;
            this.$emit("validate", key);
          }
        }
      }
      this.$emit("validate", el.name);
    },
    async onInput1(e, el) {
      let parent = "";
      if (el.json) {
        let field = el?.jsonField || "data";
        let data = JSON.parse(JSON.stringify(this.data[field]));
        data[el.name] = e;
        this.data[field] = data;
      } else if (el.type == "filesdata") {
        let del = JSON.parse(JSON.stringify(this.data["filesRemove"] || []));
        let add = JSON.parse(JSON.stringify(this.data["filesAdd"] || []));
        let d = JSON.parse(JSON.stringify(this.data["filesData"] || []));
        for (const file of e) {
          if (file.type == "add") {
            d.push(file);
            add.push(file);
          }
          if (file.type == "del") {
            d = d.filter(f => {
              return f.upload_id !== file.upload_id;
            });
            if (file?.id) {
              del.push({ upload_id: file.upload_id });
            } else {
              add = add.filter(f => {
                return f.upload_id !== file.upload_id;
              });
            }
          }
        }
        this.data[el.name] = e.filter(f => {
          return f.type !== "del";
        });
        this.data["filesData"] = d;
        this.data["filesAdd"] = add;
        this.data["filesRemove"] = del;
      } else if (el.name.indexOf(".") !== -1) {
        let field = el.name.split(".")[0];
        parent = field;
        let name = el.name.split(".")[1];
        let data = JSON.parse(JSON.stringify(this.data[field]));
        data[name] = e;
        this.data[field] = data;
      } else if (Array.isArray(e)) {
        this.data[el.name] = e;
      } else if (typeof e === "object" && e !== null) {
        if (this.data[el.name] !== null) {
          Object.assign(this.data, e);
        } else {
          this.data[el.name] = e;
        }
        //Object.assign(this.data, e);
      } else {
        this.data[el.name] = e;
      }
      if (el.calculated === true) {
        let err = [];
        if (el.errorFrom)
          for (let e of el.errorFrom) {
            this.$emit("validate", e);
          }
      }
      if (el.onChange) {
        for (const key in el.onChange) {
          if (el.onChange[key].validator) {
            let func = eval(el.onChange[key].validator);
            let model = [{ name: key, validator: func(this.data) }];
            this.$emit("changeValidator", model);
          }
          if (el.onChange[key].formula) {
            let func;
            if (typeof el.onChange[key].formula == "function") func = el.onChange[key].formula;
            if (typeof el.onChange[key].formula == "string") func = eval(el.onChange[key].formula);
            let res = func(this.data);
            this.data[key] = res;
            this.$emit("validate", key);
          }
        }
      }
      this.$emit("validate", el.name);
    },
  },
};
</script>
