/** @format */

export default {
  data() {
    return {
      loading: false,
      data: [],
      sort: [
        {
          key: 'id',
          order: 'DESC',
        },
      ],
      pager: {
        page: 0,
        count: 0,
        limit: 50,
      },
      filters: {},
      where: '',
    };
  },
  methods: {
    async updateData() {
      await this.fetchData();
    },
    async updateRowData(id) {
      let f = JSON.parse(JSON.stringify(this.filters));
      f.id = id;
      const d = await this.$axios.get(this.api, { params: { filters: f } });
      if (d.data && d.data.data) {
        let rowId = this.data.findIndex((el) => el.id == id);
        if (rowId != -1) {
          const data = d.data.data.find((el) => {
            return el.id == id;
          });
          if (!data) {
            this.$delete(this.data, rowId);
            //this.data.slice(rowId, 1);
          } else {
            for (const key of Object.keys(this.data[rowId])) {
              this.data[rowId][key] = data?.[key];
            }
          }
        }
      }
    },
    async fetchData(append = false) {
      this.loading = true;
      let where = this.where || this.defaults?.where || {};
      let filters = Object.assign({}, this.filters, where);
      //let filters = Object.assign({}, this.filters);
      let params = { sort: JSON.stringify(this.sort), filters, pager: this.pager, where: {} };
      let response;
      try {
        console.log('axios', this.api, params);
        response = await this.$axios.get(this.api, { params });
      } catch (error) {}
      this.loading = false;
      this.pager = response?.data?.pager || { count: 0 };
      let d = response?.data?.data || [];
      try {
        this.model
          .filter((el) => {
            return el.formula ? true : false;
          })
          .forEach((m) => {
            for (let i = 0; i < d.length; i++) {
              const row = d[i];
              let res = 0;
              if (m.formula) {
                let f = m.formula;
                //   if (row.id == 68) console.log('calc field ', m.name, m.formula, row);
                Object.defineProperty(d[i], m.name, {
                  get: function () {
                    let res = 0;
                    let func;
                    if (typeof f == 'function') func = f;
                    if (typeof f == 'string') func = eval(f);
                    res = func(this);
                    return res;
                  },
                });
                /*
                let func;
                if (!row?.['balanceTable']) this.$set(d[i], 'balanceTable', []);
                if (!row?.['balanceCalc']) this.$set(d[i], 'balanceCalc', { type_100: null });
                if (typeof m.formula == 'function') func = m.formula;
                if (typeof m.formula == 'string') func = eval(m.formula);
                res = func(row);
                d[i][m.name] = res;
              */
              }
            }
          });
      } catch (error) {
        console.log('ERROR', error);
      }
      if (append) {
        this.data = this.data.concat(d);
      } else {
        this.data = d;
      }
      this.$emit('dataUpdated', response?.data);
      let lastRow = this.data.slice(-1)[0];
      this.$emit('lastRow', lastRow);
      this.$emit('loadData', this.data);
      //console.log({ api: this.api, data: response.data });
      await this.afterFetchData(response);
    },
    async afterFetchData(response) {},
  },
};
