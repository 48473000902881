/** @format */

import info from './modules/info';
import object from './modules/object';
import offer from './modules/offer';
import myOffer from './modules/myOffer';
import profile from './modules/profile';
import dirs from './modules/dirs';
import billing from './modules/billing';

const models = {
  info,
  object,
  dirs,
  offer,
  myOffer,
  profile,
  billing,
};

export { models };
