<template>
  <v-lazy v-model="lazy" :options="{ threshold: 0.5 }" :key="values.id">
    <div v-if="lazy">
      <v-progress-circular v-if="loading" indeterminate color="green" />
      <span v-else> {{ text_ }} </span>
    </div>
  </v-lazy>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: Object,
    model: {
      type: Object,
      default: {},
    },
  },
  data() {
    return {
      data: [],
      text: null,
      credit: null,
      debit: null,
      loaded: false,
      lazy: false,
      loading: true,
    };
  },
  created() {},
  watch: {
    "values.id"() {
      this.loaded = false;
    },
    lazy(v) {
      if (v) this.getActivity();
    },
  },
  computed: {
    text_() {
      if (!this.loaded) this.getActivity();
      return this.text;
    },
  },
  methods: {
    async fetchData() {
      return;
    },

    async getActivity() {
      this.loaded = true;
      this.loading = true;
      let id = this.values.id;
      let params = { filters: { user_id: id }, sort: { key: "createdon", order: "DESC" } };
      let resp = await this.$axios.get("/manager/activity", { params });
      let text = "";
      this.loading = false;
      if (resp.data.status == "ok") {
        const d = resp.data.data[0].createdon;

        text = resp.data.data.length + "; " + new Date(d).toLocaleString();

        resp.data.data.forEach((el) => {});
      }
      this.text = text;
    },
  },
};
</script>
