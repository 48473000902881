/** @format */

!(function (e) {
  if ('object' == typeof exports && 'undefined' != typeof module) module.exports = e();
  else if ('function' == typeof define && define.amd) define([], e);
  else {
    ('undefined' != typeof window ? window : 'undefined' != typeof global ? global : 'undefined' != typeof self ? self : this).JsSIP = e();
  }
})(function () {
  return (function e(t, n, r) {
    function i(a, o) {
      if (!n[a]) {
        if (!t[a]) {
          var l = 'function' == typeof require && require;
          if (!o && l) return l(a, !0);
          if (s) return s(a, !0);
          var u = new Error("Cannot find module '" + a + "'");
          throw ((u.code = 'MODULE_NOT_FOUND'), u);
        }
        var c = (n[a] = { exports: {} });
        t[a][0].call(
          c.exports,
          function (e) {
            var n = t[a][1][e];
            return i(n || e);
          },
          c,
          c.exports,
          e,
          t,
          n,
          r
        );
      }
      return n[a].exports;
    }
    for (var s = 'function' == typeof require && require, a = 0; a < r.length; a++) i(r[a]);
    return i;
  })(
    {
      1: [
        function (e, t, n) {
          'use strict';
          var r = e('./Utils'),
            i = e('./Constants'),
            s = e('./Grammar'),
            a = e('./URI'),
            o = e('./Socket'),
            l = e('./Exceptions');
          n.settings = {
            authorization_user: null,
            password: null,
            realm: null,
            ha1: null,
            display_name: null,
            uri: null,
            contact_uri: null,
            instance_id: null,
            use_preloaded_route: !1,
            session_timers: !0,
            session_timers_refresh_method: i.UPDATE,
            no_answer_timeout: 60,
            register: !0,
            register_expires: 600,
            registrar_server: null,
            sockets: null,
            connection_recovery_max_interval: null,
            connection_recovery_min_interval: null,
            via_host: r.createRandomToken(12) + '.invalid',
          };
          var u = {
            mandatory: {
              sockets: function (e) {
                var t = [];
                if (o.isSocket(e)) t.push({ socket: e });
                else {
                  if (!Array.isArray(e) || !e.length) return;
                  var n = !0,
                    r = !1,
                    i = void 0;
                  try {
                    for (var s, a = e[Symbol.iterator](); !(n = (s = a.next()).done); n = !0) {
                      var l = s.value;
                      Object.prototype.hasOwnProperty.call(l, 'socket') && o.isSocket(l.socket) ? t.push(l) : o.isSocket(l) && t.push({ socket: l });
                    }
                  } catch (e) {
                    (r = !0), (i = e);
                  } finally {
                    try {
                      !n && a.return && a.return();
                    } finally {
                      if (r) throw i;
                    }
                  }
                }
                return t;
              },
              uri: function (e) {
                /^sip:/i.test(e) || (e = i.SIP + ':' + e);
                var t = a.parse(e);
                return t && t.user ? t : void 0;
              },
            },
            optional: {
              authorization_user: function (e) {
                return -1 === s.parse('"' + e + '"', 'quoted_string') ? void 0 : e;
              },
              user_agent: function (e) {
                if ('string' == typeof e) return e;
              },
              connection_recovery_max_interval: function (e) {
                if (r.isDecimal(e)) {
                  var t = Number(e);
                  if (t > 0) return t;
                }
              },
              connection_recovery_min_interval: function (e) {
                if (r.isDecimal(e)) {
                  var t = Number(e);
                  if (t > 0) return t;
                }
              },
              contact_uri: function (e) {
                if ('string' == typeof e) {
                  var t = s.parse(e, 'SIP_URI');
                  if (-1 !== t) return t;
                }
              },
              display_name: function (e) {
                return -1 === s.parse('"' + e + '"', 'display_name') ? void 0 : e;
              },
              instance_id: function (e) {
                return /^uuid:/i.test(e) && (e = e.substr(5)), -1 === s.parse(e, 'uuid') ? void 0 : e;
              },
              no_answer_timeout: function (e) {
                if (r.isDecimal(e)) {
                  var t = Number(e);
                  if (t > 0) return t;
                }
              },
              session_timers: function (e) {
                if ('boolean' == typeof e) return e;
              },
              session_timers_refresh_method: function (e) {
                if ('string' == typeof e && ((e = e.toUpperCase()) === i.INVITE || e === i.UPDATE)) return e;
              },
              password: function (e) {
                return String(e);
              },
              realm: function (e) {
                return String(e);
              },
              ha1: function (e) {
                return String(e);
              },
              register: function (e) {
                if ('boolean' == typeof e) return e;
              },
              register_expires: function (e) {
                if (r.isDecimal(e)) {
                  var t = Number(e);
                  if (t > 0) return t;
                }
              },
              registrar_server: function (e) {
                /^sip:/i.test(e) || (e = i.SIP + ':' + e);
                var t = a.parse(e);
                return t ? (t.user ? void 0 : t) : void 0;
              },
              use_preloaded_route: function (e) {
                if ('boolean' == typeof e) return e;
              },
            },
          };
          n.load = function (e, t) {
            for (var n in u.mandatory) {
              if (!t.hasOwnProperty(n)) throw new l.ConfigurationError(n);
              var i = t[n],
                s = u.mandatory[n](i);
              if (void 0 === s) throw new l.ConfigurationError(n, i);
              e[n] = s;
            }
            for (var a in u.optional)
              if (t.hasOwnProperty(a)) {
                var o = t[a];
                if (r.isEmpty(o)) continue;
                var c = u.optional[a](o);
                if (void 0 === c) throw new l.ConfigurationError(a, o);
                e[a] = c;
              }
          };
        },
        { './Constants': 2, './Exceptions': 6, './Grammar': 7, './Socket': 20, './URI': 25, './Utils': 26 },
      ],
      2: [
        function (e, t, n) {
          'use strict';
          var r = e('../package.json');
          t.exports = {
            USER_AGENT: r.title + ' ' + r.version,
            SIP: 'sip',
            SIPS: 'sips',
            causes: {
              CONNECTION_ERROR: 'Connection Error',
              REQUEST_TIMEOUT: 'Request Timeout',
              SIP_FAILURE_CODE: 'SIP Failure Code',
              INTERNAL_ERROR: 'Internal Error',
              BUSY: 'Busy',
              REJECTED: 'Rejected',
              REDIRECTED: 'Redirected',
              UNAVAILABLE: 'Unavailable',
              NOT_FOUND: 'Not Found',
              ADDRESS_INCOMPLETE: 'Address Incomplete',
              INCOMPATIBLE_SDP: 'Incompatible SDP',
              MISSING_SDP: 'Missing SDP',
              AUTHENTICATION_ERROR: 'Authentication Error',
              BYE: 'Terminated',
              WEBRTC_ERROR: 'WebRTC Error',
              CANCELED: 'Canceled',
              NO_ANSWER: 'No Answer',
              EXPIRES: 'Expires',
              NO_ACK: 'No ACK',
              DIALOG_ERROR: 'Dialog Error',
              USER_DENIED_MEDIA_ACCESS: 'User Denied Media Access',
              BAD_MEDIA_DESCRIPTION: 'Bad Media Description',
              RTP_TIMEOUT: 'RTP Timeout',
            },
            SIP_ERROR_CAUSES: {
              REDIRECTED: [300, 301, 302, 305, 380],
              BUSY: [486, 600],
              REJECTED: [403, 603],
              NOT_FOUND: [404, 604],
              UNAVAILABLE: [480, 410, 408, 430],
              ADDRESS_INCOMPLETE: [484, 424],
              INCOMPATIBLE_SDP: [488, 606],
              AUTHENTICATION_ERROR: [401, 407],
            },
            ACK: 'ACK',
            BYE: 'BYE',
            CANCEL: 'CANCEL',
            INFO: 'INFO',
            INVITE: 'INVITE',
            MESSAGE: 'MESSAGE',
            NOTIFY: 'NOTIFY',
            OPTIONS: 'OPTIONS',
            REGISTER: 'REGISTER',
            REFER: 'REFER',
            UPDATE: 'UPDATE',
            SUBSCRIBE: 'SUBSCRIBE',
            REASON_PHRASE: {
              100: 'Trying',
              180: 'Ringing',
              181: 'Call Is Being Forwarded',
              182: 'Queued',
              183: 'Session Progress',
              199: 'Early Dialog Terminated',
              200: 'OK',
              202: 'Accepted',
              204: 'No Notification',
              300: 'Multiple Choices',
              301: 'Moved Permanently',
              302: 'Moved Temporarily',
              305: 'Use Proxy',
              380: 'Alternative Service',
              400: 'Bad Request',
              401: 'Unauthorized',
              402: 'Payment Required',
              403: 'Forbidden',
              404: 'Not Found',
              405: 'Method Not Allowed',
              406: 'Not Acceptable',
              407: 'Proxy Authentication Required',
              408: 'Request Timeout',
              410: 'Gone',
              412: 'Conditional Request Failed',
              413: 'Request Entity Too Large',
              414: 'Request-URI Too Long',
              415: 'Unsupported Media Type',
              416: 'Unsupported URI Scheme',
              417: 'Unknown Resource-Priority',
              420: 'Bad Extension',
              421: 'Extension Required',
              422: 'Session Interval Too Small',
              423: 'Interval Too Brief',
              424: 'Bad Location Information',
              428: 'Use Identity Header',
              429: 'Provide Referrer Identity',
              430: 'Flow Failed',
              433: 'Anonymity Disallowed',
              436: 'Bad Identity-Info',
              437: 'Unsupported Certificate',
              438: 'Invalid Identity Header',
              439: 'First Hop Lacks Outbound Support',
              440: 'Max-Breadth Exceeded',
              469: 'Bad Info Package',
              470: 'Consent Needed',
              478: 'Unresolvable Destination',
              480: 'Temporarily Unavailable',
              481: 'Call/Transaction Does Not Exist',
              482: 'Loop Detected',
              483: 'Too Many Hops',
              484: 'Address Incomplete',
              485: 'Ambiguous',
              486: 'Busy Here',
              487: 'Request Terminated',
              488: 'Not Acceptable Here',
              489: 'Bad Event',
              491: 'Request Pending',
              493: 'Undecipherable',
              494: 'Security Agreement Required',
              500: 'JsSIP Internal Error',
              501: 'Not Implemented',
              502: 'Bad Gateway',
              503: 'Service Unavailable',
              504: 'Server Time-out',
              505: 'Version Not Supported',
              513: 'Message Too Large',
              580: 'Precondition Failure',
              600: 'Busy Everywhere',
              603: 'Decline',
              604: 'Does Not Exist Anywhere',
              606: 'Not Acceptable',
            },
            ALLOWED_METHODS: 'INVITE,ACK,CANCEL,BYE,UPDATE,MESSAGE,OPTIONS,REFER,INFO',
            ACCEPTED_BODY_TYPES: 'application/sdp, application/dtmf-relay',
            MAX_FORWARDS: 69,
            SESSION_EXPIRES: 90,
            MIN_SESSION_EXPIRES: 60,
          };
        },
        { '../package.json': 51 },
      ],
      3: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('./SIPMessage'),
            s = e('./Constants'),
            a = e('./Transactions'),
            o = e('./Dialog/RequestSender'),
            l = e('./Utils'),
            u = e('debug')('JsSIP:Dialog'),
            c = { STATUS_EARLY: 1, STATUS_CONFIRMED: 2 };
          t.exports = (function () {
            r(e, null, [
              {
                key: 'C',
                get: function () {
                  return c;
                },
              },
            ]);
            function e(t, n, r) {
              var s = arguments.length > 3 && void 0 !== arguments[3] ? arguments[3] : c.STATUS_CONFIRMED;
              if (
                ((function (e, t) {
                  if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
                })(this, e),
                (this._owner = t),
                (this._ua = t._ua),
                (this._uac_pending_reply = !1),
                (this._uas_pending_reply = !1),
                !n.hasHeader('contact'))
              )
                return { error: 'unable to create a Dialog without Contact header field' };
              n instanceof i.IncomingResponse && (s = n.status_code < 200 ? c.STATUS_EARLY : c.STATUS_CONFIRMED);
              var a = n.parseHeader('contact');
              'UAS' === r
                ? ((this._id = {
                    call_id: n.call_id,
                    local_tag: n.to_tag,
                    remote_tag: n.from_tag,
                    toString: function () {
                      return this.call_id + this.local_tag + this.remote_tag;
                    },
                  }),
                  (this._state = s),
                  (this._remote_seqnum = n.cseq),
                  (this._local_uri = n.parseHeader('to').uri),
                  (this._remote_uri = n.parseHeader('from').uri),
                  (this._remote_target = a.uri),
                  (this._route_set = n.getHeaders('record-route')),
                  (this._ack_seqnum = this._remote_seqnum))
                : 'UAC' === r &&
                  ((this._id = {
                    call_id: n.call_id,
                    local_tag: n.from_tag,
                    remote_tag: n.to_tag,
                    toString: function () {
                      return this.call_id + this.local_tag + this.remote_tag;
                    },
                  }),
                  (this._state = s),
                  (this._local_seqnum = n.cseq),
                  (this._local_uri = n.parseHeader('from').uri),
                  (this._remote_uri = n.parseHeader('to').uri),
                  (this._remote_target = a.uri),
                  (this._route_set = n.getHeaders('record-route').reverse()),
                  (this._ack_seqnum = null)),
                this._ua.newDialog(this),
                u('new ' + r + ' dialog created with status ' + (this._state === c.STATUS_EARLY ? 'EARLY' : 'CONFIRMED'));
            }
            return (
              r(e, [
                {
                  key: 'update',
                  value: function (e, t) {
                    (this._state = c.STATUS_CONFIRMED),
                      u('dialog ' + this._id.toString() + '  changed to CONFIRMED state'),
                      'UAC' === t && (this._route_set = e.getHeaders('record-route').reverse());
                  },
                },
                {
                  key: 'terminate',
                  value: function () {
                    u('dialog ' + this._id.toString() + ' deleted'), this._ua.destroyDialog(this);
                  },
                },
                {
                  key: 'sendRequest',
                  value: function (e) {
                    var t = this,
                      n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                      r = l.cloneArray(n.extraHeaders),
                      i = n.eventHandlers || {},
                      s = n.body || null,
                      a = this._createRequest(e, r, s);
                    i.onAuthenticated = function () {
                      t._local_seqnum += 1;
                    };
                    return new o(this, a, i).send(), a;
                  },
                },
                {
                  key: 'receiveRequest',
                  value: function (e) {
                    this._checkInDialogRequest(e) &&
                      (e.method === s.ACK && null !== this._ack_seqnum ? (this._ack_seqnum = null) : e.method === s.INVITE && (this._ack_seqnum = e.cseq),
                      this._owner.receiveRequest(e));
                  },
                },
                {
                  key: '_createRequest',
                  value: function (e, t, n) {
                    (t = l.cloneArray(t)), this._local_seqnum || (this._local_seqnum = Math.floor(1e4 * Math.random()));
                    var r = e === s.CANCEL || e === s.ACK ? this._local_seqnum : (this._local_seqnum += 1);
                    return new i.OutgoingRequest(
                      e,
                      this._remote_target,
                      this._ua,
                      {
                        cseq: r,
                        call_id: this._id.call_id,
                        from_uri: this._local_uri,
                        from_tag: this._id.local_tag,
                        to_uri: this._remote_uri,
                        to_tag: this._id.remote_tag,
                        route_set: this._route_set,
                      },
                      t,
                      n
                    );
                  },
                },
                {
                  key: '_checkInDialogRequest',
                  value: function (e) {
                    var t = this;
                    if (this._remote_seqnum)
                      if (e.cseq < this._remote_seqnum) {
                        if (e.method !== s.ACK) return e.reply(500), !1;
                        if (null === this._ack_seqnum || e.cseq !== this._ack_seqnum) return !1;
                      } else e.cseq > this._remote_seqnum && (this._remote_seqnum = e.cseq);
                    else this._remote_seqnum = e.cseq;
                    if (e.method === s.INVITE || (e.method === s.UPDATE && e.body)) {
                      if (!0 === this._uac_pending_reply) e.reply(491);
                      else {
                        if (!0 === this._uas_pending_reply) {
                          var n = 1 + ((10 * Math.random()) | 0);
                          return e.reply(500, null, ['Retry-After:' + n]), !1;
                        }
                        this._uas_pending_reply = !0;
                        e.server_transaction.on('stateChanged', function n() {
                          (e.server_transaction.state !== a.C.STATUS_ACCEPTED &&
                            e.server_transaction.state !== a.C.STATUS_COMPLETED &&
                            e.server_transaction.state !== a.C.STATUS_TERMINATED) ||
                            (e.server_transaction.removeListener('stateChanged', n), (t._uas_pending_reply = !1));
                        });
                      }
                      e.hasHeader('contact') &&
                        e.server_transaction.on('stateChanged', function () {
                          e.server_transaction.state === a.C.STATUS_ACCEPTED && (t._remote_target = e.parseHeader('contact').uri);
                        });
                    } else
                      e.method === s.NOTIFY &&
                        e.hasHeader('contact') &&
                        e.server_transaction.on('stateChanged', function () {
                          e.server_transaction.state === a.C.STATUS_COMPLETED && (t._remote_target = e.parseHeader('contact').uri);
                        });
                    return !0;
                  },
                },
                {
                  key: 'id',
                  get: function () {
                    return this._id;
                  },
                },
                {
                  key: 'local_seqnum',
                  get: function () {
                    return this._local_seqnum;
                  },
                  set: function (e) {
                    this._local_seqnum = e;
                  },
                },
                {
                  key: 'owner',
                  get: function () {
                    return this._owner;
                  },
                },
                {
                  key: 'uac_pending_reply',
                  get: function () {
                    return this._uac_pending_reply;
                  },
                  set: function (e) {
                    this._uac_pending_reply = e;
                  },
                },
                {
                  key: 'uas_pending_reply',
                  get: function () {
                    return this._uas_pending_reply;
                  },
                },
              ]),
              e
            );
          })();
        },
        { './Constants': 2, './Dialog/RequestSender': 4, './SIPMessage': 19, './Transactions': 22, './Utils': 26, debug: 29 },
      ],
      4: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('../Constants'),
            s = e('../Transactions'),
            a = e('../RTCSession'),
            o = e('../RequestSender'),
            l = {
              onRequestTimeout: function () {},
              onTransportError: function () {},
              onSuccessResponse: function () {},
              onErrorResponse: function () {},
              onAuthenticated: function () {},
              onDialogError: function () {},
            };
          t.exports = (function () {
            function e(t, n, r) {
              !(function (e, t) {
                if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
              })(this, e),
                (this._dialog = t),
                (this._ua = t._ua),
                (this._request = n),
                (this._eventHandlers = r),
                (this._reattempt = !1),
                (this._reattemptTimer = null);
              for (var i in l) Object.prototype.hasOwnProperty.call(l, i) && (this._eventHandlers[i] || (this._eventHandlers[i] = l[i]));
            }
            return (
              r(e, [
                {
                  key: 'send',
                  value: function () {
                    var e = this,
                      t = new o(this._ua, this._request, {
                        onRequestTimeout: function () {
                          e._eventHandlers.onRequestTimeout();
                        },
                        onTransportError: function () {
                          e._eventHandlers.onTransportError();
                        },
                        onAuthenticated: function (t) {
                          e._eventHandlers.onAuthenticated(t);
                        },
                        onReceiveResponse: function (t) {
                          e._receiveResponse(t);
                        },
                      });
                    if (
                      (t.send(),
                      (this._request.method === i.INVITE || (this._request.method === i.UPDATE && this._request.body)) &&
                        t.clientTransaction.state !== s.C.STATUS_TERMINATED)
                    ) {
                      this._dialog.uac_pending_reply = !0;
                      t.clientTransaction.on('stateChanged', function n() {
                        (t.clientTransaction.state !== s.C.STATUS_ACCEPTED &&
                          t.clientTransaction.state !== s.C.STATUS_COMPLETED &&
                          t.clientTransaction.state !== s.C.STATUS_TERMINATED) ||
                          (t.clientTransaction.removeListener('stateChanged', n), (e._dialog.uac_pending_reply = !1));
                      });
                    }
                  },
                },
                {
                  key: '_receiveResponse',
                  value: function (e) {
                    var t = this;
                    408 === e.status_code || 481 === e.status_code
                      ? this._eventHandlers.onDialogError(e)
                      : e.method === i.INVITE && 491 === e.status_code
                      ? this._reattempt
                        ? e.status_code >= 200 && e.status_code < 300
                          ? this._eventHandlers.onSuccessResponse(e)
                          : e.status_code >= 300 && this._eventHandlers.onErrorResponse(e)
                        : ((this._request.cseq.value = this._dialog.local_seqnum += 1),
                          (this._reattemptTimer = setTimeout(function () {
                            t._dialog.owner.status !== a.C.STATUS_TERMINATED && ((t._reattempt = !0), t._request_sender.send());
                          }, 1e3)))
                      : e.status_code >= 200 && e.status_code < 300
                      ? this._eventHandlers.onSuccessResponse(e)
                      : e.status_code >= 300 && this._eventHandlers.onErrorResponse(e);
                  },
                },
                {
                  key: 'request',
                  get: function () {
                    return this._request;
                  },
                },
              ]),
              e
            );
          })();
        },
        { '../Constants': 2, '../RTCSession': 12, '../RequestSender': 18, '../Transactions': 22 },
      ],
      5: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('./Utils'),
            s = e('debug')('JsSIP:DigestAuthentication'),
            a = e('debug')('JsSIP:ERROR:DigestAuthentication');
          (a.log = console.warn.bind(console)),
            (t.exports = (function () {
              function e(t) {
                !(function (e, t) {
                  if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
                })(this, e),
                  (this._credentials = t),
                  (this._cnonce = null),
                  (this._nc = 0),
                  (this._ncHex = '00000000'),
                  (this._algorithm = null),
                  (this._realm = null),
                  (this._nonce = null),
                  (this._opaque = null),
                  (this._stale = null),
                  (this._qop = null),
                  (this._method = null),
                  (this._uri = null),
                  (this._ha1 = null),
                  (this._response = null);
              }
              return (
                r(e, [
                  {
                    key: 'get',
                    value: function (e) {
                      switch (e) {
                        case 'realm':
                          return this._realm;
                        case 'ha1':
                          return this._ha1;
                        default:
                          return void a('get() | cannot get "%s" parameter', e);
                      }
                    },
                  },
                  {
                    key: 'authenticate',
                    value: function (e, t) {
                      var n = e.method,
                        r = e.ruri,
                        o = e.body,
                        l = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : null;
                      if (
                        ((this._algorithm = t.algorithm),
                        (this._realm = t.realm),
                        (this._nonce = t.nonce),
                        (this._opaque = t.opaque),
                        (this._stale = t.stale),
                        this._algorithm)
                      ) {
                        if ('MD5' !== this._algorithm) return a('authenticate() | challenge with Digest algorithm different than "MD5", authentication aborted'), !1;
                      } else this._algorithm = 'MD5';
                      if (!this._nonce) return a('authenticate() | challenge without Digest nonce, authentication aborted'), !1;
                      if (!this._realm) return a('authenticate() | challenge without Digest realm, authentication aborted'), !1;
                      if (!this._credentials.password) {
                        if (!this._credentials.ha1) return a('authenticate() | no plain SIP password nor ha1 provided, authentication aborted'), !1;
                        if (this._credentials.realm !== this._realm)
                          return (
                            a(
                              'authenticate() | no plain SIP password, and stored `realm` does not match the given `realm`, cannot authenticate [stored:"%s", given:"%s"]',
                              this._credentials.realm,
                              this._realm
                            ),
                            !1
                          );
                      }
                      if (t.qop)
                        if (t.qop.indexOf('auth-int') > -1) this._qop = 'auth-int';
                        else {
                          if (!(t.qop.indexOf('auth') > -1))
                            return a('authenticate() | challenge without Digest qop different than "auth" or "auth-int", authentication aborted'), !1;
                          this._qop = 'auth';
                        }
                      else this._qop = null;
                      (this._method = n), (this._uri = r), (this._cnonce = l || i.createRandomToken(12)), (this._nc += 1);
                      var u = Number(this._nc).toString(16);
                      (this._ncHex = '00000000'.substr(0, 8 - u.length) + u),
                        4294967296 === this._nc && ((this._nc = 1), (this._ncHex = '00000001')),
                        this._credentials.password
                          ? (this._ha1 = i.calculateMD5(this._credentials.username + ':' + this._realm + ':' + this._credentials.password))
                          : (this._ha1 = this._credentials.ha1);
                      var c = void 0;
                      return (
                        'auth' === this._qop
                          ? ((c = i.calculateMD5(this._method + ':' + this._uri)),
                            (this._response = i.calculateMD5(this._ha1 + ':' + this._nonce + ':' + this._ncHex + ':' + this._cnonce + ':auth:' + c)))
                          : 'auth-int' === this._qop
                          ? ((c = i.calculateMD5(this._method + ':' + this._uri + ':' + i.calculateMD5(o || ''))),
                            (this._response = i.calculateMD5(this._ha1 + ':' + this._nonce + ':' + this._ncHex + ':' + this._cnonce + ':auth-int:' + c)))
                          : null === this._qop &&
                            ((c = i.calculateMD5(this._method + ':' + this._uri)), (this._response = i.calculateMD5(this._ha1 + ':' + this._nonce + ':' + c))),
                        s('authenticate() | response generated'),
                        !0
                      );
                    },
                  },
                  {
                    key: 'toString',
                    value: function () {
                      var e = [];
                      if (!this._response) throw new Error('response field does not exist, cannot generate Authorization header');
                      return (
                        e.push('algorithm=' + this._algorithm),
                        e.push('username="' + this._credentials.username + '"'),
                        e.push('realm="' + this._realm + '"'),
                        e.push('nonce="' + this._nonce + '"'),
                        e.push('uri="' + this._uri + '"'),
                        e.push('response="' + this._response + '"'),
                        this._opaque && e.push('opaque="' + this._opaque + '"'),
                        this._qop && (e.push('qop=' + this._qop), e.push('cnonce="' + this._cnonce + '"'), e.push('nc=' + this._ncHex)),
                        'Digest ' + e.join(', ')
                      );
                    },
                  },
                ]),
                e
              );
            })());
        },
        { './Utils': 26, debug: 29 },
      ],
      6: [
        function (e, t, n) {
          'use strict';
          function r(e, t) {
            if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
          }
          function i(e, t) {
            if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
            return !t || ('object' != typeof t && 'function' != typeof t) ? e : t;
          }
          function s(e, t) {
            if ('function' != typeof t && null !== t) throw new TypeError('Super expression must either be null or a function, not ' + typeof t);
            (e.prototype = Object.create(t && t.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })),
              t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : (e.__proto__ = t));
          }
          var a = (function (e) {
              s(t, Error);
              function t(e, n) {
                r(this, t);
                var s = i(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
                return (
                  (s.code = 1),
                  (s.name = 'CONFIGURATION_ERROR'),
                  (s.parameter = e),
                  (s.value = n),
                  (s.message = s.value ? 'Invalid value ' + JSON.stringify(s.value) + ' for parameter "' + s.parameter + '"' : 'Missing parameter: ' + s.parameter),
                  s
                );
              }
              return t;
            })(),
            o = (function (e) {
              s(t, Error);
              function t(e) {
                r(this, t);
                var n = i(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
                return (n.code = 2), (n.name = 'INVALID_STATE_ERROR'), (n.status = e), (n.message = 'Invalid status: ' + e), n;
              }
              return t;
            })(),
            l = (function (e) {
              s(t, Error);
              function t(e) {
                r(this, t);
                var n = i(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
                return (n.code = 3), (n.name = 'NOT_SUPPORTED_ERROR'), (n.message = e), n;
              }
              return t;
            })(),
            u = (function (e) {
              s(t, Error);
              function t(e) {
                r(this, t);
                var n = i(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
                return (n.code = 4), (n.name = 'NOT_READY_ERROR'), (n.message = e), n;
              }
              return t;
            })();
          t.exports = { ConfigurationError: a, InvalidStateError: o, NotSupportedError: l, NotReadyError: u };
        },
        {},
      ],
      7: [
        function (e, t, n) {
          'use strict';
          t.exports = (function () {
            function t(e) {
              return (
                '"' +
                e
                  .replace(/\\/g, '\\\\')
                  .replace(/"/g, '\\"')
                  .replace(/\x08/g, '\\b')
                  .replace(/\t/g, '\\t')
                  .replace(/\n/g, '\\n')
                  .replace(/\f/g, '\\f')
                  .replace(/\r/g, '\\r')
                  .replace(/[\x00-\x07\x0B\x0E-\x1F\x80-\uFFFF]/g, escape) +
                '"'
              );
            }
            var n = {
              parse: function (n, r) {
                var i = {
                  CRLF: c,
                  DIGIT: d,
                  ALPHA: h,
                  HEXDIG: f,
                  WSP: p,
                  OCTET: _,
                  DQUOTE: v,
                  SP: m,
                  HTAB: g,
                  alphanum: y,
                  reserved: T,
                  unreserved: C,
                  mark: S,
                  escaped: E,
                  LWS: b,
                  SWS: R,
                  HCOLON: A,
                  TEXT_UTF8_TRIM: w,
                  TEXT_UTF8char: I,
                  UTF8_NONASCII: k,
                  UTF8_CONT: P,
                  LHEX: function () {
                    var e;
                    null === (e = d()) && (/^[a-f]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[a-f]')));
                    return e;
                  },
                  token: O,
                  token_nodot: x,
                  separators: function () {
                    var e;
                    40 === n.charCodeAt(s) ? ((e = '('), s++) : ((e = null), 0 === a && u('"("'));
                    null === e &&
                      (41 === n.charCodeAt(s) ? ((e = ')'), s++) : ((e = null), 0 === a && u('")"')),
                      null === e &&
                        (60 === n.charCodeAt(s) ? ((e = '<'), s++) : ((e = null), 0 === a && u('"<"')),
                        null === e &&
                          (62 === n.charCodeAt(s) ? ((e = '>'), s++) : ((e = null), 0 === a && u('">"')),
                          null === e &&
                            (64 === n.charCodeAt(s) ? ((e = '@'), s++) : ((e = null), 0 === a && u('"@"')),
                            null === e &&
                              (44 === n.charCodeAt(s) ? ((e = ','), s++) : ((e = null), 0 === a && u('","')),
                              null === e &&
                                (59 === n.charCodeAt(s) ? ((e = ';'), s++) : ((e = null), 0 === a && u('";"')),
                                null === e &&
                                  (58 === n.charCodeAt(s) ? ((e = ':'), s++) : ((e = null), 0 === a && u('":"')),
                                  null === e &&
                                    (92 === n.charCodeAt(s) ? ((e = '\\'), s++) : ((e = null), 0 === a && u('"\\\\"')),
                                    null === e &&
                                      null === (e = v()) &&
                                      (47 === n.charCodeAt(s) ? ((e = '/'), s++) : ((e = null), 0 === a && u('"/"')),
                                      null === e &&
                                        (91 === n.charCodeAt(s) ? ((e = '['), s++) : ((e = null), 0 === a && u('"["')),
                                        null === e &&
                                          (93 === n.charCodeAt(s) ? ((e = ']'), s++) : ((e = null), 0 === a && u('"]"')),
                                          null === e &&
                                            (63 === n.charCodeAt(s) ? ((e = '?'), s++) : ((e = null), 0 === a && u('"?"')),
                                            null === e &&
                                              (61 === n.charCodeAt(s) ? ((e = '='), s++) : ((e = null), 0 === a && u('"="')),
                                              null === e &&
                                                (123 === n.charCodeAt(s) ? ((e = '{'), s++) : ((e = null), 0 === a && u('"{"')),
                                                null === e &&
                                                  (125 === n.charCodeAt(s) ? ((e = '}'), s++) : ((e = null), 0 === a && u('"}"')),
                                                  null === e && null === (e = m()) && (e = g()))))))))))))))));
                    return e;
                  },
                  word: N,
                  STAR: D,
                  SLASH: U,
                  EQUAL: M,
                  LPAREN: L,
                  RPAREN: q,
                  RAQUOT: H,
                  LAQUOT: F,
                  COMMA: j,
                  SEMI: G,
                  COLON: B,
                  LDQUOT: W,
                  RDQUOT: V,
                  comment: function e() {
                    var t, n, r;
                    var i;
                    i = s;
                    t = L();
                    if (null !== t) {
                      for (n = [], null === (r = J()) && null === (r = $()) && (r = e()); null !== r; ) n.push(r), null === (r = J()) && null === (r = $()) && (r = e());
                      null !== n && null !== (r = q()) ? (t = [t, n, r]) : ((t = null), (s = i));
                    } else (t = null), (s = i);
                    return t;
                  },
                  ctext: J,
                  quoted_string: K,
                  quoted_string_clean: z,
                  qdtext: Y,
                  quoted_pair: $,
                  SIP_URI_noparams: X,
                  SIP_URI: Q,
                  uri_scheme: Z,
                  uri_scheme_sips: ee,
                  uri_scheme_sip: te,
                  userinfo: ne,
                  user: re,
                  user_unreserved: ie,
                  password: se,
                  hostport: ae,
                  host: oe,
                  hostname: le,
                  domainlabel: ue,
                  toplabel: ce,
                  IPv6reference: de,
                  IPv6address: he,
                  h16: fe,
                  ls32: pe,
                  IPv4address: _e,
                  dec_octet: ve,
                  port: me,
                  uri_parameters: ge,
                  uri_parameter: ye,
                  transport_param: Te,
                  user_param: Ce,
                  method_param: Se,
                  ttl_param: Ee,
                  maddr_param: be,
                  lr_param: Re,
                  other_param: Ae,
                  pname: we,
                  pvalue: Ie,
                  paramchar: ke,
                  param_unreserved: Pe,
                  headers: Oe,
                  header: xe,
                  hname: Ne,
                  hvalue: De,
                  hnv_unreserved: Ue,
                  Request_Response: function () {
                    var e;
                    null === (e = dt()) && (e = Me());
                    return e;
                  },
                  Request_Line: Me,
                  Request_URI: Le,
                  absoluteURI: qe,
                  hier_part: He,
                  net_path: Fe,
                  abs_path: je,
                  opaque_part: Ge,
                  uric: Be,
                  uric_no_slash: We,
                  path_segments: Ve,
                  segment: Je,
                  param: Ke,
                  pchar: ze,
                  scheme: Ye,
                  authority: $e,
                  srvr: Xe,
                  reg_name: Qe,
                  query: Ze,
                  SIP_Version: et,
                  INVITEm: tt,
                  ACKm: nt,
                  OPTIONSm: rt,
                  BYEm: it,
                  CANCELm: st,
                  REGISTERm: at,
                  SUBSCRIBEm: ot,
                  NOTIFYm: lt,
                  REFERm: ut,
                  Method: ct,
                  Status_Line: dt,
                  Status_Code: ht,
                  extension_code: ft,
                  Reason_Phrase: pt,
                  Allow_Events: function () {
                    var e, t, n, r, i, a;
                    if (((i = s), null !== (e = qt()))) {
                      for (t = [], a = s, null !== (n = j()) && null !== (r = qt()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                        t.push(n), (a = s), null !== (n = j()) && null !== (r = qt()) ? (n = [n, r]) : ((n = null), (s = a));
                      null !== t ? (e = [e, t]) : ((e = null), (s = i));
                    } else (e = null), (s = i);
                    return e;
                  },
                  Call_ID: function () {
                    var e, t, r, i, o, l;
                    (i = s),
                      (o = s),
                      null !== (e = N())
                        ? ((l = s),
                          64 === n.charCodeAt(s) ? ((t = '@'), s++) : ((t = null), 0 === a && u('"@"')),
                          null !== t && null !== (r = N()) ? (t = [t, r]) : ((t = null), (s = l)),
                          null !== (t = null !== t ? t : '') ? (e = [e, t]) : ((e = null), (s = o)))
                        : ((e = null), (s = o));
                    null !== e && ((c = i), (e = void (Fn = n.substring(s, c))));
                    var c;
                    null === e && (s = i);
                    return e;
                  },
                  Contact: function () {
                    var e, t, n, r, i, a, o;
                    if (((i = s), null === (e = D())))
                      if (((a = s), null !== (e = _t()))) {
                        for (t = [], o = s, null !== (n = j()) && null !== (r = _t()) ? (n = [n, r]) : ((n = null), (s = o)); null !== n; )
                          t.push(n), (o = s), null !== (n = j()) && null !== (r = _t()) ? (n = [n, r]) : ((n = null), (s = o));
                        null !== t ? (e = [e, t]) : ((e = null), (s = a));
                      } else (e = null), (s = a);
                    null !== e &&
                      (e = (function (e) {
                        var t, n;
                        for (n = Fn.multi_header.length, t = 0; t < n; t++)
                          if (null === Fn.multi_header[t].parsed) {
                            Fn = null;
                            break;
                          }
                        Fn = null !== Fn ? Fn.multi_header : -1;
                      })());
                    null === e && (s = i);
                    return e;
                  },
                  contact_param: _t,
                  name_addr: vt,
                  display_name: mt,
                  contact_params: gt,
                  c_p_q: yt,
                  c_p_expires: Tt,
                  delta_seconds: Ct,
                  qvalue: St,
                  generic_param: Et,
                  gen_value: bt,
                  Content_Disposition: function () {
                    var e, t, n, r, i, a;
                    if (((i = s), null !== (e = Rt()))) {
                      for (t = [], a = s, null !== (n = G()) && null !== (r = At()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                        t.push(n), (a = s), null !== (n = G()) && null !== (r = At()) ? (n = [n, r]) : ((n = null), (s = a));
                      null !== t ? (e = [e, t]) : ((e = null), (s = i));
                    } else (e = null), (s = i);
                    return e;
                  },
                  disp_type: Rt,
                  disp_param: At,
                  handling_param: wt,
                  Content_Encoding: function () {
                    var e, t, n, r, i, a;
                    if (((i = s), null !== (e = O()))) {
                      for (t = [], a = s, null !== (n = j()) && null !== (r = O()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                        t.push(n), (a = s), null !== (n = j()) && null !== (r = O()) ? (n = [n, r]) : ((n = null), (s = a));
                      null !== t ? (e = [e, t]) : ((e = null), (s = i));
                    } else (e = null), (s = i);
                    return e;
                  },
                  Content_Length: function () {
                    var e, t, n;
                    if (((n = s), null !== (t = d()))) for (e = []; null !== t; ) e.push(t), (t = d());
                    else e = null;
                    null !== e && (e = void (Fn = parseInt(e.join(''))));
                    null === e && (s = n);
                    return e;
                  },
                  Content_Type: function () {
                    var e, t;
                    (t = s), null !== (e = It()) && ((r = t), (e = void (Fn = n.substring(s, r))));
                    var r;
                    null === e && (s = t);
                    return e;
                  },
                  media_type: It,
                  m_type: kt,
                  discrete_type: Pt,
                  composite_type: Ot,
                  extension_token: xt,
                  x_token: Nt,
                  m_subtype: Dt,
                  m_parameter: Ut,
                  m_value: Mt,
                  CSeq: function () {
                    var e, t, n, r;
                    (r = s), null !== (e = Lt()) && null !== (t = b()) && null !== (n = ct()) ? (e = [e, t, n]) : ((e = null), (s = r));
                    return e;
                  },
                  CSeq_value: Lt,
                  Expires: function () {
                    var e, t;
                    (t = s), null !== (e = Ct()) && (e = void (Fn = e));
                    null === e && (s = t);
                    return e;
                  },
                  Event: function () {
                    var e, t, n, r, i, a, o;
                    if (((i = s), (a = s), null !== (e = qt()))) {
                      for (t = [], o = s, null !== (n = G()) && null !== (r = Et()) ? (n = [n, r]) : ((n = null), (s = o)); null !== n; )
                        t.push(n), (o = s), null !== (n = G()) && null !== (r = Et()) ? (n = [n, r]) : ((n = null), (s = o));
                      null !== t ? (e = [e, t]) : ((e = null), (s = a));
                    } else (e = null), (s = a);
                    null !== e && ((l = e[0]), (e = void (Fn.event = l.join('').toLowerCase())));
                    var l;
                    null === e && (s = i);
                    return e;
                  },
                  event_type: qt,
                  From: function () {
                    var e, t, n, r, i, a, o;
                    (i = s), (a = s), null === (e = X()) && (e = vt());
                    if (null !== e) {
                      for (t = [], o = s, null !== (n = G()) && null !== (r = Ht()) ? (n = [n, r]) : ((n = null), (s = o)); null !== n; )
                        t.push(n), (o = s), null !== (n = G()) && null !== (r = Ht()) ? (n = [n, r]) : ((n = null), (s = o));
                      null !== t ? (e = [e, t]) : ((e = null), (s = a));
                    } else (e = null), (s = a);
                    null !== e &&
                      (e = (function (e) {
                        var t = Fn.tag;
                        try {
                          (Fn = new Hn(Fn.uri, Fn.display_name, Fn.params)), t && Fn.setParam('tag', t);
                        } catch (e) {
                          Fn = -1;
                        }
                      })());
                    null === e && (s = i);
                    return e;
                  },
                  from_param: Ht,
                  tag_param: Ft,
                  Max_Forwards: function () {
                    var e, t, n;
                    if (((n = s), null !== (t = d()))) for (e = []; null !== t; ) e.push(t), (t = d());
                    else e = null;
                    null !== e && (e = void (Fn = parseInt(e.join(''))));
                    null === e && (s = n);
                    return e;
                  },
                  Min_Expires: function () {
                    var e, t;
                    (t = s), null !== (e = Ct()) && (e = void (Fn = e));
                    null === e && (s = t);
                    return e;
                  },
                  Name_Addr_Header: function () {
                    var e, t, n, r, i, a, o, l, u, c;
                    (l = s), (u = s), (e = []), (t = mt());
                    for (; null !== t; ) e.push(t), (t = mt());
                    if (null !== e)
                      if (null !== (t = F()))
                        if (null !== (n = Q()))
                          if (null !== (r = H())) {
                            for (i = [], c = s, null !== (a = G()) && null !== (o = Et()) ? (a = [a, o]) : ((a = null), (s = c)); null !== a; )
                              i.push(a), (c = s), null !== (a = G()) && null !== (o = Et()) ? (a = [a, o]) : ((a = null), (s = c));
                            null !== i ? (e = [e, t, n, r, i]) : ((e = null), (s = u));
                          } else (e = null), (s = u);
                        else (e = null), (s = u);
                      else (e = null), (s = u);
                    else (e = null), (s = u);
                    null !== e &&
                      (e = (function (e) {
                        try {
                          Fn = new Hn(Fn.uri, Fn.display_name, Fn.params);
                        } catch (e) {
                          Fn = -1;
                        }
                      })());
                    null === e && (s = l);
                    return e;
                  },
                  Proxy_Authenticate: function () {
                    return jt();
                  },
                  challenge: jt,
                  other_challenge: Gt,
                  auth_param: Bt,
                  digest_cln: Wt,
                  realm: Vt,
                  realm_value: Jt,
                  domain: Kt,
                  URI: zt,
                  nonce: Yt,
                  nonce_value: $t,
                  opaque: Xt,
                  stale: Qt,
                  algorithm: Zt,
                  qop_options: en,
                  qop_value: tn,
                  Proxy_Require: function () {
                    var e, t, n, r, i, a;
                    if (((i = s), null !== (e = O()))) {
                      for (t = [], a = s, null !== (n = j()) && null !== (r = O()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                        t.push(n), (a = s), null !== (n = j()) && null !== (r = O()) ? (n = [n, r]) : ((n = null), (s = a));
                      null !== t ? (e = [e, t]) : ((e = null), (s = i));
                    } else (e = null), (s = i);
                    return e;
                  },
                  Record_Route: function () {
                    var e, t, n, r, i, a, o;
                    if (((i = s), (a = s), null !== (e = nn()))) {
                      for (t = [], o = s, null !== (n = j()) && null !== (r = nn()) ? (n = [n, r]) : ((n = null), (s = o)); null !== n; )
                        t.push(n), (o = s), null !== (n = j()) && null !== (r = nn()) ? (n = [n, r]) : ((n = null), (s = o));
                      null !== t ? (e = [e, t]) : ((e = null), (s = a));
                    } else (e = null), (s = a);
                    null !== e &&
                      (e = (function (e) {
                        var t, n;
                        for (n = Fn.multi_header.length, t = 0; t < n; t++)
                          if (null === Fn.multi_header[t].parsed) {
                            Fn = null;
                            break;
                          }
                        Fn = null !== Fn ? Fn.multi_header : -1;
                      })());
                    null === e && (s = i);
                    return e;
                  },
                  rec_route: nn,
                  Reason: function () {
                    var e, t, r, i, o, l, c;
                    (o = s), (l = s), 'sip' === n.substr(s, 3).toLowerCase() ? ((e = n.substr(s, 3)), (s += 3)) : ((e = null), 0 === a && u('"SIP"'));
                    null === e && (e = O());
                    if (null !== e) {
                      for (t = [], c = s, null !== (r = G()) && null !== (i = rn()) ? (r = [r, i]) : ((r = null), (s = c)); null !== r; )
                        t.push(r), (c = s), null !== (r = G()) && null !== (i = rn()) ? (r = [r, i]) : ((r = null), (s = c));
                      null !== t ? (e = [e, t]) : ((e = null), (s = l));
                    } else (e = null), (s = l);
                    null !== e &&
                      (e = (function (e, t) {
                        if (((Fn.protocol = t.toLowerCase()), Fn.params || (Fn.params = {}), Fn.params.text && '"' === Fn.params.text[0])) {
                          var n = Fn.params.text;
                          (Fn.text = n.substring(1, n.length - 1)), delete Fn.params.text;
                        }
                      })(0, e[0]));
                    null === e && (s = o);
                    return e;
                  },
                  reason_param: rn,
                  reason_cause: sn,
                  Require: function () {
                    var e, t, n, r, i, a;
                    if (((i = s), null !== (e = O()))) {
                      for (t = [], a = s, null !== (n = j()) && null !== (r = O()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                        t.push(n), (a = s), null !== (n = j()) && null !== (r = O()) ? (n = [n, r]) : ((n = null), (s = a));
                      null !== t ? (e = [e, t]) : ((e = null), (s = i));
                    } else (e = null), (s = i);
                    return e;
                  },
                  Route: function () {
                    var e, t, n, r, i, a;
                    if (((i = s), null !== (e = an()))) {
                      for (t = [], a = s, null !== (n = j()) && null !== (r = an()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                        t.push(n), (a = s), null !== (n = j()) && null !== (r = an()) ? (n = [n, r]) : ((n = null), (s = a));
                      null !== t ? (e = [e, t]) : ((e = null), (s = i));
                    } else (e = null), (s = i);
                    return e;
                  },
                  route_param: an,
                  Subscription_State: function () {
                    var e, t, n, r, i, a;
                    if (((i = s), null !== (e = on()))) {
                      for (t = [], a = s, null !== (n = G()) && null !== (r = ln()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                        t.push(n), (a = s), null !== (n = G()) && null !== (r = ln()) ? (n = [n, r]) : ((n = null), (s = a));
                      null !== t ? (e = [e, t]) : ((e = null), (s = i));
                    } else (e = null), (s = i);
                    return e;
                  },
                  substate_value: on,
                  subexp_params: ln,
                  event_reason_value: un,
                  Subject: function () {
                    var e;
                    return (e = null !== (e = w()) ? e : '');
                  },
                  Supported: function () {
                    var e, t, n, r, i, a;
                    if (((i = s), null !== (e = O()))) {
                      for (t = [], a = s, null !== (n = j()) && null !== (r = O()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                        t.push(n), (a = s), null !== (n = j()) && null !== (r = O()) ? (n = [n, r]) : ((n = null), (s = a));
                      null !== t ? (e = [e, t]) : ((e = null), (s = i));
                    } else (e = null), (s = i);
                    return (e = null !== e ? e : '');
                  },
                  To: function () {
                    var e, t, n, r, i, a, o;
                    (i = s), (a = s), null === (e = X()) && (e = vt());
                    if (null !== e) {
                      for (t = [], o = s, null !== (n = G()) && null !== (r = cn()) ? (n = [n, r]) : ((n = null), (s = o)); null !== n; )
                        t.push(n), (o = s), null !== (n = G()) && null !== (r = cn()) ? (n = [n, r]) : ((n = null), (s = o));
                      null !== t ? (e = [e, t]) : ((e = null), (s = a));
                    } else (e = null), (s = a);
                    null !== e &&
                      (e = (function (e) {
                        var t = Fn.tag;
                        try {
                          (Fn = new Hn(Fn.uri, Fn.display_name, Fn.params)), t && Fn.setParam('tag', t);
                        } catch (e) {
                          Fn = -1;
                        }
                      })());
                    null === e && (s = i);
                    return e;
                  },
                  to_param: cn,
                  Via: function () {
                    var e, t, n, r, i, a;
                    if (((i = s), null !== (e = dn()))) {
                      for (t = [], a = s, null !== (n = j()) && null !== (r = dn()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                        t.push(n), (a = s), null !== (n = j()) && null !== (r = dn()) ? (n = [n, r]) : ((n = null), (s = a));
                      null !== t ? (e = [e, t]) : ((e = null), (s = i));
                    } else (e = null), (s = i);
                    return e;
                  },
                  via_param: dn,
                  via_params: hn,
                  via_ttl: fn,
                  via_maddr: pn,
                  via_received: _n,
                  via_branch: vn,
                  response_port: mn,
                  sent_protocol: gn,
                  protocol_name: yn,
                  transport: Tn,
                  sent_by: Cn,
                  via_host: Sn,
                  via_port: En,
                  ttl: bn,
                  WWW_Authenticate: function () {
                    return jt();
                  },
                  Session_Expires: function () {
                    var e, t, n, r, i, a;
                    if (((i = s), null !== (e = Rn()))) {
                      for (t = [], a = s, null !== (n = G()) && null !== (r = An()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                        t.push(n), (a = s), null !== (n = G()) && null !== (r = An()) ? (n = [n, r]) : ((n = null), (s = a));
                      null !== t ? (e = [e, t]) : ((e = null), (s = i));
                    } else (e = null), (s = i);
                    return e;
                  },
                  s_e_expires: Rn,
                  s_e_params: An,
                  s_e_refresher: wn,
                  extension_header: function () {
                    var e, t, n, r;
                    (r = s), null !== (e = O()) && null !== (t = A()) && null !== (n = In()) ? (e = [e, t, n]) : ((e = null), (s = r));
                    return e;
                  },
                  header_value: In,
                  message_body: function () {
                    var e, t;
                    (e = []), (t = _());
                    for (; null !== t; ) e.push(t), (t = _());
                    return e;
                  },
                  uuid_URI: function () {
                    var e, t, r;
                    (r = s), 'uuid:' === n.substr(s, 5) ? ((e = 'uuid:'), (s += 5)) : ((e = null), 0 === a && u('"uuid:"'));
                    null !== e && null !== (t = kn()) ? (e = [e, t]) : ((e = null), (s = r));
                    return e;
                  },
                  uuid: kn,
                  hex4: Pn,
                  hex8: On,
                  hex12: xn,
                  Refer_To: function () {
                    var e, t, n, r, i, a, o;
                    (i = s), (a = s), null === (e = X()) && (e = vt());
                    if (null !== e) {
                      for (t = [], o = s, null !== (n = G()) && null !== (r = Et()) ? (n = [n, r]) : ((n = null), (s = o)); null !== n; )
                        t.push(n), (o = s), null !== (n = G()) && null !== (r = Et()) ? (n = [n, r]) : ((n = null), (s = o));
                      null !== t ? (e = [e, t]) : ((e = null), (s = a));
                    } else (e = null), (s = a);
                    null !== e &&
                      (e = (function (e) {
                        try {
                          Fn = new Hn(Fn.uri, Fn.display_name, Fn.params);
                        } catch (e) {
                          Fn = -1;
                        }
                      })());
                    null === e && (s = i);
                    return e;
                  },
                  Replaces: function () {
                    var e, t, n, r, i, a;
                    if (((i = s), null !== (e = Nn()))) {
                      for (t = [], a = s, null !== (n = G()) && null !== (r = Dn()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                        t.push(n), (a = s), null !== (n = G()) && null !== (r = Dn()) ? (n = [n, r]) : ((n = null), (s = a));
                      null !== t ? (e = [e, t]) : ((e = null), (s = i));
                    } else (e = null), (s = i);
                    return e;
                  },
                  call_id: Nn,
                  replaces_param: Dn,
                  to_tag: Un,
                  from_tag: Mn,
                  early_flag: Ln,
                };
                if (void 0 !== r) {
                  if (void 0 === i[r]) throw new Error('Invalid rule name: ' + t(r) + '.');
                } else r = 'CRLF';
                var s = 0,
                  a = 0,
                  o = 0,
                  l = [];
                function u(e) {
                  s < o || (s > o && ((o = s), (l = [])), l.push(e));
                }
                function c() {
                  var e;
                  return '\r\n' === n.substr(s, 2) ? ((e = '\r\n'), (s += 2)) : ((e = null), 0 === a && u('"\\r\\n"')), e;
                }
                function d() {
                  var e;
                  return /^[0-9]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[0-9]')), e;
                }
                function h() {
                  var e;
                  return /^[a-zA-Z]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[a-zA-Z]')), e;
                }
                function f() {
                  var e;
                  return /^[0-9a-fA-F]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[0-9a-fA-F]')), e;
                }
                function p() {
                  var e;
                  return null === (e = m()) && (e = g()), e;
                }
                function _() {
                  var e;
                  return /^[\0-\xFF]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[\\0-\\xFF]')), e;
                }
                function v() {
                  var e;
                  return /^["]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('["]')), e;
                }
                function m() {
                  var e;
                  return 32 === n.charCodeAt(s) ? ((e = ' '), s++) : ((e = null), 0 === a && u('" "')), e;
                }
                function g() {
                  var e;
                  return 9 === n.charCodeAt(s) ? ((e = '\t'), s++) : ((e = null), 0 === a && u('"\\t"')), e;
                }
                function y() {
                  var e;
                  return /^[a-zA-Z0-9]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[a-zA-Z0-9]')), e;
                }
                function T() {
                  var e;
                  return (
                    59 === n.charCodeAt(s) ? ((e = ';'), s++) : ((e = null), 0 === a && u('";"')),
                    null === e &&
                      (47 === n.charCodeAt(s) ? ((e = '/'), s++) : ((e = null), 0 === a && u('"/"')),
                      null === e &&
                        (63 === n.charCodeAt(s) ? ((e = '?'), s++) : ((e = null), 0 === a && u('"?"')),
                        null === e &&
                          (58 === n.charCodeAt(s) ? ((e = ':'), s++) : ((e = null), 0 === a && u('":"')),
                          null === e &&
                            (64 === n.charCodeAt(s) ? ((e = '@'), s++) : ((e = null), 0 === a && u('"@"')),
                            null === e &&
                              (38 === n.charCodeAt(s) ? ((e = '&'), s++) : ((e = null), 0 === a && u('"&"')),
                              null === e &&
                                (61 === n.charCodeAt(s) ? ((e = '='), s++) : ((e = null), 0 === a && u('"="')),
                                null === e &&
                                  (43 === n.charCodeAt(s) ? ((e = '+'), s++) : ((e = null), 0 === a && u('"+"')),
                                  null === e &&
                                    (36 === n.charCodeAt(s) ? ((e = '$'), s++) : ((e = null), 0 === a && u('"$"')),
                                    null === e && (44 === n.charCodeAt(s) ? ((e = ','), s++) : ((e = null), 0 === a && u('","'))))))))))),
                    e
                  );
                }
                function C() {
                  var e;
                  return null === (e = y()) && (e = S()), e;
                }
                function S() {
                  var e;
                  return (
                    45 === n.charCodeAt(s) ? ((e = '-'), s++) : ((e = null), 0 === a && u('"-"')),
                    null === e &&
                      (95 === n.charCodeAt(s) ? ((e = '_'), s++) : ((e = null), 0 === a && u('"_"')),
                      null === e &&
                        (46 === n.charCodeAt(s) ? ((e = '.'), s++) : ((e = null), 0 === a && u('"."')),
                        null === e &&
                          (33 === n.charCodeAt(s) ? ((e = '!'), s++) : ((e = null), 0 === a && u('"!"')),
                          null === e &&
                            (126 === n.charCodeAt(s) ? ((e = '~'), s++) : ((e = null), 0 === a && u('"~"')),
                            null === e &&
                              (42 === n.charCodeAt(s) ? ((e = '*'), s++) : ((e = null), 0 === a && u('"*"')),
                              null === e &&
                                (39 === n.charCodeAt(s) ? ((e = "'"), s++) : ((e = null), 0 === a && u('"\'"')),
                                null === e &&
                                  (40 === n.charCodeAt(s) ? ((e = '('), s++) : ((e = null), 0 === a && u('"("')),
                                  null === e && (41 === n.charCodeAt(s) ? ((e = ')'), s++) : ((e = null), 0 === a && u('")"')))))))))),
                    e
                  );
                }
                function E() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    (o = s),
                    37 === n.charCodeAt(s) ? ((e = '%'), s++) : ((e = null), 0 === a && u('"%"')),
                    null !== e && null !== (t = f()) && null !== (r = f()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                    null !== e && (e = e.join('')),
                    null === e && (s = i),
                    e
                  );
                }
                function b() {
                  var e, t, n, r, i, a;
                  for (r = s, i = s, a = s, e = [], t = p(); null !== t; ) e.push(t), (t = p());
                  if ((null !== e && null !== (t = c()) ? (e = [e, t]) : ((e = null), (s = a)), null !== (e = null !== e ? e : ''))) {
                    if (null !== (n = p())) for (t = []; null !== n; ) t.push(n), (n = p());
                    else t = null;
                    null !== t ? (e = [e, t]) : ((e = null), (s = i));
                  } else (e = null), (s = i);
                  return null !== e && (e = ' '), null === e && (s = r), e;
                }
                function R() {
                  var e;
                  return (e = null !== (e = b()) ? e : '');
                }
                function A() {
                  var e, t, r, i, o;
                  for (i = s, o = s, e = [], null === (t = m()) && (t = g()); null !== t; ) e.push(t), null === (t = m()) && (t = g());
                  return (
                    null !== e
                      ? (58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                        null !== t && null !== (r = R()) ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && (e = ':'),
                    null === e && (s = i),
                    e
                  );
                }
                function w() {
                  var e, t, r, i, a, o, l;
                  if (((a = s), (o = s), null !== (t = I()))) for (e = []; null !== t; ) e.push(t), (t = I());
                  else e = null;
                  if (null !== e) {
                    for (t = [], l = s, r = [], i = b(); null !== i; ) r.push(i), (i = b());
                    for (null !== r && null !== (i = I()) ? (r = [r, i]) : ((r = null), (s = l)); null !== r; ) {
                      for (t.push(r), l = s, r = [], i = b(); null !== i; ) r.push(i), (i = b());
                      null !== r && null !== (i = I()) ? (r = [r, i]) : ((r = null), (s = l));
                    }
                    null !== t ? (e = [e, t]) : ((e = null), (s = o));
                  } else (e = null), (s = o);
                  null !== e && ((u = a), (e = n.substring(s, u)));
                  var u;
                  return null === e && (s = a), e;
                }
                function I() {
                  var e;
                  return /^[!-~]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[!-~]')), null === e && (e = k()), e;
                }
                function k() {
                  var e;
                  return /^[\x80-\uFFFF]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[\\x80-\\uFFFF]')), e;
                }
                function P() {
                  var e;
                  return /^[\x80-\xBF]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[\\x80-\\xBF]')), e;
                }
                function O() {
                  var e, t, r;
                  if (
                    ((r = s),
                    null === (t = y()) &&
                      (45 === n.charCodeAt(s) ? ((t = '-'), s++) : ((t = null), 0 === a && u('"-"')),
                      null === t &&
                        (46 === n.charCodeAt(s) ? ((t = '.'), s++) : ((t = null), 0 === a && u('"."')),
                        null === t &&
                          (33 === n.charCodeAt(s) ? ((t = '!'), s++) : ((t = null), 0 === a && u('"!"')),
                          null === t &&
                            (37 === n.charCodeAt(s) ? ((t = '%'), s++) : ((t = null), 0 === a && u('"%"')),
                            null === t &&
                              (42 === n.charCodeAt(s) ? ((t = '*'), s++) : ((t = null), 0 === a && u('"*"')),
                              null === t &&
                                (95 === n.charCodeAt(s) ? ((t = '_'), s++) : ((t = null), 0 === a && u('"_"')),
                                null === t &&
                                  (43 === n.charCodeAt(s) ? ((t = '+'), s++) : ((t = null), 0 === a && u('"+"')),
                                  null === t &&
                                    (96 === n.charCodeAt(s) ? ((t = '`'), s++) : ((t = null), 0 === a && u('"`"')),
                                    null === t &&
                                      (39 === n.charCodeAt(s) ? ((t = "'"), s++) : ((t = null), 0 === a && u('"\'"')),
                                      null === t && (126 === n.charCodeAt(s) ? ((t = '~'), s++) : ((t = null), 0 === a && u('"~"')))))))))))),
                    null !== t)
                  )
                    for (e = []; null !== t; )
                      e.push(t),
                        null === (t = y()) &&
                          (45 === n.charCodeAt(s) ? ((t = '-'), s++) : ((t = null), 0 === a && u('"-"')),
                          null === t &&
                            (46 === n.charCodeAt(s) ? ((t = '.'), s++) : ((t = null), 0 === a && u('"."')),
                            null === t &&
                              (33 === n.charCodeAt(s) ? ((t = '!'), s++) : ((t = null), 0 === a && u('"!"')),
                              null === t &&
                                (37 === n.charCodeAt(s) ? ((t = '%'), s++) : ((t = null), 0 === a && u('"%"')),
                                null === t &&
                                  (42 === n.charCodeAt(s) ? ((t = '*'), s++) : ((t = null), 0 === a && u('"*"')),
                                  null === t &&
                                    (95 === n.charCodeAt(s) ? ((t = '_'), s++) : ((t = null), 0 === a && u('"_"')),
                                    null === t &&
                                      (43 === n.charCodeAt(s) ? ((t = '+'), s++) : ((t = null), 0 === a && u('"+"')),
                                      null === t &&
                                        (96 === n.charCodeAt(s) ? ((t = '`'), s++) : ((t = null), 0 === a && u('"`"')),
                                        null === t &&
                                          (39 === n.charCodeAt(s) ? ((t = "'"), s++) : ((t = null), 0 === a && u('"\'"')),
                                          null === t && (126 === n.charCodeAt(s) ? ((t = '~'), s++) : ((t = null), 0 === a && u('"~"'))))))))))));
                  else e = null;
                  null !== e && ((i = r), (e = n.substring(s, i)));
                  var i;
                  return null === e && (s = r), e;
                }
                function x() {
                  var e, t, r;
                  if (
                    ((r = s),
                    null === (t = y()) &&
                      (45 === n.charCodeAt(s) ? ((t = '-'), s++) : ((t = null), 0 === a && u('"-"')),
                      null === t &&
                        (33 === n.charCodeAt(s) ? ((t = '!'), s++) : ((t = null), 0 === a && u('"!"')),
                        null === t &&
                          (37 === n.charCodeAt(s) ? ((t = '%'), s++) : ((t = null), 0 === a && u('"%"')),
                          null === t &&
                            (42 === n.charCodeAt(s) ? ((t = '*'), s++) : ((t = null), 0 === a && u('"*"')),
                            null === t &&
                              (95 === n.charCodeAt(s) ? ((t = '_'), s++) : ((t = null), 0 === a && u('"_"')),
                              null === t &&
                                (43 === n.charCodeAt(s) ? ((t = '+'), s++) : ((t = null), 0 === a && u('"+"')),
                                null === t &&
                                  (96 === n.charCodeAt(s) ? ((t = '`'), s++) : ((t = null), 0 === a && u('"`"')),
                                  null === t &&
                                    (39 === n.charCodeAt(s) ? ((t = "'"), s++) : ((t = null), 0 === a && u('"\'"')),
                                    null === t && (126 === n.charCodeAt(s) ? ((t = '~'), s++) : ((t = null), 0 === a && u('"~"'))))))))))),
                    null !== t)
                  )
                    for (e = []; null !== t; )
                      e.push(t),
                        null === (t = y()) &&
                          (45 === n.charCodeAt(s) ? ((t = '-'), s++) : ((t = null), 0 === a && u('"-"')),
                          null === t &&
                            (33 === n.charCodeAt(s) ? ((t = '!'), s++) : ((t = null), 0 === a && u('"!"')),
                            null === t &&
                              (37 === n.charCodeAt(s) ? ((t = '%'), s++) : ((t = null), 0 === a && u('"%"')),
                              null === t &&
                                (42 === n.charCodeAt(s) ? ((t = '*'), s++) : ((t = null), 0 === a && u('"*"')),
                                null === t &&
                                  (95 === n.charCodeAt(s) ? ((t = '_'), s++) : ((t = null), 0 === a && u('"_"')),
                                  null === t &&
                                    (43 === n.charCodeAt(s) ? ((t = '+'), s++) : ((t = null), 0 === a && u('"+"')),
                                    null === t &&
                                      (96 === n.charCodeAt(s) ? ((t = '`'), s++) : ((t = null), 0 === a && u('"`"')),
                                      null === t &&
                                        (39 === n.charCodeAt(s) ? ((t = "'"), s++) : ((t = null), 0 === a && u('"\'"')),
                                        null === t && (126 === n.charCodeAt(s) ? ((t = '~'), s++) : ((t = null), 0 === a && u('"~"')))))))))));
                  else e = null;
                  null !== e && ((i = r), (e = n.substring(s, i)));
                  var i;
                  return null === e && (s = r), e;
                }
                function N() {
                  var e, t, r;
                  if (
                    ((r = s),
                    null === (t = y()) &&
                      (45 === n.charCodeAt(s) ? ((t = '-'), s++) : ((t = null), 0 === a && u('"-"')),
                      null === t &&
                        (46 === n.charCodeAt(s) ? ((t = '.'), s++) : ((t = null), 0 === a && u('"."')),
                        null === t &&
                          (33 === n.charCodeAt(s) ? ((t = '!'), s++) : ((t = null), 0 === a && u('"!"')),
                          null === t &&
                            (37 === n.charCodeAt(s) ? ((t = '%'), s++) : ((t = null), 0 === a && u('"%"')),
                            null === t &&
                              (42 === n.charCodeAt(s) ? ((t = '*'), s++) : ((t = null), 0 === a && u('"*"')),
                              null === t &&
                                (95 === n.charCodeAt(s) ? ((t = '_'), s++) : ((t = null), 0 === a && u('"_"')),
                                null === t &&
                                  (43 === n.charCodeAt(s) ? ((t = '+'), s++) : ((t = null), 0 === a && u('"+"')),
                                  null === t &&
                                    (96 === n.charCodeAt(s) ? ((t = '`'), s++) : ((t = null), 0 === a && u('"`"')),
                                    null === t &&
                                      (39 === n.charCodeAt(s) ? ((t = "'"), s++) : ((t = null), 0 === a && u('"\'"')),
                                      null === t &&
                                        (126 === n.charCodeAt(s) ? ((t = '~'), s++) : ((t = null), 0 === a && u('"~"')),
                                        null === t &&
                                          (40 === n.charCodeAt(s) ? ((t = '('), s++) : ((t = null), 0 === a && u('"("')),
                                          null === t &&
                                            (41 === n.charCodeAt(s) ? ((t = ')'), s++) : ((t = null), 0 === a && u('")"')),
                                            null === t &&
                                              (60 === n.charCodeAt(s) ? ((t = '<'), s++) : ((t = null), 0 === a && u('"<"')),
                                              null === t &&
                                                (62 === n.charCodeAt(s) ? ((t = '>'), s++) : ((t = null), 0 === a && u('">"')),
                                                null === t &&
                                                  (58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                                                  null === t &&
                                                    (92 === n.charCodeAt(s) ? ((t = '\\'), s++) : ((t = null), 0 === a && u('"\\\\"')),
                                                    null === t &&
                                                      null === (t = v()) &&
                                                      (47 === n.charCodeAt(s) ? ((t = '/'), s++) : ((t = null), 0 === a && u('"/"')),
                                                      null === t &&
                                                        (91 === n.charCodeAt(s) ? ((t = '['), s++) : ((t = null), 0 === a && u('"["')),
                                                        null === t &&
                                                          (93 === n.charCodeAt(s) ? ((t = ']'), s++) : ((t = null), 0 === a && u('"]"')),
                                                          null === t &&
                                                            (63 === n.charCodeAt(s) ? ((t = '?'), s++) : ((t = null), 0 === a && u('"?"')),
                                                            null === t &&
                                                              (123 === n.charCodeAt(s) ? ((t = '{'), s++) : ((t = null), 0 === a && u('"{"')),
                                                              null === t &&
                                                                (125 === n.charCodeAt(s) ? ((t = '}'), s++) : ((t = null), 0 === a && u('"}"')))))))))))))))))))))))),
                    null !== t)
                  )
                    for (e = []; null !== t; )
                      e.push(t),
                        null === (t = y()) &&
                          (45 === n.charCodeAt(s) ? ((t = '-'), s++) : ((t = null), 0 === a && u('"-"')),
                          null === t &&
                            (46 === n.charCodeAt(s) ? ((t = '.'), s++) : ((t = null), 0 === a && u('"."')),
                            null === t &&
                              (33 === n.charCodeAt(s) ? ((t = '!'), s++) : ((t = null), 0 === a && u('"!"')),
                              null === t &&
                                (37 === n.charCodeAt(s) ? ((t = '%'), s++) : ((t = null), 0 === a && u('"%"')),
                                null === t &&
                                  (42 === n.charCodeAt(s) ? ((t = '*'), s++) : ((t = null), 0 === a && u('"*"')),
                                  null === t &&
                                    (95 === n.charCodeAt(s) ? ((t = '_'), s++) : ((t = null), 0 === a && u('"_"')),
                                    null === t &&
                                      (43 === n.charCodeAt(s) ? ((t = '+'), s++) : ((t = null), 0 === a && u('"+"')),
                                      null === t &&
                                        (96 === n.charCodeAt(s) ? ((t = '`'), s++) : ((t = null), 0 === a && u('"`"')),
                                        null === t &&
                                          (39 === n.charCodeAt(s) ? ((t = "'"), s++) : ((t = null), 0 === a && u('"\'"')),
                                          null === t &&
                                            (126 === n.charCodeAt(s) ? ((t = '~'), s++) : ((t = null), 0 === a && u('"~"')),
                                            null === t &&
                                              (40 === n.charCodeAt(s) ? ((t = '('), s++) : ((t = null), 0 === a && u('"("')),
                                              null === t &&
                                                (41 === n.charCodeAt(s) ? ((t = ')'), s++) : ((t = null), 0 === a && u('")"')),
                                                null === t &&
                                                  (60 === n.charCodeAt(s) ? ((t = '<'), s++) : ((t = null), 0 === a && u('"<"')),
                                                  null === t &&
                                                    (62 === n.charCodeAt(s) ? ((t = '>'), s++) : ((t = null), 0 === a && u('">"')),
                                                    null === t &&
                                                      (58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                                                      null === t &&
                                                        (92 === n.charCodeAt(s) ? ((t = '\\'), s++) : ((t = null), 0 === a && u('"\\\\"')),
                                                        null === t &&
                                                          null === (t = v()) &&
                                                          (47 === n.charCodeAt(s) ? ((t = '/'), s++) : ((t = null), 0 === a && u('"/"')),
                                                          null === t &&
                                                            (91 === n.charCodeAt(s) ? ((t = '['), s++) : ((t = null), 0 === a && u('"["')),
                                                            null === t &&
                                                              (93 === n.charCodeAt(s) ? ((t = ']'), s++) : ((t = null), 0 === a && u('"]"')),
                                                              null === t &&
                                                                (63 === n.charCodeAt(s) ? ((t = '?'), s++) : ((t = null), 0 === a && u('"?"')),
                                                                null === t &&
                                                                  (123 === n.charCodeAt(s) ? ((t = '{'), s++) : ((t = null), 0 === a && u('"{"')),
                                                                  null === t &&
                                                                    (125 === n.charCodeAt(s) ? ((t = '}'), s++) : ((t = null), 0 === a && u('"}"'))))))))))))))))))))))));
                  else e = null;
                  null !== e && ((i = r), (e = n.substring(s, i)));
                  var i;
                  return null === e && (s = r), e;
                }
                function D() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    (o = s),
                    null !== (e = R())
                      ? (42 === n.charCodeAt(s) ? ((t = '*'), s++) : ((t = null), 0 === a && u('"*"')),
                        null !== t && null !== (r = R()) ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && (e = '*'),
                    null === e && (s = i),
                    e
                  );
                }
                function U() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    (o = s),
                    null !== (e = R())
                      ? (47 === n.charCodeAt(s) ? ((t = '/'), s++) : ((t = null), 0 === a && u('"/"')),
                        null !== t && null !== (r = R()) ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && (e = '/'),
                    null === e && (s = i),
                    e
                  );
                }
                function M() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    (o = s),
                    null !== (e = R())
                      ? (61 === n.charCodeAt(s) ? ((t = '='), s++) : ((t = null), 0 === a && u('"="')),
                        null !== t && null !== (r = R()) ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && (e = '='),
                    null === e && (s = i),
                    e
                  );
                }
                function L() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    (o = s),
                    null !== (e = R())
                      ? (40 === n.charCodeAt(s) ? ((t = '('), s++) : ((t = null), 0 === a && u('"("')),
                        null !== t && null !== (r = R()) ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && (e = '('),
                    null === e && (s = i),
                    e
                  );
                }
                function q() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    (o = s),
                    null !== (e = R())
                      ? (41 === n.charCodeAt(s) ? ((t = ')'), s++) : ((t = null), 0 === a && u('")"')),
                        null !== t && null !== (r = R()) ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && (e = ')'),
                    null === e && (s = i),
                    e
                  );
                }
                function H() {
                  var e, t, r, i;
                  return (
                    (r = s),
                    (i = s),
                    62 === n.charCodeAt(s) ? ((e = '>'), s++) : ((e = null), 0 === a && u('">"')),
                    null !== e && null !== (t = R()) ? (e = [e, t]) : ((e = null), (s = i)),
                    null !== e && (e = '>'),
                    null === e && (s = r),
                    e
                  );
                }
                function F() {
                  var e, t, r, i;
                  return (
                    (r = s),
                    (i = s),
                    null !== (e = R())
                      ? (60 === n.charCodeAt(s) ? ((t = '<'), s++) : ((t = null), 0 === a && u('"<"')), null !== t ? (e = [e, t]) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    null !== e && (e = '<'),
                    null === e && (s = r),
                    e
                  );
                }
                function j() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    (o = s),
                    null !== (e = R())
                      ? (44 === n.charCodeAt(s) ? ((t = ','), s++) : ((t = null), 0 === a && u('","')),
                        null !== t && null !== (r = R()) ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && (e = ','),
                    null === e && (s = i),
                    e
                  );
                }
                function G() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    (o = s),
                    null !== (e = R())
                      ? (59 === n.charCodeAt(s) ? ((t = ';'), s++) : ((t = null), 0 === a && u('";"')),
                        null !== t && null !== (r = R()) ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && (e = ';'),
                    null === e && (s = i),
                    e
                  );
                }
                function B() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    (o = s),
                    null !== (e = R())
                      ? (58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                        null !== t && null !== (r = R()) ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && (e = ':'),
                    null === e && (s = i),
                    e
                  );
                }
                function W() {
                  var e, t, n, r;
                  return (
                    (n = s), (r = s), null !== (e = R()) && null !== (t = v()) ? (e = [e, t]) : ((e = null), (s = r)), null !== e && (e = '"'), null === e && (s = n), e
                  );
                }
                function V() {
                  var e, t, n, r;
                  return (
                    (n = s), (r = s), null !== (e = v()) && null !== (t = R()) ? (e = [e, t]) : ((e = null), (s = r)), null !== e && (e = '"'), null === e && (s = n), e
                  );
                }
                function J() {
                  var e;
                  return (
                    /^[!-']/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u("[!-']")),
                    null === e &&
                      (/^[*-[]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[*-[]')),
                      null === e &&
                        (/^[\]-~]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[\\]-~]')), null === e && null === (e = k()) && (e = b()))),
                    e
                  );
                }
                function K() {
                  var e, t, r, i, a, o;
                  if (((a = s), (o = s), null !== (e = R())))
                    if (null !== (t = v())) {
                      for (r = [], null === (i = Y()) && (i = $()); null !== i; ) r.push(i), null === (i = Y()) && (i = $());
                      null !== r && null !== (i = v()) ? (e = [e, t, r, i]) : ((e = null), (s = o));
                    } else (e = null), (s = o);
                  else (e = null), (s = o);
                  null !== e && ((l = a), (e = n.substring(s, l)));
                  var l;
                  return null === e && (s = a), e;
                }
                function z() {
                  var e, t, r, i, a, o;
                  if (((a = s), (o = s), null !== (e = R())))
                    if (null !== (t = v())) {
                      for (r = [], null === (i = Y()) && (i = $()); null !== i; ) r.push(i), null === (i = Y()) && (i = $());
                      null !== r && null !== (i = v()) ? (e = [e, t, r, i]) : ((e = null), (s = o));
                    } else (e = null), (s = o);
                  else (e = null), (s = o);
                  null !== e && ((l = a), (e = n.substring(s - 1, l + 1)));
                  var l;
                  return null === e && (s = a), e;
                }
                function Y() {
                  var e;
                  return (
                    null === (e = b()) &&
                      (33 === n.charCodeAt(s) ? ((e = '!'), s++) : ((e = null), 0 === a && u('"!"')),
                      null === e &&
                        (/^[#-[]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[#-[]')),
                        null === e && (/^[\]-~]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[\\]-~]')), null === e && (e = k())))),
                    e
                  );
                }
                function $() {
                  var e, t, r;
                  return (
                    (r = s),
                    92 === n.charCodeAt(s) ? ((e = '\\'), s++) : ((e = null), 0 === a && u('"\\\\"')),
                    null !== e
                      ? (/^[\0-\t]/.test(n.charAt(s)) ? ((t = n.charAt(s)), s++) : ((t = null), 0 === a && u('[\\0-\\t]')),
                        null === t &&
                          (/^[\x0B-\f]/.test(n.charAt(s)) ? ((t = n.charAt(s)), s++) : ((t = null), 0 === a && u('[\\x0B-\\f]')),
                          null === t && (/^[\x0E-]/.test(n.charAt(s)) ? ((t = n.charAt(s)), s++) : ((t = null), 0 === a && u('[\\x0E-]')))),
                        null !== t ? (e = [e, t]) : ((e = null), (s = r)))
                      : ((e = null), (s = r)),
                    e
                  );
                }
                function X() {
                  var e, t, r, i, o, l;
                  return (
                    (o = s),
                    (l = s),
                    null !== (e = Z())
                      ? (58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                        null !== t && null !== (r = null !== (r = ne()) ? r : '') && null !== (i = ae()) ? (e = [e, t, r, i]) : ((e = null), (s = l)))
                      : ((e = null), (s = l)),
                    null !== e &&
                      (e = (function (e) {
                        try {
                          (Fn.uri = new qn(Fn.scheme, Fn.user, Fn.host, Fn.port)), delete Fn.scheme, delete Fn.user, delete Fn.host, delete Fn.host_type, delete Fn.port;
                        } catch (e) {
                          Fn = -1;
                        }
                      })()),
                    null === e && (s = o),
                    e
                  );
                }
                function Q() {
                  var e, t, i, o, l, c, d, h;
                  return (
                    (d = s),
                    (h = s),
                    null !== (e = Z())
                      ? (58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                        null !== t &&
                        null !== (i = null !== (i = ne()) ? i : '') &&
                        null !== (o = ae()) &&
                        null !== (l = ge()) &&
                        null !== (c = null !== (c = Oe()) ? c : '')
                          ? (e = [e, t, i, o, l, c])
                          : ((e = null), (s = h)))
                      : ((e = null), (s = h)),
                    null !== e &&
                      (e = (function (e) {
                        try {
                          (Fn.uri = new qn(Fn.scheme, Fn.user, Fn.host, Fn.port, Fn.uri_params, Fn.uri_headers)),
                            delete Fn.scheme,
                            delete Fn.user,
                            delete Fn.host,
                            delete Fn.host_type,
                            delete Fn.port,
                            delete Fn.uri_params,
                            'SIP_URI' === r && (Fn = Fn.uri);
                        } catch (e) {
                          Fn = -1;
                        }
                      })()),
                    null === e && (s = d),
                    e
                  );
                }
                function Z() {
                  var e;
                  return null === (e = ee()) && (e = te()), e;
                }
                function ee() {
                  var e, t;
                  (t = s),
                    'sips' === n.substr(s, 4).toLowerCase() ? ((e = n.substr(s, 4)), (s += 4)) : ((e = null), 0 === a && u('"sips"')),
                    null !== e && ((r = e), (e = void (Fn.scheme = r.toLowerCase())));
                  var r;
                  return null === e && (s = t), e;
                }
                function te() {
                  var e, t;
                  (t = s),
                    'sip' === n.substr(s, 3).toLowerCase() ? ((e = n.substr(s, 3)), (s += 3)) : ((e = null), 0 === a && u('"sip"')),
                    null !== e && ((r = e), (e = void (Fn.scheme = r.toLowerCase())));
                  var r;
                  return null === e && (s = t), e;
                }
                function ne() {
                  var e, t, r, i, o, l;
                  (i = s),
                    (o = s),
                    null !== (e = re())
                      ? ((l = s),
                        58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                        null !== t && null !== (r = se()) ? (t = [t, r]) : ((t = null), (s = l)),
                        null !== (t = null !== t ? t : '')
                          ? (64 === n.charCodeAt(s) ? ((r = '@'), s++) : ((r = null), 0 === a && u('"@"')), null !== r ? (e = [e, t, r]) : ((e = null), (s = o)))
                          : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && ((c = i), (e = void (Fn.user = decodeURIComponent(n.substring(s - 1, c)))));
                  var c;
                  return null === e && (s = i), e;
                }
                function re() {
                  var e, t;
                  if ((null === (t = C()) && null === (t = E()) && (t = ie()), null !== t))
                    for (e = []; null !== t; ) e.push(t), null === (t = C()) && null === (t = E()) && (t = ie());
                  else e = null;
                  return e;
                }
                function ie() {
                  var e;
                  return (
                    38 === n.charCodeAt(s) ? ((e = '&'), s++) : ((e = null), 0 === a && u('"&"')),
                    null === e &&
                      (61 === n.charCodeAt(s) ? ((e = '='), s++) : ((e = null), 0 === a && u('"="')),
                      null === e &&
                        (43 === n.charCodeAt(s) ? ((e = '+'), s++) : ((e = null), 0 === a && u('"+"')),
                        null === e &&
                          (36 === n.charCodeAt(s) ? ((e = '$'), s++) : ((e = null), 0 === a && u('"$"')),
                          null === e &&
                            (44 === n.charCodeAt(s) ? ((e = ','), s++) : ((e = null), 0 === a && u('","')),
                            null === e &&
                              (59 === n.charCodeAt(s) ? ((e = ';'), s++) : ((e = null), 0 === a && u('";"')),
                              null === e &&
                                (63 === n.charCodeAt(s) ? ((e = '?'), s++) : ((e = null), 0 === a && u('"?"')),
                                null === e && (47 === n.charCodeAt(s) ? ((e = '/'), s++) : ((e = null), 0 === a && u('"/"'))))))))),
                    e
                  );
                }
                function se() {
                  var e, t, r;
                  for (
                    r = s,
                      e = [],
                      null === (t = C()) &&
                        null === (t = E()) &&
                        (38 === n.charCodeAt(s) ? ((t = '&'), s++) : ((t = null), 0 === a && u('"&"')),
                        null === t &&
                          (61 === n.charCodeAt(s) ? ((t = '='), s++) : ((t = null), 0 === a && u('"="')),
                          null === t &&
                            (43 === n.charCodeAt(s) ? ((t = '+'), s++) : ((t = null), 0 === a && u('"+"')),
                            null === t &&
                              (36 === n.charCodeAt(s) ? ((t = '$'), s++) : ((t = null), 0 === a && u('"$"')),
                              null === t && (44 === n.charCodeAt(s) ? ((t = ','), s++) : ((t = null), 0 === a && u('","')))))));
                    null !== t;

                  )
                    e.push(t),
                      null === (t = C()) &&
                        null === (t = E()) &&
                        (38 === n.charCodeAt(s) ? ((t = '&'), s++) : ((t = null), 0 === a && u('"&"')),
                        null === t &&
                          (61 === n.charCodeAt(s) ? ((t = '='), s++) : ((t = null), 0 === a && u('"="')),
                          null === t &&
                            (43 === n.charCodeAt(s) ? ((t = '+'), s++) : ((t = null), 0 === a && u('"+"')),
                            null === t &&
                              (36 === n.charCodeAt(s) ? ((t = '$'), s++) : ((t = null), 0 === a && u('"$"')),
                              null === t && (44 === n.charCodeAt(s) ? ((t = ','), s++) : ((t = null), 0 === a && u('","')))))));
                  null !== e && ((i = r), (e = void (Fn.password = n.substring(s, i))));
                  var i;
                  return null === e && (s = r), e;
                }
                function ae() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    null !== (e = oe())
                      ? ((o = s),
                        58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                        null !== t && null !== (r = me()) ? (t = [t, r]) : ((t = null), (s = o)),
                        null !== (t = null !== t ? t : '') ? (e = [e, t]) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    e
                  );
                }
                function oe() {
                  var e, t;
                  (t = s), null === (e = le()) && null === (e = _e()) && (e = de()), null !== e && ((r = t), (Fn.host = n.substring(s, r).toLowerCase()), (e = Fn.host));
                  var r;
                  return null === e && (s = t), e;
                }
                function le() {
                  var e, t, r, i, o, l;
                  for (
                    i = s,
                      o = s,
                      e = [],
                      l = s,
                      null !== (t = ue())
                        ? (46 === n.charCodeAt(s) ? ((r = '.'), s++) : ((r = null), 0 === a && u('"."')), null !== r ? (t = [t, r]) : ((t = null), (s = l)))
                        : ((t = null), (s = l));
                    null !== t;

                  )
                    e.push(t),
                      (l = s),
                      null !== (t = ue())
                        ? (46 === n.charCodeAt(s) ? ((r = '.'), s++) : ((r = null), 0 === a && u('"."')), null !== r ? (t = [t, r]) : ((t = null), (s = l)))
                        : ((t = null), (s = l));
                  null !== e && null !== (t = ce())
                    ? (46 === n.charCodeAt(s) ? ((r = '.'), s++) : ((r = null), 0 === a && u('"."')),
                      null !== (r = null !== r ? r : '') ? (e = [e, t, r]) : ((e = null), (s = o)))
                    : ((e = null), (s = o)),
                    null !== e && ((c = i), (Fn.host_type = 'domain'), (e = n.substring(s, c)));
                  var c;
                  return null === e && (s = i), e;
                }
                function ue() {
                  var e, t, r, i;
                  if (((i = s), null !== (e = y()))) {
                    for (
                      t = [],
                        null === (r = y()) &&
                          (45 === n.charCodeAt(s) ? ((r = '-'), s++) : ((r = null), 0 === a && u('"-"')),
                          null === r && (95 === n.charCodeAt(s) ? ((r = '_'), s++) : ((r = null), 0 === a && u('"_"'))));
                      null !== r;

                    )
                      t.push(r),
                        null === (r = y()) &&
                          (45 === n.charCodeAt(s) ? ((r = '-'), s++) : ((r = null), 0 === a && u('"-"')),
                          null === r && (95 === n.charCodeAt(s) ? ((r = '_'), s++) : ((r = null), 0 === a && u('"_"'))));
                    null !== t ? (e = [e, t]) : ((e = null), (s = i));
                  } else (e = null), (s = i);
                  return e;
                }
                function ce() {
                  var e, t, r, i;
                  if (((i = s), null !== (e = h()))) {
                    for (
                      t = [],
                        null === (r = y()) &&
                          (45 === n.charCodeAt(s) ? ((r = '-'), s++) : ((r = null), 0 === a && u('"-"')),
                          null === r && (95 === n.charCodeAt(s) ? ((r = '_'), s++) : ((r = null), 0 === a && u('"_"'))));
                      null !== r;

                    )
                      t.push(r),
                        null === (r = y()) &&
                          (45 === n.charCodeAt(s) ? ((r = '-'), s++) : ((r = null), 0 === a && u('"-"')),
                          null === r && (95 === n.charCodeAt(s) ? ((r = '_'), s++) : ((r = null), 0 === a && u('"_"'))));
                    null !== t ? (e = [e, t]) : ((e = null), (s = i));
                  } else (e = null), (s = i);
                  return e;
                }
                function de() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    91 === n.charCodeAt(s) ? ((e = '['), s++) : ((e = null), 0 === a && u('"["')),
                    null !== e && null !== (t = he())
                      ? (93 === n.charCodeAt(s) ? ((r = ']'), s++) : ((r = null), 0 === a && u('"]"')), null !== r ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && ((l = i), (Fn.host_type = 'IPv6'), (e = n.substring(s, l)));
                  var l;
                  return null === e && (s = i), e;
                }
                function he() {
                  var e, t, r, i, o, l, c, d, h, f, p, _, v, m, g, y;
                  (m = s),
                    (g = s),
                    null !== (e = fe())
                      ? (58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                        null !== t && null !== (r = fe())
                          ? (58 === n.charCodeAt(s) ? ((i = ':'), s++) : ((i = null), 0 === a && u('":"')),
                            null !== i && null !== (o = fe())
                              ? (58 === n.charCodeAt(s) ? ((l = ':'), s++) : ((l = null), 0 === a && u('":"')),
                                null !== l && null !== (c = fe())
                                  ? (58 === n.charCodeAt(s) ? ((d = ':'), s++) : ((d = null), 0 === a && u('":"')),
                                    null !== d && null !== (h = fe())
                                      ? (58 === n.charCodeAt(s) ? ((f = ':'), s++) : ((f = null), 0 === a && u('":"')),
                                        null !== f && null !== (p = fe())
                                          ? (58 === n.charCodeAt(s) ? ((_ = ':'), s++) : ((_ = null), 0 === a && u('":"')),
                                            null !== _ && null !== (v = pe()) ? (e = [e, t, r, i, o, l, c, d, h, f, p, _, v]) : ((e = null), (s = g)))
                                          : ((e = null), (s = g)))
                                      : ((e = null), (s = g)))
                                  : ((e = null), (s = g)))
                              : ((e = null), (s = g)))
                          : ((e = null), (s = g)))
                      : ((e = null), (s = g)),
                    null === e &&
                      ((g = s),
                      '::' === n.substr(s, 2) ? ((e = '::'), (s += 2)) : ((e = null), 0 === a && u('"::"')),
                      null !== e && null !== (t = fe())
                        ? (58 === n.charCodeAt(s) ? ((r = ':'), s++) : ((r = null), 0 === a && u('":"')),
                          null !== r && null !== (i = fe())
                            ? (58 === n.charCodeAt(s) ? ((o = ':'), s++) : ((o = null), 0 === a && u('":"')),
                              null !== o && null !== (l = fe())
                                ? (58 === n.charCodeAt(s) ? ((c = ':'), s++) : ((c = null), 0 === a && u('":"')),
                                  null !== c && null !== (d = fe())
                                    ? (58 === n.charCodeAt(s) ? ((h = ':'), s++) : ((h = null), 0 === a && u('":"')),
                                      null !== h && null !== (f = fe())
                                        ? (58 === n.charCodeAt(s) ? ((p = ':'), s++) : ((p = null), 0 === a && u('":"')),
                                          null !== p && null !== (_ = pe()) ? (e = [e, t, r, i, o, l, c, d, h, f, p, _]) : ((e = null), (s = g)))
                                        : ((e = null), (s = g)))
                                    : ((e = null), (s = g)))
                                : ((e = null), (s = g)))
                            : ((e = null), (s = g)))
                        : ((e = null), (s = g)),
                      null === e &&
                        ((g = s),
                        '::' === n.substr(s, 2) ? ((e = '::'), (s += 2)) : ((e = null), 0 === a && u('"::"')),
                        null !== e && null !== (t = fe())
                          ? (58 === n.charCodeAt(s) ? ((r = ':'), s++) : ((r = null), 0 === a && u('":"')),
                            null !== r && null !== (i = fe())
                              ? (58 === n.charCodeAt(s) ? ((o = ':'), s++) : ((o = null), 0 === a && u('":"')),
                                null !== o && null !== (l = fe())
                                  ? (58 === n.charCodeAt(s) ? ((c = ':'), s++) : ((c = null), 0 === a && u('":"')),
                                    null !== c && null !== (d = fe())
                                      ? (58 === n.charCodeAt(s) ? ((h = ':'), s++) : ((h = null), 0 === a && u('":"')),
                                        null !== h && null !== (f = pe()) ? (e = [e, t, r, i, o, l, c, d, h, f]) : ((e = null), (s = g)))
                                      : ((e = null), (s = g)))
                                  : ((e = null), (s = g)))
                              : ((e = null), (s = g)))
                          : ((e = null), (s = g)),
                        null === e &&
                          ((g = s),
                          '::' === n.substr(s, 2) ? ((e = '::'), (s += 2)) : ((e = null), 0 === a && u('"::"')),
                          null !== e && null !== (t = fe())
                            ? (58 === n.charCodeAt(s) ? ((r = ':'), s++) : ((r = null), 0 === a && u('":"')),
                              null !== r && null !== (i = fe())
                                ? (58 === n.charCodeAt(s) ? ((o = ':'), s++) : ((o = null), 0 === a && u('":"')),
                                  null !== o && null !== (l = fe())
                                    ? (58 === n.charCodeAt(s) ? ((c = ':'), s++) : ((c = null), 0 === a && u('":"')),
                                      null !== c && null !== (d = pe()) ? (e = [e, t, r, i, o, l, c, d]) : ((e = null), (s = g)))
                                    : ((e = null), (s = g)))
                                : ((e = null), (s = g)))
                            : ((e = null), (s = g)),
                          null === e &&
                            ((g = s),
                            '::' === n.substr(s, 2) ? ((e = '::'), (s += 2)) : ((e = null), 0 === a && u('"::"')),
                            null !== e && null !== (t = fe())
                              ? (58 === n.charCodeAt(s) ? ((r = ':'), s++) : ((r = null), 0 === a && u('":"')),
                                null !== r && null !== (i = fe())
                                  ? (58 === n.charCodeAt(s) ? ((o = ':'), s++) : ((o = null), 0 === a && u('":"')),
                                    null !== o && null !== (l = pe()) ? (e = [e, t, r, i, o, l]) : ((e = null), (s = g)))
                                  : ((e = null), (s = g)))
                              : ((e = null), (s = g)),
                            null === e &&
                              ((g = s),
                              '::' === n.substr(s, 2) ? ((e = '::'), (s += 2)) : ((e = null), 0 === a && u('"::"')),
                              null !== e && null !== (t = fe())
                                ? (58 === n.charCodeAt(s) ? ((r = ':'), s++) : ((r = null), 0 === a && u('":"')),
                                  null !== r && null !== (i = pe()) ? (e = [e, t, r, i]) : ((e = null), (s = g)))
                                : ((e = null), (s = g)),
                              null === e &&
                                ((g = s),
                                '::' === n.substr(s, 2) ? ((e = '::'), (s += 2)) : ((e = null), 0 === a && u('"::"')),
                                null !== e && null !== (t = pe()) ? (e = [e, t]) : ((e = null), (s = g)),
                                null === e &&
                                  ((g = s),
                                  '::' === n.substr(s, 2) ? ((e = '::'), (s += 2)) : ((e = null), 0 === a && u('"::"')),
                                  null !== e && null !== (t = fe()) ? (e = [e, t]) : ((e = null), (s = g)),
                                  null === e &&
                                    ((g = s),
                                    null !== (e = fe())
                                      ? ('::' === n.substr(s, 2) ? ((t = '::'), (s += 2)) : ((t = null), 0 === a && u('"::"')),
                                        null !== t && null !== (r = fe())
                                          ? (58 === n.charCodeAt(s) ? ((i = ':'), s++) : ((i = null), 0 === a && u('":"')),
                                            null !== i && null !== (o = fe())
                                              ? (58 === n.charCodeAt(s) ? ((l = ':'), s++) : ((l = null), 0 === a && u('":"')),
                                                null !== l && null !== (c = fe())
                                                  ? (58 === n.charCodeAt(s) ? ((d = ':'), s++) : ((d = null), 0 === a && u('":"')),
                                                    null !== d && null !== (h = fe())
                                                      ? (58 === n.charCodeAt(s) ? ((f = ':'), s++) : ((f = null), 0 === a && u('":"')),
                                                        null !== f && null !== (p = pe()) ? (e = [e, t, r, i, o, l, c, d, h, f, p]) : ((e = null), (s = g)))
                                                      : ((e = null), (s = g)))
                                                  : ((e = null), (s = g)))
                                              : ((e = null), (s = g)))
                                          : ((e = null), (s = g)))
                                      : ((e = null), (s = g)),
                                    null === e &&
                                      ((g = s),
                                      null !== (e = fe())
                                        ? ((y = s),
                                          58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                                          null !== t && null !== (r = fe()) ? (t = [t, r]) : ((t = null), (s = y)),
                                          null !== (t = null !== t ? t : '')
                                            ? ('::' === n.substr(s, 2) ? ((r = '::'), (s += 2)) : ((r = null), 0 === a && u('"::"')),
                                              null !== r && null !== (i = fe())
                                                ? (58 === n.charCodeAt(s) ? ((o = ':'), s++) : ((o = null), 0 === a && u('":"')),
                                                  null !== o && null !== (l = fe())
                                                    ? (58 === n.charCodeAt(s) ? ((c = ':'), s++) : ((c = null), 0 === a && u('":"')),
                                                      null !== c && null !== (d = fe())
                                                        ? (58 === n.charCodeAt(s) ? ((h = ':'), s++) : ((h = null), 0 === a && u('":"')),
                                                          null !== h && null !== (f = pe()) ? (e = [e, t, r, i, o, l, c, d, h, f]) : ((e = null), (s = g)))
                                                        : ((e = null), (s = g)))
                                                    : ((e = null), (s = g)))
                                                : ((e = null), (s = g)))
                                            : ((e = null), (s = g)))
                                        : ((e = null), (s = g)),
                                      null === e &&
                                        ((g = s),
                                        null !== (e = fe())
                                          ? ((y = s),
                                            58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                                            null !== t && null !== (r = fe()) ? (t = [t, r]) : ((t = null), (s = y)),
                                            null !== (t = null !== t ? t : '')
                                              ? ((y = s),
                                                58 === n.charCodeAt(s) ? ((r = ':'), s++) : ((r = null), 0 === a && u('":"')),
                                                null !== r && null !== (i = fe()) ? (r = [r, i]) : ((r = null), (s = y)),
                                                null !== (r = null !== r ? r : '')
                                                  ? ('::' === n.substr(s, 2) ? ((i = '::'), (s += 2)) : ((i = null), 0 === a && u('"::"')),
                                                    null !== i && null !== (o = fe())
                                                      ? (58 === n.charCodeAt(s) ? ((l = ':'), s++) : ((l = null), 0 === a && u('":"')),
                                                        null !== l && null !== (c = fe())
                                                          ? (58 === n.charCodeAt(s) ? ((d = ':'), s++) : ((d = null), 0 === a && u('":"')),
                                                            null !== d && null !== (h = pe()) ? (e = [e, t, r, i, o, l, c, d, h]) : ((e = null), (s = g)))
                                                          : ((e = null), (s = g)))
                                                      : ((e = null), (s = g)))
                                                  : ((e = null), (s = g)))
                                              : ((e = null), (s = g)))
                                          : ((e = null), (s = g)),
                                        null === e &&
                                          ((g = s),
                                          null !== (e = fe())
                                            ? ((y = s),
                                              58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                                              null !== t && null !== (r = fe()) ? (t = [t, r]) : ((t = null), (s = y)),
                                              null !== (t = null !== t ? t : '')
                                                ? ((y = s),
                                                  58 === n.charCodeAt(s) ? ((r = ':'), s++) : ((r = null), 0 === a && u('":"')),
                                                  null !== r && null !== (i = fe()) ? (r = [r, i]) : ((r = null), (s = y)),
                                                  null !== (r = null !== r ? r : '')
                                                    ? ((y = s),
                                                      58 === n.charCodeAt(s) ? ((i = ':'), s++) : ((i = null), 0 === a && u('":"')),
                                                      null !== i && null !== (o = fe()) ? (i = [i, o]) : ((i = null), (s = y)),
                                                      null !== (i = null !== i ? i : '')
                                                        ? ('::' === n.substr(s, 2) ? ((o = '::'), (s += 2)) : ((o = null), 0 === a && u('"::"')),
                                                          null !== o && null !== (l = fe())
                                                            ? (58 === n.charCodeAt(s) ? ((c = ':'), s++) : ((c = null), 0 === a && u('":"')),
                                                              null !== c && null !== (d = pe()) ? (e = [e, t, r, i, o, l, c, d]) : ((e = null), (s = g)))
                                                            : ((e = null), (s = g)))
                                                        : ((e = null), (s = g)))
                                                    : ((e = null), (s = g)))
                                                : ((e = null), (s = g)))
                                            : ((e = null), (s = g)),
                                          null === e &&
                                            ((g = s),
                                            null !== (e = fe())
                                              ? ((y = s),
                                                58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                                                null !== t && null !== (r = fe()) ? (t = [t, r]) : ((t = null), (s = y)),
                                                null !== (t = null !== t ? t : '')
                                                  ? ((y = s),
                                                    58 === n.charCodeAt(s) ? ((r = ':'), s++) : ((r = null), 0 === a && u('":"')),
                                                    null !== r && null !== (i = fe()) ? (r = [r, i]) : ((r = null), (s = y)),
                                                    null !== (r = null !== r ? r : '')
                                                      ? ((y = s),
                                                        58 === n.charCodeAt(s) ? ((i = ':'), s++) : ((i = null), 0 === a && u('":"')),
                                                        null !== i && null !== (o = fe()) ? (i = [i, o]) : ((i = null), (s = y)),
                                                        null !== (i = null !== i ? i : '')
                                                          ? ((y = s),
                                                            58 === n.charCodeAt(s) ? ((o = ':'), s++) : ((o = null), 0 === a && u('":"')),
                                                            null !== o && null !== (l = fe()) ? (o = [o, l]) : ((o = null), (s = y)),
                                                            null !== (o = null !== o ? o : '')
                                                              ? ('::' === n.substr(s, 2) ? ((l = '::'), (s += 2)) : ((l = null), 0 === a && u('"::"')),
                                                                null !== l && null !== (c = pe()) ? (e = [e, t, r, i, o, l, c]) : ((e = null), (s = g)))
                                                              : ((e = null), (s = g)))
                                                          : ((e = null), (s = g)))
                                                      : ((e = null), (s = g)))
                                                  : ((e = null), (s = g)))
                                              : ((e = null), (s = g)),
                                            null === e &&
                                              ((g = s),
                                              null !== (e = fe())
                                                ? ((y = s),
                                                  58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                                                  null !== t && null !== (r = fe()) ? (t = [t, r]) : ((t = null), (s = y)),
                                                  null !== (t = null !== t ? t : '')
                                                    ? ((y = s),
                                                      58 === n.charCodeAt(s) ? ((r = ':'), s++) : ((r = null), 0 === a && u('":"')),
                                                      null !== r && null !== (i = fe()) ? (r = [r, i]) : ((r = null), (s = y)),
                                                      null !== (r = null !== r ? r : '')
                                                        ? ((y = s),
                                                          58 === n.charCodeAt(s) ? ((i = ':'), s++) : ((i = null), 0 === a && u('":"')),
                                                          null !== i && null !== (o = fe()) ? (i = [i, o]) : ((i = null), (s = y)),
                                                          null !== (i = null !== i ? i : '')
                                                            ? ((y = s),
                                                              58 === n.charCodeAt(s) ? ((o = ':'), s++) : ((o = null), 0 === a && u('":"')),
                                                              null !== o && null !== (l = fe()) ? (o = [o, l]) : ((o = null), (s = y)),
                                                              null !== (o = null !== o ? o : '')
                                                                ? ((y = s),
                                                                  58 === n.charCodeAt(s) ? ((l = ':'), s++) : ((l = null), 0 === a && u('":"')),
                                                                  null !== l && null !== (c = fe()) ? (l = [l, c]) : ((l = null), (s = y)),
                                                                  null !== (l = null !== l ? l : '')
                                                                    ? ('::' === n.substr(s, 2) ? ((c = '::'), (s += 2)) : ((c = null), 0 === a && u('"::"')),
                                                                      null !== c && null !== (d = fe()) ? (e = [e, t, r, i, o, l, c, d]) : ((e = null), (s = g)))
                                                                    : ((e = null), (s = g)))
                                                                : ((e = null), (s = g)))
                                                            : ((e = null), (s = g)))
                                                        : ((e = null), (s = g)))
                                                    : ((e = null), (s = g)))
                                                : ((e = null), (s = g)),
                                              null === e &&
                                                ((g = s),
                                                null !== (e = fe())
                                                  ? ((y = s),
                                                    58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                                                    null !== t && null !== (r = fe()) ? (t = [t, r]) : ((t = null), (s = y)),
                                                    null !== (t = null !== t ? t : '')
                                                      ? ((y = s),
                                                        58 === n.charCodeAt(s) ? ((r = ':'), s++) : ((r = null), 0 === a && u('":"')),
                                                        null !== r && null !== (i = fe()) ? (r = [r, i]) : ((r = null), (s = y)),
                                                        null !== (r = null !== r ? r : '')
                                                          ? ((y = s),
                                                            58 === n.charCodeAt(s) ? ((i = ':'), s++) : ((i = null), 0 === a && u('":"')),
                                                            null !== i && null !== (o = fe()) ? (i = [i, o]) : ((i = null), (s = y)),
                                                            null !== (i = null !== i ? i : '')
                                                              ? ((y = s),
                                                                58 === n.charCodeAt(s) ? ((o = ':'), s++) : ((o = null), 0 === a && u('":"')),
                                                                null !== o && null !== (l = fe()) ? (o = [o, l]) : ((o = null), (s = y)),
                                                                null !== (o = null !== o ? o : '')
                                                                  ? ((y = s),
                                                                    58 === n.charCodeAt(s) ? ((l = ':'), s++) : ((l = null), 0 === a && u('":"')),
                                                                    null !== l && null !== (c = fe()) ? (l = [l, c]) : ((l = null), (s = y)),
                                                                    null !== (l = null !== l ? l : '')
                                                                      ? ((y = s),
                                                                        58 === n.charCodeAt(s) ? ((c = ':'), s++) : ((c = null), 0 === a && u('":"')),
                                                                        null !== c && null !== (d = fe()) ? (c = [c, d]) : ((c = null), (s = y)),
                                                                        null !== (c = null !== c ? c : '')
                                                                          ? ('::' === n.substr(s, 2) ? ((d = '::'), (s += 2)) : ((d = null), 0 === a && u('"::"')),
                                                                            null !== d ? (e = [e, t, r, i, o, l, c, d]) : ((e = null), (s = g)))
                                                                          : ((e = null), (s = g)))
                                                                      : ((e = null), (s = g)))
                                                                  : ((e = null), (s = g)))
                                                              : ((e = null), (s = g)))
                                                          : ((e = null), (s = g)))
                                                      : ((e = null), (s = g)))
                                                  : ((e = null), (s = g)))))))))))))))),
                    null !== e && ((T = m), (Fn.host_type = 'IPv6'), (e = n.substring(s, T)));
                  var T;
                  return null === e && (s = m), e;
                }
                function fe() {
                  var e, t, n, r, i;
                  return (
                    (i = s),
                    null !== (e = f()) &&
                    null !== (t = null !== (t = f()) ? t : '') &&
                    null !== (n = null !== (n = f()) ? n : '') &&
                    null !== (r = null !== (r = f()) ? r : '')
                      ? (e = [e, t, n, r])
                      : ((e = null), (s = i)),
                    e
                  );
                }
                function pe() {
                  var e, t, r, i;
                  return (
                    (i = s),
                    null !== (e = fe())
                      ? (58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                        null !== t && null !== (r = fe()) ? (e = [e, t, r]) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    null === e && (e = _e()),
                    e
                  );
                }
                function _e() {
                  var e, t, r, i, o, l, c, d, h;
                  (d = s),
                    (h = s),
                    null !== (e = ve())
                      ? (46 === n.charCodeAt(s) ? ((t = '.'), s++) : ((t = null), 0 === a && u('"."')),
                        null !== t && null !== (r = ve())
                          ? (46 === n.charCodeAt(s) ? ((i = '.'), s++) : ((i = null), 0 === a && u('"."')),
                            null !== i && null !== (o = ve())
                              ? (46 === n.charCodeAt(s) ? ((l = '.'), s++) : ((l = null), 0 === a && u('"."')),
                                null !== l && null !== (c = ve()) ? (e = [e, t, r, i, o, l, c]) : ((e = null), (s = h)))
                              : ((e = null), (s = h)))
                          : ((e = null), (s = h)))
                      : ((e = null), (s = h)),
                    null !== e && ((f = d), (Fn.host_type = 'IPv4'), (e = n.substring(s, f)));
                  var f;
                  return null === e && (s = d), e;
                }
                function ve() {
                  var e, t, r, i;
                  return (
                    (i = s),
                    '25' === n.substr(s, 2) ? ((e = '25'), (s += 2)) : ((e = null), 0 === a && u('"25"')),
                    null !== e
                      ? (/^[0-5]/.test(n.charAt(s)) ? ((t = n.charAt(s)), s++) : ((t = null), 0 === a && u('[0-5]')), null !== t ? (e = [e, t]) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    null === e &&
                      ((i = s),
                      50 === n.charCodeAt(s) ? ((e = '2'), s++) : ((e = null), 0 === a && u('"2"')),
                      null !== e
                        ? (/^[0-4]/.test(n.charAt(s)) ? ((t = n.charAt(s)), s++) : ((t = null), 0 === a && u('[0-4]')),
                          null !== t && null !== (r = d()) ? (e = [e, t, r]) : ((e = null), (s = i)))
                        : ((e = null), (s = i)),
                      null === e &&
                        ((i = s),
                        49 === n.charCodeAt(s) ? ((e = '1'), s++) : ((e = null), 0 === a && u('"1"')),
                        null !== e && null !== (t = d()) && null !== (r = d()) ? (e = [e, t, r]) : ((e = null), (s = i)),
                        null === e &&
                          ((i = s),
                          /^[1-9]/.test(n.charAt(s)) ? ((e = n.charAt(s)), s++) : ((e = null), 0 === a && u('[1-9]')),
                          null !== e && null !== (t = d()) ? (e = [e, t]) : ((e = null), (s = i)),
                          null === e && (e = d())))),
                    e
                  );
                }
                function me() {
                  var e, t, n, r, i, a, o;
                  (a = s),
                    (o = s),
                    null !== (e = null !== (e = d()) ? e : '') &&
                    null !== (t = null !== (t = d()) ? t : '') &&
                    null !== (n = null !== (n = d()) ? n : '') &&
                    null !== (r = null !== (r = d()) ? r : '') &&
                    null !== (i = null !== (i = d()) ? i : '')
                      ? (e = [e, t, n, r, i])
                      : ((e = null), (s = o)),
                    null !== e && ((l = e), (l = parseInt(l.join(''))), (Fn.port = l), (e = l));
                  var l;
                  return null === e && (s = a), e;
                }
                function ge() {
                  var e, t, r, i;
                  for (
                    e = [],
                      i = s,
                      59 === n.charCodeAt(s) ? ((t = ';'), s++) : ((t = null), 0 === a && u('";"')),
                      null !== t && null !== (r = ye()) ? (t = [t, r]) : ((t = null), (s = i));
                    null !== t;

                  )
                    e.push(t),
                      (i = s),
                      59 === n.charCodeAt(s) ? ((t = ';'), s++) : ((t = null), 0 === a && u('";"')),
                      null !== t && null !== (r = ye()) ? (t = [t, r]) : ((t = null), (s = i));
                  return e;
                }
                function ye() {
                  var e;
                  return (
                    null === (e = Te()) && null === (e = Ce()) && null === (e = Se()) && null === (e = Ee()) && null === (e = be()) && null === (e = Re()) && (e = Ae()),
                    e
                  );
                }
                function Te() {
                  var e, t, r, i;
                  (r = s),
                    (i = s),
                    'transport=' === n.substr(s, 10).toLowerCase() ? ((e = n.substr(s, 10)), (s += 10)) : ((e = null), 0 === a && u('"transport="')),
                    null !== e
                      ? ('udp' === n.substr(s, 3).toLowerCase() ? ((t = n.substr(s, 3)), (s += 3)) : ((t = null), 0 === a && u('"udp"')),
                        null === t &&
                          ('tcp' === n.substr(s, 3).toLowerCase() ? ((t = n.substr(s, 3)), (s += 3)) : ((t = null), 0 === a && u('"tcp"')),
                          null === t &&
                            ('sctp' === n.substr(s, 4).toLowerCase() ? ((t = n.substr(s, 4)), (s += 4)) : ((t = null), 0 === a && u('"sctp"')),
                            null === t &&
                              ('tls' === n.substr(s, 3).toLowerCase() ? ((t = n.substr(s, 3)), (s += 3)) : ((t = null), 0 === a && u('"tls"')),
                              null === t && (t = O())))),
                        null !== t ? (e = [e, t]) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    null !== e && ((o = e[1]), Fn.uri_params || (Fn.uri_params = {}), (e = void (Fn.uri_params.transport = o.toLowerCase())));
                  var o;
                  return null === e && (s = r), e;
                }
                function Ce() {
                  var e, t, r, i;
                  (r = s),
                    (i = s),
                    'user=' === n.substr(s, 5).toLowerCase() ? ((e = n.substr(s, 5)), (s += 5)) : ((e = null), 0 === a && u('"user="')),
                    null !== e
                      ? ('phone' === n.substr(s, 5).toLowerCase() ? ((t = n.substr(s, 5)), (s += 5)) : ((t = null), 0 === a && u('"phone"')),
                        null === t &&
                          ('ip' === n.substr(s, 2).toLowerCase() ? ((t = n.substr(s, 2)), (s += 2)) : ((t = null), 0 === a && u('"ip"')), null === t && (t = O())),
                        null !== t ? (e = [e, t]) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    null !== e && ((o = e[1]), Fn.uri_params || (Fn.uri_params = {}), (e = void (Fn.uri_params.user = o.toLowerCase())));
                  var o;
                  return null === e && (s = r), e;
                }
                function Se() {
                  var e, t, r, i;
                  (r = s),
                    (i = s),
                    'method=' === n.substr(s, 7).toLowerCase() ? ((e = n.substr(s, 7)), (s += 7)) : ((e = null), 0 === a && u('"method="')),
                    null !== e && null !== (t = ct()) ? (e = [e, t]) : ((e = null), (s = i)),
                    null !== e && ((o = e[1]), Fn.uri_params || (Fn.uri_params = {}), (e = void (Fn.uri_params.method = o)));
                  var o;
                  return null === e && (s = r), e;
                }
                function Ee() {
                  var e, t, r, i;
                  (r = s),
                    (i = s),
                    'ttl=' === n.substr(s, 4).toLowerCase() ? ((e = n.substr(s, 4)), (s += 4)) : ((e = null), 0 === a && u('"ttl="')),
                    null !== e && null !== (t = bn()) ? (e = [e, t]) : ((e = null), (s = i)),
                    null !== e && ((o = e[1]), Fn.params || (Fn.params = {}), (e = void (Fn.params.ttl = o)));
                  var o;
                  return null === e && (s = r), e;
                }
                function be() {
                  var e, t, r, i;
                  (r = s),
                    (i = s),
                    'maddr=' === n.substr(s, 6).toLowerCase() ? ((e = n.substr(s, 6)), (s += 6)) : ((e = null), 0 === a && u('"maddr="')),
                    null !== e && null !== (t = oe()) ? (e = [e, t]) : ((e = null), (s = i)),
                    null !== e && ((o = e[1]), Fn.uri_params || (Fn.uri_params = {}), (e = void (Fn.uri_params.maddr = o)));
                  var o;
                  return null === e && (s = r), e;
                }
                function Re() {
                  var e, t, r, i, o, l;
                  return (
                    (i = s),
                    (o = s),
                    'lr' === n.substr(s, 2).toLowerCase() ? ((e = n.substr(s, 2)), (s += 2)) : ((e = null), 0 === a && u('"lr"')),
                    null !== e
                      ? ((l = s),
                        61 === n.charCodeAt(s) ? ((t = '='), s++) : ((t = null), 0 === a && u('"="')),
                        null !== t && null !== (r = O()) ? (t = [t, r]) : ((t = null), (s = l)),
                        null !== (t = null !== t ? t : '') ? (e = [e, t]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && (Fn.uri_params || (Fn.uri_params = {}), (e = void (Fn.uri_params.lr = void 0))),
                    null === e && (s = i),
                    e
                  );
                }
                function Ae() {
                  var e, t, r, i, o, l;
                  (i = s),
                    (o = s),
                    null !== (e = we())
                      ? ((l = s),
                        61 === n.charCodeAt(s) ? ((t = '='), s++) : ((t = null), 0 === a && u('"="')),
                        null !== t && null !== (r = Ie()) ? (t = [t, r]) : ((t = null), (s = l)),
                        null !== (t = null !== t ? t : '') ? (e = [e, t]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e &&
                      ((c = e[0]),
                      (d = e[1]),
                      Fn.uri_params || (Fn.uri_params = {}),
                      (d = void 0 === d ? void 0 : d[1]),
                      (e = void (Fn.uri_params[c.toLowerCase()] = d)));
                  var c, d;
                  return null === e && (s = i), e;
                }
                function we() {
                  var e, t, n;
                  if (((n = s), null !== (t = ke()))) for (e = []; null !== t; ) e.push(t), (t = ke());
                  else e = null;
                  return null !== e && (e = e.join('')), null === e && (s = n), e;
                }
                function Ie() {
                  var e, t, n;
                  if (((n = s), null !== (t = ke()))) for (e = []; null !== t; ) e.push(t), (t = ke());
                  else e = null;
                  return null !== e && (e = e.join('')), null === e && (s = n), e;
                }
                function ke() {
                  var e;
                  return null === (e = Pe()) && null === (e = C()) && (e = E()), e;
                }
                function Pe() {
                  var e;
                  return (
                    91 === n.charCodeAt(s) ? ((e = '['), s++) : ((e = null), 0 === a && u('"["')),
                    null === e &&
                      (93 === n.charCodeAt(s) ? ((e = ']'), s++) : ((e = null), 0 === a && u('"]"')),
                      null === e &&
                        (47 === n.charCodeAt(s) ? ((e = '/'), s++) : ((e = null), 0 === a && u('"/"')),
                        null === e &&
                          (58 === n.charCodeAt(s) ? ((e = ':'), s++) : ((e = null), 0 === a && u('":"')),
                          null === e &&
                            (38 === n.charCodeAt(s) ? ((e = '&'), s++) : ((e = null), 0 === a && u('"&"')),
                            null === e &&
                              (43 === n.charCodeAt(s) ? ((e = '+'), s++) : ((e = null), 0 === a && u('"+"')),
                              null === e && (36 === n.charCodeAt(s) ? ((e = '$'), s++) : ((e = null), 0 === a && u('"$"')))))))),
                    e
                  );
                }
                function Oe() {
                  var e, t, r, i, o, l, c;
                  if (((l = s), 63 === n.charCodeAt(s) ? ((e = '?'), s++) : ((e = null), 0 === a && u('"?"')), null !== e))
                    if (null !== (t = xe())) {
                      for (
                        r = [],
                          c = s,
                          38 === n.charCodeAt(s) ? ((i = '&'), s++) : ((i = null), 0 === a && u('"&"')),
                          null !== i && null !== (o = xe()) ? (i = [i, o]) : ((i = null), (s = c));
                        null !== i;

                      )
                        r.push(i),
                          (c = s),
                          38 === n.charCodeAt(s) ? ((i = '&'), s++) : ((i = null), 0 === a && u('"&"')),
                          null !== i && null !== (o = xe()) ? (i = [i, o]) : ((i = null), (s = c));
                      null !== r ? (e = [e, t, r]) : ((e = null), (s = l));
                    } else (e = null), (s = l);
                  else (e = null), (s = l);
                  return e;
                }
                function xe() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    null !== (e = Ne())
                      ? (61 === n.charCodeAt(s) ? ((t = '='), s++) : ((t = null), 0 === a && u('"="')),
                        null !== t && null !== (r = De()) ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e &&
                      ((l = e[0]),
                      (c = e[2]),
                      (l = l.join('').toLowerCase()),
                      (c = c.join('')),
                      Fn.uri_headers || (Fn.uri_headers = {}),
                      (e = void (Fn.uri_headers[l] ? Fn.uri_headers[l].push(c) : (Fn.uri_headers[l] = [c]))));
                  var l, c;
                  return null === e && (s = i), e;
                }
                function Ne() {
                  var e, t;
                  if ((null === (t = Ue()) && null === (t = C()) && (t = E()), null !== t))
                    for (e = []; null !== t; ) e.push(t), null === (t = Ue()) && null === (t = C()) && (t = E());
                  else e = null;
                  return e;
                }
                function De() {
                  var e, t;
                  for (e = [], null === (t = Ue()) && null === (t = C()) && (t = E()); null !== t; ) e.push(t), null === (t = Ue()) && null === (t = C()) && (t = E());
                  return e;
                }
                function Ue() {
                  var e;
                  return (
                    91 === n.charCodeAt(s) ? ((e = '['), s++) : ((e = null), 0 === a && u('"["')),
                    null === e &&
                      (93 === n.charCodeAt(s) ? ((e = ']'), s++) : ((e = null), 0 === a && u('"]"')),
                      null === e &&
                        (47 === n.charCodeAt(s) ? ((e = '/'), s++) : ((e = null), 0 === a && u('"/"')),
                        null === e &&
                          (63 === n.charCodeAt(s) ? ((e = '?'), s++) : ((e = null), 0 === a && u('"?"')),
                          null === e &&
                            (58 === n.charCodeAt(s) ? ((e = ':'), s++) : ((e = null), 0 === a && u('":"')),
                            null === e &&
                              (43 === n.charCodeAt(s) ? ((e = '+'), s++) : ((e = null), 0 === a && u('"+"')),
                              null === e && (36 === n.charCodeAt(s) ? ((e = '$'), s++) : ((e = null), 0 === a && u('"$"')))))))),
                    e
                  );
                }
                function Me() {
                  var e, t, n, r, i, a;
                  return (
                    (a = s),
                    null !== (e = ct()) && null !== (t = m()) && null !== (n = Le()) && null !== (r = m()) && null !== (i = et())
                      ? (e = [e, t, n, r, i])
                      : ((e = null), (s = a)),
                    e
                  );
                }
                function Le() {
                  var e;
                  return null === (e = Q()) && (e = qe()), e;
                }
                function qe() {
                  var e, t, r, i;
                  return (
                    (i = s),
                    null !== (e = Ye())
                      ? (58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                        null !== t ? (null === (r = He()) && (r = Ge()), null !== r ? (e = [e, t, r]) : ((e = null), (s = i))) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    e
                  );
                }
                function He() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    null === (e = Fe()) && (e = je()),
                    null !== e
                      ? ((o = s),
                        63 === n.charCodeAt(s) ? ((t = '?'), s++) : ((t = null), 0 === a && u('"?"')),
                        null !== t && null !== (r = Ze()) ? (t = [t, r]) : ((t = null), (s = o)),
                        null !== (t = null !== t ? t : '') ? (e = [e, t]) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    e
                  );
                }
                function Fe() {
                  var e, t, r, i;
                  return (
                    (i = s),
                    '//' === n.substr(s, 2) ? ((e = '//'), (s += 2)) : ((e = null), 0 === a && u('"//"')),
                    null !== e && null !== (t = $e()) && null !== (r = null !== (r = je()) ? r : '') ? (e = [e, t, r]) : ((e = null), (s = i)),
                    e
                  );
                }
                function je() {
                  var e, t, r;
                  return (
                    (r = s),
                    47 === n.charCodeAt(s) ? ((e = '/'), s++) : ((e = null), 0 === a && u('"/"')),
                    null !== e && null !== (t = Ve()) ? (e = [e, t]) : ((e = null), (s = r)),
                    e
                  );
                }
                function Ge() {
                  var e, t, n, r;
                  if (((r = s), null !== (e = We()))) {
                    for (t = [], n = Be(); null !== n; ) t.push(n), (n = Be());
                    null !== t ? (e = [e, t]) : ((e = null), (s = r));
                  } else (e = null), (s = r);
                  return e;
                }
                function Be() {
                  var e;
                  return null === (e = T()) && null === (e = C()) && (e = E()), e;
                }
                function We() {
                  var e;
                  return (
                    null === (e = C()) &&
                      null === (e = E()) &&
                      (59 === n.charCodeAt(s) ? ((e = ';'), s++) : ((e = null), 0 === a && u('";"')),
                      null === e &&
                        (63 === n.charCodeAt(s) ? ((e = '?'), s++) : ((e = null), 0 === a && u('"?"')),
                        null === e &&
                          (58 === n.charCodeAt(s) ? ((e = ':'), s++) : ((e = null), 0 === a && u('":"')),
                          null === e &&
                            (64 === n.charCodeAt(s) ? ((e = '@'), s++) : ((e = null), 0 === a && u('"@"')),
                            null === e &&
                              (38 === n.charCodeAt(s) ? ((e = '&'), s++) : ((e = null), 0 === a && u('"&"')),
                              null === e &&
                                (61 === n.charCodeAt(s) ? ((e = '='), s++) : ((e = null), 0 === a && u('"="')),
                                null === e &&
                                  (43 === n.charCodeAt(s) ? ((e = '+'), s++) : ((e = null), 0 === a && u('"+"')),
                                  null === e &&
                                    (36 === n.charCodeAt(s) ? ((e = '$'), s++) : ((e = null), 0 === a && u('"$"')),
                                    null === e && (44 === n.charCodeAt(s) ? ((e = ','), s++) : ((e = null), 0 === a && u('","'))))))))))),
                    e
                  );
                }
                function Ve() {
                  var e, t, r, i, o, l;
                  if (((o = s), null !== (e = Je()))) {
                    for (
                      t = [],
                        l = s,
                        47 === n.charCodeAt(s) ? ((r = '/'), s++) : ((r = null), 0 === a && u('"/"')),
                        null !== r && null !== (i = Je()) ? (r = [r, i]) : ((r = null), (s = l));
                      null !== r;

                    )
                      t.push(r),
                        (l = s),
                        47 === n.charCodeAt(s) ? ((r = '/'), s++) : ((r = null), 0 === a && u('"/"')),
                        null !== r && null !== (i = Je()) ? (r = [r, i]) : ((r = null), (s = l));
                    null !== t ? (e = [e, t]) : ((e = null), (s = o));
                  } else (e = null), (s = o);
                  return e;
                }
                function Je() {
                  var e, t, r, i, o, l;
                  for (o = s, e = [], t = ze(); null !== t; ) e.push(t), (t = ze());
                  if (null !== e) {
                    for (
                      t = [],
                        l = s,
                        59 === n.charCodeAt(s) ? ((r = ';'), s++) : ((r = null), 0 === a && u('";"')),
                        null !== r && null !== (i = Ke()) ? (r = [r, i]) : ((r = null), (s = l));
                      null !== r;

                    )
                      t.push(r),
                        (l = s),
                        59 === n.charCodeAt(s) ? ((r = ';'), s++) : ((r = null), 0 === a && u('";"')),
                        null !== r && null !== (i = Ke()) ? (r = [r, i]) : ((r = null), (s = l));
                    null !== t ? (e = [e, t]) : ((e = null), (s = o));
                  } else (e = null), (s = o);
                  return e;
                }
                function Ke() {
                  var e, t;
                  for (e = [], t = ze(); null !== t; ) e.push(t), (t = ze());
                  return e;
                }
                function ze() {
                  var e;
                  return (
                    null === (e = C()) &&
                      null === (e = E()) &&
                      (58 === n.charCodeAt(s) ? ((e = ':'), s++) : ((e = null), 0 === a && u('":"')),
                      null === e &&
                        (64 === n.charCodeAt(s) ? ((e = '@'), s++) : ((e = null), 0 === a && u('"@"')),
                        null === e &&
                          (38 === n.charCodeAt(s) ? ((e = '&'), s++) : ((e = null), 0 === a && u('"&"')),
                          null === e &&
                            (61 === n.charCodeAt(s) ? ((e = '='), s++) : ((e = null), 0 === a && u('"="')),
                            null === e &&
                              (43 === n.charCodeAt(s) ? ((e = '+'), s++) : ((e = null), 0 === a && u('"+"')),
                              null === e &&
                                (36 === n.charCodeAt(s) ? ((e = '$'), s++) : ((e = null), 0 === a && u('"$"')),
                                null === e && (44 === n.charCodeAt(s) ? ((e = ','), s++) : ((e = null), 0 === a && u('","'))))))))),
                    e
                  );
                }
                function Ye() {
                  var e, t, r, i, o;
                  if (((i = s), (o = s), null !== (e = h()))) {
                    for (
                      t = [],
                        null === (r = h()) &&
                          null === (r = d()) &&
                          (43 === n.charCodeAt(s) ? ((r = '+'), s++) : ((r = null), 0 === a && u('"+"')),
                          null === r &&
                            (45 === n.charCodeAt(s) ? ((r = '-'), s++) : ((r = null), 0 === a && u('"-"')),
                            null === r && (46 === n.charCodeAt(s) ? ((r = '.'), s++) : ((r = null), 0 === a && u('"."')))));
                      null !== r;

                    )
                      t.push(r),
                        null === (r = h()) &&
                          null === (r = d()) &&
                          (43 === n.charCodeAt(s) ? ((r = '+'), s++) : ((r = null), 0 === a && u('"+"')),
                          null === r &&
                            (45 === n.charCodeAt(s) ? ((r = '-'), s++) : ((r = null), 0 === a && u('"-"')),
                            null === r && (46 === n.charCodeAt(s) ? ((r = '.'), s++) : ((r = null), 0 === a && u('"."')))));
                    null !== t ? (e = [e, t]) : ((e = null), (s = o));
                  } else (e = null), (s = o);
                  null !== e && ((l = i), (e = void (Fn.scheme = n.substring(s, l))));
                  var l;
                  return null === e && (s = i), e;
                }
                function $e() {
                  var e;
                  return null === (e = Xe()) && (e = Qe()), e;
                }
                function Xe() {
                  var e, t, r, i;
                  return (
                    (r = s),
                    (i = s),
                    null !== (e = ne())
                      ? (64 === n.charCodeAt(s) ? ((t = '@'), s++) : ((t = null), 0 === a && u('"@"')), null !== t ? (e = [e, t]) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    null !== (e = null !== e ? e : '') && null !== (t = ae()) ? (e = [e, t]) : ((e = null), (s = r)),
                    (e = null !== e ? e : '')
                  );
                }
                function Qe() {
                  var e, t;
                  if (
                    (null === (t = C()) &&
                      null === (t = E()) &&
                      (36 === n.charCodeAt(s) ? ((t = '$'), s++) : ((t = null), 0 === a && u('"$"')),
                      null === t &&
                        (44 === n.charCodeAt(s) ? ((t = ','), s++) : ((t = null), 0 === a && u('","')),
                        null === t &&
                          (59 === n.charCodeAt(s) ? ((t = ';'), s++) : ((t = null), 0 === a && u('";"')),
                          null === t &&
                            (58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                            null === t &&
                              (64 === n.charCodeAt(s) ? ((t = '@'), s++) : ((t = null), 0 === a && u('"@"')),
                              null === t &&
                                (38 === n.charCodeAt(s) ? ((t = '&'), s++) : ((t = null), 0 === a && u('"&"')),
                                null === t &&
                                  (61 === n.charCodeAt(s) ? ((t = '='), s++) : ((t = null), 0 === a && u('"="')),
                                  null === t && (43 === n.charCodeAt(s) ? ((t = '+'), s++) : ((t = null), 0 === a && u('"+"')))))))))),
                    null !== t)
                  )
                    for (e = []; null !== t; )
                      e.push(t),
                        null === (t = C()) &&
                          null === (t = E()) &&
                          (36 === n.charCodeAt(s) ? ((t = '$'), s++) : ((t = null), 0 === a && u('"$"')),
                          null === t &&
                            (44 === n.charCodeAt(s) ? ((t = ','), s++) : ((t = null), 0 === a && u('","')),
                            null === t &&
                              (59 === n.charCodeAt(s) ? ((t = ';'), s++) : ((t = null), 0 === a && u('";"')),
                              null === t &&
                                (58 === n.charCodeAt(s) ? ((t = ':'), s++) : ((t = null), 0 === a && u('":"')),
                                null === t &&
                                  (64 === n.charCodeAt(s) ? ((t = '@'), s++) : ((t = null), 0 === a && u('"@"')),
                                  null === t &&
                                    (38 === n.charCodeAt(s) ? ((t = '&'), s++) : ((t = null), 0 === a && u('"&"')),
                                    null === t &&
                                      (61 === n.charCodeAt(s) ? ((t = '='), s++) : ((t = null), 0 === a && u('"="')),
                                      null === t && (43 === n.charCodeAt(s) ? ((t = '+'), s++) : ((t = null), 0 === a && u('"+"'))))))))));
                  else e = null;
                  return e;
                }
                function Ze() {
                  var e, t;
                  for (e = [], t = Be(); null !== t; ) e.push(t), (t = Be());
                  return e;
                }
                function et() {
                  var e, t, r, i, o, l, c, h;
                  if (((c = s), (h = s), 'sip' === n.substr(s, 3).toLowerCase() ? ((e = n.substr(s, 3)), (s += 3)) : ((e = null), 0 === a && u('"SIP"')), null !== e))
                    if ((47 === n.charCodeAt(s) ? ((t = '/'), s++) : ((t = null), 0 === a && u('"/"')), null !== t)) {
                      if (null !== (i = d())) for (r = []; null !== i; ) r.push(i), (i = d());
                      else r = null;
                      if (null !== r)
                        if ((46 === n.charCodeAt(s) ? ((i = '.'), s++) : ((i = null), 0 === a && u('"."')), null !== i)) {
                          if (null !== (l = d())) for (o = []; null !== l; ) o.push(l), (l = d());
                          else o = null;
                          null !== o ? (e = [e, t, r, i, o]) : ((e = null), (s = h));
                        } else (e = null), (s = h);
                      else (e = null), (s = h);
                    } else (e = null), (s = h);
                  else (e = null), (s = h);
                  null !== e && ((f = c), (e = void (Fn.sip_version = n.substring(s, f))));
                  var f;
                  return null === e && (s = c), e;
                }
                function tt() {
                  var e;
                  return 'INVITE' === n.substr(s, 6) ? ((e = 'INVITE'), (s += 6)) : ((e = null), 0 === a && u('"INVITE"')), e;
                }
                function nt() {
                  var e;
                  return 'ACK' === n.substr(s, 3) ? ((e = 'ACK'), (s += 3)) : ((e = null), 0 === a && u('"ACK"')), e;
                }
                function rt() {
                  var e;
                  return 'OPTIONS' === n.substr(s, 7) ? ((e = 'OPTIONS'), (s += 7)) : ((e = null), 0 === a && u('"OPTIONS"')), e;
                }
                function it() {
                  var e;
                  return 'BYE' === n.substr(s, 3) ? ((e = 'BYE'), (s += 3)) : ((e = null), 0 === a && u('"BYE"')), e;
                }
                function st() {
                  var e;
                  return 'CANCEL' === n.substr(s, 6) ? ((e = 'CANCEL'), (s += 6)) : ((e = null), 0 === a && u('"CANCEL"')), e;
                }
                function at() {
                  var e;
                  return 'REGISTER' === n.substr(s, 8) ? ((e = 'REGISTER'), (s += 8)) : ((e = null), 0 === a && u('"REGISTER"')), e;
                }
                function ot() {
                  var e;
                  return 'SUBSCRIBE' === n.substr(s, 9) ? ((e = 'SUBSCRIBE'), (s += 9)) : ((e = null), 0 === a && u('"SUBSCRIBE"')), e;
                }
                function lt() {
                  var e;
                  return 'NOTIFY' === n.substr(s, 6) ? ((e = 'NOTIFY'), (s += 6)) : ((e = null), 0 === a && u('"NOTIFY"')), e;
                }
                function ut() {
                  var e;
                  return 'REFER' === n.substr(s, 5) ? ((e = 'REFER'), (s += 5)) : ((e = null), 0 === a && u('"REFER"')), e;
                }
                function ct() {
                  var e, t;
                  (t = s),
                    null === (e = tt()) &&
                      null === (e = nt()) &&
                      null === (e = rt()) &&
                      null === (e = it()) &&
                      null === (e = st()) &&
                      null === (e = at()) &&
                      null === (e = ot()) &&
                      null === (e = lt()) &&
                      null === (e = ut()) &&
                      (e = O()),
                    null !== e && ((r = t), (Fn.method = n.substring(s, r)), (e = Fn.method));
                  var r;
                  return null === e && (s = t), e;
                }
                function dt() {
                  var e, t, n, r, i, a;
                  return (
                    (a = s),
                    null !== (e = et()) && null !== (t = m()) && null !== (n = ht()) && null !== (r = m()) && null !== (i = pt())
                      ? (e = [e, t, n, r, i])
                      : ((e = null), (s = a)),
                    e
                  );
                }
                function ht() {
                  var e, t;
                  (t = s), null !== (e = ft()) && ((n = e), (e = void (Fn.status_code = parseInt(n.join('')))));
                  var n;
                  return null === e && (s = t), e;
                }
                function ft() {
                  var e, t, n, r;
                  return (r = s), null !== (e = d()) && null !== (t = d()) && null !== (n = d()) ? (e = [e, t, n]) : ((e = null), (s = r)), e;
                }
                function pt() {
                  var e, t, r;
                  for (
                    r = s,
                      e = [],
                      null === (t = T()) && null === (t = C()) && null === (t = E()) && null === (t = k()) && null === (t = P()) && null === (t = m()) && (t = g());
                    null !== t;

                  )
                    e.push(t),
                      null === (t = T()) && null === (t = C()) && null === (t = E()) && null === (t = k()) && null === (t = P()) && null === (t = m()) && (t = g());
                  null !== e && ((i = r), (e = void (Fn.reason_phrase = n.substring(s, i))));
                  var i;
                  return null === e && (s = r), e;
                }
                function _t() {
                  var e, t, n, r, i, a, o;
                  if (((i = s), (a = s), null === (e = X()) && (e = vt()), null !== e)) {
                    for (t = [], o = s, null !== (n = G()) && null !== (r = gt()) ? (n = [n, r]) : ((n = null), (s = o)); null !== n; )
                      t.push(n), (o = s), null !== (n = G()) && null !== (r = gt()) ? (n = [n, r]) : ((n = null), (s = o));
                    null !== t ? (e = [e, t]) : ((e = null), (s = a));
                  } else (e = null), (s = a);
                  return (
                    null !== e &&
                      (e = (function (e) {
                        var t;
                        Fn.multi_header || (Fn.multi_header = []);
                        try {
                          (t = new Hn(Fn.uri, Fn.display_name, Fn.params)), delete Fn.uri, delete Fn.display_name, delete Fn.params;
                        } catch (e) {
                          t = null;
                        }
                        Fn.multi_header.push({ possition: s, offset: e, parsed: t });
                      })(i)),
                    null === e && (s = i),
                    e
                  );
                }
                function vt() {
                  var e, t, n, r, i;
                  return (
                    (i = s),
                    null !== (e = null !== (e = mt()) ? e : '') && null !== (t = F()) && null !== (n = Q()) && null !== (r = H())
                      ? (e = [e, t, n, r])
                      : ((e = null), (s = i)),
                    e
                  );
                }
                function mt() {
                  var e, t, r, i, a, o, l;
                  if (((a = s), (o = s), null !== (e = O()))) {
                    for (t = [], l = s, null !== (r = b()) && null !== (i = O()) ? (r = [r, i]) : ((r = null), (s = l)); null !== r; )
                      t.push(r), (l = s), null !== (r = b()) && null !== (i = O()) ? (r = [r, i]) : ((r = null), (s = l));
                    null !== t ? (e = [e, t]) : ((e = null), (s = o));
                  } else (e = null), (s = o);
                  null === e && (e = K()),
                    null !== e && ((u = a), (c = e), '"' === (c = n.substring(s, u).trim())[0] && (c = c.substring(1, c.length - 1)), (e = void (Fn.display_name = c)));
                  var u, c;
                  return null === e && (s = a), e;
                }
                function gt() {
                  var e;
                  return null === (e = yt()) && null === (e = Tt()) && (e = Et()), e;
                }
                function yt() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'q' === n.substr(s, 1).toLowerCase() ? ((e = n.substr(s, 1)), s++) : ((e = null), 0 === a && u('"q"')),
                    null !== e && null !== (t = M()) && null !== (r = St()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), Fn.params || (Fn.params = {}), (e = void (Fn.params.q = l)));
                  var l;
                  return null === e && (s = i), e;
                }
                function Tt() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'expires' === n.substr(s, 7).toLowerCase() ? ((e = n.substr(s, 7)), (s += 7)) : ((e = null), 0 === a && u('"expires"')),
                    null !== e && null !== (t = M()) && null !== (r = Ct()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), Fn.params || (Fn.params = {}), (e = void (Fn.params.expires = l)));
                  var l;
                  return null === e && (s = i), e;
                }
                function Ct() {
                  var e, t, n;
                  if (((n = s), null !== (t = d()))) for (e = []; null !== t; ) e.push(t), (t = d());
                  else e = null;
                  return null !== e && (e = parseInt(e.join(''))), null === e && (s = n), e;
                }
                function St() {
                  var e, t, r, i, o, l, c, h;
                  (l = s),
                    (c = s),
                    48 === n.charCodeAt(s) ? ((e = '0'), s++) : ((e = null), 0 === a && u('"0"')),
                    null !== e
                      ? ((h = s),
                        46 === n.charCodeAt(s) ? ((t = '.'), s++) : ((t = null), 0 === a && u('"."')),
                        null !== t &&
                        null !== (r = null !== (r = d()) ? r : '') &&
                        null !== (i = null !== (i = d()) ? i : '') &&
                        null !== (o = null !== (o = d()) ? o : '')
                          ? (t = [t, r, i, o])
                          : ((t = null), (s = h)),
                        null !== (t = null !== t ? t : '') ? (e = [e, t]) : ((e = null), (s = c)))
                      : ((e = null), (s = c)),
                    null !== e && ((f = l), (e = parseFloat(n.substring(s, f))));
                  var f;
                  return null === e && (s = l), e;
                }
                function Et() {
                  var e, t, n, r, i, a;
                  (r = s),
                    (i = s),
                    null !== (e = O())
                      ? ((a = s),
                        null !== (t = M()) && null !== (n = bt()) ? (t = [t, n]) : ((t = null), (s = a)),
                        null !== (t = null !== t ? t : '') ? (e = [e, t]) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    null !== e &&
                      ((o = e[0]), (l = e[1]), Fn.params || (Fn.params = {}), (l = void 0 === l ? void 0 : l[1]), (e = void (Fn.params[o.toLowerCase()] = l)));
                  var o, l;
                  return null === e && (s = r), e;
                }
                function bt() {
                  var e;
                  return null === (e = O()) && null === (e = oe()) && (e = K()), e;
                }
                function Rt() {
                  var e;
                  return (
                    'render' === n.substr(s, 6).toLowerCase() ? ((e = n.substr(s, 6)), (s += 6)) : ((e = null), 0 === a && u('"render"')),
                    null === e &&
                      ('session' === n.substr(s, 7).toLowerCase() ? ((e = n.substr(s, 7)), (s += 7)) : ((e = null), 0 === a && u('"session"')),
                      null === e &&
                        ('icon' === n.substr(s, 4).toLowerCase() ? ((e = n.substr(s, 4)), (s += 4)) : ((e = null), 0 === a && u('"icon"')),
                        null === e &&
                          ('alert' === n.substr(s, 5).toLowerCase() ? ((e = n.substr(s, 5)), (s += 5)) : ((e = null), 0 === a && u('"alert"')),
                          null === e && (e = O())))),
                    e
                  );
                }
                function At() {
                  var e;
                  return null === (e = wt()) && (e = Et()), e;
                }
                function wt() {
                  var e, t, r, i;
                  return (
                    (i = s),
                    'handling' === n.substr(s, 8).toLowerCase() ? ((e = n.substr(s, 8)), (s += 8)) : ((e = null), 0 === a && u('"handling"')),
                    null !== e && null !== (t = M())
                      ? ('optional' === n.substr(s, 8).toLowerCase() ? ((r = n.substr(s, 8)), (s += 8)) : ((r = null), 0 === a && u('"optional"')),
                        null === r &&
                          ('required' === n.substr(s, 8).toLowerCase() ? ((r = n.substr(s, 8)), (s += 8)) : ((r = null), 0 === a && u('"required"')),
                          null === r && (r = O())),
                        null !== r ? (e = [e, t, r]) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    e
                  );
                }
                function It() {
                  var e, t, n, r, i, a, o, l;
                  if (((o = s), null !== (e = kt())))
                    if (null !== (t = U()))
                      if (null !== (n = Dt())) {
                        for (r = [], l = s, null !== (i = G()) && null !== (a = Ut()) ? (i = [i, a]) : ((i = null), (s = l)); null !== i; )
                          r.push(i), (l = s), null !== (i = G()) && null !== (a = Ut()) ? (i = [i, a]) : ((i = null), (s = l));
                        null !== r ? (e = [e, t, n, r]) : ((e = null), (s = o));
                      } else (e = null), (s = o);
                    else (e = null), (s = o);
                  else (e = null), (s = o);
                  return e;
                }
                function kt() {
                  var e;
                  return null === (e = Pt()) && (e = Ot()), e;
                }
                function Pt() {
                  var e;
                  return (
                    'text' === n.substr(s, 4).toLowerCase() ? ((e = n.substr(s, 4)), (s += 4)) : ((e = null), 0 === a && u('"text"')),
                    null === e &&
                      ('image' === n.substr(s, 5).toLowerCase() ? ((e = n.substr(s, 5)), (s += 5)) : ((e = null), 0 === a && u('"image"')),
                      null === e &&
                        ('audio' === n.substr(s, 5).toLowerCase() ? ((e = n.substr(s, 5)), (s += 5)) : ((e = null), 0 === a && u('"audio"')),
                        null === e &&
                          ('video' === n.substr(s, 5).toLowerCase() ? ((e = n.substr(s, 5)), (s += 5)) : ((e = null), 0 === a && u('"video"')),
                          null === e &&
                            ('application' === n.substr(s, 11).toLowerCase() ? ((e = n.substr(s, 11)), (s += 11)) : ((e = null), 0 === a && u('"application"')),
                            null === e && (e = xt()))))),
                    e
                  );
                }
                function Ot() {
                  var e;
                  return (
                    'message' === n.substr(s, 7).toLowerCase() ? ((e = n.substr(s, 7)), (s += 7)) : ((e = null), 0 === a && u('"message"')),
                    null === e &&
                      ('multipart' === n.substr(s, 9).toLowerCase() ? ((e = n.substr(s, 9)), (s += 9)) : ((e = null), 0 === a && u('"multipart"')),
                      null === e && (e = xt())),
                    e
                  );
                }
                function xt() {
                  var e;
                  return null === (e = O()) && (e = Nt()), e;
                }
                function Nt() {
                  var e, t, r;
                  return (
                    (r = s),
                    'x-' === n.substr(s, 2).toLowerCase() ? ((e = n.substr(s, 2)), (s += 2)) : ((e = null), 0 === a && u('"x-"')),
                    null !== e && null !== (t = O()) ? (e = [e, t]) : ((e = null), (s = r)),
                    e
                  );
                }
                function Dt() {
                  var e;
                  return null === (e = xt()) && (e = O()), e;
                }
                function Ut() {
                  var e, t, n, r;
                  return (r = s), null !== (e = O()) && null !== (t = M()) && null !== (n = Mt()) ? (e = [e, t, n]) : ((e = null), (s = r)), e;
                }
                function Mt() {
                  var e;
                  return null === (e = O()) && (e = K()), e;
                }
                function Lt() {
                  var e, t, n;
                  if (((n = s), null !== (t = d()))) for (e = []; null !== t; ) e.push(t), (t = d());
                  else e = null;
                  null !== e && ((r = e), (e = void (Fn.value = parseInt(r.join('')))));
                  var r;
                  return null === e && (s = n), e;
                }
                function qt() {
                  var e, t, r, i, o, l;
                  if (((o = s), null !== (e = x()))) {
                    for (
                      t = [],
                        l = s,
                        46 === n.charCodeAt(s) ? ((r = '.'), s++) : ((r = null), 0 === a && u('"."')),
                        null !== r && null !== (i = x()) ? (r = [r, i]) : ((r = null), (s = l));
                      null !== r;

                    )
                      t.push(r),
                        (l = s),
                        46 === n.charCodeAt(s) ? ((r = '.'), s++) : ((r = null), 0 === a && u('"."')),
                        null !== r && null !== (i = x()) ? (r = [r, i]) : ((r = null), (s = l));
                    null !== t ? (e = [e, t]) : ((e = null), (s = o));
                  } else (e = null), (s = o);
                  return e;
                }
                function Ht() {
                  var e;
                  return null === (e = Ft()) && (e = Et()), e;
                }
                function Ft() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'tag' === n.substr(s, 3).toLowerCase() ? ((e = n.substr(s, 3)), (s += 3)) : ((e = null), 0 === a && u('"tag"')),
                    null !== e && null !== (t = M()) && null !== (r = O()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), (e = void (Fn.tag = l)));
                  var l;
                  return null === e && (s = i), e;
                }
                function jt() {
                  var e, t, r, i, o, l, c, d;
                  if (((c = s), 'digest' === n.substr(s, 6).toLowerCase() ? ((e = n.substr(s, 6)), (s += 6)) : ((e = null), 0 === a && u('"Digest"')), null !== e))
                    if (null !== (t = b()))
                      if (null !== (r = Wt())) {
                        for (i = [], d = s, null !== (o = j()) && null !== (l = Wt()) ? (o = [o, l]) : ((o = null), (s = d)); null !== o; )
                          i.push(o), (d = s), null !== (o = j()) && null !== (l = Wt()) ? (o = [o, l]) : ((o = null), (s = d));
                        null !== i ? (e = [e, t, r, i]) : ((e = null), (s = c));
                      } else (e = null), (s = c);
                    else (e = null), (s = c);
                  else (e = null), (s = c);
                  return null === e && (e = Gt()), e;
                }
                function Gt() {
                  var e, t, n, r, i, a, o, l;
                  if (((o = s), null !== (e = O())))
                    if (null !== (t = b()))
                      if (null !== (n = Bt())) {
                        for (r = [], l = s, null !== (i = j()) && null !== (a = Bt()) ? (i = [i, a]) : ((i = null), (s = l)); null !== i; )
                          r.push(i), (l = s), null !== (i = j()) && null !== (a = Bt()) ? (i = [i, a]) : ((i = null), (s = l));
                        null !== r ? (e = [e, t, n, r]) : ((e = null), (s = o));
                      } else (e = null), (s = o);
                    else (e = null), (s = o);
                  else (e = null), (s = o);
                  return e;
                }
                function Bt() {
                  var e, t, n, r;
                  return (
                    (r = s),
                    null !== (e = O()) && null !== (t = M())
                      ? (null === (n = O()) && (n = K()), null !== n ? (e = [e, t, n]) : ((e = null), (s = r)))
                      : ((e = null), (s = r)),
                    e
                  );
                }
                function Wt() {
                  var e;
                  return (
                    null === (e = Vt()) &&
                      null === (e = Kt()) &&
                      null === (e = Yt()) &&
                      null === (e = Xt()) &&
                      null === (e = Qt()) &&
                      null === (e = Zt()) &&
                      null === (e = en()) &&
                      (e = Bt()),
                    e
                  );
                }
                function Vt() {
                  var e, t, r, i;
                  return (
                    (i = s),
                    'realm' === n.substr(s, 5).toLowerCase() ? ((e = n.substr(s, 5)), (s += 5)) : ((e = null), 0 === a && u('"realm"')),
                    null !== e && null !== (t = M()) && null !== (r = Jt()) ? (e = [e, t, r]) : ((e = null), (s = i)),
                    e
                  );
                }
                function Jt() {
                  var e, t;
                  (t = s), null !== (e = z()) && ((n = e), (e = void (Fn.realm = n)));
                  var n;
                  return null === e && (s = t), e;
                }
                function Kt() {
                  var e, t, r, i, o, l, c, d, h;
                  if (((d = s), 'domain' === n.substr(s, 6).toLowerCase() ? ((e = n.substr(s, 6)), (s += 6)) : ((e = null), 0 === a && u('"domain"')), null !== e))
                    if (null !== (t = M()))
                      if (null !== (r = W()))
                        if (null !== (i = zt())) {
                          if (((o = []), (h = s), null !== (c = m()))) for (l = []; null !== c; ) l.push(c), (c = m());
                          else l = null;
                          for (null !== l && null !== (c = zt()) ? (l = [l, c]) : ((l = null), (s = h)); null !== l; ) {
                            if ((o.push(l), (h = s), null !== (c = m()))) for (l = []; null !== c; ) l.push(c), (c = m());
                            else l = null;
                            null !== l && null !== (c = zt()) ? (l = [l, c]) : ((l = null), (s = h));
                          }
                          null !== o && null !== (l = V()) ? (e = [e, t, r, i, o, l]) : ((e = null), (s = d));
                        } else (e = null), (s = d);
                      else (e = null), (s = d);
                    else (e = null), (s = d);
                  else (e = null), (s = d);
                  return e;
                }
                function zt() {
                  var e;
                  return null === (e = qe()) && (e = je()), e;
                }
                function Yt() {
                  var e, t, r, i;
                  return (
                    (i = s),
                    'nonce' === n.substr(s, 5).toLowerCase() ? ((e = n.substr(s, 5)), (s += 5)) : ((e = null), 0 === a && u('"nonce"')),
                    null !== e && null !== (t = M()) && null !== (r = $t()) ? (e = [e, t, r]) : ((e = null), (s = i)),
                    e
                  );
                }
                function $t() {
                  var e, t;
                  (t = s), null !== (e = z()) && ((n = e), (e = void (Fn.nonce = n)));
                  var n;
                  return null === e && (s = t), e;
                }
                function Xt() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'opaque' === n.substr(s, 6).toLowerCase() ? ((e = n.substr(s, 6)), (s += 6)) : ((e = null), 0 === a && u('"opaque"')),
                    null !== e && null !== (t = M()) && null !== (r = z()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), (e = void (Fn.opaque = l)));
                  var l;
                  return null === e && (s = i), e;
                }
                function Qt() {
                  var e, t, r, i, o;
                  return (
                    (i = s),
                    'stale' === n.substr(s, 5).toLowerCase() ? ((e = n.substr(s, 5)), (s += 5)) : ((e = null), 0 === a && u('"stale"')),
                    null !== e && null !== (t = M())
                      ? ((o = s),
                        'true' === n.substr(s, 4).toLowerCase() ? ((r = n.substr(s, 4)), (s += 4)) : ((r = null), 0 === a && u('"true"')),
                        null !== r && (r = void (Fn.stale = !0)),
                        null === r && (s = o),
                        null === r &&
                          ((o = s),
                          'false' === n.substr(s, 5).toLowerCase() ? ((r = n.substr(s, 5)), (s += 5)) : ((r = null), 0 === a && u('"false"')),
                          null !== r && (r = void (Fn.stale = !1)),
                          null === r && (s = o)),
                        null !== r ? (e = [e, t, r]) : ((e = null), (s = i)))
                      : ((e = null), (s = i)),
                    e
                  );
                }
                function Zt() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'algorithm' === n.substr(s, 9).toLowerCase() ? ((e = n.substr(s, 9)), (s += 9)) : ((e = null), 0 === a && u('"algorithm"')),
                    null !== e && null !== (t = M())
                      ? ('md5' === n.substr(s, 3).toLowerCase() ? ((r = n.substr(s, 3)), (s += 3)) : ((r = null), 0 === a && u('"MD5"')),
                        null === r &&
                          ('md5-sess' === n.substr(s, 8).toLowerCase() ? ((r = n.substr(s, 8)), (s += 8)) : ((r = null), 0 === a && u('"MD5-sess"')),
                          null === r && (r = O())),
                        null !== r ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), (e = void (Fn.algorithm = l.toUpperCase())));
                  var l;
                  return null === e && (s = i), e;
                }
                function en() {
                  var e, t, r, i, o, l, c, d, h, f;
                  if (((d = s), 'qop' === n.substr(s, 3).toLowerCase() ? ((e = n.substr(s, 3)), (s += 3)) : ((e = null), 0 === a && u('"qop"')), null !== e))
                    if (null !== (t = M()))
                      if (null !== (r = W())) {
                        if (((h = s), null !== (i = tn()))) {
                          for (
                            o = [],
                              f = s,
                              44 === n.charCodeAt(s) ? ((l = ','), s++) : ((l = null), 0 === a && u('","')),
                              null !== l && null !== (c = tn()) ? (l = [l, c]) : ((l = null), (s = f));
                            null !== l;

                          )
                            o.push(l),
                              (f = s),
                              44 === n.charCodeAt(s) ? ((l = ','), s++) : ((l = null), 0 === a && u('","')),
                              null !== l && null !== (c = tn()) ? (l = [l, c]) : ((l = null), (s = f));
                          null !== o ? (i = [i, o]) : ((i = null), (s = h));
                        } else (i = null), (s = h);
                        null !== i && null !== (o = V()) ? (e = [e, t, r, i, o]) : ((e = null), (s = d));
                      } else (e = null), (s = d);
                    else (e = null), (s = d);
                  else (e = null), (s = d);
                  return e;
                }
                function tn() {
                  var e, t;
                  (t = s),
                    'auth-int' === n.substr(s, 8).toLowerCase() ? ((e = n.substr(s, 8)), (s += 8)) : ((e = null), 0 === a && u('"auth-int"')),
                    null === e &&
                      ('auth' === n.substr(s, 4).toLowerCase() ? ((e = n.substr(s, 4)), (s += 4)) : ((e = null), 0 === a && u('"auth"')), null === e && (e = O())),
                    null !== e && ((r = e), Fn.qop || (Fn.qop = []), (e = void Fn.qop.push(r.toLowerCase())));
                  var r;
                  return null === e && (s = t), e;
                }
                function nn() {
                  var e, t, n, r, i, a, o;
                  if (((i = s), (a = s), null !== (e = vt()))) {
                    for (t = [], o = s, null !== (n = G()) && null !== (r = Et()) ? (n = [n, r]) : ((n = null), (s = o)); null !== n; )
                      t.push(n), (o = s), null !== (n = G()) && null !== (r = Et()) ? (n = [n, r]) : ((n = null), (s = o));
                    null !== t ? (e = [e, t]) : ((e = null), (s = a));
                  } else (e = null), (s = a);
                  return (
                    null !== e &&
                      (e = (function (e) {
                        var t;
                        Fn.multi_header || (Fn.multi_header = []);
                        try {
                          (t = new Hn(Fn.uri, Fn.display_name, Fn.params)), delete Fn.uri, delete Fn.display_name, delete Fn.params;
                        } catch (e) {
                          t = null;
                        }
                        Fn.multi_header.push({ possition: s, offset: e, parsed: t });
                      })(i)),
                    null === e && (s = i),
                    e
                  );
                }
                function rn() {
                  var e;
                  return null === (e = sn()) && (e = Et()), e;
                }
                function sn() {
                  var e, t, r, i, o, l;
                  if (((o = s), (l = s), 'cause' === n.substr(s, 5).toLowerCase() ? ((e = n.substr(s, 5)), (s += 5)) : ((e = null), 0 === a && u('"cause"')), null !== e))
                    if (null !== (t = M())) {
                      if (null !== (i = d())) for (r = []; null !== i; ) r.push(i), (i = d());
                      else r = null;
                      null !== r ? (e = [e, t, r]) : ((e = null), (s = l));
                    } else (e = null), (s = l);
                  else (e = null), (s = l);
                  null !== e && ((c = e[2]), (e = void (Fn.cause = parseInt(c.join('')))));
                  var c;
                  return null === e && (s = o), e;
                }
                function an() {
                  var e, t, n, r, i, a;
                  if (((i = s), null !== (e = vt()))) {
                    for (t = [], a = s, null !== (n = G()) && null !== (r = Et()) ? (n = [n, r]) : ((n = null), (s = a)); null !== n; )
                      t.push(n), (a = s), null !== (n = G()) && null !== (r = Et()) ? (n = [n, r]) : ((n = null), (s = a));
                    null !== t ? (e = [e, t]) : ((e = null), (s = i));
                  } else (e = null), (s = i);
                  return e;
                }
                function on() {
                  var e, t;
                  (t = s),
                    'active' === n.substr(s, 6).toLowerCase() ? ((e = n.substr(s, 6)), (s += 6)) : ((e = null), 0 === a && u('"active"')),
                    null === e &&
                      ('pending' === n.substr(s, 7).toLowerCase() ? ((e = n.substr(s, 7)), (s += 7)) : ((e = null), 0 === a && u('"pending"')),
                      null === e &&
                        ('terminated' === n.substr(s, 10).toLowerCase() ? ((e = n.substr(s, 10)), (s += 10)) : ((e = null), 0 === a && u('"terminated"')),
                        null === e && (e = O()))),
                    null !== e && ((r = t), (e = void (Fn.state = n.substring(s, r))));
                  var r;
                  return null === e && (s = t), e;
                }
                function ln() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'reason' === n.substr(s, 6).toLowerCase() ? ((e = n.substr(s, 6)), (s += 6)) : ((e = null), 0 === a && u('"reason"')),
                    null !== e && null !== (t = M()) && null !== (r = un()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                    null !== e && (e = void (void 0 !== (l = e[2]) && (Fn.reason = l)));
                  var l;
                  null === e && (s = i),
                    null === e &&
                      ((i = s),
                      (o = s),
                      'expires' === n.substr(s, 7).toLowerCase() ? ((e = n.substr(s, 7)), (s += 7)) : ((e = null), 0 === a && u('"expires"')),
                      null !== e && null !== (t = M()) && null !== (r = Ct()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                      null !== e && (e = void (void 0 !== (d = e[2]) && (Fn.expires = d))),
                      null === e && (s = i),
                      null === e &&
                        ((i = s),
                        (o = s),
                        'retry_after' === n.substr(s, 11).toLowerCase() ? ((e = n.substr(s, 11)), (s += 11)) : ((e = null), 0 === a && u('"retry_after"')),
                        null !== e && null !== (t = M()) && null !== (r = Ct()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                        null !== e && (e = void (void 0 !== (c = e[2]) && (Fn.retry_after = c))),
                        null === e && (s = i),
                        null === e && (e = Et())));
                  var c, d;
                  return e;
                }
                function un() {
                  var e;
                  return (
                    'deactivated' === n.substr(s, 11).toLowerCase() ? ((e = n.substr(s, 11)), (s += 11)) : ((e = null), 0 === a && u('"deactivated"')),
                    null === e &&
                      ('probation' === n.substr(s, 9).toLowerCase() ? ((e = n.substr(s, 9)), (s += 9)) : ((e = null), 0 === a && u('"probation"')),
                      null === e &&
                        ('rejected' === n.substr(s, 8).toLowerCase() ? ((e = n.substr(s, 8)), (s += 8)) : ((e = null), 0 === a && u('"rejected"')),
                        null === e &&
                          ('timeout' === n.substr(s, 7).toLowerCase() ? ((e = n.substr(s, 7)), (s += 7)) : ((e = null), 0 === a && u('"timeout"')),
                          null === e &&
                            ('giveup' === n.substr(s, 6).toLowerCase() ? ((e = n.substr(s, 6)), (s += 6)) : ((e = null), 0 === a && u('"giveup"')),
                            null === e &&
                              ('noresource' === n.substr(s, 10).toLowerCase() ? ((e = n.substr(s, 10)), (s += 10)) : ((e = null), 0 === a && u('"noresource"')),
                              null === e &&
                                ('invariant' === n.substr(s, 9).toLowerCase() ? ((e = n.substr(s, 9)), (s += 9)) : ((e = null), 0 === a && u('"invariant"')),
                                null === e && (e = O()))))))),
                    e
                  );
                }
                function cn() {
                  var e;
                  return null === (e = Ft()) && (e = Et()), e;
                }
                function dn() {
                  var e, t, n, r, i, a, o, l;
                  if (((o = s), null !== (e = gn())))
                    if (null !== (t = b()))
                      if (null !== (n = Cn())) {
                        for (r = [], l = s, null !== (i = G()) && null !== (a = hn()) ? (i = [i, a]) : ((i = null), (s = l)); null !== i; )
                          r.push(i), (l = s), null !== (i = G()) && null !== (a = hn()) ? (i = [i, a]) : ((i = null), (s = l));
                        null !== r ? (e = [e, t, n, r]) : ((e = null), (s = o));
                      } else (e = null), (s = o);
                    else (e = null), (s = o);
                  else (e = null), (s = o);
                  return e;
                }
                function hn() {
                  var e;
                  return null === (e = fn()) && null === (e = pn()) && null === (e = _n()) && null === (e = vn()) && null === (e = mn()) && (e = Et()), e;
                }
                function fn() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'ttl' === n.substr(s, 3).toLowerCase() ? ((e = n.substr(s, 3)), (s += 3)) : ((e = null), 0 === a && u('"ttl"')),
                    null !== e && null !== (t = M()) && null !== (r = bn()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), (e = void (Fn.ttl = l)));
                  var l;
                  return null === e && (s = i), e;
                }
                function pn() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'maddr' === n.substr(s, 5).toLowerCase() ? ((e = n.substr(s, 5)), (s += 5)) : ((e = null), 0 === a && u('"maddr"')),
                    null !== e && null !== (t = M()) && null !== (r = oe()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), (e = void (Fn.maddr = l)));
                  var l;
                  return null === e && (s = i), e;
                }
                function _n() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'received' === n.substr(s, 8).toLowerCase() ? ((e = n.substr(s, 8)), (s += 8)) : ((e = null), 0 === a && u('"received"')),
                    null !== e && null !== (t = M()) ? (null === (r = _e()) && (r = he()), null !== r ? (e = [e, t, r]) : ((e = null), (s = o))) : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), (e = void (Fn.received = l)));
                  var l;
                  return null === e && (s = i), e;
                }
                function vn() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'branch' === n.substr(s, 6).toLowerCase() ? ((e = n.substr(s, 6)), (s += 6)) : ((e = null), 0 === a && u('"branch"')),
                    null !== e && null !== (t = M()) && null !== (r = O()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), (e = void (Fn.branch = l)));
                  var l;
                  return null === e && (s = i), e;
                }
                function mn() {
                  var e, t, r, i, o, l, c;
                  if (
                    ((o = s), (l = s), 'rport' === n.substr(s, 5).toLowerCase() ? ((e = n.substr(s, 5)), (s += 5)) : ((e = null), 0 === a && u('"rport"')), null !== e)
                  ) {
                    if (((c = s), null !== (t = M()))) {
                      for (r = [], i = d(); null !== i; ) r.push(i), (i = d());
                      null !== r ? (t = [t, r]) : ((t = null), (s = c));
                    } else (t = null), (s = c);
                    null !== (t = null !== t ? t : '') ? (e = [e, t]) : ((e = null), (s = l));
                  } else (e = null), (s = l);
                  return null !== e && (e = void ('undefined' != typeof response_port && (Fn.rport = response_port.join('')))), null === e && (s = o), e;
                }
                function gn() {
                  var e, t, n, r, i, a;
                  return (
                    (a = s),
                    null !== (e = yn()) && null !== (t = U()) && null !== (n = O()) && null !== (r = U()) && null !== (i = Tn())
                      ? (e = [e, t, n, r, i])
                      : ((e = null), (s = a)),
                    e
                  );
                }
                function yn() {
                  var e, t;
                  (t = s),
                    'sip' === n.substr(s, 3).toLowerCase() ? ((e = n.substr(s, 3)), (s += 3)) : ((e = null), 0 === a && u('"SIP"')),
                    null === e && (e = O()),
                    null !== e && ((r = e), (e = void (Fn.protocol = r)));
                  var r;
                  return null === e && (s = t), e;
                }
                function Tn() {
                  var e, t;
                  (t = s),
                    'udp' === n.substr(s, 3).toLowerCase() ? ((e = n.substr(s, 3)), (s += 3)) : ((e = null), 0 === a && u('"UDP"')),
                    null === e &&
                      ('tcp' === n.substr(s, 3).toLowerCase() ? ((e = n.substr(s, 3)), (s += 3)) : ((e = null), 0 === a && u('"TCP"')),
                      null === e &&
                        ('tls' === n.substr(s, 3).toLowerCase() ? ((e = n.substr(s, 3)), (s += 3)) : ((e = null), 0 === a && u('"TLS"')),
                        null === e &&
                          ('sctp' === n.substr(s, 4).toLowerCase() ? ((e = n.substr(s, 4)), (s += 4)) : ((e = null), 0 === a && u('"SCTP"')), null === e && (e = O())))),
                    null !== e && ((r = e), (e = void (Fn.transport = r)));
                  var r;
                  return null === e && (s = t), e;
                }
                function Cn() {
                  var e, t, n, r, i;
                  return (
                    (r = s),
                    null !== (e = Sn())
                      ? ((i = s),
                        null !== (t = B()) && null !== (n = En()) ? (t = [t, n]) : ((t = null), (s = i)),
                        null !== (t = null !== t ? t : '') ? (e = [e, t]) : ((e = null), (s = r)))
                      : ((e = null), (s = r)),
                    e
                  );
                }
                function Sn() {
                  var e, t;
                  (t = s), null === (e = _e()) && null === (e = de()) && (e = le()), null !== e && ((r = t), (e = void (Fn.host = n.substring(s, r))));
                  var r;
                  return null === e && (s = t), e;
                }
                function En() {
                  var e, t, n, r, i, a, o;
                  (a = s),
                    (o = s),
                    null !== (e = null !== (e = d()) ? e : '') &&
                    null !== (t = null !== (t = d()) ? t : '') &&
                    null !== (n = null !== (n = d()) ? n : '') &&
                    null !== (r = null !== (r = d()) ? r : '') &&
                    null !== (i = null !== (i = d()) ? i : '')
                      ? (e = [e, t, n, r, i])
                      : ((e = null), (s = o)),
                    null !== e && ((l = e), (e = void (Fn.port = parseInt(l.join('')))));
                  var l;
                  return null === e && (s = a), e;
                }
                function bn() {
                  var e, t, n, r, i;
                  return (
                    (r = s),
                    (i = s),
                    null !== (e = d()) && null !== (t = null !== (t = d()) ? t : '') && null !== (n = null !== (n = d()) ? n : '')
                      ? (e = [e, t, n])
                      : ((e = null), (s = i)),
                    null !== e && (e = parseInt(e.join(''))),
                    null === e && (s = r),
                    e
                  );
                }
                function Rn() {
                  var e, t;
                  (t = s), null !== (e = Ct()) && ((n = e), (e = void (Fn.expires = n)));
                  var n;
                  return null === e && (s = t), e;
                }
                function An() {
                  var e;
                  return null === (e = wn()) && (e = Et()), e;
                }
                function wn() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'refresher' === n.substr(s, 9).toLowerCase() ? ((e = n.substr(s, 9)), (s += 9)) : ((e = null), 0 === a && u('"refresher"')),
                    null !== e && null !== (t = M())
                      ? ('uac' === n.substr(s, 3).toLowerCase() ? ((r = n.substr(s, 3)), (s += 3)) : ((r = null), 0 === a && u('"uac"')),
                        null === r && ('uas' === n.substr(s, 3).toLowerCase() ? ((r = n.substr(s, 3)), (s += 3)) : ((r = null), 0 === a && u('"uas"'))),
                        null !== r ? (e = [e, t, r]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), (e = void (Fn.refresher = l.toLowerCase())));
                  var l;
                  return null === e && (s = i), e;
                }
                function In() {
                  var e, t;
                  for (e = [], null === (t = I()) && null === (t = P()) && (t = b()); null !== t; ) e.push(t), null === (t = I()) && null === (t = P()) && (t = b());
                  return e;
                }
                function kn() {
                  var e, t, r, i, o, l, c, d, h, f, p;
                  (f = s),
                    (p = s),
                    null !== (e = On())
                      ? (45 === n.charCodeAt(s) ? ((t = '-'), s++) : ((t = null), 0 === a && u('"-"')),
                        null !== t && null !== (r = Pn())
                          ? (45 === n.charCodeAt(s) ? ((i = '-'), s++) : ((i = null), 0 === a && u('"-"')),
                            null !== i && null !== (o = Pn())
                              ? (45 === n.charCodeAt(s) ? ((l = '-'), s++) : ((l = null), 0 === a && u('"-"')),
                                null !== l && null !== (c = Pn())
                                  ? (45 === n.charCodeAt(s) ? ((d = '-'), s++) : ((d = null), 0 === a && u('"-"')),
                                    null !== d && null !== (h = xn()) ? (e = [e, t, r, i, o, l, c, d, h]) : ((e = null), (s = p)))
                                  : ((e = null), (s = p)))
                              : ((e = null), (s = p)))
                          : ((e = null), (s = p)))
                      : ((e = null), (s = p)),
                    null !== e && ((_ = f), e[0], (e = void (Fn = n.substring(s + 5, _))));
                  var _;
                  return null === e && (s = f), e;
                }
                function Pn() {
                  var e, t, n, r, i;
                  return (i = s), null !== (e = f()) && null !== (t = f()) && null !== (n = f()) && null !== (r = f()) ? (e = [e, t, n, r]) : ((e = null), (s = i)), e;
                }
                function On() {
                  var e, t, n;
                  return (n = s), null !== (e = Pn()) && null !== (t = Pn()) ? (e = [e, t]) : ((e = null), (s = n)), e;
                }
                function xn() {
                  var e, t, n, r;
                  return (r = s), null !== (e = Pn()) && null !== (t = Pn()) && null !== (n = Pn()) ? (e = [e, t, n]) : ((e = null), (s = r)), e;
                }
                function Nn() {
                  var e, t, r, i, o, l;
                  (i = s),
                    (o = s),
                    null !== (e = N())
                      ? ((l = s),
                        64 === n.charCodeAt(s) ? ((t = '@'), s++) : ((t = null), 0 === a && u('"@"')),
                        null !== t && null !== (r = N()) ? (t = [t, r]) : ((t = null), (s = l)),
                        null !== (t = null !== t ? t : '') ? (e = [e, t]) : ((e = null), (s = o)))
                      : ((e = null), (s = o)),
                    null !== e && ((c = i), (e = void (Fn.call_id = n.substring(s, c))));
                  var c;
                  return null === e && (s = i), e;
                }
                function Dn() {
                  var e;
                  return null === (e = Un()) && null === (e = Mn()) && null === (e = Ln()) && (e = Et()), e;
                }
                function Un() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'to-tag' === n.substr(s, 6) ? ((e = 'to-tag'), (s += 6)) : ((e = null), 0 === a && u('"to-tag"')),
                    null !== e && null !== (t = M()) && null !== (r = O()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), (e = void (Fn.to_tag = l)));
                  var l;
                  return null === e && (s = i), e;
                }
                function Mn() {
                  var e, t, r, i, o;
                  (i = s),
                    (o = s),
                    'from-tag' === n.substr(s, 8) ? ((e = 'from-tag'), (s += 8)) : ((e = null), 0 === a && u('"from-tag"')),
                    null !== e && null !== (t = M()) && null !== (r = O()) ? (e = [e, t, r]) : ((e = null), (s = o)),
                    null !== e && ((l = e[2]), (e = void (Fn.from_tag = l)));
                  var l;
                  return null === e && (s = i), e;
                }
                function Ln() {
                  var e, t;
                  return (
                    (t = s),
                    'early-only' === n.substr(s, 10) ? ((e = 'early-only'), (s += 10)) : ((e = null), 0 === a && u('"early-only"')),
                    null !== e && (e = void (Fn.early_only = !0)),
                    null === e && (s = t),
                    e
                  );
                }
                var qn = e('./URI'),
                  Hn = e('./NameAddrHeader'),
                  Fn = {};
                if (null === i[r]() || s !== n.length) {
                  var jn = Math.max(s, o),
                    Gn = jn < n.length ? n.charAt(jn) : null,
                    Bn = (function () {
                      for (var e = 1, t = 1, r = !1, i = 0; i < Math.max(s, o); i++) {
                        var a = n.charAt(i);
                        '\n' === a ? (r || e++, (t = 1), (r = !1)) : '\r' === a || '\u2028' === a || '\u2029' === a ? (e++, (t = 1), (r = !0)) : (t++, (r = !1));
                      }
                      return { line: e, column: t };
                    })();
                  return (
                    new this.SyntaxError(
                      (function (e) {
                        e.sort();
                        for (var t = null, n = [], r = 0; r < e.length; r++) e[r] !== t && (n.push(e[r]), (t = e[r]));
                        return n;
                      })(l),
                      Gn,
                      jn,
                      Bn.line,
                      Bn.column
                    ),
                    -1
                  );
                }
                return Fn;
              },
              toSource: function () {
                return this._source;
              },
            };
            return (
              (n.SyntaxError = function (e, n, r, i, s) {
                (this.name = 'SyntaxError'),
                  (this.expected = e),
                  (this.found = n),
                  (this.message = (function (e, n) {
                    var r;
                    switch (e.length) {
                      case 0:
                        r = 'end of input';
                        break;
                      case 1:
                        r = e[0];
                        break;
                      default:
                        r = e.slice(0, e.length - 1).join(', ') + ' or ' + e[e.length - 1];
                    }
                    return 'Expected ' + r + ' but ' + (n ? t(n) : 'end of input') + ' found.';
                  })(e, n)),
                  (this.offset = r),
                  (this.line = i),
                  (this.column = s);
              }),
              (n.SyntaxError.prototype = Error.prototype),
              n
            );
          })();
        },
        { './NameAddrHeader': 10, './URI': 25 },
      ],
      8: [
        function (e, t, n) {
          'use strict';
          var r = e('webrtc-adapter'),
            i = e('../package.json'),
            s = e('./Constants'),
            a = e('./Exceptions'),
            o = e('./Utils'),
            l = e('./UA'),
            u = e('./URI'),
            c = e('./NameAddrHeader'),
            d = e('./Grammar'),
            h = e('./WebSocketInterface');
          e('debug')('JsSIP')('version %s', i.version),
            (t.exports = {
              C: s,
              Exceptions: a,
              Utils: o,
              UA: l,
              URI: u,
              NameAddrHeader: c,
              WebSocketInterface: h,
              Grammar: d,
              debug: e('debug'),
              adapter: r,
              get name() {
                return i.title;
              },
              get version() {
                return i.version;
              },
            });
        },
        {
          '../package.json': 51,
          './Constants': 2,
          './Exceptions': 6,
          './Grammar': 7,
          './NameAddrHeader': 10,
          './UA': 24,
          './URI': 25,
          './Utils': 26,
          './WebSocketInterface': 27,
          debug: 29,
          'webrtc-adapter': 40,
        },
      ],
      9: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('events').EventEmitter,
            s = e('./Constants'),
            a = e('./SIPMessage'),
            o = e('./Utils'),
            l = e('./RequestSender'),
            u = e('./Exceptions'),
            c = e('debug')('JsSIP:Message');
          t.exports = (function (e) {
            !(function (e, t) {
              if ('function' != typeof t && null !== t) throw new TypeError('Super expression must either be null or a function, not ' + typeof t);
              (e.prototype = Object.create(t && t.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })),
                t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : (e.__proto__ = t));
            })(t, i);
            function t(e) {
              !(function (e, t) {
                if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
              })(this, t);
              var n = (function (e, t) {
                if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
                return !t || ('object' != typeof t && 'function' != typeof t) ? e : t;
              })(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
              return (
                (n._ua = e),
                (n._request = null),
                (n._closed = !1),
                (n._direction = null),
                (n._local_identity = null),
                (n._remote_identity = null),
                (n._is_replied = !1),
                (n._data = {}),
                n
              );
            }
            return (
              r(t, [
                {
                  key: 'send',
                  value: function (e, t) {
                    var n = this,
                      r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {},
                      i = e;
                    if (void 0 === e || void 0 === t) throw new TypeError('Not enough arguments');
                    if (!(e = this._ua.normalizeTarget(e))) throw new TypeError('Invalid target: ' + i);
                    var u = o.cloneArray(r.extraHeaders),
                      c = r.eventHandlers || {},
                      d = r.contentType || 'text/plain';
                    for (var h in c) Object.prototype.hasOwnProperty.call(c, h) && this.on(h, c[h]);
                    u.push('Content-Type: ' + d), (this._request = new a.OutgoingRequest(s.MESSAGE, e, this._ua, null, u)), t && (this._request.body = t);
                    var f = new l(this._ua, this._request, {
                      onRequestTimeout: function () {
                        n._onRequestTimeout();
                      },
                      onTransportError: function () {
                        n._onTransportError();
                      },
                      onReceiveResponse: function (e) {
                        n._receiveResponse(e);
                      },
                    });
                    this._newMessage('local', this._request), f.send();
                  },
                },
                {
                  key: 'init_incoming',
                  value: function (e) {
                    (this._request = e), this._newMessage('remote', e), this._is_replied || ((this._is_replied = !0), e.reply(200)), this._close();
                  },
                },
                {
                  key: 'accept',
                  value: function () {
                    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
                      t = o.cloneArray(e.extraHeaders),
                      n = e.body;
                    if ('incoming' !== this._direction) throw new u.NotSupportedError('"accept" not supported for outgoing Message');
                    if (this._is_replied) throw new Error('incoming Message already replied');
                    (this._is_replied = !0), this._request.reply(200, null, t, n);
                  },
                },
                {
                  key: 'reject',
                  value: function () {
                    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
                      t = e.status_code || 480,
                      n = e.reason_phrase,
                      r = o.cloneArray(e.extraHeaders),
                      i = e.body;
                    if ('incoming' !== this._direction) throw new u.NotSupportedError('"reject" not supported for outgoing Message');
                    if (this._is_replied) throw new Error('incoming Message already replied');
                    if (t < 300 || t >= 700) throw new TypeError('Invalid status_code: ' + t);
                    (this._is_replied = !0), this._request.reply(t, n, r, i);
                  },
                },
                {
                  key: '_receiveResponse',
                  value: function (e) {
                    if (!this._closed)
                      switch (!0) {
                        case /^1[0-9]{2}$/.test(e.status_code):
                          break;
                        case /^2[0-9]{2}$/.test(e.status_code):
                          this._succeeded('remote', e);
                          break;
                        default:
                          var t = o.sipErrorCause(e.status_code);
                          this._failed('remote', e, t);
                      }
                  },
                },
                {
                  key: '_onRequestTimeout',
                  value: function () {
                    this._closed || this._failed('system', null, s.causes.REQUEST_TIMEOUT);
                  },
                },
                {
                  key: '_onTransportError',
                  value: function () {
                    this._closed || this._failed('system', null, s.causes.CONNECTION_ERROR);
                  },
                },
                {
                  key: '_close',
                  value: function () {
                    (this._closed = !0), this._ua.destroyMessage(this);
                  },
                },
                {
                  key: '_newMessage',
                  value: function (e, t) {
                    'remote' === e
                      ? ((this._direction = 'incoming'), (this._local_identity = t.to), (this._remote_identity = t.from))
                      : 'local' === e && ((this._direction = 'outgoing'), (this._local_identity = t.from), (this._remote_identity = t.to)),
                      this._ua.newMessage(this, { originator: e, message: this, request: t });
                  },
                },
                {
                  key: '_failed',
                  value: function (e, t, n) {
                    c('MESSAGE failed'), this._close(), c('emit "failed"'), this.emit('failed', { originator: e, response: t || null, cause: n });
                  },
                },
                {
                  key: '_succeeded',
                  value: function (e, t) {
                    c('MESSAGE succeeded'), this._close(), c('emit "succeeded"'), this.emit('succeeded', { originator: e, response: t });
                  },
                },
                {
                  key: 'direction',
                  get: function () {
                    return this._direction;
                  },
                },
                {
                  key: 'local_identity',
                  get: function () {
                    return this._local_identity;
                  },
                },
                {
                  key: 'remote_identity',
                  get: function () {
                    return this._remote_identity;
                  },
                },
              ]),
              t
            );
          })();
        },
        { './Constants': 2, './Exceptions': 6, './RequestSender': 18, './SIPMessage': 19, './Utils': 26, debug: 29, events: 31 },
      ],
      10: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('./URI'),
            s = e('./Grammar');
          t.exports = (function () {
            r(e, null, [
              {
                key: 'parse',
                value: function (e) {
                  return -1 !== (e = s.parse(e, 'Name_Addr_Header')) ? e : void 0;
                },
              },
            ]);
            function e(t, n, r) {
              if (
                ((function (e, t) {
                  if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
                })(this, e),
                !(t && t instanceof i))
              )
                throw new TypeError('missing or invalid "uri" parameter');
              (this._uri = t), (this._parameters = {}), (this._display_name = n);
              for (var s in r) Object.prototype.hasOwnProperty.call(r, s) && this.setParam(s, r[s]);
            }
            return (
              r(e, [
                {
                  key: 'setParam',
                  value: function (e, t) {
                    e && (this._parameters[e.toLowerCase()] = void 0 === t || null === t ? null : t.toString());
                  },
                },
                {
                  key: 'getParam',
                  value: function (e) {
                    if (e) return this._parameters[e.toLowerCase()];
                  },
                },
                {
                  key: 'hasParam',
                  value: function (e) {
                    if (e) return !!this._parameters.hasOwnProperty(e.toLowerCase());
                  },
                },
                {
                  key: 'deleteParam',
                  value: function (e) {
                    if (((e = e.toLowerCase()), this._parameters.hasOwnProperty(e))) {
                      var t = this._parameters[e];
                      return delete this._parameters[e], t;
                    }
                  },
                },
                {
                  key: 'clearParams',
                  value: function () {
                    this._parameters = {};
                  },
                },
                {
                  key: 'clone',
                  value: function () {
                    return new e(this._uri.clone(), this._display_name, JSON.parse(JSON.stringify(this._parameters)));
                  },
                },
                {
                  key: 'toString',
                  value: function () {
                    var e = this._display_name || 0 === this._display_name ? '"' + this._display_name + '" ' : '';
                    e += '<' + this._uri.toString() + '>';
                    for (var t in this._parameters)
                      Object.prototype.hasOwnProperty.call(this._parameters, t) && ((e += ';' + t), null !== this._parameters[t] && (e += '=' + this._parameters[t]));
                    return e;
                  },
                },
                {
                  key: 'uri',
                  get: function () {
                    return this._uri;
                  },
                },
                {
                  key: 'display_name',
                  get: function () {
                    return this._display_name;
                  },
                  set: function (e) {
                    this._display_name = 0 === e ? '0' : e;
                  },
                },
              ]),
              e
            );
          })();
        },
        { './Grammar': 7, './URI': 25 },
      ],
      11: [
        function (e, t, n) {
          'use strict';
          var r = e('./Grammar'),
            i = e('./SIPMessage'),
            s = e('debug')('JsSIP:ERROR:Parser');
          (s.log = console.warn.bind(console)),
            (n.parseMessage = function (e, t) {
              var n = void 0,
                l = void 0,
                u = e.indexOf('\r\n');
              if (-1 !== u) {
                var c = e.substring(0, u),
                  d = r.parse(c, 'Request_Response');
                if (-1 !== d) {
                  d.status_code
                    ? (((n = new i.IncomingResponse()).status_code = d.status_code), (n.reason_phrase = d.reason_phrase))
                    : (((n = new i.IncomingRequest(t)).method = d.method), (n.ruri = d.uri)),
                    (n.data = e);
                  for (var h = u + 2; ; ) {
                    if (-2 === (u = a(e, h))) {
                      l = h + 2;
                      break;
                    }
                    if (-1 === u) return void s('parseMessage() | malformed message');
                    if (!0 !== (d = o(n, e, h, u))) return void s('parseMessage() |', d.error);
                    h = u + 2;
                  }
                  if (n.hasHeader('content-length')) {
                    var f = n.getHeader('content-length');
                    n.body = e.substr(l, f);
                  } else n.body = e.substring(l);
                  return n;
                }
                s('parseMessage() | error parsing first line of SIP message: "' + c + '"');
              } else s('parseMessage() | no CRLF found, not a SIP message');
            });
          function a(e, t) {
            var n = t,
              r = 0,
              i = 0;
            if (e.substring(n, n + 2).match(/(^\r\n)/)) return -2;
            for (; 0 === r; ) {
              if (-1 === (i = e.indexOf('\r\n', n))) return i;
              !e.substring(i + 2, i + 4).match(/(^\r\n)/) && e.charAt(i + 2).match(/(^\s+)/) ? (n = i + 2) : (r = i);
            }
            return r;
          }
          function o(e, t, n, s) {
            var a = void 0,
              o = t.indexOf(':', n),
              l = t.substring(n, o).trim(),
              u = t.substring(o + 1, s).trim();
            switch (l.toLowerCase()) {
              case 'via':
              case 'v':
                e.addHeader('via', u), 1 === e.getHeaders('via').length ? (a = e.parseHeader('Via')) && ((e.via = a), (e.via_branch = a.branch)) : (a = 0);
                break;
              case 'from':
              case 'f':
                e.setHeader('from', u), (a = e.parseHeader('from')) && ((e.from = a), (e.from_tag = a.getParam('tag')));
                break;
              case 'to':
              case 't':
                e.setHeader('to', u), (a = e.parseHeader('to')) && ((e.to = a), (e.to_tag = a.getParam('tag')));
                break;
              case 'record-route':
                if (-1 === (a = r.parse(u, 'Record_Route'))) a = void 0;
                else {
                  var c = !0,
                    d = !1,
                    h = void 0;
                  try {
                    for (var f, p = a[Symbol.iterator](); !(c = (f = p.next()).done); c = !0) {
                      var _ = f.value;
                      e.addHeader('record-route', u.substring(_.possition, _.offset)),
                        (e.headers['Record-Route'][e.getHeaders('record-route').length - 1].parsed = _.parsed);
                    }
                  } catch (e) {
                    (d = !0), (h = e);
                  } finally {
                    try {
                      !c && p.return && p.return();
                    } finally {
                      if (d) throw h;
                    }
                  }
                }
                break;
              case 'call-id':
              case 'i':
                e.setHeader('call-id', u), (a = e.parseHeader('call-id')) && (e.call_id = u);
                break;
              case 'contact':
              case 'm':
                if (-1 === (a = r.parse(u, 'Contact'))) a = void 0;
                else {
                  var v = !0,
                    m = !1,
                    g = void 0;
                  try {
                    for (var y, T = a[Symbol.iterator](); !(v = (y = T.next()).done); v = !0) {
                      var C = y.value;
                      e.addHeader('contact', u.substring(C.possition, C.offset)), (e.headers.Contact[e.getHeaders('contact').length - 1].parsed = C.parsed);
                    }
                  } catch (e) {
                    (m = !0), (g = e);
                  } finally {
                    try {
                      !v && T.return && T.return();
                    } finally {
                      if (m) throw g;
                    }
                  }
                }
                break;
              case 'content-length':
              case 'l':
                e.setHeader('content-length', u), (a = e.parseHeader('content-length'));
                break;
              case 'content-type':
              case 'c':
                e.setHeader('content-type', u), (a = e.parseHeader('content-type'));
                break;
              case 'cseq':
                e.setHeader('cseq', u), (a = e.parseHeader('cseq')) && (e.cseq = a.value), e instanceof i.IncomingResponse && (e.method = a.method);
                break;
              case 'max-forwards':
                e.setHeader('max-forwards', u), (a = e.parseHeader('max-forwards'));
                break;
              case 'www-authenticate':
                e.setHeader('www-authenticate', u), (a = e.parseHeader('www-authenticate'));
                break;
              case 'proxy-authenticate':
                e.setHeader('proxy-authenticate', u), (a = e.parseHeader('proxy-authenticate'));
                break;
              case 'session-expires':
              case 'x':
                e.setHeader('session-expires', u),
                  (a = e.parseHeader('session-expires')) && ((e.session_expires = a.expires), (e.session_expires_refresher = a.refresher));
                break;
              case 'refer-to':
              case 'r':
                e.setHeader('refer-to', u), (a = e.parseHeader('refer-to')) && (e.refer_to = a);
                break;
              case 'replaces':
                e.setHeader('replaces', u), (a = e.parseHeader('replaces')) && (e.replaces = a);
                break;
              case 'event':
              case 'o':
                e.setHeader('event', u), (a = e.parseHeader('event')) && (e.event = a);
                break;
              default:
                e.setHeader(l, u), (a = 0);
            }
            return void 0 !== a || { error: 'error parsing header "' + l + '"' };
          }
        },
        { './Grammar': 7, './SIPMessage': 19, debug: 29 },
      ],
      12: [
        function (e, t, n) {
          'use strict';
          var r =
              'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
                ? function (e) {
                    return typeof e;
                  }
                : function (e) {
                    return e && 'function' == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? 'symbol' : typeof e;
                  },
            i = (function () {
              function e(e, t) {
                for (var n = 0; n < t.length; n++) {
                  var r = t[n];
                  (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
                }
              }
              return function (t, n, r) {
                return n && e(t.prototype, n), r && e(t, r), t;
              };
            })();
          var s = e('events').EventEmitter,
            a = e('sdp-transform'),
            o = e('./Constants'),
            l = e('./Exceptions'),
            u = e('./Transactions'),
            c = e('./Utils'),
            d = e('./Timers'),
            h = e('./SIPMessage'),
            f = e('./Dialog'),
            p = e('./RequestSender'),
            _ = e('./RTCSession/DTMF'),
            v = e('./RTCSession/Info'),
            m = e('./RTCSession/ReferNotifier'),
            g = e('./RTCSession/ReferSubscriber'),
            y = e('debug')('JsSIP:RTCSession'),
            T = e('debug')('JsSIP:ERROR:RTCSession');
          T.log = console.warn.bind(console);
          var C = {
              STATUS_NULL: 0,
              STATUS_INVITE_SENT: 1,
              STATUS_1XX_RECEIVED: 2,
              STATUS_INVITE_RECEIVED: 3,
              STATUS_WAITING_FOR_ANSWER: 4,
              STATUS_ANSWERED: 5,
              STATUS_WAITING_FOR_ACK: 6,
              STATUS_CANCELED: 7,
              STATUS_TERMINATED: 8,
              STATUS_CONFIRMED: 9,
            },
            S = ['audio', 'video'];
          t.exports = (function (e) {
            !(function (e, t) {
              if ('function' != typeof t && null !== t) throw new TypeError('Super expression must either be null or a function, not ' + typeof t);
              (e.prototype = Object.create(t && t.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })),
                t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : (e.__proto__ = t));
            })(t, s),
              i(t, null, [
                {
                  key: 'C',
                  get: function () {
                    return C;
                  },
                },
              ]);
            function t(e) {
              !(function (e, t) {
                if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
              })(this, t),
                y('new');
              var n = (function (e, t) {
                if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
                return !t || ('object' != typeof t && 'function' != typeof t) ? e : t;
              })(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
              return (
                (n._id = null),
                (n._ua = e),
                (n._status = C.STATUS_NULL),
                (n._dialog = null),
                (n._earlyDialogs = {}),
                (n._contact = null),
                (n._from_tag = null),
                (n._to_tag = null),
                (n._connection = null),
                (n._connectionPromiseQueue = Promise.resolve()),
                (n._request = null),
                (n._is_canceled = !1),
                (n._cancel_reason = ''),
                (n._is_confirmed = !1),
                (n._late_sdp = !1),
                (n._rtcOfferConstraints = null),
                (n._rtcAnswerConstraints = null),
                (n._localMediaStream = null),
                (n._localMediaStreamLocallyGenerated = !1),
                (n._rtcReady = !0),
                (n._timers = { ackTimer: null, expiresTimer: null, invite2xxTimer: null, userNoAnswerTimer: null }),
                (n._direction = null),
                (n._local_identity = null),
                (n._remote_identity = null),
                (n._start_time = null),
                (n._end_time = null),
                (n._tones = null),
                (n._audioMuted = !1),
                (n._videoMuted = !1),
                (n._localHold = !1),
                (n._remoteHold = !1),
                (n._sessionTimers = {
                  enabled: n._ua.configuration.session_timers,
                  refreshMethod: n._ua.configuration.session_timers_refresh_method,
                  defaultExpires: o.SESSION_EXPIRES,
                  currentExpires: null,
                  running: !1,
                  refresher: !1,
                  timer: null,
                }),
                (n._referSubscribers = {}),
                (n._data = {}),
                n
              );
            }
            return (
              i(t, [
                {
                  key: 'isInProgress',
                  value: function () {
                    switch (this._status) {
                      case C.STATUS_NULL:
                      case C.STATUS_INVITE_SENT:
                      case C.STATUS_1XX_RECEIVED:
                      case C.STATUS_INVITE_RECEIVED:
                      case C.STATUS_WAITING_FOR_ANSWER:
                        return !0;
                      default:
                        return !1;
                    }
                  },
                },
                {
                  key: 'isEstablished',
                  value: function () {
                    switch (this._status) {
                      case C.STATUS_ANSWERED:
                      case C.STATUS_WAITING_FOR_ACK:
                      case C.STATUS_CONFIRMED:
                        return !0;
                      default:
                        return !1;
                    }
                  },
                },
                {
                  key: 'isEnded',
                  value: function () {
                    switch (this._status) {
                      case C.STATUS_CANCELED:
                      case C.STATUS_TERMINATED:
                        return !0;
                      default:
                        return !1;
                    }
                  },
                },
                {
                  key: 'isMuted',
                  value: function () {
                    return { audio: this._audioMuted, video: this._videoMuted };
                  },
                },
                {
                  key: 'isOnHold',
                  value: function () {
                    return { local: this._localHold, remote: this._remoteHold };
                  },
                },
                {
                  key: 'connect',
                  value: function (e) {
                    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {},
                      n = arguments[2];
                    y('connect()');
                    var r = e,
                      i = t.eventHandlers || {},
                      s = c.cloneArray(t.extraHeaders),
                      a = t.mediaConstraints || { audio: !0, video: !0 },
                      u = t.mediaStream || null,
                      d = t.pcConfig || { iceServers: [] },
                      f = t.rtcConstraints || null,
                      p = t.rtcOfferConstraints || null;
                    if (
                      ((this._rtcOfferConstraints = p), (this._rtcAnswerConstraints = t.rtcAnswerConstraints || null), (this._data = t.data || this._data), void 0 === e)
                    )
                      throw new TypeError('Not enough arguments');
                    if (this._status !== C.STATUS_NULL) throw new l.InvalidStateError(this._status);
                    if (!window.RTCPeerConnection) throw new l.NotSupportedError('WebRTC not supported');
                    if (!(e = this._ua.normalizeTarget(e))) throw new TypeError('Invalid target: ' + r);
                    this._sessionTimers.enabled &&
                      c.isDecimal(t.sessionTimersExpires) &&
                      (t.sessionTimersExpires >= o.MIN_SESSION_EXPIRES
                        ? (this._sessionTimers.defaultExpires = t.sessionTimersExpires)
                        : (this._sessionTimers.defaultExpires = o.SESSION_EXPIRES));
                    for (var _ in i) Object.prototype.hasOwnProperty.call(i, _) && this.on(_, i[_]);
                    this._from_tag = c.newTag();
                    var v = t.anonymous || !1,
                      m = { from_tag: this._from_tag };
                    (this._contact = this._ua.contact.toString({ anonymous: v, outbound: !0 })),
                      v &&
                        ((m.from_display_name = 'Anonymous'),
                        (m.from_uri = 'sip:anonymous@anonymous.invalid'),
                        s.push('P-Preferred-Identity: ' + this._ua.configuration.uri.toString()),
                        s.push('Privacy: id')),
                      s.push('Contact: ' + this._contact),
                      s.push('Content-Type: application/sdp'),
                      this._sessionTimers.enabled && s.push('Session-Expires: ' + this._sessionTimers.defaultExpires),
                      (this._request = new h.InitialOutgoingInviteRequest(e, this._ua, m, s)),
                      (this._id = this._request.call_id + this._from_tag),
                      this._createRTCConnection(d, f),
                      (this._direction = 'outgoing'),
                      (this._local_identity = this._request.from),
                      (this._remote_identity = this._request.to),
                      n && n(this),
                      this._newRTCSession('local', this._request),
                      this._sendInitialRequest(a, p, u);
                  },
                },
                {
                  key: 'init_incoming',
                  value: function (e, t) {
                    var n = this;
                    y('init_incoming()');
                    var r = void 0,
                      i = e.getHeader('Content-Type');
                    e.body && 'application/sdp' !== i
                      ? e.reply(415)
                      : ((this._status = C.STATUS_INVITE_RECEIVED),
                        (this._from_tag = e.from_tag),
                        (this._id = e.call_id + this._from_tag),
                        (this._request = e),
                        (this._contact = this._ua.contact.toString()),
                        e.hasHeader('expires') && (r = 1e3 * e.getHeader('expires')),
                        (e.to_tag = c.newTag()),
                        this._createDialog(e, 'UAS', !0)
                          ? (e.body ? (this._late_sdp = !1) : (this._late_sdp = !0),
                            (this._status = C.STATUS_WAITING_FOR_ANSWER),
                            (this._timers.userNoAnswerTimer = setTimeout(function () {
                              e.reply(408), n._failed('local', null, o.causes.NO_ANSWER);
                            }, this._ua.configuration.no_answer_timeout)),
                            r &&
                              (this._timers.expiresTimer = setTimeout(function () {
                                n._status === C.STATUS_WAITING_FOR_ANSWER && (e.reply(487), n._failed('system', null, o.causes.EXPIRES));
                              }, r)),
                            (this._direction = 'incoming'),
                            (this._local_identity = e.to),
                            (this._remote_identity = e.from),
                            t && t(this),
                            this._newRTCSession('remote', e),
                            this._status !== C.STATUS_TERMINATED && (e.reply(180, null, ['Contact: ' + this._contact]), this._progress('local', null)))
                          : e.reply(500, 'Missing Contact header field'));
                  },
                },
                {
                  key: 'answer',
                  value: function () {
                    var e = this,
                      t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
                    y('answer()');
                    var n = this._request,
                      r = c.cloneArray(t.extraHeaders),
                      i = t.mediaConstraints || {},
                      s = t.mediaStream || null,
                      a = t.pcConfig || { iceServers: [] },
                      u = t.rtcConstraints || null,
                      d = t.rtcAnswerConstraints || null,
                      h = void 0,
                      f = !1,
                      p = !1,
                      _ = !1,
                      v = !1;
                    if (
                      ((this._rtcAnswerConstraints = d),
                      (this._rtcOfferConstraints = t.rtcOfferConstraints || null),
                      (this._data = t.data || this._data),
                      'incoming' !== this._direction)
                    )
                      throw new l.NotSupportedError('"answer" not supported for outgoing RTCSession');
                    if (this._status !== C.STATUS_WAITING_FOR_ANSWER) throw new l.InvalidStateError(this._status);
                    if (
                      (this._sessionTimers.enabled &&
                        c.isDecimal(t.sessionTimersExpires) &&
                        (t.sessionTimersExpires >= o.MIN_SESSION_EXPIRES
                          ? (this._sessionTimers.defaultExpires = t.sessionTimersExpires)
                          : (this._sessionTimers.defaultExpires = o.SESSION_EXPIRES)),
                      (this._status = C.STATUS_ANSWERED),
                      this._createDialog(n, 'UAS'))
                    ) {
                      clearTimeout(this._timers.userNoAnswerTimer), r.unshift('Contact: ' + this._contact);
                      var m = n.parseSDP();
                      Array.isArray(m.media) || (m.media = [m.media]);
                      var g = !0,
                        S = !1,
                        E = void 0;
                      try {
                        for (var b, R = m.media[Symbol.iterator](); !(g = (b = R.next()).done); g = !0) {
                          var A = b.value;
                          'audio' === A.type && ((f = !0), (A.direction && 'sendrecv' !== A.direction) || (_ = !0)),
                            'video' === A.type && ((p = !0), (A.direction && 'sendrecv' !== A.direction) || (v = !0));
                        }
                      } catch (e) {
                        (S = !0), (E = e);
                      } finally {
                        try {
                          !g && R.return && R.return();
                        } finally {
                          if (S) throw E;
                        }
                      }
                      if (s && !1 === i.audio) {
                        h = s.getAudioTracks();
                        var w = !0,
                          I = !1,
                          k = void 0;
                        try {
                          for (var P, O = h[Symbol.iterator](); !(w = (P = O.next()).done); w = !0) {
                            var x = P.value;
                            s.removeTrack(x);
                          }
                        } catch (e) {
                          (I = !0), (k = e);
                        } finally {
                          try {
                            !w && O.return && O.return();
                          } finally {
                            if (I) throw k;
                          }
                        }
                      }
                      if (s && !1 === i.video) {
                        h = s.getVideoTracks();
                        var N = !0,
                          D = !1,
                          U = void 0;
                        try {
                          for (var M, L = h[Symbol.iterator](); !(N = (M = L.next()).done); N = !0) {
                            var q = M.value;
                            s.removeTrack(q);
                          }
                        } catch (e) {
                          (D = !0), (U = e);
                        } finally {
                          try {
                            !N && L.return && L.return();
                          } finally {
                            if (D) throw U;
                          }
                        }
                      }
                      s || void 0 !== i.audio || (i.audio = _),
                        s || void 0 !== i.video || (i.video = v),
                        s || f || (i.audio = !1),
                        s || p || (i.video = !1),
                        this._createRTCConnection(a, u),
                        s
                          ? H.call(this, s)
                          : i.audio || i.video
                          ? ((this._localMediaStreamLocallyGenerated = !0),
                            navigator.mediaDevices
                              .getUserMedia(i)
                              .then(H.bind(this))
                              .catch(function (t) {
                                (function () {
                                  if (this._status === C.STATUS_TERMINATED) return;
                                  n.reply(480), this._failed('local', null, o.causes.USER_DENIED_MEDIA_ACCESS);
                                }.call(e, t),
                                  T('emit "getusermediafailed" [error:%o]', t),
                                  e.emit('getusermediafailed', t));
                              }))
                          : H.call(this, null);
                    } else n.reply(500, 'Error creating dialog');
                    function H(e) {
                      var t = this;
                      if (this._status !== C.STATUS_TERMINATED)
                        if (((this._localMediaStream = e), e && this._connection.addStream(e), this._late_sdp)) F.call(this);
                        else {
                          var r = { originator: 'remote', type: 'offer', sdp: n.body };
                          y('emit "sdp"'), this.emit('sdp', r);
                          var i = new RTCSessionDescription({ type: 'offer', sdp: r.sdp });
                          this._connectionPromiseQueue = this._connectionPromiseQueue
                            .then(function () {
                              return t._connection.setRemoteDescription(i);
                            })
                            .then(F.bind(this))
                            .catch(function (e) {
                              n.reply(488),
                                t._failed('system', null, o.causes.WEBRTC_ERROR),
                                T('emit "peerconnection:setremotedescriptionfailed" [error:%o]', e),
                                t.emit('peerconnection:setremotedescriptionfailed', e);
                            });
                        }
                    }
                    function F() {
                      this._connecting(n),
                        this._late_sdp
                          ? this._createLocalDescription('offer', j.bind(this), G.bind(this), this._rtcOfferConstraints)
                          : this._createLocalDescription('answer', j.bind(this), G.bind(this), d);
                    }
                    function j(e) {
                      this._status !== C.STATUS_TERMINATED &&
                        (this._handleSessionTimersInIncomingRequest(n, r),
                        n.reply(
                          200,
                          null,
                          r,
                          e,
                          function () {
                            (this._status = C.STATUS_WAITING_FOR_ACK), this._setInvite2xxTimer(n, e), this._setACKTimer(), this._accepted('local');
                          }.bind(this),
                          function () {
                            this._failed('system', null, o.causes.CONNECTION_ERROR);
                          }.bind(this)
                        ));
                    }
                    function G() {
                      this._status !== C.STATUS_TERMINATED && (n.reply(500), this._failed('system', null, o.causes.WEBRTC_ERROR));
                    }
                  },
                },
                {
                  key: 'terminate',
                  value: function () {
                    var e = this,
                      t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
                    y('terminate()');
                    var n = t.cause || o.causes.BYE,
                      r = c.cloneArray(t.extraHeaders),
                      i = t.body,
                      s = void 0,
                      a = t.status_code,
                      d = t.reason_phrase;
                    if (this._status === C.STATUS_TERMINATED) throw new l.InvalidStateError(this._status);
                    switch (this._status) {
                      case C.STATUS_NULL:
                      case C.STATUS_INVITE_SENT:
                      case C.STATUS_1XX_RECEIVED:
                        if ((y('canceling session'), a && (a < 200 || a >= 700))) throw new TypeError('Invalid status_code: ' + a);
                        a && (s = 'SIP ;cause=' + a + ' ;text="' + (d = d || o.REASON_PHRASE[a] || '') + '"'),
                          this._status === C.STATUS_NULL || this._status === C.STATUS_INVITE_SENT
                            ? ((this._is_canceled = !0), (this._cancel_reason = s))
                            : this._status === C.STATUS_1XX_RECEIVED && this._request.cancel(s),
                          (this._status = C.STATUS_CANCELED),
                          this._failed('local', null, o.causes.CANCELED);
                        break;
                      case C.STATUS_WAITING_FOR_ANSWER:
                      case C.STATUS_ANSWERED:
                        if ((y('rejecting session'), (a = a || 480) < 300 || a >= 700)) throw new TypeError('Invalid status_code: ' + a);
                        this._request.reply(a, d, r, i), this._failed('local', null, o.causes.REJECTED);
                        break;
                      case C.STATUS_WAITING_FOR_ACK:
                      case C.STATUS_CONFIRMED:
                        if ((y('terminating session'), (d = t.reason_phrase || o.REASON_PHRASE[a] || ''), a && (a < 200 || a >= 700)))
                          throw new TypeError('Invalid status_code: ' + a);
                        if (
                          (a && r.push('Reason: SIP ;cause=' + a + '; text="' + d + '"'),
                          this._status === C.STATUS_WAITING_FOR_ACK && 'incoming' === this._direction && this._request.server_transaction.state !== u.C.STATUS_TERMINATED)
                        ) {
                          var h = this._dialog;
                          (this.receiveRequest = function (t) {
                            t.method === o.ACK && (e.sendRequest(o.BYE, { extraHeaders: r, body: i }), h.terminate());
                          }),
                            this._request.server_transaction.on('stateChanged', function () {
                              e._request.server_transaction.state === u.C.STATUS_TERMINATED && (e.sendRequest(o.BYE, { extraHeaders: r, body: i }), h.terminate());
                            }),
                            this._ended('local', null, n),
                            (this._dialog = h),
                            this._ua.newDialog(h);
                        } else this.sendRequest(o.BYE, { extraHeaders: r, body: i }), this._ended('local', null, n);
                    }
                  },
                },
                {
                  key: 'sendDTMF',
                  value: function (e) {
                    var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
                    y('sendDTMF() | tones: %s', e);
                    var n = 0,
                      r = t.duration || null,
                      i = t.interToneGap || null;
                    if (void 0 === e) throw new TypeError('Not enough arguments');
                    if (this._status !== C.STATUS_CONFIRMED && this._status !== C.STATUS_WAITING_FOR_ACK) throw new l.InvalidStateError(this._status);
                    if (('number' == typeof e && (e = e.toString()), !e || 'string' != typeof e || !e.match(/^[0-9A-DR#*,]+$/i)))
                      throw new TypeError('Invalid tones: ' + e);
                    if (r && !c.isDecimal(r)) throw new TypeError('Invalid tone duration: ' + r);
                    if (
                      (r
                        ? r < _.C.MIN_DURATION
                          ? (y('"duration" value is lower than the minimum allowed, setting it to ' + _.C.MIN_DURATION + ' milliseconds'), (r = _.C.MIN_DURATION))
                          : r > _.C.MAX_DURATION
                          ? (y('"duration" value is greater than the maximum allowed, setting it to ' + _.C.MAX_DURATION + ' milliseconds'), (r = _.C.MAX_DURATION))
                          : (r = Math.abs(r))
                        : (r = _.C.DEFAULT_DURATION),
                      (t.duration = r),
                      i && !c.isDecimal(i))
                    )
                      throw new TypeError('Invalid interToneGap: ' + i);
                    i
                      ? i < _.C.MIN_INTER_TONE_GAP
                        ? (y('"interToneGap" value is lower than the minimum allowed, setting it to ' + _.C.MIN_INTER_TONE_GAP + ' milliseconds'),
                          (i = _.C.MIN_INTER_TONE_GAP))
                        : (i = Math.abs(i))
                      : (i = _.C.DEFAULT_INTER_TONE_GAP),
                      this._tones
                        ? (this._tones += e)
                        : ((this._tones = e),
                          function e() {
                            var s = this;
                            var a = void 0;
                            if (this._status === C.STATUS_TERMINATED || !this._tones || n >= this._tones.length) return void (this._tones = null);
                            var o = this._tones[n];
                            n += 1;
                            if (',' === o) a = 2e3;
                            else {
                              var l = new _(this);
                              (t.eventHandlers = {
                                onFailed: function () {
                                  s._tones = null;
                                },
                              }),
                                l.send(o, t),
                                (a = r + i);
                            }
                            setTimeout(e.bind(this), a);
                          }.call(this));
                  },
                },
                {
                  key: 'sendInfo',
                  value: function (e, t) {
                    var n = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
                    if ((y('sendInfo()'), this._status !== C.STATUS_CONFIRMED && this._status !== C.STATUS_WAITING_FOR_ACK)) throw new l.InvalidStateError(this._status);
                    new v(this).send(e, t, n);
                  },
                },
                {
                  key: 'mute',
                  value: function () {
                    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : { audio: !0, video: !1 };
                    y('mute()');
                    var t = !1,
                      n = !1;
                    !1 === this._audioMuted && e.audio && ((t = !0), (this._audioMuted = !0), this._toogleMuteAudio(!0)),
                      !1 === this._videoMuted && e.video && ((n = !0), (this._videoMuted = !0), this._toogleMuteVideo(!0)),
                      (!0 !== t && !0 !== n) || this._onmute({ audio: t, video: n });
                  },
                },
                {
                  key: 'unmute',
                  value: function () {
                    var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : { audio: !0, video: !0 };
                    y('unmute()');
                    var t = !1,
                      n = !1;
                    !0 === this._audioMuted && e.audio && ((t = !0), (this._audioMuted = !1), !1 === this._localHold && this._toogleMuteAudio(!1)),
                      !0 === this._videoMuted && e.video && ((n = !0), (this._videoMuted = !1), !1 === this._localHold && this._toogleMuteVideo(!1)),
                      (!0 !== t && !0 !== n) || this._onunmute({ audio: t, video: n });
                  },
                },
                {
                  key: 'hold',
                  value: function () {
                    var e = this,
                      t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
                      n = arguments[1];
                    if ((y('hold()'), this._status !== C.STATUS_WAITING_FOR_ACK && this._status !== C.STATUS_CONFIRMED)) return !1;
                    if (!0 === this._localHold) return !1;
                    if (!this._isReadyToReOffer()) return !1;
                    (this._localHold = !0), this._onhold('local');
                    var r = {
                      succeeded: function () {
                        n && n();
                      },
                      failed: function () {
                        e.terminate({ cause: o.causes.WEBRTC_ERROR, status_code: 500, reason_phrase: 'Hold Failed' });
                      },
                    };
                    return (
                      t.useUpdate
                        ? this._sendUpdate({ sdpOffer: !0, eventHandlers: r, extraHeaders: t.extraHeaders })
                        : this._sendReinvite({ eventHandlers: r, extraHeaders: t.extraHeaders }),
                      !0
                    );
                  },
                },
                {
                  key: 'unhold',
                  value: function () {
                    var e = this,
                      t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
                      n = arguments[1];
                    if ((y('unhold()'), this._status !== C.STATUS_WAITING_FOR_ACK && this._status !== C.STATUS_CONFIRMED)) return !1;
                    if (!1 === this._localHold) return !1;
                    if (!this._isReadyToReOffer()) return !1;
                    (this._localHold = !1), this._onunhold('local');
                    var r = {
                      succeeded: function () {
                        n && n();
                      },
                      failed: function () {
                        e.terminate({ cause: o.causes.WEBRTC_ERROR, status_code: 500, reason_phrase: 'Unhold Failed' });
                      },
                    };
                    return (
                      t.useUpdate
                        ? this._sendUpdate({ sdpOffer: !0, eventHandlers: r, extraHeaders: t.extraHeaders })
                        : this._sendReinvite({ eventHandlers: r, extraHeaders: t.extraHeaders }),
                      !0
                    );
                  },
                },
                {
                  key: 'renegotiate',
                  value: function () {
                    var e = this,
                      t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
                      n = arguments[1];
                    y('renegotiate()');
                    var r = t.rtcOfferConstraints || null;
                    if (this._status !== C.STATUS_WAITING_FOR_ACK && this._status !== C.STATUS_CONFIRMED) return !1;
                    if (!this._isReadyToReOffer()) return !1;
                    var i = {
                      succeeded: function () {
                        n && n();
                      },
                      failed: function () {
                        e.terminate({ cause: o.causes.WEBRTC_ERROR, status_code: 500, reason_phrase: 'Media Renegotiation Failed' });
                      },
                    };
                    return (
                      this._setLocalMediaStatus(),
                      t.useUpdate
                        ? this._sendUpdate({ sdpOffer: !0, eventHandlers: i, rtcOfferConstraints: r, extraHeaders: t.extraHeaders })
                        : this._sendReinvite({ eventHandlers: i, rtcOfferConstraints: r, extraHeaders: t.extraHeaders }),
                      !0
                    );
                  },
                },
                {
                  key: 'refer',
                  value: function (e, t) {
                    var n = this;
                    y('refer()');
                    var r = e;
                    if (this._status !== C.STATUS_WAITING_FOR_ACK && this._status !== C.STATUS_CONFIRMED) return !1;
                    if (!(e = this._ua.normalizeTarget(e))) throw new TypeError('Invalid target: ' + r);
                    var i = new g(this);
                    i.sendRefer(e, t);
                    var s = i.id;
                    return (
                      (this._referSubscribers[s] = i),
                      i.on('requestFailed', function () {
                        delete n._referSubscribers[s];
                      }),
                      i.on('accepted', function () {
                        delete n._referSubscribers[s];
                      }),
                      i.on('failed', function () {
                        delete n._referSubscribers[s];
                      }),
                      i
                    );
                  },
                },
                {
                  key: 'sendRequest',
                  value: function (e, t) {
                    return y('sendRequest()'), this._dialog.sendRequest(e, t);
                  },
                },
                {
                  key: 'receiveRequest',
                  value: function (e) {
                    var t = this;
                    if ((y('receiveRequest()'), e.method === o.CANCEL))
                      (this._status !== C.STATUS_WAITING_FOR_ANSWER && this._status !== C.STATUS_ANSWERED) ||
                        ((this._status = C.STATUS_CANCELED), this._request.reply(487), this._failed('remote', e, o.causes.CANCELED));
                    else
                      switch (e.method) {
                        case o.ACK:
                          if (this._status !== C.STATUS_WAITING_FOR_ACK) return;
                          if (((this._status = C.STATUS_CONFIRMED), clearTimeout(this._timers.ackTimer), clearTimeout(this._timers.invite2xxTimer), this._late_sdp)) {
                            if (!e.body) {
                              this.terminate({ cause: o.causes.MISSING_SDP, status_code: 400 });
                              break;
                            }
                            var n = { originator: 'remote', type: 'answer', sdp: e.body };
                            y('emit "sdp"'), this.emit('sdp', n);
                            var r = new RTCSessionDescription({ type: 'answer', sdp: n.sdp });
                            this._connectionPromiseQueue = this._connectionPromiseQueue
                              .then(function () {
                                return t._connection.setRemoteDescription(r);
                              })
                              .then(function () {
                                t._is_confirmed || t._confirmed('remote', e);
                              })
                              .catch(function (e) {
                                t.terminate({ cause: o.causes.BAD_MEDIA_DESCRIPTION, status_code: 488 }),
                                  T('emit "peerconnection:setremotedescriptionfailed" [error:%o]', e),
                                  t.emit('peerconnection:setremotedescriptionfailed', e);
                              });
                          } else this._is_confirmed || this._confirmed('remote', e);
                          break;
                        case o.BYE:
                          this._status === C.STATUS_CONFIRMED
                            ? (e.reply(200), this._ended('remote', e, o.causes.BYE))
                            : this._status === C.STATUS_INVITE_RECEIVED
                            ? (e.reply(200), this._request.reply(487, 'BYE Received'), this._ended('remote', e, o.causes.BYE))
                            : e.reply(403, 'Wrong Status');
                          break;
                        case o.INVITE:
                          this._status === C.STATUS_CONFIRMED
                            ? e.hasHeader('replaces')
                              ? this._receiveReplaces(e)
                              : this._receiveReinvite(e)
                            : e.reply(403, 'Wrong Status');
                          break;
                        case o.INFO:
                          if (
                            this._status === C.STATUS_1XX_RECEIVED ||
                            this._status === C.STATUS_WAITING_FOR_ANSWER ||
                            this._status === C.STATUS_ANSWERED ||
                            this._status === C.STATUS_WAITING_FOR_ACK ||
                            this._status === C.STATUS_CONFIRMED
                          ) {
                            var i = e.getHeader('content-type');
                            i && i.match(/^application\/dtmf-relay/i) ? new _(this).init_incoming(e) : void 0 !== i ? new v(this).init_incoming(e) : e.reply(415);
                          } else e.reply(403, 'Wrong Status');
                          break;
                        case o.UPDATE:
                          this._status === C.STATUS_CONFIRMED ? this._receiveUpdate(e) : e.reply(403, 'Wrong Status');
                          break;
                        case o.REFER:
                          this._status === C.STATUS_CONFIRMED ? this._receiveRefer(e) : e.reply(403, 'Wrong Status');
                          break;
                        case o.NOTIFY:
                          this._status === C.STATUS_CONFIRMED ? this._receiveNotify(e) : e.reply(403, 'Wrong Status');
                          break;
                        default:
                          e.reply(501);
                      }
                  },
                },
                {
                  key: 'onTransportError',
                  value: function () {
                    T('onTransportError()'),
                      this._status !== C.STATUS_TERMINATED &&
                        this.terminate({ status_code: 500, reason_phrase: o.causes.CONNECTION_ERROR, cause: o.causes.CONNECTION_ERROR });
                  },
                },
                {
                  key: 'onRequestTimeout',
                  value: function () {
                    T('onRequestTimeout()'),
                      this._status !== C.STATUS_TERMINATED &&
                        this.terminate({ status_code: 408, reason_phrase: o.causes.REQUEST_TIMEOUT, cause: o.causes.REQUEST_TIMEOUT });
                  },
                },
                {
                  key: 'onDialogError',
                  value: function () {
                    T('onDialogError()'),
                      this._status !== C.STATUS_TERMINATED && this.terminate({ status_code: 500, reason_phrase: o.causes.DIALOG_ERROR, cause: o.causes.DIALOG_ERROR });
                  },
                },
                {
                  key: 'newDTMF',
                  value: function (e) {
                    y('newDTMF()'), this.emit('newDTMF', e);
                  },
                },
                {
                  key: 'newInfo',
                  value: function (e) {
                    y('newInfo()'), this.emit('newInfo', e);
                  },
                },
                {
                  key: '_isReadyToReOffer',
                  value: function () {
                    return this._rtcReady
                      ? this._dialog
                        ? (!0 !== this._dialog.uac_pending_reply && !0 !== this._dialog.uas_pending_reply) ||
                          (y('_isReadyToReOffer() | there is another INVITE/UPDATE transaction in progress'), !1)
                        : (y('_isReadyToReOffer() | session not established yet'), !1)
                      : (y('_isReadyToReOffer() | internal WebRTC status not ready'), !1);
                  },
                },
                {
                  key: '_close',
                  value: function () {
                    if ((y('close()'), this._status !== C.STATUS_TERMINATED)) {
                      if (((this._status = C.STATUS_TERMINATED), this._connection))
                        try {
                          this._connection.close();
                        } catch (e) {
                          T('close() | error closing the RTCPeerConnection: %o', e);
                        }
                      this._localMediaStream &&
                        this._localMediaStreamLocallyGenerated &&
                        (y('close() | closing local MediaStream'), c.closeMediaStream(this._localMediaStream));
                      for (var e in this._timers) Object.prototype.hasOwnProperty.call(this._timers, e) && clearTimeout(this._timers[e]);
                      clearTimeout(this._sessionTimers.timer), this._dialog && (this._dialog.terminate(), delete this._dialog);
                      for (var t in this._earlyDialogs)
                        Object.prototype.hasOwnProperty.call(this._earlyDialogs, t) && (this._earlyDialogs[t].terminate(), delete this._earlyDialogs[t]);
                      for (var n in this._referSubscribers) Object.prototype.hasOwnProperty.call(this._referSubscribers, n) && delete this._referSubscribers[n];
                      this._ua.destroyRTCSession(this);
                    }
                  },
                },
                {
                  key: '_setInvite2xxTimer',
                  value: function (e, t) {
                    var n = d.T1;
                    this._timers.invite2xxTimer = setTimeout(
                      function r() {
                        this._status === C.STATUS_WAITING_FOR_ACK &&
                          (e.reply(200, null, ['Contact: ' + this._contact], t),
                          n < d.T2 && (n *= 2) > d.T2 && (n = d.T2),
                          (this._timers.invite2xxTimer = setTimeout(r.bind(this), n)));
                      }.bind(this),
                      n
                    );
                  },
                },
                {
                  key: '_setACKTimer',
                  value: function () {
                    var e = this;
                    this._timers.ackTimer = setTimeout(function () {
                      e._status === C.STATUS_WAITING_FOR_ACK &&
                        (y('no ACK received, terminating the session'),
                        clearTimeout(e._timers.invite2xxTimer),
                        e.sendRequest(o.BYE),
                        e._ended('remote', null, o.causes.NO_ACK));
                    }, d.TIMER_H);
                  },
                },
                {
                  key: '_createRTCConnection',
                  value: function (e, t) {
                    var n = this;
                    (this._connection = new RTCPeerConnection(e, t)),
                      this._connection.addEventListener('iceconnectionstatechange', function () {
                        'failed' === n._connection.iceConnectionState &&
                          n.terminate({ cause: o.causes.RTP_TIMEOUT, status_code: 408, reason_phrase: o.causes.RTP_TIMEOUT });
                      }),
                      y('emit "peerconnection"'),
                      this.emit('peerconnection', { peerconnection: this._connection });
                  },
                },
                {
                  key: '_createLocalDescription',
                  value: function (e, t, n, r) {
                    var i = this;
                    y('createLocalDescription()');
                    var s = this._connection;
                    if (((this._rtcReady = !1), 'offer' === e))
                      this._connectionPromiseQueue = this._connectionPromiseQueue
                        .then(function () {
                          return s.createOffer(r);
                        })
                        .then(a.bind(this))
                        .catch(function (e) {
                          (i._rtcReady = !0), n && n(e), T('emit "peerconnection:createofferfailed" [error:%o]', e), i.emit('peerconnection:createofferfailed', e);
                        });
                    else {
                      if ('answer' !== e) throw new Error('createLocalDescription() | type must be "offer" or "answer", but "' + e + '" was given');
                      this._connectionPromiseQueue = this._connectionPromiseQueue
                        .then(function () {
                          return s.createAnswer(r);
                        })
                        .then(a.bind(this))
                        .catch(function (e) {
                          (i._rtcReady = !0), n && n(e), T('emit "peerconnection:createanswerfailed" [error:%o]', e), i.emit('peerconnection:createanswerfailed', e);
                        });
                    }
                    function a(r) {
                      var i = this,
                        a = void 0;
                      s.addEventListener(
                        'icecandidate',
                        (a = function (n) {
                          if (!n.candidate) {
                            if ((s.removeEventListener('icecandidate', a), (i._rtcReady = !0), t)) {
                              var r = { originator: 'local', type: e, sdp: s.localDescription.sdp };
                              y('emit "sdp"'), i.emit('sdp', r), t(r.sdp);
                            }
                            t = null;
                          }
                        })
                      ),
                        s
                          .setLocalDescription(r)
                          .then(function () {
                            if ('complete' === s.iceGatheringState && ((i._rtcReady = !0), t)) {
                              var n = { originator: 'local', type: e, sdp: s.localDescription.sdp };
                              y('emit "sdp"'), i.emit('sdp', n), t(n.sdp), (t = null);
                            }
                          })
                          .catch(function (e) {
                            (i._rtcReady = !0),
                              n && n(e),
                              T('emit "peerconnection:setlocaldescriptionfailed" [error:%o]', e),
                              i.emit('peerconnection:setlocaldescriptionfailed', e);
                          });
                    }
                  },
                },
                {
                  key: '_createDialog',
                  value: function (e, t, n) {
                    var r = 'UAS' === t ? e.to_tag : e.from_tag,
                      i = 'UAS' === t ? e.from_tag : e.to_tag,
                      s = e.call_id + r + i,
                      a = this._earlyDialogs[s];
                    if (n)
                      return (
                        !!a ||
                        ((a = new f(this, e, t, f.C.STATUS_EARLY)).error
                          ? (y(a.error), this._failed('remote', e, o.causes.INTERNAL_ERROR), !1)
                          : ((this._earlyDialogs[s] = a), !0))
                      );
                    if (((this._from_tag = e.from_tag), (this._to_tag = e.to_tag), a)) return a.update(e, t), (this._dialog = a), delete this._earlyDialogs[s], !0;
                    var l = new f(this, e, t);
                    return l.error ? (y(l.error), this._failed('remote', e, o.causes.INTERNAL_ERROR), !1) : ((this._dialog = l), !0);
                  },
                },
                {
                  key: '_receiveReinvite',
                  value: function (e) {
                    var t = this;
                    y('receiveReinvite()');
                    var n = e.getHeader('Content-Type'),
                      r = {
                        request: e,
                        callback: void 0,
                        reject: function () {
                          var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
                          s = !0;
                          var n = t.status_code || 403,
                            r = t.reason_phrase || '',
                            i = c.cloneArray(t.extraHeaders);
                          if (this._status !== C.STATUS_CONFIRMED) return !1;
                          if (n < 300 || n >= 700) throw new TypeError('Invalid status_code: ' + n);
                          e.reply(n, r, i);
                        }.bind(this),
                      },
                      i = !1,
                      s = !1;
                    if ((this.emit('reinvite', r), !s))
                      if (e.body) {
                        if (((this._late_sdp = !1), 'application/sdp' !== n)) return y('invalid Content-Type'), void e.reply(415);
                        var a = e.parseSDP(),
                          o = !0,
                          l = !1,
                          u = void 0;
                        try {
                          for (var d, h = a.media[Symbol.iterator](); !(o = (d = h.next()).done); o = !0) {
                            var f = d.value;
                            if (-1 !== S.indexOf(f.type)) {
                              var p = f.direction || a.direction || 'sendrecv';
                              if ('sendonly' !== p && 'inactive' !== p) {
                                i = !1;
                                break;
                              }
                              i = !0;
                            }
                          }
                        } catch (e) {
                          (l = !0), (u = e);
                        } finally {
                          try {
                            !o && h.return && h.return();
                          } finally {
                            if (l) throw u;
                          }
                        }
                        var _ = { originator: 'remote', type: 'offer', sdp: e.body };
                        y('emit "sdp"'), this.emit('sdp', _);
                        var v = new RTCSessionDescription({ type: 'offer', sdp: _.sdp });
                        this._connectionPromiseQueue = this._connectionPromiseQueue
                          .then(function () {
                            return t._connection.setRemoteDescription(v);
                          })
                          .then(m.bind(this))
                          .catch(function (n) {
                            e.reply(488), T('emit "peerconnection:setremotedescriptionfailed" [error:%o]', n), t.emit('peerconnection:setremotedescriptionfailed', n);
                          });
                      } else (this._late_sdp = !0), m.call(this);
                    function m() {
                      var t = this;
                      (function (e, t) {
                        this._late_sdp
                          ? this._createLocalDescription('offer', e, t, this._rtcOfferConstraints)
                          : (!0 === this._remoteHold && !1 === i
                              ? ((this._remoteHold = !1), this._onunhold('remote'))
                              : !1 === this._remoteHold && !0 === i && ((this._remoteHold = !0), this._onhold('remote')),
                            this._createLocalDescription('answer', e, t, this._rtcAnswerConstraints));
                      }.call(
                        this,
                        function (n) {
                          var i = ['Contact: ' + t._contact];
                          t._handleSessionTimersInIncomingRequest(e, i),
                            t._late_sdp && (n = t._mangleOffer(n)),
                            e.reply(200, null, i, n, function () {
                              (t._status = C.STATUS_WAITING_FOR_ACK), t._setInvite2xxTimer(e, n), t._setACKTimer();
                            }),
                            'function' == typeof r.callback && r.callback();
                        },
                        function () {
                          e.reply(500);
                        }
                      ));
                    }
                  },
                },
                {
                  key: '_receiveUpdate',
                  value: function (e) {
                    var t = this;
                    y('receiveUpdate()');
                    var n = e.getHeader('Content-Type'),
                      r = {
                        request: e,
                        callback: void 0,
                        reject: function () {
                          var t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
                          i = !0;
                          var n = t.status_code || 403,
                            r = t.reason_phrase || '',
                            s = c.cloneArray(t.extraHeaders);
                          if (this._status !== C.STATUS_CONFIRMED) return !1;
                          if (n < 300 || n >= 700) throw new TypeError('Invalid status_code: ' + n);
                          e.reply(n, r, s);
                        }.bind(this),
                      },
                      i = !1,
                      s = !1;
                    if ((this.emit('update', r), !i)) {
                      if (!e.body) {
                        var a = [];
                        return this._handleSessionTimersInIncomingRequest(e, a), void e.reply(200, null, a);
                      }
                      if ('application/sdp' !== n) return y('invalid Content-Type'), void e.reply(415);
                      var o = e.parseSDP(),
                        l = !0,
                        u = !1,
                        d = void 0;
                      try {
                        for (var h, f = o.media[Symbol.iterator](); !(l = (h = f.next()).done); l = !0) {
                          var p = h.value;
                          if (-1 !== S.indexOf(p.type)) {
                            var _ = p.direction || o.direction || 'sendrecv';
                            if ('sendonly' !== _ && 'inactive' !== _) {
                              s = !1;
                              break;
                            }
                            s = !0;
                          }
                        }
                      } catch (e) {
                        (u = !0), (d = e);
                      } finally {
                        try {
                          !l && f.return && f.return();
                        } finally {
                          if (u) throw d;
                        }
                      }
                      var v = { originator: 'remote', type: 'offer', sdp: e.body };
                      y('emit "sdp"'), this.emit('sdp', v);
                      var m = new RTCSessionDescription({ type: 'offer', sdp: v.sdp });
                      this._connectionPromiseQueue = this._connectionPromiseQueue
                        .then(function () {
                          return t._connection.setRemoteDescription(m);
                        })
                        .then(function () {
                          !0 === t._remoteHold && !1 === s
                            ? ((t._remoteHold = !1), t._onunhold('remote'))
                            : !1 === t._remoteHold && !0 === s && ((t._remoteHold = !0), t._onhold('remote')),
                            t._createLocalDescription(
                              'answer',
                              function (n) {
                                var i = ['Contact: ' + t._contact];
                                t._handleSessionTimersInIncomingRequest(e, i), e.reply(200, null, i, n), 'function' == typeof r.callback && r.callback();
                              },
                              function () {
                                e.reply(500);
                              }
                            );
                        })
                        .catch(function (n) {
                          e.reply(488), T('emit "peerconnection:setremotedescriptionfailed" [error:%o]', n), t.emit('peerconnection:setremotedescriptionfailed', n);
                        });
                    }
                  },
                },
                {
                  key: '_receiveRefer',
                  value: function (e) {
                    var n = this;
                    if ((y('receiveRefer()'), void 0 === r(e.refer_to))) return y('no Refer-To header field present in REFER'), void e.reply(400);
                    if (e.refer_to.uri.scheme !== o.SIP) return y('Refer-To header field points to a non-SIP URI scheme'), void e.reply(416);
                    e.reply(202);
                    var i = new m(this, e.cseq);
                    y('emit "refer"'),
                      this.emit('refer', {
                        request: e,
                        accept: function (r, s) {
                          (function (n) {
                            var r = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
                            if (((n = 'function' == typeof n ? n : null), this._status !== C.STATUS_WAITING_FOR_ACK && this._status !== C.STATUS_CONFIRMED)) return !1;
                            var s = new t(this._ua);
                            if (
                              (s.on('progress', function (e) {
                                var t = e.response;
                                i.notify(t.status_code, t.reason_phrase);
                              }),
                              s.on('accepted', function (e) {
                                var t = e.response;
                                i.notify(t.status_code, t.reason_phrase);
                              }),
                              s.on('failed', function (e) {
                                var t = e.message,
                                  n = e.cause;
                                t ? i.notify(t.status_code, t.reason_phrase) : i.notify(487, n);
                              }),
                              e.refer_to.uri.hasHeader('replaces'))
                            ) {
                              var a = decodeURIComponent(e.refer_to.uri.getHeader('replaces'));
                              (r.extraHeaders = c.cloneArray(r.extraHeaders)), r.extraHeaders.push('Replaces: ' + a);
                            }
                            s.connect(e.refer_to.uri.toAor(), r, n);
                          }.call(n, r, s));
                        },
                        reject: function () {
                          (function () {
                            i.notify(603);
                          }.call(n));
                        },
                      });
                  },
                },
                {
                  key: '_receiveNotify',
                  value: function (e) {
                    switch ((y('receiveNotify()'), void 0 === r(e.event) && e.reply(400), e.event.event)) {
                      case 'refer':
                        var t = void 0,
                          n = void 0;
                        if (e.event.params && e.event.params.id) (t = e.event.params.id), (n = this._referSubscribers[t]);
                        else {
                          if (1 !== Object.keys(this._referSubscribers).length) return void e.reply(400, 'Missing event id parameter');
                          n = this._referSubscribers[Object.keys(this._referSubscribers)[0]];
                        }
                        if (!n) return void e.reply(481, 'Subscription does not exist');
                        n.receiveNotify(e), e.reply(200);
                        break;
                      default:
                        e.reply(489);
                    }
                  },
                },
                {
                  key: '_receiveReplaces',
                  value: function (e) {
                    var n = this;
                    y('receiveReplaces()');
                    this.emit('replaces', {
                      request: e,
                      accept: function (r) {
                        (function (n) {
                          var r = this;
                          if (this._status !== C.STATUS_WAITING_FOR_ACK && this._status !== C.STATUS_CONFIRMED) return !1;
                          var i = new t(this._ua);
                          i.on('confirmed', function () {
                            r.terminate();
                          }),
                            i.init_incoming(e, n);
                        }.call(n, r));
                      },
                      reject: function () {
                        (function () {
                          y('Replaced INVITE rejected by the user'), e.reply(486);
                        }.call(n));
                      },
                    });
                  },
                },
                {
                  key: '_sendInitialRequest',
                  value: function (e, t, n) {
                    var r = this,
                      i = new p(this._ua, this._request, {
                        onRequestTimeout: function () {
                          r.onRequestTimeout();
                        },
                        onTransportError: function () {
                          r.onTransportError();
                        },
                        onAuthenticated: function (e) {
                          r._request = e;
                        },
                        onReceiveResponse: function (e) {
                          r._receiveInviteResponse(e);
                        },
                      });
                    n
                      ? setTimeout(function () {
                          s.call(r, n);
                        })
                      : e.audio || e.video
                      ? ((this._localMediaStreamLocallyGenerated = !0),
                        navigator.mediaDevices
                          .getUserMedia(e)
                          .then(s.bind(this))
                          .catch(function (e) {
                            (function () {
                              if (this._status === C.STATUS_TERMINATED) return;
                              this._failed('local', null, o.causes.USER_DENIED_MEDIA_ACCESS);
                            }.call(r, e),
                              T('emit "getusermediafailed" [error:%o]', e),
                              r.emit('getusermediafailed', e));
                          }))
                      : s.call(this, null);
                    function s(e) {
                      this._status !== C.STATUS_TERMINATED &&
                        ((this._localMediaStream = e),
                        e && this._connection.addStream(e),
                        this._connecting(this._request),
                        this._createLocalDescription(
                          'offer',
                          function (e) {
                            if (this._is_canceled || this._status === C.STATUS_TERMINATED) return;
                            (this._request.body = e),
                              (this._status = C.STATUS_INVITE_SENT),
                              y('emit "sending" [request:%o]', this._request),
                              this.emit('sending', { request: this._request }),
                              i.send();
                          }.bind(this),
                          function () {
                            if (this._status === C.STATUS_TERMINATED) return;
                            this._failed('system', null, o.causes.WEBRTC_ERROR);
                          }.bind(this),
                          t
                        ));
                    }
                  },
                },
                {
                  key: '_receiveInviteResponse',
                  value: function (e) {
                    var t = this;
                    if ((y('receiveInviteResponse()'), this._dialog && e.status_code >= 200 && e.status_code <= 299)) {
                      if (this._dialog.id.call_id === e.call_id && this._dialog.id.local_tag === e.from_tag && this._dialog.id.remote_tag === e.to_tag)
                        return void this.sendRequest(o.ACK);
                      var n = new f(this, e, 'UAC');
                      return void 0 !== n.error ? void y(n.error) : (this.sendRequest(o.ACK), void this.sendRequest(o.BYE));
                    }
                    if (this._is_canceled)
                      e.status_code >= 100 && e.status_code < 200
                        ? this._request.cancel(this._cancel_reason)
                        : e.status_code >= 200 && e.status_code < 299 && this._acceptAndTerminate(e);
                    else if (this._status === C.STATUS_INVITE_SENT || this._status === C.STATUS_1XX_RECEIVED)
                      switch (!0) {
                        case /^100$/.test(e.status_code):
                          this._status = C.STATUS_1XX_RECEIVED;
                          break;
                        case /^1[0-9]{2}$/.test(e.status_code):
                          if (!e.to_tag) {
                            y('1xx response received without to tag');
                            break;
                          }
                          if (e.hasHeader('contact') && !this._createDialog(e, 'UAC', !0)) break;
                          if (((this._status = C.STATUS_1XX_RECEIVED), this._progress('remote', e), !e.body)) break;
                          var r = { originator: 'remote', type: 'answer', sdp: e.body };
                          y('emit "sdp"'), this.emit('sdp', r);
                          var i = new RTCSessionDescription({ type: 'answer', sdp: r.sdp });
                          this._connectionPromiseQueue = this._connectionPromiseQueue
                            .then(function () {
                              return t._connection.setRemoteDescription(i);
                            })
                            .catch(function (e) {
                              T('emit "peerconnection:setremotedescriptionfailed" [error:%o]', e), t.emit('peerconnection:setremotedescriptionfailed', e);
                            });
                          break;
                        case /^2[0-9]{2}$/.test(e.status_code):
                          if (((this._status = C.STATUS_CONFIRMED), !e.body)) {
                            this._acceptAndTerminate(e, 400, o.causes.MISSING_SDP), this._failed('remote', e, o.causes.BAD_MEDIA_DESCRIPTION);
                            break;
                          }
                          if (!this._createDialog(e, 'UAC')) break;
                          var s = { originator: 'remote', type: 'answer', sdp: e.body };
                          y('emit "sdp"'), this.emit('sdp', s);
                          var a = new RTCSessionDescription({ type: 'answer', sdp: s.sdp });
                          this._connectionPromiseQueue = this._connectionPromiseQueue
                            .then(function () {
                              if ('stable' === t._connection.signalingState)
                                return t._connection
                                  .createOffer()
                                  .then(function (e) {
                                    return t._connection.setLocalDescription(e);
                                  })
                                  .catch(function (n) {
                                    t._acceptAndTerminate(e, 500, n.toString()),
                                      t._failed('local', e, o.causes.WEBRTC_ERROR),
                                      T('emit "peerconnection:setlocaldescriptionfailed" [error:%o]', n),
                                      t.emit('peerconnection:setlocaldescriptionfailed', n);
                                  });
                            })
                            .then(function () {
                              t._connection
                                .setRemoteDescription(a)
                                .then(function () {
                                  t._handleSessionTimersInIncomingResponse(e), t._accepted('remote', e), t.sendRequest(o.ACK), t._confirmed('local', null);
                                })
                                .catch(function (n) {
                                  t._acceptAndTerminate(e, 488, 'Not Acceptable Here'),
                                    t._failed('remote', e, o.causes.BAD_MEDIA_DESCRIPTION),
                                    T('emit "peerconnection:setremotedescriptionfailed" [error:%o]', n),
                                    t.emit('peerconnection:setremotedescriptionfailed', n);
                                });
                            });
                          break;
                        default:
                          var l = c.sipErrorCause(e.status_code);
                          this._failed('remote', e, l);
                      }
                  },
                },
                {
                  key: '_sendReinvite',
                  value: function () {
                    var e = this,
                      t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
                    y('sendReinvite()');
                    var n = c.cloneArray(t.extraHeaders),
                      r = t.eventHandlers || {},
                      i = t.rtcOfferConstraints || this._rtcOfferConstraints || null,
                      s = !1;
                    n.push('Contact: ' + this._contact),
                      n.push('Content-Type: application/sdp'),
                      this._sessionTimers.running &&
                        n.push('Session-Expires: ' + this._sessionTimers.currentExpires + ';refresher=' + (this._sessionTimers.refresher ? 'uac' : 'uas')),
                      this._createLocalDescription(
                        'offer',
                        function (t) {
                          (t = e._mangleOffer(t)),
                            e.sendRequest(o.INVITE, {
                              extraHeaders: n,
                              body: t,
                              eventHandlers: {
                                onSuccessResponse: function (t) {
                                  (function (e) {
                                    var t = this;
                                    if (this._status === C.STATUS_TERMINATED) return;
                                    if ((this.sendRequest(o.ACK), s)) return;
                                    {
                                      if ((this._handleSessionTimersInIncomingResponse(e), !e.body)) return void a.call(this);
                                      if ('application/sdp' !== e.getHeader('Content-Type')) return void a.call(this);
                                    }
                                    var n = { originator: 'remote', type: 'answer', sdp: e.body };
                                    y('emit "sdp"'), this.emit('sdp', n);
                                    var i = new RTCSessionDescription({ type: 'answer', sdp: n.sdp });
                                    this._connectionPromiseQueue = this._connectionPromiseQueue
                                      .then(function () {
                                        return t._connection.setRemoteDescription(i);
                                      })
                                      .then(function () {
                                        r.succeeded && r.succeeded(e);
                                      })
                                      .catch(function (e) {
                                        a.call(t),
                                          T('emit "peerconnection:setremotedescriptionfailed" [error:%o]', e),
                                          t.emit('peerconnection:setremotedescriptionfailed', e);
                                      });
                                  }.call(e, t),
                                    (s = !0));
                                },
                                onErrorResponse: function (t) {
                                  a.call(e, t);
                                },
                                onTransportError: function () {
                                  e.onTransportError();
                                },
                                onRequestTimeout: function () {
                                  e.onRequestTimeout();
                                },
                                onDialogError: function () {
                                  e.onDialogError();
                                },
                              },
                            });
                        },
                        function () {
                          a();
                        },
                        i
                      );
                    function a(e) {
                      r.failed && r.failed(e);
                    }
                  },
                },
                {
                  key: '_sendUpdate',
                  value: function () {
                    var e = this,
                      t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
                    y('sendUpdate()');
                    var n = c.cloneArray(t.extraHeaders),
                      r = t.eventHandlers || {},
                      i = t.rtcOfferConstraints || this._rtcOfferConstraints || null,
                      s = t.sdpOffer || !1,
                      a = !1;
                    n.push('Contact: ' + this._contact),
                      this._sessionTimers.running &&
                        n.push('Session-Expires: ' + this._sessionTimers.currentExpires + ';refresher=' + (this._sessionTimers.refresher ? 'uac' : 'uas')),
                      s
                        ? (n.push('Content-Type: application/sdp'),
                          this._createLocalDescription(
                            'offer',
                            function (t) {
                              (t = e._mangleOffer(t)),
                                e.sendRequest(o.UPDATE, {
                                  extraHeaders: n,
                                  body: t,
                                  eventHandlers: {
                                    onSuccessResponse: function (t) {
                                      l.call(e, t), (a = !0);
                                    },
                                    onErrorResponse: function (t) {
                                      u.call(e, t);
                                    },
                                    onTransportError: function () {
                                      e.onTransportError();
                                    },
                                    onRequestTimeout: function () {
                                      e.onRequestTimeout();
                                    },
                                    onDialogError: function () {
                                      e.onDialogError();
                                    },
                                  },
                                });
                            },
                            function () {
                              u.call(e);
                            },
                            i
                          ))
                        : this.sendRequest(o.UPDATE, {
                            extraHeaders: n,
                            eventHandlers: {
                              onSuccessResponse: function (t) {
                                l.call(e, t);
                              },
                              onErrorResponse: function (t) {
                                u.call(e, t);
                              },
                              onTransportError: function () {
                                e.onTransportError();
                              },
                              onRequestTimeout: function () {
                                e.onRequestTimeout();
                              },
                              onDialogError: function () {
                                e.onDialogError();
                              },
                            },
                          });
                    function l(e) {
                      var t = this;
                      if (this._status !== C.STATUS_TERMINATED && !a)
                        if ((this._handleSessionTimersInIncomingResponse(e), s)) {
                          if (!e.body) return void u.call(this);
                          if ('application/sdp' !== e.getHeader('Content-Type')) return void u.call(this);
                          var n = { originator: 'remote', type: 'answer', sdp: e.body };
                          y('emit "sdp"'), this.emit('sdp', n);
                          var i = new RTCSessionDescription({ type: 'answer', sdp: n.sdp });
                          this._connectionPromiseQueue = this._connectionPromiseQueue
                            .then(function () {
                              return t._connection.setRemoteDescription(i);
                            })
                            .then(function () {
                              r.succeeded && r.succeeded(e);
                            })
                            .catch(function (e) {
                              u.call(t), T('emit "peerconnection:setremotedescriptionfailed" [error:%o]', e), t.emit('peerconnection:setremotedescriptionfailed', e);
                            });
                        } else r.succeeded && r.succeeded(e);
                    }
                    function u(e) {
                      r.failed && r.failed(e);
                    }
                  },
                },
                {
                  key: '_acceptAndTerminate',
                  value: function (e, t, n) {
                    y('acceptAndTerminate()');
                    var r = [];
                    t && ((n = n || o.REASON_PHRASE[t] || ''), r.push('Reason: SIP ;cause=' + t + '; text="' + n + '"')),
                      (this._dialog || this._createDialog(e, 'UAC')) && (this.sendRequest(o.ACK), this.sendRequest(o.BYE, { extraHeaders: r })),
                      (this._status = C.STATUS_TERMINATED);
                  },
                },
                {
                  key: '_mangleOffer',
                  value: function (e) {
                    if (!this._localHold && !this._remoteHold) return e;
                    if (((e = a.parse(e)), this._localHold && !this._remoteHold)) {
                      y('mangleOffer() | me on hold, mangling offer');
                      var t = !0,
                        n = !1,
                        r = void 0;
                      try {
                        for (var i, s = e.media[Symbol.iterator](); !(t = (i = s.next()).done); t = !0) {
                          var o = i.value;
                          -1 !== S.indexOf(o.type) &&
                            (o.direction
                              ? 'sendrecv' === o.direction
                                ? (o.direction = 'sendonly')
                                : 'recvonly' === o.direction && (o.direction = 'inactive')
                              : (o.direction = 'sendonly'));
                        }
                      } catch (e) {
                        (n = !0), (r = e);
                      } finally {
                        try {
                          !t && s.return && s.return();
                        } finally {
                          if (n) throw r;
                        }
                      }
                    } else if (this._localHold && this._remoteHold) {
                      y('mangleOffer() | both on hold, mangling offer');
                      var l = !0,
                        u = !1,
                        c = void 0;
                      try {
                        for (var d, h = e.media[Symbol.iterator](); !(l = (d = h.next()).done); l = !0) {
                          var f = d.value;
                          -1 !== S.indexOf(f.type) && (f.direction = 'inactive');
                        }
                      } catch (e) {
                        (u = !0), (c = e);
                      } finally {
                        try {
                          !l && h.return && h.return();
                        } finally {
                          if (u) throw c;
                        }
                      }
                    } else if (this._remoteHold) {
                      y('mangleOffer() | remote on hold, mangling offer');
                      var p = !0,
                        _ = !1,
                        v = void 0;
                      try {
                        for (var m, g = e.media[Symbol.iterator](); !(p = (m = g.next()).done); p = !0) {
                          var T = m.value;
                          -1 !== S.indexOf(T.type) &&
                            (T.direction
                              ? 'sendrecv' === T.direction
                                ? (T.direction = 'recvonly')
                                : 'recvonly' === T.direction && (T.direction = 'inactive')
                              : (T.direction = 'recvonly'));
                        }
                      } catch (e) {
                        (_ = !0), (v = e);
                      } finally {
                        try {
                          !p && g.return && g.return();
                        } finally {
                          if (_) throw v;
                        }
                      }
                    }
                    return a.write(e);
                  },
                },
                {
                  key: '_setLocalMediaStatus',
                  value: function () {
                    var e = !0,
                      t = !0;
                    (this._localHold || this._remoteHold) && ((e = !1), (t = !1)),
                      this._audioMuted && (e = !1),
                      this._videoMuted && (t = !1),
                      this._toogleMuteAudio(!e),
                      this._toogleMuteVideo(!t);
                  },
                },
                {
                  key: '_handleSessionTimersInIncomingRequest',
                  value: function (e, t) {
                    if (this._sessionTimers.enabled) {
                      var n = void 0;
                      e.session_expires && e.session_expires >= o.MIN_SESSION_EXPIRES
                        ? ((this._sessionTimers.currentExpires = e.session_expires), (n = e.session_expires_refresher || 'uas'))
                        : ((this._sessionTimers.currentExpires = this._sessionTimers.defaultExpires), (n = 'uas')),
                        t.push('Session-Expires: ' + this._sessionTimers.currentExpires + ';refresher=' + n),
                        (this._sessionTimers.refresher = 'uas' === n),
                        this._runSessionTimer();
                    }
                  },
                },
                {
                  key: '_handleSessionTimersInIncomingResponse',
                  value: function (e) {
                    if (this._sessionTimers.enabled) {
                      var t = void 0;
                      e.session_expires && e.session_expires >= o.MIN_SESSION_EXPIRES
                        ? ((this._sessionTimers.currentExpires = e.session_expires), (t = e.session_expires_refresher || 'uac'))
                        : ((this._sessionTimers.currentExpires = this._sessionTimers.defaultExpires), (t = 'uac')),
                        (this._sessionTimers.refresher = 'uac' === t),
                        this._runSessionTimer();
                    }
                  },
                },
                {
                  key: '_runSessionTimer',
                  value: function () {
                    var e = this,
                      t = this._sessionTimers.currentExpires;
                    (this._sessionTimers.running = !0),
                      clearTimeout(this._sessionTimers.timer),
                      this._sessionTimers.refresher
                        ? (this._sessionTimers.timer = setTimeout(function () {
                            e._status !== C.STATUS_TERMINATED &&
                              (y('runSessionTimer() | sending session refresh request'),
                              e._sessionTimers.refreshMethod === o.UPDATE ? e._sendUpdate() : e._sendReinvite());
                          }, 500 * t))
                        : (this._sessionTimers.timer = setTimeout(function () {
                            e._status !== C.STATUS_TERMINATED &&
                              (T('runSessionTimer() | timer expired, terminating the session'),
                              e.terminate({ cause: o.causes.REQUEST_TIMEOUT, status_code: 408, reason_phrase: 'Session Timer Expired' }));
                          }, 1100 * t));
                  },
                },
                {
                  key: '_toogleMuteAudio',
                  value: function (e) {
                    var t = this._connection.getLocalStreams(),
                      n = !0,
                      r = !1,
                      i = void 0;
                    try {
                      for (var s, a = t[Symbol.iterator](); !(n = (s = a.next()).done); n = !0) {
                        var o = s.value.getAudioTracks(),
                          l = !0,
                          u = !1,
                          c = void 0;
                        try {
                          for (var d, h = o[Symbol.iterator](); !(l = (d = h.next()).done); l = !0) {
                            d.value.enabled = !e;
                          }
                        } catch (e) {
                          (u = !0), (c = e);
                        } finally {
                          try {
                            !l && h.return && h.return();
                          } finally {
                            if (u) throw c;
                          }
                        }
                      }
                    } catch (e) {
                      (r = !0), (i = e);
                    } finally {
                      try {
                        !n && a.return && a.return();
                      } finally {
                        if (r) throw i;
                      }
                    }
                  },
                },
                {
                  key: '_toogleMuteVideo',
                  value: function (e) {
                    var t = this._connection.getLocalStreams(),
                      n = !0,
                      r = !1,
                      i = void 0;
                    try {
                      for (var s, a = t[Symbol.iterator](); !(n = (s = a.next()).done); n = !0) {
                        var o = s.value.getVideoTracks(),
                          l = !0,
                          u = !1,
                          c = void 0;
                        try {
                          for (var d, h = o[Symbol.iterator](); !(l = (d = h.next()).done); l = !0) {
                            d.value.enabled = !e;
                          }
                        } catch (e) {
                          (u = !0), (c = e);
                        } finally {
                          try {
                            !l && h.return && h.return();
                          } finally {
                            if (u) throw c;
                          }
                        }
                      }
                    } catch (e) {
                      (r = !0), (i = e);
                    } finally {
                      try {
                        !n && a.return && a.return();
                      } finally {
                        if (r) throw i;
                      }
                    }
                  },
                },
                {
                  key: '_newRTCSession',
                  value: function (e, t) {
                    y('newRTCSession()'), this._ua.newRTCSession(this, { originator: e, session: this, request: t });
                  },
                },
                {
                  key: '_connecting',
                  value: function (e) {
                    y('session connecting'), y('emit "connecting"'), this.emit('connecting', { request: e });
                  },
                },
                {
                  key: '_progress',
                  value: function (e, t) {
                    y('session progress'), y('emit "progress"'), this.emit('progress', { originator: e, response: t || null });
                  },
                },
                {
                  key: '_accepted',
                  value: function (e, t) {
                    y('session accepted'), (this._start_time = new Date()), y('emit "accepted"'), this.emit('accepted', { originator: e, response: t || null });
                  },
                },
                {
                  key: '_confirmed',
                  value: function (e, t) {
                    y('session confirmed'), (this._is_confirmed = !0), y('emit "confirmed"'), this.emit('confirmed', { originator: e, ack: t || null });
                  },
                },
                {
                  key: '_ended',
                  value: function (e, t, n) {
                    y('session ended'),
                      (this._end_time = new Date()),
                      this._close(),
                      y('emit "ended"'),
                      this.emit('ended', { originator: e, message: t || null, cause: n });
                  },
                },
                {
                  key: '_failed',
                  value: function (e, t, n) {
                    y('session failed'), this._close(), y('emit "failed"'), this.emit('failed', { originator: e, message: t || null, cause: n });
                  },
                },
                {
                  key: '_onhold',
                  value: function (e) {
                    y('session onhold'), this._setLocalMediaStatus(), y('emit "hold"'), this.emit('hold', { originator: e });
                  },
                },
                {
                  key: '_onunhold',
                  value: function (e) {
                    y('session onunhold'), this._setLocalMediaStatus(), y('emit "unhold"'), this.emit('unhold', { originator: e });
                  },
                },
                {
                  key: '_onmute',
                  value: function (e) {
                    var t = e.audio,
                      n = e.video;
                    y('session onmute'), this._setLocalMediaStatus(), y('emit "muted"'), this.emit('muted', { audio: t, video: n });
                  },
                },
                {
                  key: '_onunmute',
                  value: function (e) {
                    var t = e.audio,
                      n = e.video;
                    y('session onunmute'), this._setLocalMediaStatus(), y('emit "unmuted"'), this.emit('unmuted', { audio: t, video: n });
                  },
                },
                {
                  key: 'C',
                  get: function () {
                    return C;
                  },
                },
                {
                  key: 'causes',
                  get: function () {
                    return o.causes;
                  },
                },
                {
                  key: 'id',
                  get: function () {
                    return this._id;
                  },
                },
                {
                  key: 'connection',
                  get: function () {
                    return this._connection;
                  },
                },
                {
                  key: 'direction',
                  get: function () {
                    return this._direction;
                  },
                },
                {
                  key: 'local_identity',
                  get: function () {
                    return this._local_identity;
                  },
                },
                {
                  key: 'remote_identity',
                  get: function () {
                    return this._remote_identity;
                  },
                },
                {
                  key: 'start_time',
                  get: function () {
                    return this._start_time;
                  },
                },
                {
                  key: 'end_time',
                  get: function () {
                    return this._end_time;
                  },
                },
                {
                  key: 'data',
                  get: function () {
                    return this._data;
                  },
                  set: function (e) {
                    this._data = e;
                  },
                },
                {
                  key: 'status',
                  get: function () {
                    return this._status;
                  },
                },
              ]),
              t
            );
          })();
        },
        {
          './Constants': 2,
          './Dialog': 3,
          './Exceptions': 6,
          './RTCSession/DTMF': 13,
          './RTCSession/Info': 14,
          './RTCSession/ReferNotifier': 15,
          './RTCSession/ReferSubscriber': 16,
          './RequestSender': 18,
          './SIPMessage': 19,
          './Timers': 21,
          './Transactions': 22,
          './Utils': 26,
          debug: 29,
          events: 31,
          'sdp-transform': 36,
        },
      ],
      13: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('events').EventEmitter,
            s = e('../Constants'),
            a = e('../Exceptions'),
            o = e('../Utils'),
            l = e('debug')('JsSIP:RTCSession:DTMF');
          e('debug')('JsSIP:ERROR:RTCSession:DTMF').log = console.warn.bind(console);
          var u = { MIN_DURATION: 70, MAX_DURATION: 6e3, DEFAULT_DURATION: 100, MIN_INTER_TONE_GAP: 50, DEFAULT_INTER_TONE_GAP: 500 };
          (t.exports = (function (e) {
            !(function (e, t) {
              if ('function' != typeof t && null !== t) throw new TypeError('Super expression must either be null or a function, not ' + typeof t);
              (e.prototype = Object.create(t && t.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })),
                t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : (e.__proto__ = t));
            })(t, i);
            function t(e) {
              !(function (e, t) {
                if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
              })(this, t);
              var n = (function (e, t) {
                if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
                return !t || ('object' != typeof t && 'function' != typeof t) ? e : t;
              })(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
              return (n._session = e), (n._direction = null), (n._tone = null), (n._duration = null), (n._request = null), n;
            }
            return (
              r(t, [
                {
                  key: 'send',
                  value: function (e) {
                    var t = this,
                      n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
                    if (void 0 === e) throw new TypeError('Not enough arguments');
                    if (
                      ((this._direction = 'outgoing'),
                      this._session.status !== this._session.C.STATUS_CONFIRMED && this._session.status !== this._session.C.STATUS_WAITING_FOR_ACK)
                    )
                      throw new a.InvalidStateError(this._session.status);
                    var r = o.cloneArray(n.extraHeaders);
                    if (((this.eventHandlers = n.eventHandlers || {}), 'string' == typeof e)) e = e.toUpperCase();
                    else {
                      if ('number' != typeof e) throw new TypeError('Invalid tone: ' + e);
                      e = e.toString();
                    }
                    if (!e.match(/^[0-9A-DR#*]$/)) throw new TypeError('Invalid tone: ' + e);
                    (this._tone = e), (this._duration = n.duration), r.push('Content-Type: application/dtmf-relay');
                    var i = 'Signal=' + this._tone + '\r\n';
                    (i += 'Duration=' + this._duration),
                      this._session.newDTMF({ originator: 'local', dtmf: this, request: this._request }),
                      this._session.sendRequest(s.INFO, {
                        extraHeaders: r,
                        eventHandlers: {
                          onSuccessResponse: function (e) {
                            t.emit('succeeded', { originator: 'remote', response: e });
                          },
                          onErrorResponse: function (e) {
                            t.eventHandlers.onFailed && t.eventHandlers.onFailed(), t.emit('failed', { originator: 'remote', response: e });
                          },
                          onRequestTimeout: function () {
                            t._session.onRequestTimeout();
                          },
                          onTransportError: function () {
                            t._session.onTransportError();
                          },
                          onDialogError: function () {
                            t._session.onDialogError();
                          },
                        },
                        body: i,
                      });
                  },
                },
                {
                  key: 'init_incoming',
                  value: function (e) {
                    var t = /^(Signal\s*?=\s*?)([0-9A-D#*]{1})(\s)?.*/,
                      n = /^(Duration\s?=\s?)([0-9]{1,4})(\s)?.*/;
                    if (((this._direction = 'incoming'), (this._request = e), e.reply(200), e.body)) {
                      var r = e.body.split('\n');
                      r.length >= 1 && t.test(r[0]) && (this._tone = r[0].replace(t, '$2')),
                        r.length >= 2 && n.test(r[1]) && (this._duration = parseInt(r[1].replace(n, '$2'), 10));
                    }
                    this._duration || (this._duration = u.DEFAULT_DURATION),
                      this._tone ? this._session.newDTMF({ originator: 'remote', dtmf: this, request: e }) : l('invalid INFO DTMF received, discarded');
                  },
                },
                {
                  key: 'tone',
                  get: function () {
                    return this._tone;
                  },
                },
                {
                  key: 'duration',
                  get: function () {
                    return this._duration;
                  },
                },
              ]),
              t
            );
          })()),
            (t.exports.C = u);
        },
        { '../Constants': 2, '../Exceptions': 6, '../Utils': 26, debug: 29, events: 31 },
      ],
      14: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('events').EventEmitter;
          e('debug')('JsSIP:ERROR:RTCSession:Info').log = console.warn.bind(console);
          var s = e('../Constants'),
            a = e('../Exceptions'),
            o = e('../Utils');
          t.exports = (function (e) {
            !(function (e, t) {
              if ('function' != typeof t && null !== t) throw new TypeError('Super expression must either be null or a function, not ' + typeof t);
              (e.prototype = Object.create(t && t.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })),
                t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : (e.__proto__ = t));
            })(t, i);
            function t(e) {
              !(function (e, t) {
                if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
              })(this, t);
              var n = (function (e, t) {
                if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
                return !t || ('object' != typeof t && 'function' != typeof t) ? e : t;
              })(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
              return (n._session = e), (n._direction = null), (n._contentType = null), (n._body = null), n;
            }
            return (
              r(t, [
                {
                  key: 'send',
                  value: function (e, t) {
                    var n = this,
                      r = arguments.length > 2 && void 0 !== arguments[2] ? arguments[2] : {};
                    if (((this._direction = 'outgoing'), void 0 === e)) throw new TypeError('Not enough arguments');
                    if (this._session.status !== this._session.C.STATUS_CONFIRMED && this._session.status !== this._session.C.STATUS_WAITING_FOR_ACK)
                      throw new a.InvalidStateError(this._session.status);
                    (this._contentType = e), (this._body = t);
                    var i = o.cloneArray(r.extraHeaders);
                    i.push('Content-Type: ' + e),
                      this._session.newInfo({ originator: 'local', info: this, request: this.request }),
                      this._session.sendRequest(s.INFO, {
                        extraHeaders: i,
                        eventHandlers: {
                          onSuccessResponse: function (e) {
                            n.emit('succeeded', { originator: 'remote', response: e });
                          },
                          onErrorResponse: function (e) {
                            n.emit('failed', { originator: 'remote', response: e });
                          },
                          onTransportError: function () {
                            n._session.onTransportError();
                          },
                          onRequestTimeout: function () {
                            n._session.onRequestTimeout();
                          },
                          onDialogError: function () {
                            n._session.onDialogError();
                          },
                        },
                        body: t,
                      });
                  },
                },
                {
                  key: 'init_incoming',
                  value: function (e) {
                    (this._direction = 'incoming'),
                      (this.request = e),
                      e.reply(200),
                      (this._contentType = e.getHeader('content-type')),
                      (this._body = e.body),
                      this._session.newInfo({ originator: 'remote', info: this, request: e });
                  },
                },
                {
                  key: 'contentType',
                  get: function () {
                    return this._contentType;
                  },
                },
                {
                  key: 'body',
                  get: function () {
                    return this._body;
                  },
                },
              ]),
              t
            );
          })();
        },
        { '../Constants': 2, '../Exceptions': 6, '../Utils': 26, debug: 29, events: 31 },
      ],
      15: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('../Constants'),
            s = e('debug')('JsSIP:RTCSession:ReferNotifier'),
            a = { event_type: 'refer', body_type: 'message/sipfrag;version=2.0', expires: 300 };
          t.exports = (function () {
            function e(t, n, r) {
              !(function (e, t) {
                if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
              })(this, e),
                (this._session = t),
                (this._id = n),
                (this._expires = r || a.expires),
                (this._active = !0),
                this.notify(100);
            }
            return (
              r(e, [
                {
                  key: 'notify',
                  value: function (e, t) {
                    if ((s('notify()'), !1 !== this._active)) {
                      t = t || i.REASON_PHRASE[e] || '';
                      var n = void 0;
                      (n = e >= 200 ? 'terminated;reason=noresource' : 'active;expires=' + this._expires),
                        this._session.sendRequest(i.NOTIFY, {
                          extraHeaders: ['Event: ' + a.event_type + ';id=' + this._id, 'Subscription-State: ' + n, 'Content-Type: ' + a.body_type],
                          body: 'SIP/2.0 ' + e + ' ' + t,
                          eventHandlers: {
                            onErrorResponse: function () {
                              this._active = !1;
                            },
                          },
                        });
                    }
                  },
                },
              ]),
              e
            );
          })();
        },
        { '../Constants': 2, debug: 29 },
      ],
      16: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('events').EventEmitter,
            s = e('../Constants'),
            a = e('../Grammar'),
            o = e('../Utils'),
            l = e('debug')('JsSIP:RTCSession:ReferSubscriber');
          t.exports = (function (e) {
            !(function (e, t) {
              if ('function' != typeof t && null !== t) throw new TypeError('Super expression must either be null or a function, not ' + typeof t);
              (e.prototype = Object.create(t && t.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })),
                t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : (e.__proto__ = t));
            })(t, i);
            function t(e) {
              !(function (e, t) {
                if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
              })(this, t);
              var n = (function (e, t) {
                if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
                return !t || ('object' != typeof t && 'function' != typeof t) ? e : t;
              })(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
              return (n._id = null), (n._session = e), n;
            }
            return (
              r(t, [
                {
                  key: 'sendRefer',
                  value: function (e) {
                    var t = this,
                      n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : {};
                    l('sendRefer()');
                    var r = o.cloneArray(n.extraHeaders),
                      i = n.eventHandlers || {};
                    for (var a in i) Object.prototype.hasOwnProperty.call(i, a) && this.on(a, i[a]);
                    var u = null;
                    n.replaces &&
                      ((u = n.replaces._request.call_id),
                      (u += ';to-tag=' + n.replaces._to_tag),
                      (u += ';from-tag=' + n.replaces._from_tag),
                      (u = encodeURIComponent(u)));
                    var c = 'Refer-To: <' + e + (u ? '?Replaces=' + u : '') + '>';
                    r.push(c);
                    var d = this._session.sendRequest(s.REFER, {
                      extraHeaders: r,
                      eventHandlers: {
                        onSuccessResponse: function (e) {
                          t._requestSucceeded(e);
                        },
                        onErrorResponse: function (e) {
                          t._requestFailed(e, s.causes.REJECTED);
                        },
                        onTransportError: function () {
                          t._requestFailed(null, s.causes.CONNECTION_ERROR);
                        },
                        onRequestTimeout: function () {
                          t._requestFailed(null, s.causes.REQUEST_TIMEOUT);
                        },
                        onDialogError: function () {
                          t._requestFailed(null, s.causes.DIALOG_ERROR);
                        },
                      },
                    });
                    this._id = d.cseq;
                  },
                },
                {
                  key: 'receiveNotify',
                  value: function (e) {
                    if ((l('receiveNotify()'), e.body)) {
                      var t = a.parse(e.body.trim(), 'Status_Line');
                      if (-1 !== t)
                        switch (!0) {
                          case /^100$/.test(t.status_code):
                            this.emit('trying', { request: e, status_line: t });
                            break;
                          case /^1[0-9]{2}$/.test(t.status_code):
                            this.emit('progress', { request: e, status_line: t });
                            break;
                          case /^2[0-9]{2}$/.test(t.status_code):
                            this.emit('accepted', { request: e, status_line: t });
                            break;
                          default:
                            this.emit('failed', { request: e, status_line: t });
                        }
                      else l('receiveNotify() | error parsing NOTIFY body: "' + e.body + '"');
                    }
                  },
                },
                {
                  key: '_requestSucceeded',
                  value: function (e) {
                    l('REFER succeeded'), l('emit "requestSucceeded"'), this.emit('requestSucceeded', { response: e });
                  },
                },
                {
                  key: '_requestFailed',
                  value: function (e, t) {
                    l('REFER failed'), l('emit "requestFailed"'), this.emit('requestFailed', { response: e || null, cause: t });
                  },
                },
                {
                  key: 'id',
                  get: function () {
                    return this._id;
                  },
                },
              ]),
              t
            );
          })();
        },
        { '../Constants': 2, '../Grammar': 7, '../Utils': 26, debug: 29, events: 31 },
      ],
      17: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('./Utils'),
            s = e('./Constants'),
            a = e('./SIPMessage'),
            o = e('./RequestSender'),
            l = e('debug')('JsSIP:Registrator');
          t.exports = (function () {
            function e(t, n) {
              !(function (e, t) {
                if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
              })(this, e);
              (this._ua = t),
                (this._transport = n),
                (this._registrar = t.configuration.registrar_server),
                (this._expires = t.configuration.register_expires),
                (this._call_id = i.createRandomToken(22)),
                (this._cseq = 0),
                (this._to_uri = t.configuration.uri),
                (this._registrationTimer = null),
                (this._registering = !1),
                (this._registered = !1),
                (this._contact = this._ua.contact.toString()),
                (this._contact += ';+sip.ice'),
                (this._extraHeaders = []),
                (this._extraContactParams = ''),
                (this._contact += ';reg-id=1'),
                (this._contact += ';+sip.instance="<urn:uuid:' + this._ua.configuration.instance_id + '>"');
            }
            return (
              r(e, [
                {
                  key: 'setExtraHeaders',
                  value: function (e) {
                    Array.isArray(e) || (e = []), (this._extraHeaders = e.slice());
                  },
                },
                {
                  key: 'setExtraContactParams',
                  value: function (e) {
                    e instanceof Object || (e = {}), (this._extraContactParams = '');
                    for (var t in e)
                      if (Object.prototype.hasOwnProperty.call(e, t)) {
                        var n = e[t];
                        (this._extraContactParams += ';' + t), n && (this._extraContactParams += '=' + n);
                      }
                  },
                },
                {
                  key: 'register',
                  value: function () {
                    var e = this;
                    if (this._registering) l('Register request in progress...');
                    else {
                      var t = this._extraHeaders.slice();
                      t.push('Contact: ' + this._contact + ';expires=' + this._expires + this._extraContactParams), t.push('Expires: ' + this._expires);
                      var n = new a.OutgoingRequest(s.REGISTER, this._registrar, this._ua, { to_uri: this._to_uri, call_id: this._call_id, cseq: (this._cseq += 1) }, t),
                        r = new o(this._ua, n, {
                          onRequestTimeout: function () {
                            e._registrationFailure(null, s.causes.REQUEST_TIMEOUT);
                          },
                          onTransportError: function () {
                            e._registrationFailure(null, s.causes.CONNECTION_ERROR);
                          },
                          onAuthenticated: function () {
                            e._cseq += 1;
                          },
                          onReceiveResponse: function (t) {
                            var n = void 0,
                              r = void 0,
                              a = t.getHeaders('contact').length;
                            if (t.cseq === e._cseq)
                              switch ((null !== e._registrationTimer && (clearTimeout(e._registrationTimer), (e._registrationTimer = null)), !0)) {
                                case /^1[0-9]{2}$/.test(t.status_code):
                                  break;
                                case /^2[0-9]{2}$/.test(t.status_code):
                                  if (((e._registering = !1), !a)) {
                                    l('no Contact header in response to REGISTER, response ignored');
                                    break;
                                  }
                                  for (; a--; ) {
                                    if ((n = t.parseHeader('contact', a)).uri.user === e._ua.contact.uri.user) {
                                      r = n.getParam('expires');
                                      break;
                                    }
                                    n = null;
                                  }
                                  if (!n) {
                                    l('no Contact header pointing to us, response ignored');
                                    break;
                                  }
                                  !r && t.hasHeader('expires') && (r = t.getHeader('expires')),
                                    r || (r = e._expires),
                                    (r = Number(r)) < 10 && (r = 10),
                                    (e._registrationTimer = setTimeout(function () {
                                      (e._registrationTimer = null),
                                        0 === e._ua.listeners('registrationExpiring').length ? e.register() : e._ua.emit('registrationExpiring');
                                    }, 1e3 * r - 5e3)),
                                    n.hasParam('temp-gruu') && (e._ua.contact.temp_gruu = n.getParam('temp-gruu').replace(/"/g, '')),
                                    n.hasParam('pub-gruu') && (e._ua.contact.pub_gruu = n.getParam('pub-gruu').replace(/"/g, '')),
                                    e._registered || ((e._registered = !0), e._ua.registered({ response: t }));
                                  break;
                                case /^423$/.test(t.status_code):
                                  t.hasHeader('min-expires')
                                    ? ((e._expires = Number(t.getHeader('min-expires'))), e._expires < 10 && (e._expires = 10), e.register())
                                    : (l('423 response received for REGISTER without Min-Expires'), e._registrationFailure(t, s.causes.SIP_FAILURE_CODE));
                                  break;
                                default:
                                  var o = i.sipErrorCause(t.status_code);
                                  e._registrationFailure(t, o);
                              }
                          },
                        });
                      (this._registering = !0), r.send();
                    }
                  },
                },
                {
                  key: 'unregister',
                  value: function () {
                    var e = this,
                      t = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {};
                    if (this._registered) {
                      (this._registered = !1), null !== this._registrationTimer && (clearTimeout(this._registrationTimer), (this._registrationTimer = null));
                      var n = this._extraHeaders.slice();
                      t.all ? n.push('Contact: *' + this._extraContactParams) : n.push('Contact: ' + this._contact + ';expires=0' + this._extraContactParams),
                        n.push('Expires: 0');
                      var r = new a.OutgoingRequest(s.REGISTER, this._registrar, this._ua, { to_uri: this._to_uri, call_id: this._call_id, cseq: (this._cseq += 1) }, n);
                      new o(this._ua, r, {
                        onRequestTimeout: function () {
                          e._unregistered(null, s.causes.REQUEST_TIMEOUT);
                        },
                        onTransportError: function () {
                          e._unregistered(null, s.causes.CONNECTION_ERROR);
                        },
                        onAuthenticated: function () {
                          e._cseq += 1;
                        },
                        onReceiveResponse: function (t) {
                          switch (!0) {
                            case /^1[0-9]{2}$/.test(t.status_code):
                              break;
                            case /^2[0-9]{2}$/.test(t.status_code):
                              e._unregistered(t);
                              break;
                            default:
                              var n = i.sipErrorCause(t.status_code);
                              e._unregistered(t, n);
                          }
                        },
                      }).send();
                    } else l('already unregistered');
                  },
                },
                {
                  key: 'close',
                  value: function () {
                    this._registered && this.unregister();
                  },
                },
                {
                  key: 'onTransportClosed',
                  value: function () {
                    (this._registering = !1),
                      null !== this._registrationTimer && (clearTimeout(this._registrationTimer), (this._registrationTimer = null)),
                      this._registered && ((this._registered = !1), this._ua.unregistered({}));
                  },
                },
                {
                  key: '_registrationFailure',
                  value: function (e, t) {
                    (this._registering = !1),
                      this._ua.registrationFailed({ response: e || null, cause: t }),
                      this._registered && ((this._registered = !1), this._ua.unregistered({ response: e || null, cause: t }));
                  },
                },
                {
                  key: '_unregistered',
                  value: function (e, t) {
                    (this._registering = !1), (this._registered = !1), this._ua.unregistered({ response: e || null, cause: t || null });
                  },
                },
                {
                  key: 'registered',
                  get: function () {
                    return this._registered;
                  },
                },
              ]),
              e
            );
          })();
        },
        { './Constants': 2, './RequestSender': 18, './SIPMessage': 19, './Utils': 26, debug: 29 },
      ],
      18: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('./Constants'),
            s = e('./DigestAuthentication'),
            a = e('./Transactions'),
            o = e('debug')('JsSIP:RequestSender'),
            l = { onRequestTimeout: function () {}, onTransportError: function () {}, onReceiveResponse: function () {}, onAuthenticated: function () {} };
          t.exports = (function () {
            function e(t, n, r) {
              !(function (e, t) {
                if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
              })(this, e),
                (this._ua = t),
                (this._eventHandlers = r),
                (this._method = n.method),
                (this._request = n),
                (this._auth = null),
                (this._challenged = !1),
                (this._staled = !1);
              for (var s in l) Object.prototype.hasOwnProperty.call(l, s) && (this._eventHandlers[s] || (this._eventHandlers[s] = l[s]));
              t.status !== t.C.STATUS_USER_CLOSED || (this._method === i.BYE && this._method === i.ACK) || this._eventHandlers.onTransportError();
            }
            return (
              r(e, [
                {
                  key: 'send',
                  value: function () {
                    var e = this,
                      t = {
                        onRequestTimeout: function () {
                          e._eventHandlers.onRequestTimeout();
                        },
                        onTransportError: function () {
                          e._eventHandlers.onTransportError();
                        },
                        onReceiveResponse: function (t) {
                          e._receiveResponse(t);
                        },
                      };
                    switch (this._method) {
                      case 'INVITE':
                        this.clientTransaction = new a.InviteClientTransaction(this._ua, this._ua.transport, this._request, t);
                        break;
                      case 'ACK':
                        this.clientTransaction = new a.AckClientTransaction(this._ua, this._ua.transport, this._request, t);
                        break;
                      default:
                        this.clientTransaction = new a.NonInviteClientTransaction(this._ua, this._ua.transport, this._request, t);
                    }
                    this.clientTransaction.send();
                  },
                },
                {
                  key: '_receiveResponse',
                  value: function (e) {
                    var t = void 0,
                      n = void 0,
                      r = e.status_code;
                    if ((401 !== r && 407 !== r) || (null === this._ua.configuration.password && null === this._ua.configuration.ha1))
                      this._eventHandlers.onReceiveResponse(e);
                    else {
                      if (
                        (401 === e.status_code
                          ? ((t = e.parseHeader('www-authenticate')), (n = 'authorization'))
                          : ((t = e.parseHeader('proxy-authenticate')), (n = 'proxy-authorization')),
                        !t)
                      )
                        return o(e.status_code + ' with wrong or missing challenge, cannot authenticate'), void this._eventHandlers.onReceiveResponse(e);
                      if (!this._challenged || (!this._staled && !0 === t.stale)) {
                        if (
                          (this._auth ||
                            (this._auth = new s({
                              username: this._ua.configuration.authorization_user,
                              password: this._ua.configuration.password,
                              realm: this._ua.configuration.realm,
                              ha1: this._ua.configuration.ha1,
                            })),
                          !this._auth.authenticate(this._request, t))
                        )
                          return void this._eventHandlers.onReceiveResponse(e);
                        (this._challenged = !0),
                          this._ua.set('realm', this._auth.get('realm')),
                          this._ua.set('ha1', this._auth.get('ha1')),
                          t.stale && (this._staled = !0),
                          (this._request = this._request.clone()),
                          (this._request.cseq += 1),
                          this._request.setHeader('cseq', this._request.cseq + ' ' + this._method),
                          this._request.setHeader(n, this._auth.toString()),
                          this._eventHandlers.onAuthenticated(this._request),
                          this.send();
                      } else this._eventHandlers.onReceiveResponse(e);
                    }
                  },
                },
              ]),
              e
            );
          })();
        },
        { './Constants': 2, './DigestAuthentication': 5, './Transactions': 22, debug: 29 },
      ],
      19: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          function i(e, t) {
            if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
            return !t || ('object' != typeof t && 'function' != typeof t) ? e : t;
          }
          function s(e, t) {
            if ('function' != typeof t && null !== t) throw new TypeError('Super expression must either be null or a function, not ' + typeof t);
            (e.prototype = Object.create(t && t.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })),
              t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : (e.__proto__ = t));
          }
          function a(e, t) {
            if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
          }
          var o = e('sdp-transform'),
            l = e('./Constants'),
            u = e('./Utils'),
            c = e('./NameAddrHeader'),
            d = e('./Grammar'),
            h = e('debug')('JsSIP:SIPMessage'),
            f = (function () {
              function e(t, n, r, i, s, o) {
                if ((a(this, e), !t || !n || !r)) return null;
                (i = i || {}),
                  (this.ua = r),
                  (this.headers = {}),
                  (this.method = t),
                  (this.ruri = n),
                  (this.body = o),
                  (this.extraHeaders = u.cloneArray(s)),
                  i.route_set ? this.setHeader('route', i.route_set) : r.configuration.use_preloaded_route && this.setHeader('route', '<' + r.transport.sip_uri + ';lr>'),
                  this.setHeader('via', ''),
                  this.setHeader('max-forwards', l.MAX_FORWARDS);
                var d = i.to_display_name || 0 === i.to_display_name ? '"' + i.to_display_name + '" ' : '';
                (d += '<' + (i.to_uri || n) + '>'), (d += i.to_tag ? ';tag=' + i.to_tag : ''), (this.to = c.parse(d)), this.setHeader('to', d);
                var h = void 0;
                (h =
                  i.from_display_name || 0 === i.from_display_name
                    ? '"' + i.from_display_name + '" '
                    : r.configuration.display_name
                    ? '"' + r.configuration.display_name + '" '
                    : ''),
                  (h += '<' + (i.from_uri || r.configuration.uri) + '>;tag='),
                  (h += i.from_tag || u.newTag()),
                  (this.from = c.parse(h)),
                  this.setHeader('from', h);
                var f = i.call_id || r.configuration.jssip_id + u.createRandomToken(15);
                (this.call_id = f), this.setHeader('call-id', f);
                var p = i.cseq || Math.floor(1e4 * Math.random());
                (this.cseq = p), this.setHeader('cseq', p + ' ' + t);
              }
              return (
                r(e, [
                  {
                    key: 'setHeader',
                    value: function (e, t) {
                      for (var n = new RegExp('^\\s*' + e + '\\s*:', 'i'), r = 0; r < this.extraHeaders.length; r++)
                        n.test(this.extraHeaders[r]) && this.extraHeaders.splice(r, 1);
                      this.headers[u.headerize(e)] = Array.isArray(t) ? t : [t];
                    },
                  },
                  {
                    key: 'getHeader',
                    value: function (e) {
                      var t = this.headers[u.headerize(e)];
                      if (t) {
                        if (t[0]) return t[0];
                      } else {
                        var n = new RegExp('^\\s*' + e + '\\s*:', 'i'),
                          r = !0,
                          i = !1,
                          s = void 0;
                        try {
                          for (var a, o = this.extraHeaders[Symbol.iterator](); !(r = (a = o.next()).done); r = !0) {
                            var l = a.value;
                            if (n.test(l)) return l.substring(l.indexOf(':') + 1).trim();
                          }
                        } catch (e) {
                          (i = !0), (s = e);
                        } finally {
                          try {
                            !r && o.return && o.return();
                          } finally {
                            if (i) throw s;
                          }
                        }
                      }
                    },
                  },
                  {
                    key: 'getHeaders',
                    value: function (e) {
                      var t = this.headers[u.headerize(e)],
                        n = [];
                      if (t) {
                        var r = !0,
                          i = !1,
                          s = void 0;
                        try {
                          for (var a, o = t[Symbol.iterator](); !(r = (a = o.next()).done); r = !0) {
                            var l = a.value;
                            n.push(l);
                          }
                        } catch (e) {
                          (i = !0), (s = e);
                        } finally {
                          try {
                            !r && o.return && o.return();
                          } finally {
                            if (i) throw s;
                          }
                        }
                        return n;
                      }
                      var c = new RegExp('^\\s*' + e + '\\s*:', 'i'),
                        d = !0,
                        h = !1,
                        f = void 0;
                      try {
                        for (var p, _ = this.extraHeaders[Symbol.iterator](); !(d = (p = _.next()).done); d = !0) {
                          var v = p.value;
                          c.test(v) && n.push(v.substring(v.indexOf(':') + 1).trim());
                        }
                      } catch (e) {
                        (h = !0), (f = e);
                      } finally {
                        try {
                          !d && _.return && _.return();
                        } finally {
                          if (h) throw f;
                        }
                      }
                      return n;
                    },
                  },
                  {
                    key: 'hasHeader',
                    value: function (e) {
                      if (this.headers[u.headerize(e)]) return !0;
                      var t = new RegExp('^\\s*' + e + '\\s*:', 'i'),
                        n = !0,
                        r = !1,
                        i = void 0;
                      try {
                        for (var s, a = this.extraHeaders[Symbol.iterator](); !(n = (s = a.next()).done); n = !0) {
                          var o = s.value;
                          if (t.test(o)) return !0;
                        }
                      } catch (e) {
                        (r = !0), (i = e);
                      } finally {
                        try {
                          !n && a.return && a.return();
                        } finally {
                          if (r) throw i;
                        }
                      }
                      return !1;
                    },
                  },
                  {
                    key: 'parseSDP',
                    value: function (e) {
                      return !e && this.sdp ? this.sdp : ((this.sdp = o.parse(this.body || '')), this.sdp);
                    },
                  },
                  {
                    key: 'toString',
                    value: function () {
                      var e = this.method + ' ' + this.ruri + ' SIP/2.0\r\n';
                      for (var t in this.headers)
                        if (Object.prototype.hasOwnProperty.call(this.headers, t)) {
                          var n = !0,
                            r = !1,
                            i = void 0;
                          try {
                            for (var s, a = this.headers[t][Symbol.iterator](); !(n = (s = a.next()).done); n = !0) {
                              e += t + ': ' + s.value + '\r\n';
                            }
                          } catch (e) {
                            (r = !0), (i = e);
                          } finally {
                            try {
                              !n && a.return && a.return();
                            } finally {
                              if (r) throw i;
                            }
                          }
                        }
                      var o = !0,
                        c = !1,
                        d = void 0;
                      try {
                        for (var h, f = this.extraHeaders[Symbol.iterator](); !(o = (h = f.next()).done); o = !0) {
                          e += h.value.trim() + '\r\n';
                        }
                      } catch (e) {
                        (c = !0), (d = e);
                      } finally {
                        try {
                          !o && f.return && f.return();
                        } finally {
                          if (c) throw d;
                        }
                      }
                      var p = [];
                      switch (this.method) {
                        case l.REGISTER:
                          p.push('path', 'gruu');
                          break;
                        case l.INVITE:
                          this.ua.configuration.session_timers && p.push('timer'),
                            (this.ua.contact.pub_gruu || this.ua.contact.temp_gruu) && p.push('gruu'),
                            p.push('ice', 'replaces');
                          break;
                        case l.UPDATE:
                          this.ua.configuration.session_timers && p.push('timer'), p.push('ice');
                      }
                      p.push('outbound');
                      var _ = this.ua.configuration.user_agent || l.USER_AGENT;
                      if (((e += 'Allow: ' + l.ALLOWED_METHODS + '\r\n'), (e += 'Supported: ' + p + '\r\n'), (e += 'User-Agent: ' + _ + '\r\n'), this.body)) {
                        (e += 'Content-Length: ' + u.str_utf8_length(this.body) + '\r\n\r\n'), (e += this.body);
                      } else e += 'Content-Length: 0\r\n\r\n';
                      return e;
                    },
                  },
                  {
                    key: 'clone',
                    value: function () {
                      var t = new e(this.method, this.ruri, this.ua);
                      return (
                        Object.keys(this.headers).forEach(function (e) {
                          t.headers[e] = this.headers[e].slice();
                        }, this),
                        (t.body = this.body),
                        (t.extraHeaders = u.cloneArray(this.extraHeaders)),
                        (t.to = this.to),
                        (t.from = this.from),
                        (t.call_id = this.call_id),
                        (t.cseq = this.cseq),
                        t
                      );
                    },
                  },
                ]),
                e
              );
            })(),
            p = (function (e) {
              s(t, f);
              function t(e, n, r, s, o) {
                a(this, t);
                var u = i(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this, l.INVITE, e, n, r, s, o));
                return (u.transaction = null), u;
              }
              return (
                r(t, [
                  {
                    key: 'cancel',
                    value: function (e) {
                      this.transaction.cancel(e);
                    },
                  },
                  {
                    key: 'clone',
                    value: function () {
                      var e = new t(this.ruri, this.ua);
                      return (
                        Object.keys(this.headers).forEach(function (t) {
                          e.headers[t] = this.headers[t].slice();
                        }, this),
                        (e.body = this.body),
                        (e.extraHeaders = u.cloneArray(this.extraHeaders)),
                        (e.to = this.to),
                        (e.from = this.from),
                        (e.call_id = this.call_id),
                        (e.cseq = this.cseq),
                        (e.transaction = this.transaction),
                        e
                      );
                    },
                  },
                ]),
                t
              );
            })(),
            _ = (function () {
              function e() {
                a(this, e),
                  (this.data = null),
                  (this.headers = null),
                  (this.method = null),
                  (this.via = null),
                  (this.via_branch = null),
                  (this.call_id = null),
                  (this.cseq = null),
                  (this.from = null),
                  (this.from_tag = null),
                  (this.to = null),
                  (this.to_tag = null),
                  (this.body = null),
                  (this.sdp = null);
              }
              return (
                r(e, [
                  {
                    key: 'addHeader',
                    value: function (e, t) {
                      var n = { raw: t };
                      (e = u.headerize(e)), this.headers[e] ? this.headers[e].push(n) : (this.headers[e] = [n]);
                    },
                  },
                  {
                    key: 'getHeader',
                    value: function (e) {
                      var t = this.headers[u.headerize(e)];
                      if (t) return t[0] ? t[0].raw : void 0;
                    },
                  },
                  {
                    key: 'getHeaders',
                    value: function (e) {
                      var t = this.headers[u.headerize(e)],
                        n = [];
                      if (!t) return [];
                      var r = !0,
                        i = !1,
                        s = void 0;
                      try {
                        for (var a, o = t[Symbol.iterator](); !(r = (a = o.next()).done); r = !0) {
                          var l = a.value;
                          n.push(l.raw);
                        }
                      } catch (e) {
                        (i = !0), (s = e);
                      } finally {
                        try {
                          !r && o.return && o.return();
                        } finally {
                          if (i) throw s;
                        }
                      }
                      return n;
                    },
                  },
                  {
                    key: 'hasHeader',
                    value: function (e) {
                      return !!this.headers[u.headerize(e)];
                    },
                  },
                  {
                    key: 'parseHeader',
                    value: function (e) {
                      var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 0;
                      if (((e = u.headerize(e)), this.headers[e])) {
                        if (!(t >= this.headers[e].length)) {
                          var n = this.headers[e][t],
                            r = n.raw;
                          if (n.parsed) return n.parsed;
                          var i = d.parse(r, e.replace(/-/g, '_'));
                          return -1 === i ? (this.headers[e].splice(t, 1), void h('error parsing "' + e + '" header field with value "' + r + '"')) : ((n.parsed = i), i);
                        }
                        h('not so many "' + e + '" headers present');
                      } else h('header "' + e + '" not present');
                    },
                  },
                  {
                    key: 's',
                    value: function (e, t) {
                      return this.parseHeader(e, t);
                    },
                  },
                  {
                    key: 'setHeader',
                    value: function (e, t) {
                      var n = { raw: t };
                      this.headers[u.headerize(e)] = [n];
                    },
                  },
                  {
                    key: 'parseSDP',
                    value: function (e) {
                      return !e && this.sdp ? this.sdp : ((this.sdp = o.parse(this.body || '')), this.sdp);
                    },
                  },
                  {
                    key: 'toString',
                    value: function () {
                      return this.data;
                    },
                  },
                ]),
                e
              );
            })(),
            v = (function (e) {
              s(t, _);
              function t(e) {
                a(this, t);
                var n = i(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
                return (n.ua = e), (n.headers = {}), (n.ruri = null), (n.transport = null), (n.server_transaction = null), n;
              }
              return (
                r(t, [
                  {
                    key: 'reply',
                    value: function (e, t, n, r, i, s) {
                      var a = [],
                        o = this.getHeader('To');
                      if (((e = e || null), (t = t || null), !e || e < 100 || e > 699)) throw new TypeError('Invalid status_code: ' + e);
                      if (t && 'string' != typeof t && !(t instanceof String)) throw new TypeError('Invalid reason_phrase: ' + t);
                      (t = t || l.REASON_PHRASE[e] || ''), (n = u.cloneArray(n));
                      var c = 'SIP/2.0 ' + e + ' ' + t + '\r\n';
                      if (this.method === l.INVITE && e > 100 && e <= 200) {
                        var d = this.getHeaders('record-route'),
                          h = !0,
                          f = !1,
                          p = void 0;
                        try {
                          for (var _, v = d[Symbol.iterator](); !(h = (_ = v.next()).done); h = !0) {
                            c += 'Record-Route: ' + _.value + '\r\n';
                          }
                        } catch (e) {
                          (f = !0), (p = e);
                        } finally {
                          try {
                            !h && v.return && v.return();
                          } finally {
                            if (f) throw p;
                          }
                        }
                      }
                      var m = this.getHeaders('via'),
                        g = !0,
                        y = !1,
                        T = void 0;
                      try {
                        for (var C, S = m[Symbol.iterator](); !(g = (C = S.next()).done); g = !0) {
                          c += 'Via: ' + C.value + '\r\n';
                        }
                      } catch (e) {
                        (y = !0), (T = e);
                      } finally {
                        try {
                          !g && S.return && S.return();
                        } finally {
                          if (y) throw T;
                        }
                      }
                      !this.to_tag && e > 100 ? (o += ';tag=' + u.newTag()) : this.to_tag && !this.s('to').hasParam('tag') && (o += ';tag=' + this.to_tag),
                        (c += 'To: ' + o + '\r\n'),
                        (c += 'From: ' + this.getHeader('From') + '\r\n'),
                        (c += 'Call-ID: ' + this.call_id + '\r\n'),
                        (c += 'CSeq: ' + this.cseq + ' ' + this.method + '\r\n');
                      var E = !0,
                        b = !1,
                        R = void 0;
                      try {
                        for (var A, w = n[Symbol.iterator](); !(E = (A = w.next()).done); E = !0) {
                          c += A.value.trim() + '\r\n';
                        }
                      } catch (e) {
                        (b = !0), (R = e);
                      } finally {
                        try {
                          !E && w.return && w.return();
                        } finally {
                          if (b) throw R;
                        }
                      }
                      switch (this.method) {
                        case l.INVITE:
                          this.ua.configuration.session_timers && a.push('timer'),
                            (this.ua.contact.pub_gruu || this.ua.contact.temp_gruu) && a.push('gruu'),
                            a.push('ice', 'replaces');
                          break;
                        case l.UPDATE:
                          this.ua.configuration.session_timers && a.push('timer'), r && a.push('ice'), a.push('replaces');
                      }
                      if (
                        (a.push('outbound'),
                        this.method === l.OPTIONS
                          ? ((c += 'Allow: ' + l.ALLOWED_METHODS + '\r\n'), (c += 'Accept: ' + l.ACCEPTED_BODY_TYPES + '\r\n'))
                          : 405 === e
                          ? (c += 'Allow: ' + l.ALLOWED_METHODS + '\r\n')
                          : 415 === e && (c += 'Accept: ' + l.ACCEPTED_BODY_TYPES + '\r\n'),
                        (c += 'Supported: ' + a + '\r\n'),
                        r)
                      ) {
                        (c += 'Content-Type: application/sdp\r\n'), (c += 'Content-Length: ' + u.str_utf8_length(r) + '\r\n\r\n'), (c += r);
                      } else c += 'Content-Length: 0\r\n\r\n';
                      this.server_transaction.receiveResponse(e, c, i, s);
                    },
                  },
                  {
                    key: 'reply_sl',
                    value: function () {
                      var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : null,
                        t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : null,
                        n = this.getHeaders('via');
                      if (!e || e < 100 || e > 699) throw new TypeError('Invalid status_code: ' + e);
                      if (t && 'string' != typeof t && !(t instanceof String)) throw new TypeError('Invalid reason_phrase: ' + t);
                      var r = 'SIP/2.0 ' + e + ' ' + (t = t || l.REASON_PHRASE[e] || '') + '\r\n',
                        i = !0,
                        s = !1,
                        a = void 0;
                      try {
                        for (var o, c = n[Symbol.iterator](); !(i = (o = c.next()).done); i = !0) {
                          r += 'Via: ' + o.value + '\r\n';
                        }
                      } catch (e) {
                        (s = !0), (a = e);
                      } finally {
                        try {
                          !i && c.return && c.return();
                        } finally {
                          if (s) throw a;
                        }
                      }
                      var d = this.getHeader('To');
                      !this.to_tag && e > 100 ? (d += ';tag=' + u.newTag()) : this.to_tag && !this.s('to').hasParam('tag') && (d += ';tag=' + this.to_tag),
                        (r += 'To: ' + d + '\r\n'),
                        (r += 'From: ' + this.getHeader('From') + '\r\n'),
                        (r += 'Call-ID: ' + this.call_id + '\r\n'),
                        (r += 'CSeq: ' + this.cseq + ' ' + this.method + '\r\n'),
                        (r += 'Content-Length: 0\r\n\r\n'),
                        this.transport.send(r);
                    },
                  },
                ]),
                t
              );
            })(),
            m = (function (e) {
              s(t, _);
              function t() {
                a(this, t);
                var e = i(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
                return (e.headers = {}), (e.status_code = null), (e.reason_phrase = null), e;
              }
              return t;
            })();
          t.exports = { OutgoingRequest: f, InitialOutgoingInviteRequest: p, IncomingRequest: v, IncomingResponse: m };
        },
        { './Constants': 2, './Grammar': 7, './NameAddrHeader': 10, './Utils': 26, debug: 29, 'sdp-transform': 36 },
      ],
      20: [
        function (e, t, n) {
          'use strict';
          var r = e('./Utils'),
            i = e('./Grammar'),
            s = e('debug')('JsSIP:ERROR:Socket');
          (s.log = console.warn.bind(console)),
            (n.isSocket = function (e) {
              if (Array.isArray(e)) return !1;
              if (void 0 === e) return s('undefined JsSIP.Socket instance'), !1;
              try {
                if (!r.isString(e.url)) throw (s('missing or invalid JsSIP.Socket url property'), new Error());
                if (!r.isString(e.via_transport)) throw (s('missing or invalid JsSIP.Socket via_transport property'), new Error());
                if (-1 === i.parse(e.sip_uri, 'SIP_URI')) throw (s('missing or invalid JsSIP.Socket sip_uri property'), new Error());
              } catch (e) {
                return !1;
              }
              try {
                ['connect', 'disconnect', 'send'].forEach(function (t) {
                  if (!r.isFunction(e[t])) throw (s('missing or invalid JsSIP.Socket method: ' + t), new Error());
                });
              } catch (e) {
                return !1;
              }
              return !0;
            });
        },
        { './Grammar': 7, './Utils': 26, debug: 29 },
      ],
      21: [
        function (e, t, n) {
          'use strict';
          t.exports = {
            T1: 500,
            T2: 4e3,
            T4: 5e3,
            TIMER_B: 32e3,
            TIMER_D: 0,
            TIMER_F: 32e3,
            TIMER_H: 32e3,
            TIMER_I: 0,
            TIMER_J: 0,
            TIMER_K: 0,
            TIMER_L: 32e3,
            TIMER_M: 32e3,
            PROVISIONAL_RESPONSE_INTERVAL: 6e4,
          };
        },
        {},
      ],
      22: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          function i(e, t) {
            if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
          }
          function s(e, t) {
            if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
            return !t || ('object' != typeof t && 'function' != typeof t) ? e : t;
          }
          function a(e, t) {
            if ('function' != typeof t && null !== t) throw new TypeError('Super expression must either be null or a function, not ' + typeof t);
            (e.prototype = Object.create(t && t.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })),
              t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : (e.__proto__ = t));
          }
          var o = e('events').EventEmitter,
            l = e('./Constants'),
            u = e('./SIPMessage'),
            c = e('./Timers'),
            d = e('debug')('JsSIP:NonInviteClientTransaction'),
            h = e('debug')('JsSIP:InviteClientTransaction'),
            f = e('debug')('JsSIP:AckClientTransaction'),
            p = e('debug')('JsSIP:NonInviteServerTransaction'),
            _ = e('debug')('JsSIP:InviteServerTransaction'),
            v = {
              STATUS_TRYING: 1,
              STATUS_PROCEEDING: 2,
              STATUS_CALLING: 3,
              STATUS_ACCEPTED: 4,
              STATUS_COMPLETED: 5,
              STATUS_TERMINATED: 6,
              STATUS_CONFIRMED: 7,
              NON_INVITE_CLIENT: 'nict',
              NON_INVITE_SERVER: 'nist',
              INVITE_CLIENT: 'ict',
              INVITE_SERVER: 'ist',
            },
            m = (function (e) {
              a(t, o);
              function t(e, n, r, a) {
                i(this, t);
                var o = s(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
                (o.type = v.NON_INVITE_CLIENT),
                  (o.id = 'z9hG4bK' + Math.floor(1e7 * Math.random())),
                  (o.ua = e),
                  (o.transport = n),
                  (o.request = r),
                  (o.eventHandlers = a);
                var l = 'SIP/2.0/' + n.via_transport;
                return (l += ' ' + e.configuration.via_host + ';branch=' + o.id), o.request.setHeader('via', l), o.ua.newTransaction(o), o;
              }
              return (
                r(t, [
                  {
                    key: 'stateChanged',
                    value: function (e) {
                      (this.state = e), this.emit('stateChanged');
                    },
                  },
                  {
                    key: 'send',
                    value: function () {
                      var e = this;
                      this.stateChanged(v.STATUS_TRYING),
                        (this.F = setTimeout(function () {
                          e.timer_F();
                        }, c.TIMER_F)),
                        this.transport.send(this.request) || this.onTransportError();
                    },
                  },
                  {
                    key: 'onTransportError',
                    value: function () {
                      d('transport error occurred, deleting transaction ' + this.id),
                        clearTimeout(this.F),
                        clearTimeout(this.K),
                        this.stateChanged(v.STATUS_TERMINATED),
                        this.ua.destroyTransaction(this),
                        this.eventHandlers.onTransportError();
                    },
                  },
                  {
                    key: 'timer_F',
                    value: function () {
                      d('Timer F expired for transaction ' + this.id),
                        this.stateChanged(v.STATUS_TERMINATED),
                        this.ua.destroyTransaction(this),
                        this.eventHandlers.onRequestTimeout();
                    },
                  },
                  {
                    key: 'timer_K',
                    value: function () {
                      this.stateChanged(v.STATUS_TERMINATED), this.ua.destroyTransaction(this);
                    },
                  },
                  {
                    key: 'receiveResponse',
                    value: function (e) {
                      var t = this,
                        n = e.status_code;
                      if (n < 200)
                        switch (this.state) {
                          case v.STATUS_TRYING:
                          case v.STATUS_PROCEEDING:
                            this.stateChanged(v.STATUS_PROCEEDING), this.eventHandlers.onReceiveResponse(e);
                        }
                      else
                        switch (this.state) {
                          case v.STATUS_TRYING:
                          case v.STATUS_PROCEEDING:
                            this.stateChanged(v.STATUS_COMPLETED),
                              clearTimeout(this.F),
                              408 === n ? this.eventHandlers.onRequestTimeout() : this.eventHandlers.onReceiveResponse(e),
                              (this.K = setTimeout(function () {
                                t.timer_K();
                              }, c.TIMER_K));
                            break;
                          case v.STATUS_COMPLETED:
                        }
                    },
                  },
                  {
                    key: 'C',
                    get: function () {
                      return v;
                    },
                  },
                ]),
                t
              );
            })(),
            g = (function (e) {
              a(t, o);
              function t(e, n, r, a) {
                i(this, t);
                var o = s(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
                (o.type = v.INVITE_CLIENT),
                  (o.id = 'z9hG4bK' + Math.floor(1e7 * Math.random())),
                  (o.ua = e),
                  (o.transport = n),
                  (o.request = r),
                  (o.eventHandlers = a),
                  (r.transaction = o);
                var l = 'SIP/2.0/' + n.via_transport;
                return (l += ' ' + e.configuration.via_host + ';branch=' + o.id), o.request.setHeader('via', l), o.ua.newTransaction(o), o;
              }
              return (
                r(t, [
                  {
                    key: 'stateChanged',
                    value: function (e) {
                      (this.state = e), this.emit('stateChanged');
                    },
                  },
                  {
                    key: 'send',
                    value: function () {
                      var e = this;
                      this.stateChanged(v.STATUS_CALLING),
                        (this.B = setTimeout(function () {
                          e.timer_B();
                        }, c.TIMER_B)),
                        this.transport.send(this.request) || this.onTransportError();
                    },
                  },
                  {
                    key: 'onTransportError',
                    value: function () {
                      clearTimeout(this.B),
                        clearTimeout(this.D),
                        clearTimeout(this.M),
                        this.state !== v.STATUS_ACCEPTED && (h('transport error occurred, deleting transaction ' + this.id), this.eventHandlers.onTransportError()),
                        this.stateChanged(v.STATUS_TERMINATED),
                        this.ua.destroyTransaction(this);
                    },
                  },
                  {
                    key: 'timer_M',
                    value: function () {
                      h('Timer M expired for transaction ' + this.id),
                        this.state === v.STATUS_ACCEPTED && (clearTimeout(this.B), this.stateChanged(v.STATUS_TERMINATED), this.ua.destroyTransaction(this));
                    },
                  },
                  {
                    key: 'timer_B',
                    value: function () {
                      h('Timer B expired for transaction ' + this.id),
                        this.state === v.STATUS_CALLING &&
                          (this.stateChanged(v.STATUS_TERMINATED), this.ua.destroyTransaction(this), this.eventHandlers.onRequestTimeout());
                    },
                  },
                  {
                    key: 'timer_D',
                    value: function () {
                      h('Timer D expired for transaction ' + this.id), clearTimeout(this.B), this.stateChanged(v.STATUS_TERMINATED), this.ua.destroyTransaction(this);
                    },
                  },
                  {
                    key: 'sendACK',
                    value: function (e) {
                      var t = this,
                        n = new u.OutgoingRequest(l.ACK, this.request.ruri, this.ua, {
                          route_set: this.request.getHeader('route'),
                          call_id: this.request.getHeader('call-id'),
                          cseq: this.request.cseq,
                        });
                      n.setHeader('from', this.request.getHeader('from')),
                        n.setHeader('via', this.request.getHeader('via')),
                        n.setHeader('to', e.getHeader('to')),
                        (this.D = setTimeout(function () {
                          t.timer_D();
                        }, c.TIMER_D)),
                        this.transport.send(n);
                    },
                  },
                  {
                    key: 'cancel',
                    value: function (e) {
                      if (this.state === v.STATUS_PROCEEDING) {
                        var t = new u.OutgoingRequest(l.CANCEL, this.request.ruri, this.ua, {
                          route_set: this.request.getHeader('route'),
                          call_id: this.request.getHeader('call-id'),
                          cseq: this.request.cseq,
                        });
                        t.setHeader('from', this.request.getHeader('from')),
                          t.setHeader('via', this.request.getHeader('via')),
                          t.setHeader('to', this.request.getHeader('to')),
                          e && t.setHeader('reason', e),
                          this.transport.send(t);
                      }
                    },
                  },
                  {
                    key: 'receiveResponse',
                    value: function (e) {
                      var t = this,
                        n = e.status_code;
                      if (n >= 100 && n <= 199)
                        switch (this.state) {
                          case v.STATUS_CALLING:
                            this.stateChanged(v.STATUS_PROCEEDING), this.eventHandlers.onReceiveResponse(e);
                            break;
                          case v.STATUS_PROCEEDING:
                            this.eventHandlers.onReceiveResponse(e);
                        }
                      else if (n >= 200 && n <= 299)
                        switch (this.state) {
                          case v.STATUS_CALLING:
                          case v.STATUS_PROCEEDING:
                            this.stateChanged(v.STATUS_ACCEPTED),
                              (this.M = setTimeout(function () {
                                t.timer_M();
                              }, c.TIMER_M)),
                              this.eventHandlers.onReceiveResponse(e);
                            break;
                          case v.STATUS_ACCEPTED:
                            this.eventHandlers.onReceiveResponse(e);
                        }
                      else if (n >= 300 && n <= 699)
                        switch (this.state) {
                          case v.STATUS_CALLING:
                          case v.STATUS_PROCEEDING:
                            this.stateChanged(v.STATUS_COMPLETED), this.sendACK(e), this.eventHandlers.onReceiveResponse(e);
                            break;
                          case v.STATUS_COMPLETED:
                            this.sendACK(e);
                        }
                    },
                  },
                  {
                    key: 'C',
                    get: function () {
                      return v;
                    },
                  },
                ]),
                t
              );
            })(),
            y = (function (e) {
              a(t, o);
              function t(e, n, r, a) {
                i(this, t);
                var o = s(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
                (o.id = 'z9hG4bK' + Math.floor(1e7 * Math.random())), (o.transport = n), (o.request = r), (o.eventHandlers = a);
                var l = 'SIP/2.0/' + n.via_transport;
                return (l += ' ' + e.configuration.via_host + ';branch=' + o.id), o.request.setHeader('via', l), o;
              }
              return (
                r(t, [
                  {
                    key: 'send',
                    value: function () {
                      this.transport.send(this.request) || this.onTransportError();
                    },
                  },
                  {
                    key: 'onTransportError',
                    value: function () {
                      f('transport error occurred for transaction ' + this.id), this.eventHandlers.onTransportError();
                    },
                  },
                  {
                    key: 'C',
                    get: function () {
                      return v;
                    },
                  },
                ]),
                t
              );
            })(),
            T = (function (e) {
              a(t, o);
              function t(e, n, r) {
                i(this, t);
                var a = s(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
                return (
                  (a.type = v.NON_INVITE_SERVER),
                  (a.id = r.via_branch),
                  (a.ua = e),
                  (a.transport = n),
                  (a.request = r),
                  (a.last_response = ''),
                  (r.server_transaction = a),
                  (a.state = v.STATUS_TRYING),
                  e.newTransaction(a),
                  a
                );
              }
              return (
                r(t, [
                  {
                    key: 'stateChanged',
                    value: function (e) {
                      (this.state = e), this.emit('stateChanged');
                    },
                  },
                  {
                    key: 'timer_J',
                    value: function () {
                      p('Timer J expired for transaction ' + this.id), this.stateChanged(v.STATUS_TERMINATED), this.ua.destroyTransaction(this);
                    },
                  },
                  {
                    key: 'onTransportError',
                    value: function () {
                      this.transportError ||
                        ((this.transportError = !0),
                        p('transport error occurred, deleting transaction ' + this.id),
                        clearTimeout(this.J),
                        this.stateChanged(v.STATUS_TERMINATED),
                        this.ua.destroyTransaction(this));
                    },
                  },
                  {
                    key: 'receiveResponse',
                    value: function (e, t, n, r) {
                      var i = this;
                      if (100 === e)
                        switch (this.state) {
                          case v.STATUS_TRYING:
                            this.stateChanged(v.STATUS_PROCEEDING), this.transport.send(t) || this.onTransportError();
                            break;
                          case v.STATUS_PROCEEDING:
                            (this.last_response = t), this.transport.send(t) ? n && n() : (this.onTransportError(), r && r());
                        }
                      else if (e >= 200 && e <= 699)
                        switch (this.state) {
                          case v.STATUS_TRYING:
                          case v.STATUS_PROCEEDING:
                            this.stateChanged(v.STATUS_COMPLETED),
                              (this.last_response = t),
                              (this.J = setTimeout(function () {
                                i.timer_J();
                              }, c.TIMER_J)),
                              this.transport.send(t) ? n && n() : (this.onTransportError(), r && r());
                            break;
                          case v.STATUS_COMPLETED:
                        }
                    },
                  },
                  {
                    key: 'C',
                    get: function () {
                      return v;
                    },
                  },
                ]),
                t
              );
            })(),
            C = (function (e) {
              a(t, o);
              function t(e, n, r) {
                i(this, t);
                var a = s(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
                return (
                  (a.type = v.INVITE_SERVER),
                  (a.id = r.via_branch),
                  (a.ua = e),
                  (a.transport = n),
                  (a.request = r),
                  (a.last_response = ''),
                  (r.server_transaction = a),
                  (a.state = v.STATUS_PROCEEDING),
                  e.newTransaction(a),
                  (a.resendProvisionalTimer = null),
                  r.reply(100),
                  a
                );
              }
              return (
                r(t, [
                  {
                    key: 'stateChanged',
                    value: function (e) {
                      (this.state = e), this.emit('stateChanged');
                    },
                  },
                  {
                    key: 'timer_H',
                    value: function () {
                      _('Timer H expired for transaction ' + this.id),
                        this.state === v.STATUS_COMPLETED && _('ACK not received, dialog will be terminated'),
                        this.stateChanged(v.STATUS_TERMINATED),
                        this.ua.destroyTransaction(this);
                    },
                  },
                  {
                    key: 'timer_I',
                    value: function () {
                      this.stateChanged(v.STATUS_TERMINATED);
                    },
                  },
                  {
                    key: 'timer_L',
                    value: function () {
                      _('Timer L expired for transaction ' + this.id),
                        this.state === v.STATUS_ACCEPTED && (this.stateChanged(v.STATUS_TERMINATED), this.ua.destroyTransaction(this));
                    },
                  },
                  {
                    key: 'onTransportError',
                    value: function () {
                      this.transportError ||
                        ((this.transportError = !0),
                        _('transport error occurred, deleting transaction ' + this.id),
                        null !== this.resendProvisionalTimer && (clearInterval(this.resendProvisionalTimer), (this.resendProvisionalTimer = null)),
                        clearTimeout(this.L),
                        clearTimeout(this.H),
                        clearTimeout(this.I),
                        this.stateChanged(v.STATUS_TERMINATED),
                        this.ua.destroyTransaction(this));
                    },
                  },
                  {
                    key: 'resend_provisional',
                    value: function () {
                      this.transport.send(this.last_response) || this.onTransportError();
                    },
                  },
                  {
                    key: 'receiveResponse',
                    value: function (e, t, n, r) {
                      var i = this;
                      if (e >= 100 && e <= 199)
                        switch (this.state) {
                          case v.STATUS_PROCEEDING:
                            this.transport.send(t) || this.onTransportError(), (this.last_response = t);
                        }
                      if (e > 100 && e <= 199 && this.state === v.STATUS_PROCEEDING)
                        null === this.resendProvisionalTimer &&
                          (this.resendProvisionalTimer = setInterval(function () {
                            i.resend_provisional();
                          }, c.PROVISIONAL_RESPONSE_INTERVAL));
                      else if (e >= 200 && e <= 299)
                        switch (this.state) {
                          case v.STATUS_PROCEEDING:
                            this.stateChanged(v.STATUS_ACCEPTED),
                              (this.last_response = t),
                              (this.L = setTimeout(function () {
                                i.timer_L();
                              }, c.TIMER_L)),
                              null !== this.resendProvisionalTimer && (clearInterval(this.resendProvisionalTimer), (this.resendProvisionalTimer = null));
                          case v.STATUS_ACCEPTED:
                            this.transport.send(t) ? n && n() : (this.onTransportError(), r && r());
                        }
                      else if (e >= 300 && e <= 699)
                        switch (this.state) {
                          case v.STATUS_PROCEEDING:
                            null !== this.resendProvisionalTimer && (clearInterval(this.resendProvisionalTimer), (this.resendProvisionalTimer = null)),
                              this.transport.send(t)
                                ? (this.stateChanged(v.STATUS_COMPLETED),
                                  (this.H = setTimeout(function () {
                                    i.timer_H();
                                  }, c.TIMER_H)),
                                  n && n())
                                : (this.onTransportError(), r && r());
                        }
                    },
                  },
                  {
                    key: 'C',
                    get: function () {
                      return v;
                    },
                  },
                ]),
                t
              );
            })();
          t.exports = {
            C: v,
            NonInviteClientTransaction: m,
            InviteClientTransaction: g,
            AckClientTransaction: y,
            NonInviteServerTransaction: T,
            InviteServerTransaction: C,
            checkTransaction: function (e, t) {
              var n = e._transactions,
                r = void 0;
              switch (t.method) {
                case l.INVITE:
                  if ((r = n.ist[t.via_branch])) {
                    switch (r.state) {
                      case v.STATUS_PROCEEDING:
                        r.transport.send(r.last_response);
                        break;
                      case v.STATUS_ACCEPTED:
                    }
                    return !0;
                  }
                  break;
                case l.ACK:
                  if (!(r = n.ist[t.via_branch])) return !1;
                  if (r.state === v.STATUS_ACCEPTED) return !1;
                  if (r.state === v.STATUS_COMPLETED)
                    return (
                      (r.state = v.STATUS_CONFIRMED),
                      (r.I = setTimeout(function () {
                        r.timer_I();
                      }, c.TIMER_I)),
                      !0
                    );
                  break;
                case l.CANCEL:
                  return (r = n.ist[t.via_branch]) ? (t.reply_sl(200), r.state !== v.STATUS_PROCEEDING) : (t.reply_sl(481), !0);
                default:
                  if ((r = n.nist[t.via_branch])) {
                    switch (r.state) {
                      case v.STATUS_TRYING:
                        break;
                      case v.STATUS_PROCEEDING:
                      case v.STATUS_COMPLETED:
                        r.transport.send(r.last_response);
                    }
                    return !0;
                  }
              }
            },
          };
        },
        { './Constants': 2, './SIPMessage': 19, './Timers': 21, debug: 29, events: 31 },
      ],
      23: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('./Socket'),
            s = e('debug')('JsSIP:Transport'),
            a = e('debug')('JsSIP:ERROR:Transport');
          a.log = console.warn.bind(console);
          var o = {
            STATUS_CONNECTED: 0,
            STATUS_CONNECTING: 1,
            STATUS_DISCONNECTED: 2,
            SOCKET_STATUS_READY: 0,
            SOCKET_STATUS_ERROR: 1,
            recovery_options: { min_interval: 2, max_interval: 30 },
          };
          t.exports = (function () {
            function e(t) {
              var n = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : o.recovery_options;
              if (
                ((function (e, t) {
                  if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
                })(this, e),
                s('new()'),
                (this.status = o.STATUS_DISCONNECTED),
                (this.socket = null),
                (this.sockets = []),
                (this.recovery_options = n),
                (this.recover_attempts = 0),
                (this.recovery_timer = null),
                (this.close_requested = !1),
                void 0 === t)
              )
                throw new TypeError("Invalid argument. undefined 'sockets' argument");
              t instanceof Array || (t = [t]),
                t.forEach(function (e) {
                  if (!i.isSocket(e.socket)) throw new TypeError("Invalid argument. invalid 'JsSIP.Socket' instance");
                  if (e.weight && !Number(e.weight)) throw new TypeError("Invalid argument. 'weight' attribute is not a number");
                  this.sockets.push({ socket: e.socket, weight: e.weight || 0, status: o.SOCKET_STATUS_READY });
                }, this),
                this._getSocket();
            }
            return (
              r(e, [
                {
                  key: 'connect',
                  value: function () {
                    s('connect()'),
                      this.isConnected()
                        ? s('Transport is already connected')
                        : this.isConnecting()
                        ? s('Transport is connecting')
                        : ((this.close_requested = !1),
                          (this.status = o.STATUS_CONNECTING),
                          this.onconnecting({ socket: this.socket, attempts: this.recover_attempts }),
                          this.close_requested ||
                            ((this.socket.onconnect = this._onConnect.bind(this)),
                            (this.socket.ondisconnect = this._onDisconnect.bind(this)),
                            (this.socket.ondata = this._onData.bind(this)),
                            this.socket.connect()));
                  },
                },
                {
                  key: 'disconnect',
                  value: function () {
                    s('close()'),
                      (this.close_requested = !0),
                      (this.recover_attempts = 0),
                      (this.status = o.STATUS_DISCONNECTED),
                      null !== this.recovery_timer && (clearTimeout(this.recovery_timer), (this.recovery_timer = null)),
                      (this.socket.onconnect = function () {}),
                      (this.socket.ondisconnect = function () {}),
                      (this.socket.ondata = function () {}),
                      this.socket.disconnect(),
                      this.ondisconnect();
                  },
                },
                {
                  key: 'send',
                  value: function (e) {
                    if ((s('send()'), !this.isConnected())) return a('unable to send message, transport is not connected'), !1;
                    var t = e.toString();
                    return s('sending message:\n\n' + t + '\n'), this.socket.send(t);
                  },
                },
                {
                  key: 'isConnected',
                  value: function () {
                    return this.status === o.STATUS_CONNECTED;
                  },
                },
                {
                  key: 'isConnecting',
                  value: function () {
                    return this.status === o.STATUS_CONNECTING;
                  },
                },
                {
                  key: '_reconnect',
                  value: function () {
                    var e = this;
                    this.recover_attempts += 1;
                    var t = Math.floor(Math.random() * Math.pow(2, this.recover_attempts) + 1);
                    t < this.recovery_options.min_interval
                      ? (t = this.recovery_options.min_interval)
                      : t > this.recovery_options.max_interval && (t = this.recovery_options.max_interval),
                      s('reconnection attempt: ' + this.recover_attempts + '. next connection attempt in ' + t + ' seconds'),
                      (this.recovery_timer = setTimeout(function () {
                        e.close_requested || e.isConnected() || e.isConnecting() || (e._getSocket(), e.connect());
                      }, 1e3 * t));
                  },
                },
                {
                  key: '_getSocket',
                  value: function () {
                    var e = [];
                    if (
                      (this.sockets.forEach(function (t) {
                        t.status !== o.SOCKET_STATUS_ERROR && (0 === e.length ? e.push(t) : t.weight > e[0].weight ? (e = [t]) : t.weight === e[0].weight && e.push(t));
                      }),
                      0 === e.length)
                    )
                      return (
                        this.sockets.forEach(function (e) {
                          e.status = o.SOCKET_STATUS_READY;
                        }),
                        void this._getSocket()
                      );
                    var t = Math.floor(Math.random() * e.length);
                    this.socket = e[t].socket;
                  },
                },
                {
                  key: '_onConnect',
                  value: function () {
                    (this.recover_attempts = 0),
                      (this.status = o.STATUS_CONNECTED),
                      null !== this.recovery_timer && (clearTimeout(this.recovery_timer), (this.recovery_timer = null)),
                      this.onconnect({ socket: this });
                  },
                },
                {
                  key: '_onDisconnect',
                  value: function (e, t, n) {
                    (this.status = o.STATUS_DISCONNECTED),
                      this.ondisconnect({ socket: this.socket, error: e, code: t, reason: n }),
                      this.close_requested ||
                        (this.sockets.forEach(function (e) {
                          this.socket === e.socket && (e.status = o.SOCKET_STATUS_ERROR);
                        }, this),
                        this._reconnect(e));
                  },
                },
                {
                  key: '_onData',
                  value: function (e) {
                    if ('\r\n' !== e) {
                      if ('string' != typeof e) {
                        try {
                          e = String.fromCharCode.apply(null, new Uint8Array(e));
                        } catch (e) {
                          return void s('received binary message failed to be converted into string, message discarded');
                        }
                        s('received binary message:\n\n' + e + '\n');
                      } else s('received text message:\n\n' + e + '\n');
                      this.ondata({ transport: this, message: e });
                    } else s('received message with CRLF Keep Alive response');
                  },
                },
                {
                  key: 'via_transport',
                  get: function () {
                    return this.socket.via_transport;
                  },
                },
                {
                  key: 'url',
                  get: function () {
                    return this.socket.url;
                  },
                },
                {
                  key: 'sip_uri',
                  get: function () {
                    return this.socket.sip_uri;
                  },
                },
              ]),
              e
            );
          })();
        },
        { './Socket': 20, debug: 29 },
      ],
      24: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('events').EventEmitter,
            s = e('./Constants'),
            a = e('./Registrator'),
            o = e('./RTCSession'),
            l = e('./Message'),
            u = e('./Transactions'),
            c = e('./Transport'),
            d = e('./Utils'),
            h = e('./Exceptions'),
            f = e('./URI'),
            p = e('./Grammar'),
            _ = e('./Parser'),
            v = e('./SIPMessage'),
            m = e('./sanityCheck'),
            g = e('./Config'),
            y = e('debug')('JsSIP:UA'),
            T = e('debug')('JsSIP:ERROR:UA');
          T.log = console.warn.bind(console);
          var C = { STATUS_INIT: 0, STATUS_READY: 1, STATUS_USER_CLOSED: 2, STATUS_NOT_READY: 3, CONFIGURATION_ERROR: 1, NETWORK_ERROR: 2 };
          t.exports = (function (e) {
            !(function (e, t) {
              if ('function' != typeof t && null !== t) throw new TypeError('Super expression must either be null or a function, not ' + typeof t);
              (e.prototype = Object.create(t && t.prototype, { constructor: { value: e, enumerable: !1, writable: !0, configurable: !0 } })),
                t && (Object.setPrototypeOf ? Object.setPrototypeOf(e, t) : (e.__proto__ = t));
            })(t, i),
              r(t, null, [
                {
                  key: 'C',
                  get: function () {
                    return C;
                  },
                },
              ]);
            function t(e) {
              !(function (e, t) {
                if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
              })(this, t),
                y('new() [configuration:%o]', e);
              var n = (function (e, t) {
                if (!e) throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
                return !t || ('object' != typeof t && 'function' != typeof t) ? e : t;
              })(this, (t.__proto__ || Object.getPrototypeOf(t)).call(this));
              if (
                ((n._cache = { credentials: {} }),
                (n._configuration = Object.assign({}, g.settings)),
                (n._dynConfiguration = {}),
                (n._dialogs = {}),
                (n._applicants = {}),
                (n._sessions = {}),
                (n._transport = null),
                (n._contact = null),
                (n._status = C.STATUS_INIT),
                (n._error = null),
                (n._transactions = { nist: {}, nict: {}, ist: {}, ict: {} }),
                (n._data = {}),
                (n._closeTimer = null),
                void 0 === e)
              )
                throw new TypeError('Not enough arguments');
              try {
                n._loadConfig(e);
              } catch (e) {
                throw ((n._status = C.STATUS_NOT_READY), (n._error = C.CONFIGURATION_ERROR), e);
              }
              return (n._registrator = new a(n)), n;
            }
            return (
              r(t, [
                {
                  key: 'start',
                  value: function () {
                    y('start()'),
                      this._status === C.STATUS_INIT
                        ? this._transport.connect()
                        : this._status === C.STATUS_USER_CLOSED
                        ? (y('restarting UA'),
                          null !== this._closeTimer && (clearTimeout(this._closeTimer), (this._closeTimer = null), this._transport.disconnect()),
                          (this._status = C.STATUS_INIT),
                          this._transport.connect())
                        : this._status === C.STATUS_READY
                        ? y('UA is in READY status, not restarted')
                        : y('ERROR: connection is down, Auto-Recovery system is trying to reconnect'),
                      (this._dynConfiguration.register = this._configuration.register);
                  },
                },
                {
                  key: 'register',
                  value: function () {
                    y('register()'), (this._dynConfiguration.register = !0), this._registrator.register();
                  },
                },
                {
                  key: 'unregister',
                  value: function (e) {
                    y('unregister()'), (this._dynConfiguration.register = !1), this._registrator.unregister(e);
                  },
                },
                {
                  key: 'registrator',
                  value: function () {
                    return this._registrator;
                  },
                },
                {
                  key: 'isRegistered',
                  value: function () {
                    return this._registrator.registered;
                  },
                },
                {
                  key: 'isConnected',
                  value: function () {
                    return this._transport.isConnected();
                  },
                },
                {
                  key: 'call',
                  value: function (e, t) {
                    y('call()');
                    var n = new o(this);
                    return n.connect(e, t), n;
                  },
                },
                {
                  key: 'sendMessage',
                  value: function (e, t, n) {
                    y('sendMessage()');
                    var r = new l(this);
                    return r.send(e, t, n), r;
                  },
                },
                {
                  key: 'terminateSessions',
                  value: function (e) {
                    y('terminateSessions()');
                    for (var t in this._sessions) this._sessions[t].isEnded() || this._sessions[t].terminate(e);
                  },
                },
                {
                  key: 'stop',
                  value: function () {
                    var e = this;
                    if ((y('stop()'), (this._dynConfiguration = {}), this._status !== C.STATUS_USER_CLOSED)) {
                      this._registrator.close();
                      var t = Object.keys(this._sessions).length;
                      for (var n in this._sessions)
                        if (Object.prototype.hasOwnProperty.call(this._sessions, n)) {
                          y('closing session ' + n);
                          try {
                            this._sessions[n].terminate();
                          } catch (e) {}
                        }
                      for (var r in this._applicants)
                        if (Object.prototype.hasOwnProperty.call(this._applicants, r))
                          try {
                            this._applicants[r].close();
                          } catch (e) {}
                      this._status = C.STATUS_USER_CLOSED;
                      0 ===
                        Object.keys(this._transactions.nict).length +
                          Object.keys(this._transactions.nist).length +
                          Object.keys(this._transactions.ict).length +
                          Object.keys(this._transactions.ist).length && 0 === t
                        ? this._transport.disconnect()
                        : (this._closeTimer = setTimeout(function () {
                            (e._closeTimer = null), e._transport.disconnect();
                          }, 2e3));
                    } else y('UA already closed');
                  },
                },
                {
                  key: 'normalizeTarget',
                  value: function (e) {
                    return d.normalizeTarget(e, this._configuration.hostport_params);
                  },
                },
                {
                  key: 'get',
                  value: function (e) {
                    switch (e) {
                      case 'realm':
                        return this._configuration.realm;
                      case 'ha1':
                        return this._configuration.ha1;
                      default:
                        return void T('get() | cannot get "%s" parameter in runtime', e);
                    }
                  },
                },
                {
                  key: 'set',
                  value: function (e, t) {
                    switch (e) {
                      case 'password':
                        this._configuration.password = String(t);
                        break;
                      case 'realm':
                        this._configuration.realm = String(t);
                        break;
                      case 'ha1':
                        (this._configuration.ha1 = String(t)), (this._configuration.password = null);
                        break;
                      case 'display_name':
                        if (-1 === p.parse('"' + t + '"', 'display_name')) return T('set() | wrong "display_name"'), !1;
                        this._configuration.display_name = t;
                        break;
                      default:
                        return T('set() | cannot set "%s" parameter in runtime', e), !1;
                    }
                    return !0;
                  },
                },
                {
                  key: 'newTransaction',
                  value: function (e) {
                    (this._transactions[e.type][e.id] = e), this.emit('newTransaction', { transaction: e });
                  },
                },
                {
                  key: 'destroyTransaction',
                  value: function (e) {
                    delete this._transactions[e.type][e.id], this.emit('transactionDestroyed', { transaction: e });
                  },
                },
                {
                  key: 'newDialog',
                  value: function (e) {
                    this._dialogs[e.id] = e;
                  },
                },
                {
                  key: 'destroyDialog',
                  value: function (e) {
                    delete this._dialogs[e.id];
                  },
                },
                {
                  key: 'newMessage',
                  value: function (e, t) {
                    (this._applicants[e] = e), this.emit('newMessage', t);
                  },
                },
                {
                  key: 'destroyMessage',
                  value: function (e) {
                    delete this._applicants[e];
                  },
                },
                {
                  key: 'newRTCSession',
                  value: function (e, t) {
                    (this._sessions[e.id] = e), this.emit('newRTCSession', t);
                  },
                },
                {
                  key: 'destroyRTCSession',
                  value: function (e) {
                    delete this._sessions[e.id];
                  },
                },
                {
                  key: 'registered',
                  value: function (e) {
                    this.emit('registered', e);
                  },
                },
                {
                  key: 'unregistered',
                  value: function (e) {
                    this.emit('unregistered', e);
                  },
                },
                {
                  key: 'registrationFailed',
                  value: function (e) {
                    this.emit('registrationFailed', e);
                  },
                },
                {
                  key: 'receiveRequest',
                  value: function (e) {
                    var t = e.method;
                    if (e.ruri.user !== this._configuration.uri.user && e.ruri.user !== this._contact.uri.user)
                      return y('Request-URI does not point to us'), void (e.method !== s.ACK && e.reply_sl(404));
                    if (e.ruri.scheme !== s.SIPS) {
                      if (!u.checkTransaction(this, e)) {
                        if (
                          (t === s.INVITE
                            ? new u.InviteServerTransaction(this, this._transport, e)
                            : t !== s.ACK && t !== s.CANCEL && new u.NonInviteServerTransaction(this, this._transport, e),
                          t === s.OPTIONS)
                        )
                          e.reply(200);
                        else if (t === s.MESSAGE) {
                          if (0 === this.listeners('newMessage').length) return void e.reply(405);
                          new l(this).init_incoming(e);
                        } else if (t === s.INVITE && !e.to_tag && 0 === this.listeners('newRTCSession').length) return void e.reply(405);
                        var n = void 0,
                          r = void 0;
                        if (e.to_tag)
                          (n = this._findDialog(e.call_id, e.from_tag, e.to_tag))
                            ? n.receiveRequest(e)
                            : t === s.NOTIFY
                            ? (r = this._findSession(e))
                              ? r.receiveRequest(e)
                              : (y('received NOTIFY request for a non existent subscription'), e.reply(481, 'Subscription does not exist'))
                            : t !== s.ACK && e.reply(481);
                        else
                          switch (t) {
                            case s.INVITE:
                              if (window.RTCPeerConnection)
                                if (e.hasHeader('replaces')) {
                                  var i = e.replaces;
                                  (n = this._findDialog(i.call_id, i.from_tag, i.to_tag)) ? ((r = n.owner).isEnded() ? e.reply(603) : r.receiveRequest(e)) : e.reply(481);
                                } else (r = new o(this)).init_incoming(e);
                              else T('INVITE received but WebRTC is not supported'), e.reply(488);
                              break;
                            case s.BYE:
                              e.reply(481);
                              break;
                            case s.CANCEL:
                              (r = this._findSession(e)) ? r.receiveRequest(e) : y('received CANCEL request for a non existent session');
                              break;
                            case s.ACK:
                              break;
                            default:
                              e.reply(405);
                          }
                      }
                    } else e.reply_sl(416);
                  },
                },
                {
                  key: '_findSession',
                  value: function (e) {
                    var t = e.call_id,
                      n = e.from_tag,
                      r = e.to_tag,
                      i = t + n,
                      s = this._sessions[i],
                      a = t + r,
                      o = this._sessions[a];
                    return s || o || null;
                  },
                },
                {
                  key: '_findDialog',
                  value: function (e, t, n) {
                    var r = e + t + n,
                      i = this._dialogs[r];
                    return i || ((r = e + n + t), (i = this._dialogs[r]) || null);
                  },
                },
                {
                  key: '_loadConfig',
                  value: function (e) {
                    try {
                      g.load(this._configuration, e);
                    } catch (e) {
                      throw e;
                    }
                    0 === this._configuration.display_name && (this._configuration.display_name = '0'),
                      this._configuration.instance_id || (this._configuration.instance_id = d.newUUID()),
                      (this._configuration.jssip_id = d.createRandomToken(5));
                    var t = this._configuration.uri.clone();
                    (t.user = null), (this._configuration.hostport_params = t.toString().replace(/^sip:/i, ''));
                    try {
                      (this._transport = new c(this._configuration.sockets, {
                        max_interval: this._configuration.connection_recovery_max_interval,
                        min_interval: this._configuration.connection_recovery_min_interval,
                      })),
                        (this._transport.onconnecting = function (e) {
                          this.emit('connecting', e);
                        }.bind(this)),
                        (this._transport.onconnect = function (e) {
                          if (this._status === C.STATUS_USER_CLOSED) return;
                          (this._status = C.STATUS_READY),
                            (this._error = null),
                            this.emit('connected', e),
                            this._dynConfiguration.register && this._registrator.register();
                        }.bind(this)),
                        (this._transport.ondisconnect = function (e) {
                          var t = !0,
                            n = !1,
                            r = void 0;
                          try {
                            for (var i, s = ['nict', 'ict', 'nist', 'ist'][Symbol.iterator](); !(t = (i = s.next()).done); t = !0) {
                              var a = i.value;
                              for (var o in this._transactions[a])
                                Object.prototype.hasOwnProperty.call(this._transactions[a], o) && this._transactions[a][o].onTransportError();
                            }
                          } catch (e) {
                            (n = !0), (r = e);
                          } finally {
                            try {
                              !t && s.return && s.return();
                            } finally {
                              if (n) throw r;
                            }
                          }
                          this.emit('disconnected', e),
                            this._registrator.onTransportClosed(),
                            this._status !== C.STATUS_USER_CLOSED && ((this._status = C.STATUS_NOT_READY), (this._error = C.NETWORK_ERROR));
                        }.bind(this)),
                        (this._transport.ondata = function (e) {
                          var t = e.transport,
                            n = e.message;
                          if (!(n = _.parseMessage(n, this))) return;
                          if (this._status === C.STATUS_USER_CLOSED && n instanceof v.IncomingRequest) return;
                          if (!m(n, this, t)) return;
                          if (n instanceof v.IncomingRequest) (n.transport = t), this.receiveRequest(n);
                          else if (n instanceof v.IncomingResponse) {
                            var r = void 0;
                            switch (n.method) {
                              case s.INVITE:
                                (r = this._transactions.ict[n.via_branch]) && r.receiveResponse(n);
                                break;
                              case s.ACK:
                                break;
                              default:
                                (r = this._transactions.nict[n.via_branch]) && r.receiveResponse(n);
                            }
                          }
                        }.bind(this));
                    } catch (e) {
                      throw (T(e), new h.ConfigurationError('sockets', this._configuration.sockets));
                    }
                    if (
                      (delete this._configuration.sockets,
                      this._configuration.authorization_user || (this._configuration.authorization_user = this._configuration.uri.user),
                      !this._configuration.registrar_server)
                    ) {
                      var n = this._configuration.uri.clone();
                      (n.user = null), n.clearParams(), n.clearHeaders(), (this._configuration.registrar_server = n);
                    }
                    (this._configuration.no_answer_timeout *= 1e3),
                      this._configuration.contact_uri
                        ? (this._configuration.via_host = this._configuration.contact_uri.host)
                        : (this._configuration.contact_uri = new f('sip', d.createRandomToken(8), this._configuration.via_host, null, { transport: 'ws' })),
                      (this._contact = {
                        pub_gruu: null,
                        temp_gruu: null,
                        uri: this._configuration.contact_uri,
                        toString: function () {
                          var e = arguments.length > 0 && void 0 !== arguments[0] ? arguments[0] : {},
                            t = e.anonymous || null,
                            n = e.outbound || null,
                            r = '<';
                          return (
                            (r += t ? this.temp_gruu || 'sip:anonymous@anonymous.invalid;transport=ws' : this.pub_gruu || this.uri.toString()),
                            !n || (t ? this.temp_gruu : this.pub_gruu) || (r += ';ob'),
                            (r += '>')
                          );
                        },
                      });
                    var r = ['password', 'realm', 'ha1', 'display_name', 'register'];
                    for (var i in this._configuration)
                      Object.prototype.hasOwnProperty.call(this._configuration, i) &&
                        (-1 !== r.indexOf(i)
                          ? Object.defineProperty(this._configuration, i, { writable: !0, configurable: !1 })
                          : Object.defineProperty(this._configuration, i, { writable: !1, configurable: !1 }));
                    y('configuration parameters after validation:');
                    for (var a in this._configuration)
                      if (Object.prototype.hasOwnProperty.call(g.settings, a))
                        switch (a) {
                          case 'uri':
                          case 'registrar_server':
                            y('- ' + a + ': ' + this._configuration[a]);
                            break;
                          case 'password':
                          case 'ha1':
                            y('- ' + a + ': NOT SHOWN');
                            break;
                          default:
                            y('- ' + a + ': ' + JSON.stringify(this._configuration[a]));
                        }
                  },
                },
                {
                  key: 'C',
                  get: function () {
                    return C;
                  },
                },
                {
                  key: 'status',
                  get: function () {
                    return this._status;
                  },
                },
                {
                  key: 'contact',
                  get: function () {
                    return this._contact;
                  },
                },
                {
                  key: 'configuration',
                  get: function () {
                    return this._configuration;
                  },
                },
                {
                  key: 'transport',
                  get: function () {
                    return this._transport;
                  },
                },
              ]),
              t
            );
          })();
        },
        {
          './Config': 1,
          './Constants': 2,
          './Exceptions': 6,
          './Grammar': 7,
          './Message': 9,
          './Parser': 11,
          './RTCSession': 12,
          './Registrator': 17,
          './SIPMessage': 19,
          './Transactions': 22,
          './Transport': 23,
          './URI': 25,
          './Utils': 26,
          './sanityCheck': 28,
          debug: 29,
          events: 31,
        },
      ],
      25: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('./Constants'),
            s = e('./Utils'),
            a = e('./Grammar');
          t.exports = (function () {
            r(e, null, [
              {
                key: 'parse',
                value: function (e) {
                  return -1 !== (e = a.parse(e, 'SIP_URI')) ? e : void 0;
                },
              },
            ]);
            function e(t, n, r, s) {
              var a = arguments.length > 4 && void 0 !== arguments[4] ? arguments[4] : {},
                o = arguments.length > 5 && void 0 !== arguments[5] ? arguments[5] : {};
              if (
                ((function (e, t) {
                  if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
                })(this, e),
                !r)
              )
                throw new TypeError('missing or invalid "host" parameter');
              (this._parameters = {}), (this._headers = {}), (this._scheme = t || i.SIP), (this._user = n), (this._host = r), (this._port = s);
              for (var l in a) Object.prototype.hasOwnProperty.call(a, l) && this.setParam(l, a[l]);
              for (var u in o) Object.prototype.hasOwnProperty.call(o, u) && this.setHeader(u, o[u]);
            }
            return (
              r(e, [
                {
                  key: 'setParam',
                  value: function (e, t) {
                    e && (this._parameters[e.toLowerCase()] = void 0 === t || null === t ? null : t.toString());
                  },
                },
                {
                  key: 'getParam',
                  value: function (e) {
                    if (e) return this._parameters[e.toLowerCase()];
                  },
                },
                {
                  key: 'hasParam',
                  value: function (e) {
                    if (e) return !!this._parameters.hasOwnProperty(e.toLowerCase());
                  },
                },
                {
                  key: 'deleteParam',
                  value: function (e) {
                    if (((e = e.toLowerCase()), this._parameters.hasOwnProperty(e))) {
                      var t = this._parameters[e];
                      return delete this._parameters[e], t;
                    }
                  },
                },
                {
                  key: 'clearParams',
                  value: function () {
                    this._parameters = {};
                  },
                },
                {
                  key: 'setHeader',
                  value: function (e, t) {
                    this._headers[s.headerize(e)] = Array.isArray(t) ? t : [t];
                  },
                },
                {
                  key: 'getHeader',
                  value: function (e) {
                    if (e) return this._headers[s.headerize(e)];
                  },
                },
                {
                  key: 'hasHeader',
                  value: function (e) {
                    if (e) return !!this._headers.hasOwnProperty(s.headerize(e));
                  },
                },
                {
                  key: 'deleteHeader',
                  value: function (e) {
                    if (((e = s.headerize(e)), this._headers.hasOwnProperty(e))) {
                      var t = this._headers[e];
                      return delete this._headers[e], t;
                    }
                  },
                },
                {
                  key: 'clearHeaders',
                  value: function () {
                    this._headers = {};
                  },
                },
                {
                  key: 'clone',
                  value: function () {
                    return new e(
                      this._scheme,
                      this._user,
                      this._host,
                      this._port,
                      JSON.parse(JSON.stringify(this._parameters)),
                      JSON.parse(JSON.stringify(this._headers))
                    );
                  },
                },
                {
                  key: 'toString',
                  value: function () {
                    var e = [],
                      t = this._scheme + ':';
                    this._user && (t += s.escapeUser(this._user) + '@'), (t += this._host), (this._port || 0 === this._port) && (t += ':' + this._port);
                    for (var n in this._parameters)
                      Object.prototype.hasOwnProperty.call(this._parameters, n) && ((t += ';' + n), null !== this._parameters[n] && (t += '=' + this._parameters[n]));
                    for (var r in this._headers)
                      if (Object.prototype.hasOwnProperty.call(this._headers, r)) {
                        var i = !0,
                          a = !1,
                          o = void 0;
                        try {
                          for (var l, u = this._headers[r][Symbol.iterator](); !(i = (l = u.next()).done); i = !0) {
                            var c = l.value;
                            e.push(r + '=' + c);
                          }
                        } catch (e) {
                          (a = !0), (o = e);
                        } finally {
                          try {
                            !i && u.return && u.return();
                          } finally {
                            if (a) throw o;
                          }
                        }
                      }
                    return e.length > 0 && (t += '?' + e.join('&')), t;
                  },
                },
                {
                  key: 'toAor',
                  value: function (e) {
                    var t = this._scheme + ':';
                    return this._user && (t += s.escapeUser(this._user) + '@'), (t += this._host), e && (this._port || 0 === this._port) && (t += ':' + this._port), t;
                  },
                },
                {
                  key: 'scheme',
                  get: function () {
                    return this._scheme;
                  },
                  set: function (e) {
                    this._scheme = e.toLowerCase();
                  },
                },
                {
                  key: 'user',
                  get: function () {
                    return this._user;
                  },
                  set: function (e) {
                    this._user = e;
                  },
                },
                {
                  key: 'host',
                  get: function () {
                    return this._host;
                  },
                  set: function (e) {
                    this._host = e.toLowerCase();
                  },
                },
                {
                  key: 'port',
                  get: function () {
                    return this._port;
                  },
                  set: function (e) {
                    this._port = 0 === e ? e : parseInt(e, 10) || null;
                  },
                },
              ]),
              e
            );
          })();
        },
        { './Constants': 2, './Grammar': 7, './Utils': 26 },
      ],
      26: [
        function (e, t, n) {
          'use strict';
          var r =
              'function' == typeof Symbol && 'symbol' == typeof Symbol.iterator
                ? function (e) {
                    return typeof e;
                  }
                : function (e) {
                    return e && 'function' == typeof Symbol && e.constructor === Symbol && e !== Symbol.prototype ? 'symbol' : typeof e;
                  },
            i = e('./Constants'),
            s = e('./URI'),
            a = e('./Grammar');
          n.str_utf8_length = function (e) {
            return unescape(encodeURIComponent(e)).length;
          };
          var o = (n.isFunction = function (e) {
            return void 0 !== e && '[object Function]' === Object.prototype.toString.call(e);
          });
          (n.isString = function (e) {
            return void 0 !== e && '[object String]' === Object.prototype.toString.call(e);
          }),
            (n.isDecimal = function (e) {
              return !isNaN(e) && parseFloat(e) === parseInt(e, 10);
            }),
            (n.isEmpty = function (e) {
              return null === e || '' === e || void 0 === e || (Array.isArray(e) && 0 === e.length) || ('number' == typeof e && isNaN(e));
            }),
            (n.hasMethods = function (e) {
              for (var t = arguments.length, n = Array(t > 1 ? t - 1 : 0), r = 1; r < t; r++) n[r - 1] = arguments[r];
              var i = !0,
                s = !1,
                a = void 0;
              try {
                for (var l, u = n[Symbol.iterator](); !(i = (l = u.next()).done); i = !0) {
                  var c = l.value;
                  if (o(e[c])) return !1;
                }
              } catch (e) {
                (s = !0), (a = e);
              } finally {
                try {
                  !i && u.return && u.return();
                } finally {
                  if (s) throw a;
                }
              }
              return !0;
            });
          var l = (n.createRandomToken = function (e) {
            var t = arguments.length > 1 && void 0 !== arguments[1] ? arguments[1] : 32,
              n = void 0,
              r = '';
            for (n = 0; n < e; n++) r += ((Math.random() * t) | 0).toString(t);
            return r;
          });
          (n.newTag = function () {
            return l(10);
          }),
            (n.newUUID = function () {
              return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (e) {
                var t = (16 * Math.random()) | 0;
                return ('x' === e ? t : (3 & t) | 8).toString(16);
              });
            }),
            (n.hostType = function (e) {
              if (e) return -1 !== (e = a.parse(e, 'host')) ? e.host_type : void 0;
            });
          var u = (n.escapeUser = function (e) {
            return encodeURIComponent(decodeURIComponent(e)).replace(/%3A/gi, ':').replace(/%2B/gi, '+').replace(/%3F/gi, '?').replace(/%2F/gi, '/');
          });
          (n.normalizeTarget = function (e, t) {
            if (e) {
              if (e instanceof s) return e;
              if ('string' == typeof e) {
                var n = e.split('@'),
                  r = void 0,
                  a = void 0;
                switch (n.length) {
                  case 1:
                    if (!t) return;
                    (r = e), (a = t);
                    break;
                  case 2:
                    (r = n[0]), (a = n[1]);
                    break;
                  default:
                    (r = n.slice(0, n.length - 1).join('@')), (a = n[n.length - 1]);
                }
                (r = r.replace(/^(sips?|tel):/i, '')), /^[-.()]*\+?[0-9\-.()]+$/.test(r) && (r = r.replace(/[-.()]/g, '')), (e = i.SIP + ':' + u(r) + '@' + a);
                var o = void 0;
                return (o = s.parse(e)) ? o : void 0;
              }
            } else;
          }),
            (n.headerize = function (e) {
              var t = { 'Call-Id': 'Call-ID', Cseq: 'CSeq', 'Www-Authenticate': 'WWW-Authenticate' },
                n = e.toLowerCase().replace(/_/g, '-').split('-'),
                r = '',
                i = n.length,
                s = void 0;
              for (s = 0; s < i; s++) 0 !== s && (r += '-'), (r += n[s].charAt(0).toUpperCase() + n[s].substring(1));
              return t[r] && (r = t[r]), r;
            }),
            (n.sipErrorCause = function (e) {
              for (var t in i.SIP_ERROR_CAUSES) if (-1 !== i.SIP_ERROR_CAUSES[t].indexOf(e)) return i.causes[t];
              return i.causes.SIP_FAILURE_CODE;
            }),
            (n.getRandomTestNetIP = function () {
              return '192.0.2.' + ((e = 1), (t = 254), Math.floor(Math.random() * (t - e + 1) + e));
              var e, t;
            }),
            (n.calculateMD5 = function (e) {
              function t(e, t) {
                return (e << t) | (e >>> (32 - t));
              }
              function n(e, t) {
                var n = 2147483648 & e,
                  r = 2147483648 & t,
                  i = 1073741824 & e,
                  s = 1073741824 & t,
                  a = (1073741823 & e) + (1073741823 & t);
                return i & s ? 2147483648 ^ a ^ n ^ r : i | s ? (1073741824 & a ? 3221225472 ^ a ^ n ^ r : 1073741824 ^ a ^ n ^ r) : a ^ n ^ r;
              }
              function r(e, r, i, s, a, o, l) {
                var u, c, d;
                return n(t((e = n(e, n(n(((u = r), (c = i), (d = s), (u & c) | (~u & d)), a), l))), o), r);
              }
              function i(e, r, i, s, a, o, l) {
                var u, c, d;
                return n(t((e = n(e, n(n(((u = r), (c = i), (d = s), (u & d) | (c & ~d)), a), l))), o), r);
              }
              function s(e, r, i, s, a, o, l) {
                var u, c, d;
                return n(t((e = n(e, n(n(((u = r), (c = i), (d = s), u ^ c ^ d), a), l))), o), r);
              }
              function a(e, r, i, s, a, o, l) {
                var u, c, d;
                return n(t((e = n(e, n(n(((u = r), (c = i), (d = s), c ^ (u | ~d)), a), l))), o), r);
              }
              function o(e) {
                var t = '',
                  n = '',
                  r = void 0;
                for (r = 0; r <= 3; r++) t += (n = '0' + ((e >>> (8 * r)) & 255).toString(16)).substr(n.length - 2, 2);
                return t;
              }
              var l = [],
                u = void 0,
                c = void 0,
                d = void 0,
                h = void 0,
                f = void 0,
                p = void 0,
                _ = void 0,
                v = void 0,
                m = void 0;
              for (
                l = (function (e) {
                  for (var t = void 0, n = e.length, r = n + 8, i = 16 * ((r - (r % 64)) / 64 + 1), s = new Array(i - 1), a = 0, o = 0; o < n; )
                    (a = (o % 4) * 8), (s[(t = (o - (o % 4)) / 4)] = s[t] | (e.charCodeAt(o) << a)), o++;
                  return (a = (o % 4) * 8), (s[(t = (o - (o % 4)) / 4)] = s[t] | (128 << a)), (s[i - 2] = n << 3), (s[i - 1] = n >>> 29), s;
                })(
                  (e = (function (e) {
                    e = e.replace(/\r\n/g, '\n');
                    for (var t = '', n = 0; n < e.length; n++) {
                      var r = e.charCodeAt(n);
                      r < 128
                        ? (t += String.fromCharCode(r))
                        : r > 127 && r < 2048
                        ? ((t += String.fromCharCode((r >> 6) | 192)), (t += String.fromCharCode((63 & r) | 128)))
                        : ((t += String.fromCharCode((r >> 12) | 224)), (t += String.fromCharCode(((r >> 6) & 63) | 128)), (t += String.fromCharCode((63 & r) | 128)));
                    }
                    return t;
                  })(e))
                ),
                  p = 1732584193,
                  _ = 4023233417,
                  v = 2562383102,
                  m = 271733878,
                  u = 0;
                u < l.length;
                u += 16
              )
                (c = p),
                  (d = _),
                  (h = v),
                  (f = m),
                  (_ = a(
                    (_ = a(
                      (_ = a(
                        (_ = a(
                          (_ = s(
                            (_ = s(
                              (_ = s(
                                (_ = s(
                                  (_ = i(
                                    (_ = i(
                                      (_ = i(
                                        (_ = i(
                                          (_ = r(
                                            (_ = r(
                                              (_ = r(
                                                (_ = r(
                                                  _,
                                                  (v = r(
                                                    v,
                                                    (m = r(m, (p = r(p, _, v, m, l[u + 0], 7, 3614090360)), _, v, l[u + 1], 12, 3905402710)),
                                                    p,
                                                    _,
                                                    l[u + 2],
                                                    17,
                                                    606105819
                                                  )),
                                                  m,
                                                  p,
                                                  l[u + 3],
                                                  22,
                                                  3250441966
                                                )),
                                                (v = r(
                                                  v,
                                                  (m = r(m, (p = r(p, _, v, m, l[u + 4], 7, 4118548399)), _, v, l[u + 5], 12, 1200080426)),
                                                  p,
                                                  _,
                                                  l[u + 6],
                                                  17,
                                                  2821735955
                                                )),
                                                m,
                                                p,
                                                l[u + 7],
                                                22,
                                                4249261313
                                              )),
                                              (v = r(
                                                v,
                                                (m = r(m, (p = r(p, _, v, m, l[u + 8], 7, 1770035416)), _, v, l[u + 9], 12, 2336552879)),
                                                p,
                                                _,
                                                l[u + 10],
                                                17,
                                                4294925233
                                              )),
                                              m,
                                              p,
                                              l[u + 11],
                                              22,
                                              2304563134
                                            )),
                                            (v = r(
                                              v,
                                              (m = r(m, (p = r(p, _, v, m, l[u + 12], 7, 1804603682)), _, v, l[u + 13], 12, 4254626195)),
                                              p,
                                              _,
                                              l[u + 14],
                                              17,
                                              2792965006
                                            )),
                                            m,
                                            p,
                                            l[u + 15],
                                            22,
                                            1236535329
                                          )),
                                          (v = i(
                                            v,
                                            (m = i(m, (p = i(p, _, v, m, l[u + 1], 5, 4129170786)), _, v, l[u + 6], 9, 3225465664)),
                                            p,
                                            _,
                                            l[u + 11],
                                            14,
                                            643717713
                                          )),
                                          m,
                                          p,
                                          l[u + 0],
                                          20,
                                          3921069994
                                        )),
                                        (v = i(
                                          v,
                                          (m = i(m, (p = i(p, _, v, m, l[u + 5], 5, 3593408605)), _, v, l[u + 10], 9, 38016083)),
                                          p,
                                          _,
                                          l[u + 15],
                                          14,
                                          3634488961
                                        )),
                                        m,
                                        p,
                                        l[u + 4],
                                        20,
                                        3889429448
                                      )),
                                      (v = i(v, (m = i(m, (p = i(p, _, v, m, l[u + 9], 5, 568446438)), _, v, l[u + 14], 9, 3275163606)), p, _, l[u + 3], 14, 4107603335)),
                                      m,
                                      p,
                                      l[u + 8],
                                      20,
                                      1163531501
                                    )),
                                    (v = i(v, (m = i(m, (p = i(p, _, v, m, l[u + 13], 5, 2850285829)), _, v, l[u + 2], 9, 4243563512)), p, _, l[u + 7], 14, 1735328473)),
                                    m,
                                    p,
                                    l[u + 12],
                                    20,
                                    2368359562
                                  )),
                                  (v = s(v, (m = s(m, (p = s(p, _, v, m, l[u + 5], 4, 4294588738)), _, v, l[u + 8], 11, 2272392833)), p, _, l[u + 11], 16, 1839030562)),
                                  m,
                                  p,
                                  l[u + 14],
                                  23,
                                  4259657740
                                )),
                                (v = s(v, (m = s(m, (p = s(p, _, v, m, l[u + 1], 4, 2763975236)), _, v, l[u + 4], 11, 1272893353)), p, _, l[u + 7], 16, 4139469664)),
                                m,
                                p,
                                l[u + 10],
                                23,
                                3200236656
                              )),
                              (v = s(v, (m = s(m, (p = s(p, _, v, m, l[u + 13], 4, 681279174)), _, v, l[u + 0], 11, 3936430074)), p, _, l[u + 3], 16, 3572445317)),
                              m,
                              p,
                              l[u + 6],
                              23,
                              76029189
                            )),
                            (v = s(v, (m = s(m, (p = s(p, _, v, m, l[u + 9], 4, 3654602809)), _, v, l[u + 12], 11, 3873151461)), p, _, l[u + 15], 16, 530742520)),
                            m,
                            p,
                            l[u + 2],
                            23,
                            3299628645
                          )),
                          (v = a(v, (m = a(m, (p = a(p, _, v, m, l[u + 0], 6, 4096336452)), _, v, l[u + 7], 10, 1126891415)), p, _, l[u + 14], 15, 2878612391)),
                          m,
                          p,
                          l[u + 5],
                          21,
                          4237533241
                        )),
                        (v = a(v, (m = a(m, (p = a(p, _, v, m, l[u + 12], 6, 1700485571)), _, v, l[u + 3], 10, 2399980690)), p, _, l[u + 10], 15, 4293915773)),
                        m,
                        p,
                        l[u + 1],
                        21,
                        2240044497
                      )),
                      (v = a(v, (m = a(m, (p = a(p, _, v, m, l[u + 8], 6, 1873313359)), _, v, l[u + 15], 10, 4264355552)), p, _, l[u + 6], 15, 2734768916)),
                      m,
                      p,
                      l[u + 13],
                      21,
                      1309151649
                    )),
                    (v = a(v, (m = a(m, (p = a(p, _, v, m, l[u + 4], 6, 4149444226)), _, v, l[u + 11], 10, 3174756917)), p, _, l[u + 2], 15, 718787259)),
                    m,
                    p,
                    l[u + 9],
                    21,
                    3951481745
                  )),
                  (p = n(p, c)),
                  (_ = n(_, d)),
                  (v = n(v, h)),
                  (m = n(m, f));
              return (o(p) + o(_) + o(v) + o(m)).toLowerCase();
            }),
            (n.closeMediaStream = function (e) {
              if (e)
                try {
                  var t = void 0;
                  if (e.getTracks) {
                    t = e.getTracks();
                    var n = !0,
                      i = !1,
                      s = void 0;
                    try {
                      for (var a, o = t[Symbol.iterator](); !(n = (a = o.next()).done); n = !0) {
                        a.value.stop();
                      }
                    } catch (e) {
                      (i = !0), (s = e);
                    } finally {
                      try {
                        !n && o.return && o.return();
                      } finally {
                        if (i) throw s;
                      }
                    }
                  } else {
                    t = e.getAudioTracks();
                    var l = !0,
                      u = !1,
                      c = void 0;
                    try {
                      for (var d, h = t[Symbol.iterator](); !(l = (d = h.next()).done); l = !0) {
                        d.value.stop();
                      }
                    } catch (e) {
                      (u = !0), (c = e);
                    } finally {
                      try {
                        !l && h.return && h.return();
                      } finally {
                        if (u) throw c;
                      }
                    }
                    t = e.getVideoTracks();
                    var f = !0,
                      p = !1,
                      _ = void 0;
                    try {
                      for (var v, m = t[Symbol.iterator](); !(f = (v = m.next()).done); f = !0) {
                        v.value.stop();
                      }
                    } catch (e) {
                      (p = !0), (_ = e);
                    } finally {
                      try {
                        !f && m.return && m.return();
                      } finally {
                        if (p) throw _;
                      }
                    }
                  }
                } catch (t) {
                  ('function' != typeof e.stop && 'object' !== r(e.stop)) || e.stop();
                }
            }),
            (n.cloneArray = function (e) {
              return (e && e.slice()) || [];
            });
        },
        { './Constants': 2, './Grammar': 7, './URI': 25 },
      ],
      27: [
        function (e, t, n) {
          'use strict';
          var r = (function () {
            function e(e, t) {
              for (var n = 0; n < t.length; n++) {
                var r = t[n];
                (r.enumerable = r.enumerable || !1), (r.configurable = !0), 'value' in r && (r.writable = !0), Object.defineProperty(e, r.key, r);
              }
            }
            return function (t, n, r) {
              return n && e(t.prototype, n), r && e(t, r), t;
            };
          })();
          var i = e('./Grammar'),
            s = e('debug')('JsSIP:WebSocketInterface'),
            a = e('debug')('JsSIP:ERROR:WebSocketInterface');
          (a.log = console.warn.bind(console)),
            (t.exports = (function () {
              function e(t) {
                !(function (e, t) {
                  if (!(e instanceof t)) throw new TypeError('Cannot call a class as a function');
                })(this, e),
                  s('new() [url:"%s"]', t),
                  (this._url = t),
                  (this._sip_uri = null),
                  (this._via_transport = null),
                  (this._ws = null);
                var n = i.parse(t, 'absoluteURI');
                if (-1 === n) throw (a('invalid WebSocket URI: ' + t), new TypeError('Invalid argument: ' + t));
                if ('wss' !== n.scheme && 'ws' !== n.scheme) throw (a('invalid WebSocket URI scheme: ' + n.scheme), new TypeError('Invalid argument: ' + t));
                (this._sip_uri = 'sip:' + n.host + (n.port ? ':' + n.port : '') + ';transport=ws'), (this._via_transport = n.scheme.toUpperCase());
              }
              return (
                r(e, [
                  {
                    key: 'connect',
                    value: function () {
                      if ((s('connect()'), this.isConnected())) s('WebSocket ' + this._url + ' is already connected');
                      else if (this.isConnecting()) s('WebSocket ' + this._url + ' is connecting');
                      else {
                        this._ws && this.disconnect(), s('connecting to WebSocket ' + this._url);
                        try {
                          (this._ws = new WebSocket(this._url, 'sip')),
                            (this._ws.binaryType = 'arraybuffer'),
                            (this._ws.onopen = this._onOpen.bind(this)),
                            (this._ws.onclose = this._onClose.bind(this)),
                            (this._ws.onmessage = this._onMessage.bind(this)),
                            (this._ws.onerror = this._onError.bind(this));
                        } catch (e) {
                          this._onError(e);
                        }
                      }
                    },
                  },
                  {
                    key: 'disconnect',
                    value: function () {
                      s('disconnect()'),
                        this._ws &&
                          ((this._ws.onopen = function () {}),
                          (this._ws.onclose = function () {}),
                          (this._ws.onmessage = function () {}),
                          (this._ws.onerror = function () {}),
                          this._ws.close(),
                          (this._ws = null));
                    },
                  },
                  {
                    key: 'send',
                    value: function (e) {
                      return s('send()'), this.isConnected() ? (this._ws.send(e), !0) : (a('unable to send message, WebSocket is not open'), !1);
                    },
                  },
                  {
                    key: 'isConnected',
                    value: function () {
                      return this._ws && this._ws.readyState === this._ws.OPEN;
                    },
                  },
                  {
                    key: 'isConnecting',
                    value: function () {
                      return this._ws && this._ws.readyState === this._ws.CONNECTING;
                    },
                  },
                  {
                    key: '_onOpen',
                    value: function () {
                      s('WebSocket ' + this._url + ' connected'), this.onconnect();
                    },
                  },
                  {
                    key: '_onClose',
                    value: function (e) {
                      var t = e.wasClean,
                        n = e.code,
                        r = e.reason;
                      s('WebSocket ' + this._url + ' closed'), !1 === t && s('WebSocket abrupt disconnection');
                      var i = { socket: this, error: !t, code: n, reason: r };
                      this.ondisconnect(i);
                    },
                  },
                  {
                    key: '_onMessage',
                    value: function (e) {
                      var t = e.data;
                      s('received WebSocket message'), this.ondata(t);
                    },
                  },
                  {
                    key: '_onError',
                    value: function (e) {
                      a('WebSocket ' + this._url + ' error: ' + e);
                    },
                  },
                  {
                    key: 'via_transport',
                    get: function () {
                      return this._via_transport;
                    },
                    set: function (e) {
                      this._via_transport = e.toUpperCase();
                    },
                  },
                  {
                    key: 'sip_uri',
                    get: function () {
                      return this._sip_uri;
                    },
                  },
                  {
                    key: 'url',
                    get: function () {
                      return this._url;
                    },
                  },
                ]),
                e
              );
            })());
        },
        { './Grammar': 7, debug: 29 },
      ],
      28: [
        function (e, t, n) {
          'use strict';
          var r = e('./Constants'),
            i = e('./SIPMessage'),
            s = e('./Utils'),
            a = e('debug')('JsSIP:sanityCheck'),
            o = [
              function () {
                var e = !0,
                  t = !1,
                  n = void 0;
                try {
                  for (var r, i = ['from', 'to', 'call_id', 'cseq', 'via'][Symbol.iterator](); !(e = (r = i.next()).done); e = !0) {
                    var s = r.value;
                    if (!c.hasHeader(s)) return a('missing mandatory header field : ' + s + ', dropping the response'), !1;
                  }
                } catch (e) {
                  (t = !0), (n = e);
                } finally {
                  try {
                    !e && i.return && i.return();
                  } finally {
                    if (t) throw n;
                  }
                }
              },
            ],
            l = [
              function () {
                if ('sip' !== c.s('to').uri.scheme) return f(416), !1;
              },
              function () {
                if (!c.to_tag && c.call_id.substr(0, 5) === d.configuration.jssip_id) return f(482), !1;
              },
              function () {
                var e = s.str_utf8_length(c.body),
                  t = c.getHeader('content-length');
                if (e < t) return f(400), !1;
              },
              function () {
                var e = c.from_tag,
                  t = c.call_id,
                  n = c.cseq,
                  i = void 0;
                if (c.to_tag) return;
                if (c.method === r.INVITE) {
                  if (d._transactions.ist[c.via_branch]) return !1;
                  for (var s in d._transactions.ist)
                    if (
                      Object.prototype.hasOwnProperty.call(d._transactions.ist, s) &&
                      (i = d._transactions.ist[s]).request.from_tag === e &&
                      i.request.call_id === t &&
                      i.request.cseq === n
                    )
                      return f(482), !1;
                } else {
                  if (d._transactions.nist[c.via_branch]) return !1;
                  for (var a in d._transactions.nist)
                    if (
                      Object.prototype.hasOwnProperty.call(d._transactions.nist, a) &&
                      (i = d._transactions.nist[a]).request.from_tag === e &&
                      i.request.call_id === t &&
                      i.request.cseq === n
                    )
                      return f(482), !1;
                }
              },
            ],
            u = [
              function () {
                if (c.getHeaders('via').length > 1) return a('more than one Via header field present in the response, dropping the response'), !1;
              },
              function () {
                var e = s.str_utf8_length(c.body),
                  t = c.getHeader('content-length');
                if (e < t) return a('message body length is lower than the value in Content-Length header field, dropping the response'), !1;
              },
            ],
            c = void 0,
            d = void 0,
            h = void 0;
          t.exports = function (e, t, n) {
            (c = e), (d = t), (h = n);
            var r = !0,
              s = !1,
              a = void 0;
            try {
              for (var f, p = o[Symbol.iterator](); !(r = (f = p.next()).done); r = !0) {
                if (!1 === (0, f.value)()) return !1;
              }
            } catch (e) {
              (s = !0), (a = e);
            } finally {
              try {
                !r && p.return && p.return();
              } finally {
                if (s) throw a;
              }
            }
            if (c instanceof i.IncomingRequest) {
              var _ = !0,
                v = !1,
                m = void 0;
              try {
                for (var g, y = l[Symbol.iterator](); !(_ = (g = y.next()).done); _ = !0) {
                  if (!1 === (0, g.value)()) return !1;
                }
              } catch (e) {
                (v = !0), (m = e);
              } finally {
                try {
                  !_ && y.return && y.return();
                } finally {
                  if (v) throw m;
                }
              }
            } else if (c instanceof i.IncomingResponse) {
              var T = !0,
                C = !1,
                S = void 0;
              try {
                for (var E, b = u[Symbol.iterator](); !(T = (E = b.next()).done); T = !0) {
                  if (!1 === (0, E.value)()) return !1;
                }
              } catch (e) {
                (C = !0), (S = e);
              } finally {
                try {
                  !T && b.return && b.return();
                } finally {
                  if (C) throw S;
                }
              }
            }
            return !0;
          };
          function f(e) {
            var t = c.getHeaders('via'),
              n = void 0,
              i = 'SIP/2.0 ' + e + ' ' + r.REASON_PHRASE[e] + '\r\n',
              a = !0,
              o = !1,
              l = void 0;
            try {
              for (var u, d = t[Symbol.iterator](); !(a = (u = d.next()).done); a = !0) {
                i += 'Via: ' + u.value + '\r\n';
              }
            } catch (e) {
              (o = !0), (l = e);
            } finally {
              try {
                !a && d.return && d.return();
              } finally {
                if (o) throw l;
              }
            }
            (n = c.getHeader('To')),
              c.to_tag || (n += ';tag=' + s.newTag()),
              (i += 'To: ' + n + '\r\n'),
              (i += 'From: ' + c.getHeader('From') + '\r\n'),
              (i += 'Call-ID: ' + c.call_id + '\r\n'),
              (i += 'CSeq: ' + c.cseq + ' ' + c.method + '\r\n'),
              (i += '\r\n'),
              h.send(i);
          }
        },
        { './Constants': 2, './SIPMessage': 19, './Utils': 26, debug: 29 },
      ],
      29: [
        function (e, t, n) {
          (function (r) {
            ((n = t.exports = e('./debug')).log = function () {
              return 'object' == typeof console && console.log && Function.prototype.apply.call(console.log, console, arguments);
            }),
              (n.formatArgs = function (e) {
                var t = this.useColors;
                if (((e[0] = (t ? '%c' : '') + this.namespace + (t ? ' %c' : ' ') + e[0] + (t ? '%c ' : ' ') + '+' + n.humanize(this.diff)), !t)) return;
                var r = 'color: ' + this.color;
                e.splice(1, 0, r, 'color: inherit');
                var i = 0,
                  s = 0;
                e[0].replace(/%[a-zA-Z%]/g, function (e) {
                  '%%' !== e && '%c' === e && (s = ++i);
                }),
                  e.splice(s, 0, r);
              }),
              (n.save = function (e) {
                try {
                  null == e ? n.storage.removeItem('debug') : (n.storage.debug = e);
                } catch (e) {}
              }),
              (n.load = i),
              (n.useColors = function () {
                if ('undefined' != typeof window && window.process && 'renderer' === window.process.type) return !0;
                if ('undefined' != typeof navigator && navigator.userAgent && navigator.userAgent.toLowerCase().match(/(edge|trident)\/(\d+)/)) return !1;
                return (
                  ('undefined' != typeof document && document.documentElement && document.documentElement.style && document.documentElement.style.WebkitAppearance) ||
                  ('undefined' != typeof window && window.console && (window.console.firebug || (window.console.exception && window.console.table))) ||
                  ('undefined' != typeof navigator &&
                    navigator.userAgent &&
                    navigator.userAgent.toLowerCase().match(/firefox\/(\d+)/) &&
                    parseInt(RegExp.$1, 10) >= 31) ||
                  ('undefined' != typeof navigator && navigator.userAgent && navigator.userAgent.toLowerCase().match(/applewebkit\/(\d+)/))
                );
              }),
              (n.storage =
                'undefined' != typeof chrome && void 0 !== chrome.storage
                  ? chrome.storage.local
                  : (function () {
                      try {
                        return window.localStorage;
                      } catch (e) {}
                    })()),
              (n.colors = [
                '#0000CC',
                '#0000FF',
                '#0033CC',
                '#0033FF',
                '#0066CC',
                '#0066FF',
                '#0099CC',
                '#0099FF',
                '#00CC00',
                '#00CC33',
                '#00CC66',
                '#00CC99',
                '#00CCCC',
                '#00CCFF',
                '#3300CC',
                '#3300FF',
                '#3333CC',
                '#3333FF',
                '#3366CC',
                '#3366FF',
                '#3399CC',
                '#3399FF',
                '#33CC00',
                '#33CC33',
                '#33CC66',
                '#33CC99',
                '#33CCCC',
                '#33CCFF',
                '#6600CC',
                '#6600FF',
                '#6633CC',
                '#6633FF',
                '#66CC00',
                '#66CC33',
                '#9900CC',
                '#9900FF',
                '#9933CC',
                '#9933FF',
                '#99CC00',
                '#99CC33',
                '#CC0000',
                '#CC0033',
                '#CC0066',
                '#CC0099',
                '#CC00CC',
                '#CC00FF',
                '#CC3300',
                '#CC3333',
                '#CC3366',
                '#CC3399',
                '#CC33CC',
                '#CC33FF',
                '#CC6600',
                '#CC6633',
                '#CC9900',
                '#CC9933',
                '#CCCC00',
                '#CCCC33',
                '#FF0000',
                '#FF0033',
                '#FF0066',
                '#FF0099',
                '#FF00CC',
                '#FF00FF',
                '#FF3300',
                '#FF3333',
                '#FF3366',
                '#FF3399',
                '#FF33CC',
                '#FF33FF',
                '#FF6600',
                '#FF6633',
                '#FF9900',
                '#FF9933',
                '#FFCC00',
                '#FFCC33',
              ]);
            n.formatters.j = function (e) {
              try {
                return JSON.stringify(e);
              } catch (e) {
                return '[UnexpectedJSONParseError]: ' + e.message;
              }
            };
            function i() {
              var e;
              try {
                e = n.storage.debug;
              } catch (e) {}
              return !e && void 0 !== r && 'env' in r && (e = r.env.DEBUG), e;
            }
            n.enable(i());
          }.call(this, e('_process')));
        },
        { './debug': 30, _process: 33 },
      ],
      30: [
        function (e, t, n) {
          ((n = t.exports = r.debug = r.default = r).coerce = function (e) {
            return e instanceof Error ? e.stack || e.message : e;
          }),
            (n.disable = function () {
              n.enable('');
            }),
            (n.enable = function (e) {
              n.save(e), (n.names = []), (n.skips = []);
              var t,
                r = ('string' == typeof e ? e : '').split(/[\s,]+/),
                i = r.length;
              for (t = 0; t < i; t++)
                r[t] && ('-' === (e = r[t].replace(/\*/g, '.*?'))[0] ? n.skips.push(new RegExp('^' + e.substr(1) + '$')) : n.names.push(new RegExp('^' + e + '$')));
              for (t = 0; t < n.instances.length; t++) {
                var s = n.instances[t];
                s.enabled = n.enabled(s.namespace);
              }
            }),
            (n.enabled = function (e) {
              if ('*' === e[e.length - 1]) return !0;
              var t, r;
              for (t = 0, r = n.skips.length; t < r; t++) if (n.skips[t].test(e)) return !1;
              for (t = 0, r = n.names.length; t < r; t++) if (n.names[t].test(e)) return !0;
              return !1;
            }),
            (n.humanize = e('ms')),
            (n.instances = []),
            (n.names = []),
            (n.skips = []),
            (n.formatters = {});
          function r(e) {
            var t;
            function r() {
              if (r.enabled) {
                var e = r,
                  i = +new Date(),
                  s = i - (t || i);
                (e.diff = s), (e.prev = t), (e.curr = i), (t = i);
                for (var a = new Array(arguments.length), o = 0; o < a.length; o++) a[o] = arguments[o];
                (a[0] = n.coerce(a[0])), 'string' != typeof a[0] && a.unshift('%O');
                var l = 0;
                (a[0] = a[0].replace(/%([a-zA-Z%])/g, function (t, r) {
                  if ('%%' === t) return t;
                  l++;
                  var i = n.formatters[r];
                  if ('function' == typeof i) {
                    var s = a[l];
                    (t = i.call(e, s)), a.splice(l, 1), l--;
                  }
                  return t;
                })),
                  n.formatArgs.call(e, a);
                (r.log || n.log || console.log.bind(console)).apply(e, a);
              }
            }
            return (
              (r.namespace = e),
              (r.enabled = n.enabled(e)),
              (r.useColors = n.useColors()),
              (r.color = (function (e) {
                var t,
                  r = 0;
                for (t in e) (r = (r << 5) - r + e.charCodeAt(t)), (r |= 0);
                return n.colors[Math.abs(r) % n.colors.length];
              })(e)),
              (r.destroy = i),
              'function' == typeof n.init && n.init(r),
              n.instances.push(r),
              r
            );
          }
          function i() {
            var e = n.instances.indexOf(this);
            return -1 !== e && (n.instances.splice(e, 1), !0);
          }
        },
        { ms: 32 },
      ],
      31: [
        function (e, t, n) {
          function r() {
            (this._events = this._events || {}), (this._maxListeners = this._maxListeners || void 0);
          }
          (t.exports = r),
            (r.EventEmitter = r),
            (r.prototype._events = void 0),
            (r.prototype._maxListeners = void 0),
            (r.defaultMaxListeners = 10),
            (r.prototype.setMaxListeners = function (e) {
              if ('number' != typeof e || e < 0 || isNaN(e)) throw TypeError('n must be a positive number');
              return (this._maxListeners = e), this;
            }),
            (r.prototype.emit = function (e) {
              var t, n, r, o, l, u;
              if ((this._events || (this._events = {}), 'error' === e && (!this._events.error || (s(this._events.error) && !this._events.error.length)))) {
                if ((t = arguments[1]) instanceof Error) throw t;
                var c = new Error('Uncaught, unspecified "error" event. (' + t + ')');
                throw ((c.context = t), c);
              }
              if (a((n = this._events[e]))) return !1;
              if (i(n))
                switch (arguments.length) {
                  case 1:
                    n.call(this);
                    break;
                  case 2:
                    n.call(this, arguments[1]);
                    break;
                  case 3:
                    n.call(this, arguments[1], arguments[2]);
                    break;
                  default:
                    (o = Array.prototype.slice.call(arguments, 1)), n.apply(this, o);
                }
              else if (s(n)) for (o = Array.prototype.slice.call(arguments, 1), r = (u = n.slice()).length, l = 0; l < r; l++) u[l].apply(this, o);
              return !0;
            }),
            (r.prototype.addListener = function (e, t) {
              var n;
              if (!i(t)) throw TypeError('listener must be a function');
              return (
                this._events || (this._events = {}),
                this._events.newListener && this.emit('newListener', e, i(t.listener) ? t.listener : t),
                this._events[e] ? (s(this._events[e]) ? this._events[e].push(t) : (this._events[e] = [this._events[e], t])) : (this._events[e] = t),
                s(this._events[e]) &&
                  !this._events[e].warned &&
                  (n = a(this._maxListeners) ? r.defaultMaxListeners : this._maxListeners) &&
                  n > 0 &&
                  this._events[e].length > n &&
                  ((this._events[e].warned = !0),
                  console.error(
                    '(node) warning: possible EventEmitter memory leak detected. %d listeners added. Use emitter.setMaxListeners() to increase limit.',
                    this._events[e].length
                  ),
                  'function' == typeof console.trace && console.trace()),
                this
              );
            }),
            (r.prototype.on = r.prototype.addListener),
            (r.prototype.once = function (e, t) {
              if (!i(t)) throw TypeError('listener must be a function');
              var n = !1;
              function r() {
                this.removeListener(e, r), n || ((n = !0), t.apply(this, arguments));
              }
              return (r.listener = t), this.on(e, r), this;
            }),
            (r.prototype.removeListener = function (e, t) {
              var n, r, a, o;
              if (!i(t)) throw TypeError('listener must be a function');
              if (!this._events || !this._events[e]) return this;
              if (((a = (n = this._events[e]).length), (r = -1), n === t || (i(n.listener) && n.listener === t)))
                delete this._events[e], this._events.removeListener && this.emit('removeListener', e, t);
              else if (s(n)) {
                for (o = a; o-- > 0; )
                  if (n[o] === t || (n[o].listener && n[o].listener === t)) {
                    r = o;
                    break;
                  }
                if (r < 0) return this;
                1 === n.length ? ((n.length = 0), delete this._events[e]) : n.splice(r, 1), this._events.removeListener && this.emit('removeListener', e, t);
              }
              return this;
            }),
            (r.prototype.removeAllListeners = function (e) {
              var t, n;
              if (!this._events) return this;
              if (!this._events.removeListener) return 0 === arguments.length ? (this._events = {}) : this._events[e] && delete this._events[e], this;
              if (0 === arguments.length) {
                for (t in this._events) 'removeListener' !== t && this.removeAllListeners(t);
                return this.removeAllListeners('removeListener'), (this._events = {}), this;
              }
              if (i((n = this._events[e]))) this.removeListener(e, n);
              else if (n) for (; n.length; ) this.removeListener(e, n[n.length - 1]);
              return delete this._events[e], this;
            }),
            (r.prototype.listeners = function (e) {
              return this._events && this._events[e] ? (i(this._events[e]) ? [this._events[e]] : this._events[e].slice()) : [];
            }),
            (r.prototype.listenerCount = function (e) {
              if (this._events) {
                var t = this._events[e];
                if (i(t)) return 1;
                if (t) return t.length;
              }
              return 0;
            }),
            (r.listenerCount = function (e, t) {
              return e.listenerCount(t);
            });
          function i(e) {
            return 'function' == typeof e;
          }
          function s(e) {
            return 'object' == typeof e && null !== e;
          }
          function a(e) {
            return void 0 === e;
          }
        },
        {},
      ],
      32: [
        function (e, t, n) {
          var r = 1e3,
            i = 60 * r,
            s = 60 * i,
            a = 24 * s,
            o = 365.25 * a;
          t.exports = function (e, t) {
            t = t || {};
            var n = typeof e;
            if ('string' === n && e.length > 0)
              return (function (e) {
                if ((e = String(e)).length > 100) return;
                var t = /^((?:\d+)?\.?\d+) *(milliseconds?|msecs?|ms|seconds?|secs?|s|minutes?|mins?|m|hours?|hrs?|h|days?|d|years?|yrs?|y)?$/i.exec(e);
                if (!t) return;
                var n = parseFloat(t[1]);
                switch ((t[2] || 'ms').toLowerCase()) {
                  case 'years':
                  case 'year':
                  case 'yrs':
                  case 'yr':
                  case 'y':
                    return n * o;
                  case 'days':
                  case 'day':
                  case 'd':
                    return n * a;
                  case 'hours':
                  case 'hour':
                  case 'hrs':
                  case 'hr':
                  case 'h':
                    return n * s;
                  case 'minutes':
                  case 'minute':
                  case 'mins':
                  case 'min':
                  case 'm':
                    return n * i;
                  case 'seconds':
                  case 'second':
                  case 'secs':
                  case 'sec':
                  case 's':
                    return n * r;
                  case 'milliseconds':
                  case 'millisecond':
                  case 'msecs':
                  case 'msec':
                  case 'ms':
                    return n;
                  default:
                    return;
                }
              })(e);
            if ('number' === n && !1 === isNaN(e))
              return t.long
                ? l((u = e), a, 'day') || l(u, s, 'hour') || l(u, i, 'minute') || l(u, r, 'second') || u + ' ms'
                : (function (e) {
                    if (e >= a) return Math.round(e / a) + 'd';
                    if (e >= s) return Math.round(e / s) + 'h';
                    if (e >= i) return Math.round(e / i) + 'm';
                    if (e >= r) return Math.round(e / r) + 's';
                    return e + 'ms';
                  })(e);
            var u;
            throw new Error('val is not a non-empty string or a valid number. val=' + JSON.stringify(e));
          };
          function l(e, t, n) {
            if (!(e < t)) return e < 1.5 * t ? Math.floor(e / t) + ' ' + n : Math.ceil(e / t) + ' ' + n + 's';
          }
        },
        {},
      ],
      33: [
        function (e, t, n) {
          var r,
            i,
            s = (t.exports = {});
          function a() {
            throw new Error('setTimeout has not been defined');
          }
          function o() {
            throw new Error('clearTimeout has not been defined');
          }
          !(function () {
            try {
              r = 'function' == typeof setTimeout ? setTimeout : a;
            } catch (e) {
              r = a;
            }
            try {
              i = 'function' == typeof clearTimeout ? clearTimeout : o;
            } catch (e) {
              i = o;
            }
          })();
          function l(e) {
            if (r === setTimeout) return setTimeout(e, 0);
            if ((r === a || !r) && setTimeout) return (r = setTimeout), setTimeout(e, 0);
            try {
              return r(e, 0);
            } catch (t) {
              try {
                return r.call(null, e, 0);
              } catch (t) {
                return r.call(this, e, 0);
              }
            }
          }
          var u,
            c = [],
            d = !1,
            h = -1;
          function f() {
            d && u && ((d = !1), u.length ? (c = u.concat(c)) : (h = -1), c.length && p());
          }
          function p() {
            if (!d) {
              var e = l(f);
              d = !0;
              for (var t = c.length; t; ) {
                for (u = c, c = []; ++h < t; ) u && u[h].run();
                (h = -1), (t = c.length);
              }
              (u = null),
                (d = !1),
                (function (e) {
                  if (i === clearTimeout) return clearTimeout(e);
                  if ((i === o || !i) && clearTimeout) return (i = clearTimeout), clearTimeout(e);
                  try {
                    i(e);
                  } catch (t) {
                    try {
                      return i.call(null, e);
                    } catch (t) {
                      return i.call(this, e);
                    }
                  }
                })(e);
            }
          }
          s.nextTick = function (e) {
            var t = new Array(arguments.length - 1);
            if (arguments.length > 1) for (var n = 1; n < arguments.length; n++) t[n - 1] = arguments[n];
            c.push(new _(e, t)), 1 !== c.length || d || l(p);
          };
          function _(e, t) {
            (this.fun = e), (this.array = t);
          }
          (_.prototype.run = function () {
            this.fun.apply(null, this.array);
          }),
            (s.title = 'browser'),
            (s.browser = !0),
            (s.env = {}),
            (s.argv = []),
            (s.version = ''),
            (s.versions = {});
          function v() {}
          (s.on = v),
            (s.addListener = v),
            (s.once = v),
            (s.off = v),
            (s.removeListener = v),
            (s.removeAllListeners = v),
            (s.emit = v),
            (s.prependListener = v),
            (s.prependOnceListener = v),
            (s.listeners = function (e) {
              return [];
            }),
            (s.binding = function (e) {
              throw new Error('process.binding is not supported');
            }),
            (s.cwd = function () {
              return '/';
            }),
            (s.chdir = function (e) {
              throw new Error('process.chdir is not supported');
            }),
            (s.umask = function () {
              return 0;
            });
        },
        {},
      ],
      34: [
        function (e, t, n) {
          'use strict';
          var r = e('sdp');
          function i(e, t, n, i, s) {
            var a = r.writeRtpDescription(e.kind, t);
            if (
              ((a += r.writeIceParameters(e.iceGatherer.getLocalParameters())),
              (a += r.writeDtlsParameters(e.dtlsTransport.getLocalParameters(), 'offer' === n ? 'actpass' : s || 'active')),
              (a += 'a=mid:' + e.mid + '\r\n'),
              e.rtpSender && e.rtpReceiver
                ? (a += 'a=sendrecv\r\n')
                : e.rtpSender
                ? (a += 'a=sendonly\r\n')
                : e.rtpReceiver
                ? (a += 'a=recvonly\r\n')
                : (a += 'a=inactive\r\n'),
              e.rtpSender)
            ) {
              var o = 'msid:' + i.id + ' ' + e.rtpSender.track.id + '\r\n';
              (a += 'a=' + o),
                (a += 'a=ssrc:' + e.sendEncodingParameters[0].ssrc + ' ' + o),
                e.sendEncodingParameters[0].rtx &&
                  ((a += 'a=ssrc:' + e.sendEncodingParameters[0].rtx.ssrc + ' ' + o),
                  (a += 'a=ssrc-group:FID ' + e.sendEncodingParameters[0].ssrc + ' ' + e.sendEncodingParameters[0].rtx.ssrc + '\r\n'));
            }
            return (
              (a += 'a=ssrc:' + e.sendEncodingParameters[0].ssrc + ' cname:' + r.localCName + '\r\n'),
              e.rtpSender && e.sendEncodingParameters[0].rtx && (a += 'a=ssrc:' + e.sendEncodingParameters[0].rtx.ssrc + ' cname:' + r.localCName + '\r\n'),
              a
            );
          }
          function s(e, t) {
            var n = { codecs: [], headerExtensions: [], fecMechanisms: [] },
              r = function (e, t) {
                e = parseInt(e, 10);
                for (var n = 0; n < t.length; n++) if (t[n].payloadType === e || t[n].preferredPayloadType === e) return t[n];
              },
              i = function (e, t, n, i) {
                var s = r(e.parameters.apt, n),
                  a = r(t.parameters.apt, i);
                return s && a && s.name.toLowerCase() === a.name.toLowerCase();
              };
            return (
              e.codecs.forEach(function (r) {
                for (var s = 0; s < t.codecs.length; s++) {
                  var a = t.codecs[s];
                  if (r.name.toLowerCase() === a.name.toLowerCase() && r.clockRate === a.clockRate) {
                    if ('rtx' === r.name.toLowerCase() && r.parameters && a.parameters.apt && !i(r, a, e.codecs, t.codecs)) continue;
                    ((a = JSON.parse(JSON.stringify(a))).numChannels = Math.min(r.numChannels, a.numChannels)),
                      n.codecs.push(a),
                      (a.rtcpFeedback = a.rtcpFeedback.filter(function (e) {
                        for (var t = 0; t < r.rtcpFeedback.length; t++) if (r.rtcpFeedback[t].type === e.type && r.rtcpFeedback[t].parameter === e.parameter) return !0;
                        return !1;
                      }));
                    break;
                  }
                }
              }),
              e.headerExtensions.forEach(function (e) {
                for (var r = 0; r < t.headerExtensions.length; r++) {
                  var i = t.headerExtensions[r];
                  if (e.uri === i.uri) {
                    n.headerExtensions.push(i);
                    break;
                  }
                }
              }),
              n
            );
          }
          function a(e, t, n) {
            return (
              -1 !==
              {
                offer: { setLocalDescription: ['stable', 'have-local-offer'], setRemoteDescription: ['stable', 'have-remote-offer'] },
                answer: { setLocalDescription: ['have-remote-offer', 'have-local-pranswer'], setRemoteDescription: ['have-local-offer', 'have-remote-pranswer'] },
              }[t][e].indexOf(n)
            );
          }
          function o(e, t) {
            var n = e.getRemoteCandidates().find(function (e) {
              return t.foundation === e.foundation && t.ip === e.ip && t.port === e.port && t.priority === e.priority && t.protocol === e.protocol && t.type === e.type;
            });
            return n || e.addRemoteCandidate(t), !n;
          }
          function l(e, t) {
            t.addTrack(e);
            var n = new Event('addtrack');
            (n.track = e), t.dispatchEvent(n);
          }
          function u(e, t, n, r) {
            var i = new Event('track');
            (i.track = t),
              (i.receiver = n),
              (i.transceiver = { receiver: n }),
              (i.streams = r),
              window.setTimeout(function () {
                e._dispatchEvent('track', i);
              });
          }
          function c(e, t) {
            var n = new Error(t);
            return (n.name = e), n;
          }
          t.exports = function (e, t) {
            var n = function (n) {
              var i = this,
                s = document.createDocumentFragment();
              if (
                (['addEventListener', 'removeEventListener', 'dispatchEvent'].forEach(function (e) {
                  i[e] = s[e].bind(s);
                }),
                (this.canTrickleIceCandidates = null),
                (this.needNegotiation = !1),
                (this.localStreams = []),
                (this.remoteStreams = []),
                (this.localDescription = null),
                (this.remoteDescription = null),
                (this.signalingState = 'stable'),
                (this.iceConnectionState = 'new'),
                (this.iceGatheringState = 'new'),
                (n = JSON.parse(JSON.stringify(n || {}))),
                (this.usingBundle = 'max-bundle' === n.bundlePolicy),
                'negotiate' === n.rtcpMuxPolicy)
              )
                throw c('NotSupportedError', "rtcpMuxPolicy 'negotiate' is not supported");
              switch ((n.rtcpMuxPolicy || (n.rtcpMuxPolicy = 'require'), n.iceTransportPolicy)) {
                case 'all':
                case 'relay':
                  break;
                default:
                  n.iceTransportPolicy = 'all';
              }
              switch (n.bundlePolicy) {
                case 'balanced':
                case 'max-compat':
                case 'max-bundle':
                  break;
                default:
                  n.bundlePolicy = 'balanced';
              }
              if (
                ((n.iceServers = (function (e, t) {
                  var n = !1;
                  return (e = JSON.parse(JSON.stringify(e))).filter(function (e) {
                    if (e && (e.urls || e.url)) {
                      var r = e.urls || e.url;
                      e.url && !e.urls && console.warn('RTCIceServer.url is deprecated! Use urls instead.');
                      var i = 'string' == typeof r;
                      return (
                        i && (r = [r]),
                        (r = r.filter(function (e) {
                          return 0 !== e.indexOf('turn:') || -1 === e.indexOf('transport=udp') || -1 !== e.indexOf('turn:[') || n
                            ? 0 === e.indexOf('stun:') && t >= 14393 && -1 === e.indexOf('?transport=udp')
                            : ((n = !0), !0);
                        })),
                        delete e.url,
                        (e.urls = i ? r[0] : r),
                        !!r.length
                      );
                    }
                  });
                })(n.iceServers || [], t)),
                (this._iceGatherers = []),
                n.iceCandidatePoolSize)
              )
                for (var a = n.iceCandidatePoolSize; a > 0; a--)
                  this._iceGatherers = new e.RTCIceGatherer({ iceServers: n.iceServers, gatherPolicy: n.iceTransportPolicy });
              else n.iceCandidatePoolSize = 0;
              (this._config = n),
                (this.transceivers = []),
                (this._sdpSessionId = r.generateSessionId()),
                (this._sdpSessionVersion = 0),
                (this._dtlsRole = void 0),
                (this._isClosed = !1);
            };
            (n.prototype.onicecandidate = null),
              (n.prototype.onaddstream = null),
              (n.prototype.ontrack = null),
              (n.prototype.onremovestream = null),
              (n.prototype.onsignalingstatechange = null),
              (n.prototype.oniceconnectionstatechange = null),
              (n.prototype.onicegatheringstatechange = null),
              (n.prototype.onnegotiationneeded = null),
              (n.prototype.ondatachannel = null),
              (n.prototype._dispatchEvent = function (e, t) {
                this._isClosed || (this.dispatchEvent(t), 'function' == typeof this['on' + e] && this['on' + e](t));
              }),
              (n.prototype._emitGatheringStateChange = function () {
                var e = new Event('icegatheringstatechange');
                this._dispatchEvent('icegatheringstatechange', e);
              }),
              (n.prototype.getConfiguration = function () {
                return this._config;
              }),
              (n.prototype.getLocalStreams = function () {
                return this.localStreams;
              }),
              (n.prototype.getRemoteStreams = function () {
                return this.remoteStreams;
              }),
              (n.prototype._createTransceiver = function (e) {
                var t = this.transceivers.length > 0,
                  n = {
                    track: null,
                    iceGatherer: null,
                    iceTransport: null,
                    dtlsTransport: null,
                    localCapabilities: null,
                    remoteCapabilities: null,
                    rtpSender: null,
                    rtpReceiver: null,
                    kind: e,
                    mid: null,
                    sendEncodingParameters: null,
                    recvEncodingParameters: null,
                    stream: null,
                    associatedRemoteMediaStreams: [],
                    wantReceive: !0,
                  };
                if (this.usingBundle && t) (n.iceTransport = this.transceivers[0].iceTransport), (n.dtlsTransport = this.transceivers[0].dtlsTransport);
                else {
                  var r = this._createIceAndDtlsTransports();
                  (n.iceTransport = r.iceTransport), (n.dtlsTransport = r.dtlsTransport);
                }
                return this.transceivers.push(n), n;
              }),
              (n.prototype.addTrack = function (t, n) {
                if (
                  this.transceivers.find(function (e) {
                    return e.track === t;
                  })
                )
                  throw c('InvalidAccessError', 'Track already exists.');
                if ('closed' === this.signalingState) throw c('InvalidStateError', 'Attempted to call addTrack on a closed peerconnection.');
                for (var r, i = 0; i < this.transceivers.length; i++) this.transceivers[i].track || this.transceivers[i].kind !== t.kind || (r = this.transceivers[i]);
                return (
                  r || (r = this._createTransceiver(t.kind)),
                  this._maybeFireNegotiationNeeded(),
                  -1 === this.localStreams.indexOf(n) && this.localStreams.push(n),
                  (r.track = t),
                  (r.stream = n),
                  (r.rtpSender = new e.RTCRtpSender(t, r.dtlsTransport)),
                  r.rtpSender
                );
              }),
              (n.prototype.addStream = function (e) {
                var n = this;
                if (t >= 15025)
                  e.getTracks().forEach(function (t) {
                    n.addTrack(t, e);
                  });
                else {
                  var r = e.clone();
                  e.getTracks().forEach(function (e, t) {
                    var n = r.getTracks()[t];
                    e.addEventListener('enabled', function (e) {
                      n.enabled = e.enabled;
                    });
                  }),
                    r.getTracks().forEach(function (e) {
                      n.addTrack(e, r);
                    });
                }
              }),
              (n.prototype.removeTrack = function (t) {
                if (!(t instanceof e.RTCRtpSender)) throw new TypeError('Argument 1 of RTCPeerConnection.removeTrack does not implement interface RTCRtpSender.');
                var n = this.transceivers.find(function (e) {
                  return e.rtpSender === t;
                });
                if (!n) throw c('InvalidAccessError', 'Sender was not created by this connection.');
                var r = n.stream;
                n.rtpSender.stop(), (n.rtpSender = null), (n.track = null), (n.stream = null);
                -1 ===
                  this.transceivers
                    .map(function (e) {
                      return e.stream;
                    })
                    .indexOf(r) &&
                  this.localStreams.indexOf(r) > -1 &&
                  this.localStreams.splice(this.localStreams.indexOf(r), 1),
                  this._maybeFireNegotiationNeeded();
              }),
              (n.prototype.removeStream = function (e) {
                var t = this;
                e.getTracks().forEach(function (e) {
                  var n = t.getSenders().find(function (t) {
                    return t.track === e;
                  });
                  n && t.removeTrack(n);
                });
              }),
              (n.prototype.getSenders = function () {
                return this.transceivers
                  .filter(function (e) {
                    return !!e.rtpSender;
                  })
                  .map(function (e) {
                    return e.rtpSender;
                  });
              }),
              (n.prototype.getReceivers = function () {
                return this.transceivers
                  .filter(function (e) {
                    return !!e.rtpReceiver;
                  })
                  .map(function (e) {
                    return e.rtpReceiver;
                  });
              }),
              (n.prototype._createIceGatherer = function (t, n) {
                var r = this;
                if (n && t > 0) return this.transceivers[0].iceGatherer;
                if (this._iceGatherers.length) return this._iceGatherers.shift();
                var i = new e.RTCIceGatherer({ iceServers: this._config.iceServers, gatherPolicy: this._config.iceTransportPolicy });
                return (
                  Object.defineProperty(i, 'state', { value: 'new', writable: !0 }),
                  (this.transceivers[t].candidates = []),
                  (this.transceivers[t].bufferCandidates = function (e) {
                    var n = !e.candidate || 0 === Object.keys(e.candidate).length;
                    (i.state = n ? 'completed' : 'gathering'), null !== r.transceivers[t].candidates && r.transceivers[t].candidates.push(e.candidate);
                  }),
                  i.addEventListener('localcandidate', this.transceivers[t].bufferCandidates),
                  i
                );
              }),
              (n.prototype._gather = function (t, n) {
                var i = this,
                  s = this.transceivers[n].iceGatherer;
                if (!s.onlocalcandidate) {
                  var a = this.transceivers[n].candidates;
                  (this.transceivers[n].candidates = null),
                    s.removeEventListener('localcandidate', this.transceivers[n].bufferCandidates),
                    (s.onlocalcandidate = function (e) {
                      if (!(i.usingBundle && n > 0)) {
                        var a = new Event('icecandidate');
                        a.candidate = { sdpMid: t, sdpMLineIndex: n };
                        var o = e.candidate,
                          l = !o || 0 === Object.keys(o).length;
                        l
                          ? ('new' !== s.state && 'gathering' !== s.state) || (s.state = 'completed')
                          : ('new' === s.state && (s.state = 'gathering'), (o.component = 1), (a.candidate.candidate = r.writeCandidate(o)));
                        var u = r.splitSections(i.localDescription.sdp);
                        (u[a.candidate.sdpMLineIndex + 1] += l ? 'a=end-of-candidates\r\n' : 'a=' + a.candidate.candidate + '\r\n'),
                          (i.localDescription.sdp = u.join(''));
                        var c = i.transceivers.every(function (e) {
                          return e.iceGatherer && 'completed' === e.iceGatherer.state;
                        });
                        'gathering' !== i.iceGatheringState && ((i.iceGatheringState = 'gathering'), i._emitGatheringStateChange()),
                          l || i._dispatchEvent('icecandidate', a),
                          c && (i._dispatchEvent('icecandidate', new Event('icecandidate')), (i.iceGatheringState = 'complete'), i._emitGatheringStateChange());
                      }
                    }),
                    e.setTimeout(function () {
                      a.forEach(function (e) {
                        var t = new Event('RTCIceGatherEvent');
                        (t.candidate = e), s.onlocalcandidate(t);
                      });
                    }, 0);
                }
              }),
              (n.prototype._createIceAndDtlsTransports = function () {
                var t = this,
                  n = new e.RTCIceTransport(null);
                n.onicestatechange = function () {
                  t._updateConnectionState();
                };
                var r = new e.RTCDtlsTransport(n);
                return (
                  (r.ondtlsstatechange = function () {
                    t._updateConnectionState();
                  }),
                  (r.onerror = function () {
                    Object.defineProperty(r, 'state', { value: 'failed', writable: !0 }), t._updateConnectionState();
                  }),
                  { iceTransport: n, dtlsTransport: r }
                );
              }),
              (n.prototype._disposeIceAndDtlsTransports = function (e) {
                var t = this.transceivers[e].iceGatherer;
                t && (delete t.onlocalcandidate, delete this.transceivers[e].iceGatherer);
                var n = this.transceivers[e].iceTransport;
                n && (delete n.onicestatechange, delete this.transceivers[e].iceTransport);
                var r = this.transceivers[e].dtlsTransport;
                r && (delete r.ondtlsstatechange, delete r.onerror, delete this.transceivers[e].dtlsTransport);
              }),
              (n.prototype._transceive = function (e, n, i) {
                var a = s(e.localCapabilities, e.remoteCapabilities);
                n &&
                  e.rtpSender &&
                  ((a.encodings = e.sendEncodingParameters),
                  (a.rtcp = { cname: r.localCName, compound: e.rtcpParameters.compound }),
                  e.recvEncodingParameters.length && (a.rtcp.ssrc = e.recvEncodingParameters[0].ssrc),
                  e.rtpSender.send(a)),
                  i &&
                    e.rtpReceiver &&
                    a.codecs.length > 0 &&
                    ('video' === e.kind &&
                      e.recvEncodingParameters &&
                      t < 15019 &&
                      e.recvEncodingParameters.forEach(function (e) {
                        delete e.rtx;
                      }),
                    e.recvEncodingParameters.length && (a.encodings = e.recvEncodingParameters),
                    (a.rtcp = { compound: e.rtcpParameters.compound }),
                    e.rtcpParameters.cname && (a.rtcp.cname = e.rtcpParameters.cname),
                    e.sendEncodingParameters.length && (a.rtcp.ssrc = e.sendEncodingParameters[0].ssrc),
                    e.rtpReceiver.receive(a));
              }),
              (n.prototype.setLocalDescription = function (e) {
                var t = this;
                if (!a('setLocalDescription', e.type, this.signalingState) || this._isClosed)
                  return Promise.reject(c('InvalidStateError', 'Can not set local ' + e.type + ' in state ' + t.signalingState));
                var n, i;
                if ('offer' === e.type)
                  (n = r.splitSections(e.sdp)),
                    (i = n.shift()),
                    n.forEach(function (e, n) {
                      var i = r.parseRtpParameters(e);
                      t.transceivers[n].localCapabilities = i;
                    }),
                    this.transceivers.forEach(function (e, n) {
                      t._gather(e.mid, n);
                    });
                else if ('answer' === e.type) {
                  (n = r.splitSections(t.remoteDescription.sdp)), (i = n.shift());
                  var o = r.matchPrefix(i, 'a=ice-lite').length > 0;
                  n.forEach(function (e, n) {
                    var a = t.transceivers[n],
                      l = a.iceGatherer,
                      u = a.iceTransport,
                      c = a.dtlsTransport,
                      d = a.localCapabilities,
                      h = a.remoteCapabilities;
                    if (!(r.isRejected(e) && 0 === r.matchPrefix(e, 'a=bundle-only').length) && !a.isDatachannel) {
                      var f = r.getIceParameters(e, i),
                        p = r.getDtlsParameters(e, i);
                      o && (p.role = 'server'),
                        (t.usingBundle && 0 !== n) ||
                          (t._gather(a.mid, n), 'new' === u.state && u.start(l, f, o ? 'controlling' : 'controlled'), 'new' === c.state && c.start(p));
                      var _ = s(d, h);
                      t._transceive(a, _.codecs.length > 0, !1);
                    }
                  });
                }
                switch (((this.localDescription = { type: e.type, sdp: e.sdp }), e.type)) {
                  case 'offer':
                    this._updateSignalingState('have-local-offer');
                    break;
                  case 'answer':
                    this._updateSignalingState('stable');
                    break;
                  default:
                    throw new TypeError('unsupported type "' + e.type + '"');
                }
                return Promise.resolve();
              }),
              (n.prototype.setRemoteDescription = function (n) {
                var i = this;
                if (!a('setRemoteDescription', n.type, this.signalingState) || this._isClosed)
                  return Promise.reject(c('InvalidStateError', 'Can not set remote ' + n.type + ' in state ' + i.signalingState));
                var s = {};
                this.remoteStreams.forEach(function (e) {
                  s[e.id] = e;
                });
                var d = [],
                  h = r.splitSections(n.sdp),
                  f = h.shift(),
                  p = r.matchPrefix(f, 'a=ice-lite').length > 0,
                  _ = r.matchPrefix(f, 'a=group:BUNDLE ').length > 0;
                this.usingBundle = _;
                var v = r.matchPrefix(f, 'a=ice-options:')[0];
                switch (
                  ((this.canTrickleIceCandidates = !!v && v.substr(14).split(' ').indexOf('trickle') >= 0),
                  h.forEach(function (a, u) {
                    var c = r.splitLines(a),
                      h = r.getKind(a),
                      v = r.isRejected(a) && 0 === r.matchPrefix(a, 'a=bundle-only').length,
                      m = c[0].substr(2).split(' ')[2],
                      g = r.getDirection(a, f),
                      y = r.parseMsid(a),
                      T = r.getMid(a) || r.generateIdentifier();
                    if ('application' !== h || 'DTLS/SCTP' !== m) {
                      var C,
                        S,
                        E,
                        b,
                        R,
                        A,
                        w,
                        I,
                        k,
                        P,
                        O,
                        x = r.parseRtpParameters(a);
                      v || ((P = r.getIceParameters(a, f)), ((O = r.getDtlsParameters(a, f)).role = 'client')), (w = r.parseRtpEncodingParameters(a));
                      var N = r.parseRtcpParameters(a),
                        D = r.matchPrefix(a, 'a=end-of-candidates', f).length > 0,
                        U = r
                          .matchPrefix(a, 'a=candidate:')
                          .map(function (e) {
                            return r.parseCandidate(e);
                          })
                          .filter(function (e) {
                            return 1 === e.component;
                          });
                      if (
                        (('offer' === n.type || 'answer' === n.type) &&
                          !v &&
                          _ &&
                          u > 0 &&
                          i.transceivers[u] &&
                          (i._disposeIceAndDtlsTransports(u),
                          (i.transceivers[u].iceGatherer = i.transceivers[0].iceGatherer),
                          (i.transceivers[u].iceTransport = i.transceivers[0].iceTransport),
                          (i.transceivers[u].dtlsTransport = i.transceivers[0].dtlsTransport),
                          i.transceivers[u].rtpSender && i.transceivers[u].rtpSender.setTransport(i.transceivers[0].dtlsTransport),
                          i.transceivers[u].rtpReceiver && i.transceivers[u].rtpReceiver.setTransport(i.transceivers[0].dtlsTransport)),
                        'offer' !== n.type || v)
                      )
                        'answer' !== n.type ||
                          v ||
                          ((S = (C = i.transceivers[u]).iceGatherer),
                          (E = C.iceTransport),
                          (b = C.dtlsTransport),
                          (R = C.rtpReceiver),
                          (A = C.sendEncodingParameters),
                          (I = C.localCapabilities),
                          (i.transceivers[u].recvEncodingParameters = w),
                          (i.transceivers[u].remoteCapabilities = x),
                          (i.transceivers[u].rtcpParameters = N),
                          U.length &&
                            'new' === E.state &&
                            ((!p && !D) || (_ && 0 !== u)
                              ? U.forEach(function (e) {
                                  o(C.iceTransport, e);
                                })
                              : E.setRemoteCandidates(U)),
                          (_ && 0 !== u) || ('new' === E.state && E.start(S, P, 'controlling'), 'new' === b.state && b.start(O)),
                          i._transceive(C, 'sendrecv' === g || 'recvonly' === g, 'sendrecv' === g || 'sendonly' === g),
                          !R || ('sendrecv' !== g && 'sendonly' !== g)
                            ? delete C.rtpReceiver
                            : ((k = R.track),
                              y
                                ? (s[y.stream] || (s[y.stream] = new e.MediaStream()), l(k, s[y.stream]), d.push([k, R, s[y.stream]]))
                                : (s.default || (s.default = new e.MediaStream()), l(k, s.default), d.push([k, R, s.default]))));
                      else {
                        ((C = i.transceivers[u] || i._createTransceiver(h)).mid = T),
                          C.iceGatherer || (C.iceGatherer = i._createIceGatherer(u, _)),
                          U.length &&
                            'new' === C.iceTransport.state &&
                            (!D || (_ && 0 !== u)
                              ? U.forEach(function (e) {
                                  o(C.iceTransport, e);
                                })
                              : C.iceTransport.setRemoteCandidates(U)),
                          (I = e.RTCRtpReceiver.getCapabilities(h)),
                          t < 15019 &&
                            (I.codecs = I.codecs.filter(function (e) {
                              return 'rtx' !== e.name;
                            })),
                          (A = C.sendEncodingParameters || [{ ssrc: 1001 * (2 * u + 2) }]);
                        var M = !1;
                        if ('sendrecv' === g || 'sendonly' === g) {
                          if (((M = !C.rtpReceiver), (R = C.rtpReceiver || new e.RTCRtpReceiver(C.dtlsTransport, h)), M)) {
                            var L;
                            (k = R.track),
                              (y && '-' === y.stream) ||
                                (y
                                  ? (s[y.stream] ||
                                      ((s[y.stream] = new e.MediaStream()),
                                      Object.defineProperty(s[y.stream], 'id', {
                                        get: function () {
                                          return y.stream;
                                        },
                                      })),
                                    Object.defineProperty(k, 'id', {
                                      get: function () {
                                        return y.track;
                                      },
                                    }),
                                    (L = s[y.stream]))
                                  : (s.default || (s.default = new e.MediaStream()), (L = s.default))),
                              L && (l(k, L), C.associatedRemoteMediaStreams.push(L)),
                              d.push([k, R, L]);
                          }
                        } else
                          C.rtpReceiver &&
                            C.rtpReceiver.track &&
                            (C.associatedRemoteMediaStreams.forEach(function (e) {
                              var t = e.getTracks().find(function (e) {
                                return e.id === C.rtpReceiver.track.id;
                              });
                              t &&
                                (function (e, t) {
                                  t.removeTrack(e);
                                  var n = new Event('removetrack');
                                  (n.track = e), t.dispatchEvent(n);
                                })(t, e);
                            }),
                            (C.associatedRemoteMediaStreams = []));
                        (C.localCapabilities = I),
                          (C.remoteCapabilities = x),
                          (C.rtpReceiver = R),
                          (C.rtcpParameters = N),
                          (C.sendEncodingParameters = A),
                          (C.recvEncodingParameters = w),
                          i._transceive(i.transceivers[u], !1, M);
                      }
                    } else i.transceivers[u] = { mid: T, isDatachannel: !0 };
                  }),
                  void 0 === this._dtlsRole && (this._dtlsRole = 'offer' === n.type ? 'active' : 'passive'),
                  (this.remoteDescription = { type: n.type, sdp: n.sdp }),
                  n.type)
                ) {
                  case 'offer':
                    this._updateSignalingState('have-remote-offer');
                    break;
                  case 'answer':
                    this._updateSignalingState('stable');
                    break;
                  default:
                    throw new TypeError('unsupported type "' + n.type + '"');
                }
                return (
                  Object.keys(s).forEach(function (t) {
                    var n = s[t];
                    if (n.getTracks().length) {
                      if (-1 === i.remoteStreams.indexOf(n)) {
                        i.remoteStreams.push(n);
                        var r = new Event('addstream');
                        (r.stream = n),
                          e.setTimeout(function () {
                            i._dispatchEvent('addstream', r);
                          });
                      }
                      d.forEach(function (e) {
                        var t = e[0],
                          r = e[1];
                        n.id === e[2].id && u(i, t, r, [n]);
                      });
                    }
                  }),
                  d.forEach(function (e) {
                    e[2] || u(i, e[0], e[1], []);
                  }),
                  e.setTimeout(function () {
                    i &&
                      i.transceivers &&
                      i.transceivers.forEach(function (e) {
                        e.iceTransport &&
                          'new' === e.iceTransport.state &&
                          e.iceTransport.getRemoteCandidates().length > 0 &&
                          (console.warn('Timeout for addRemoteCandidate. Consider sending an end-of-candidates notification'), e.iceTransport.addRemoteCandidate({}));
                      });
                  }, 4e3),
                  Promise.resolve()
                );
              }),
              (n.prototype.close = function () {
                this.transceivers.forEach(function (e) {
                  e.iceTransport && e.iceTransport.stop(),
                    e.dtlsTransport && e.dtlsTransport.stop(),
                    e.rtpSender && e.rtpSender.stop(),
                    e.rtpReceiver && e.rtpReceiver.stop();
                }),
                  (this._isClosed = !0),
                  this._updateSignalingState('closed');
              }),
              (n.prototype._updateSignalingState = function (e) {
                this.signalingState = e;
                var t = new Event('signalingstatechange');
                this._dispatchEvent('signalingstatechange', t);
              }),
              (n.prototype._maybeFireNegotiationNeeded = function () {
                var t = this;
                'stable' === this.signalingState &&
                  !0 !== this.needNegotiation &&
                  ((this.needNegotiation = !0),
                  e.setTimeout(function () {
                    if (!1 !== t.needNegotiation) {
                      t.needNegotiation = !1;
                      var e = new Event('negotiationneeded');
                      t._dispatchEvent('negotiationneeded', e);
                    }
                  }, 0));
              }),
              (n.prototype._updateConnectionState = function () {
                var e,
                  t = { new: 0, closed: 0, connecting: 0, checking: 0, connected: 0, completed: 0, disconnected: 0, failed: 0 };
                if (
                  (this.transceivers.forEach(function (e) {
                    t[e.iceTransport.state]++, t[e.dtlsTransport.state]++;
                  }),
                  (t.connected += t.completed),
                  (e = 'new'),
                  t.failed > 0
                    ? (e = 'failed')
                    : t.connecting > 0 || t.checking > 0
                    ? (e = 'connecting')
                    : t.disconnected > 0
                    ? (e = 'disconnected')
                    : t.new > 0
                    ? (e = 'new')
                    : (t.connected > 0 || t.completed > 0) && (e = 'connected'),
                  e !== this.iceConnectionState)
                ) {
                  this.iceConnectionState = e;
                  var n = new Event('iceconnectionstatechange');
                  this._dispatchEvent('iceconnectionstatechange', n);
                }
              }),
              (n.prototype.createOffer = function () {
                var n = this;
                if (this._isClosed) return Promise.reject(c('InvalidStateError', 'Can not call createOffer after close'));
                var s = this.transceivers.filter(function (e) {
                    return 'audio' === e.kind;
                  }).length,
                  a = this.transceivers.filter(function (e) {
                    return 'video' === e.kind;
                  }).length,
                  o = arguments[0];
                if (o) {
                  if (o.mandatory || o.optional) throw new TypeError('Legacy mandatory/optional constraints not supported.');
                  void 0 !== o.offerToReceiveAudio && (s = !0 === o.offerToReceiveAudio ? 1 : !1 === o.offerToReceiveAudio ? 0 : o.offerToReceiveAudio),
                    void 0 !== o.offerToReceiveVideo && (a = !0 === o.offerToReceiveVideo ? 1 : !1 === o.offerToReceiveVideo ? 0 : o.offerToReceiveVideo);
                }
                for (
                  this.transceivers.forEach(function (e) {
                    'audio' === e.kind ? --s < 0 && (e.wantReceive = !1) : 'video' === e.kind && --a < 0 && (e.wantReceive = !1);
                  });
                  s > 0 || a > 0;

                )
                  s > 0 && (this._createTransceiver('audio'), s--), a > 0 && (this._createTransceiver('video'), a--);
                var l = r.writeSessionBoilerplate(this._sdpSessionId, this._sdpSessionVersion++);
                this.transceivers.forEach(function (i, s) {
                  var a = i.track,
                    o = i.kind,
                    l = r.generateIdentifier();
                  (i.mid = l), i.iceGatherer || (i.iceGatherer = n._createIceGatherer(s, n.usingBundle));
                  var u = e.RTCRtpSender.getCapabilities(o);
                  t < 15019 &&
                    (u.codecs = u.codecs.filter(function (e) {
                      return 'rtx' !== e.name;
                    })),
                    u.codecs.forEach(function (e) {
                      'H264' === e.name && void 0 === e.parameters['level-asymmetry-allowed'] && (e.parameters['level-asymmetry-allowed'] = '1');
                    });
                  var c = i.sendEncodingParameters || [{ ssrc: 1001 * (2 * s + 1) }];
                  a && t >= 15019 && 'video' === o && !c[0].rtx && (c[0].rtx = { ssrc: c[0].ssrc + 1 }),
                    i.wantReceive && (i.rtpReceiver = new e.RTCRtpReceiver(i.dtlsTransport, o)),
                    (i.localCapabilities = u),
                    (i.sendEncodingParameters = c);
                }),
                  'max-compat' !== this._config.bundlePolicy &&
                    (l +=
                      'a=group:BUNDLE ' +
                      this.transceivers
                        .map(function (e) {
                          return e.mid;
                        })
                        .join(' ') +
                      '\r\n'),
                  (l += 'a=ice-options:trickle\r\n'),
                  this.transceivers.forEach(function (e, t) {
                    (l += i(e, e.localCapabilities, 'offer', e.stream, n._dtlsRole)),
                      (l += 'a=rtcp-rsize\r\n'),
                      !e.iceGatherer ||
                        'new' === n.iceGatheringState ||
                        (0 !== t && n.usingBundle) ||
                        (e.iceGatherer.getLocalCandidates().forEach(function (e) {
                          (e.component = 1), (l += 'a=' + r.writeCandidate(e) + '\r\n');
                        }),
                        'completed' === e.iceGatherer.state && (l += 'a=end-of-candidates\r\n'));
                  });
                var u = new e.RTCSessionDescription({ type: 'offer', sdp: l });
                return Promise.resolve(u);
              }),
              (n.prototype.createAnswer = function () {
                var n = this;
                if (this._isClosed) return Promise.reject(c('InvalidStateError', 'Can not call createAnswer after close'));
                var a = r.writeSessionBoilerplate(this._sdpSessionId, this._sdpSessionVersion++);
                this.usingBundle &&
                  (a +=
                    'a=group:BUNDLE ' +
                    this.transceivers
                      .map(function (e) {
                        return e.mid;
                      })
                      .join(' ') +
                    '\r\n');
                var o = r.splitSections(this.remoteDescription.sdp).length - 1;
                this.transceivers.forEach(function (e, r) {
                  if (!(r + 1 > o))
                    if (e.isDatachannel) a += 'm=application 0 DTLS/SCTP 5000\r\nc=IN IP4 0.0.0.0\r\na=mid:' + e.mid + '\r\n';
                    else {
                      if (e.stream) {
                        var l;
                        'audio' === e.kind ? (l = e.stream.getAudioTracks()[0]) : 'video' === e.kind && (l = e.stream.getVideoTracks()[0]),
                          l &&
                            t >= 15019 &&
                            'video' === e.kind &&
                            !e.sendEncodingParameters[0].rtx &&
                            (e.sendEncodingParameters[0].rtx = { ssrc: e.sendEncodingParameters[0].ssrc + 1 });
                      }
                      var u = s(e.localCapabilities, e.remoteCapabilities);
                      !u.codecs.filter(function (e) {
                        return 'rtx' === e.name.toLowerCase();
                      }).length &&
                        e.sendEncodingParameters[0].rtx &&
                        delete e.sendEncodingParameters[0].rtx,
                        (a += i(e, u, 'answer', e.stream, n._dtlsRole)),
                        e.rtcpParameters && e.rtcpParameters.reducedSize && (a += 'a=rtcp-rsize\r\n');
                    }
                });
                var l = new e.RTCSessionDescription({ type: 'answer', sdp: a });
                return Promise.resolve(l);
              }),
              (n.prototype.addIceCandidate = function (e) {
                var t;
                if (e && '' !== e.candidate) {
                  if (void 0 === e.sdpMLineIndex && !e.sdpMid) throw new TypeError('sdpMLineIndex or sdpMid required');
                  if (!this.remoteDescription) return Promise.reject(c('InvalidStateError', 'Can not add ICE candidate without a remote description'));
                  var n = e.sdpMLineIndex;
                  if (e.sdpMid)
                    for (var i = 0; i < this.transceivers.length; i++)
                      if (this.transceivers[i].mid === e.sdpMid) {
                        n = i;
                        break;
                      }
                  var s = this.transceivers[n];
                  if (!s) return Promise.reject(c('OperationError', 'Can not add ICE candidate'));
                  if (s.isDatachannel) return Promise.resolve();
                  var a = Object.keys(e.candidate).length > 0 ? r.parseCandidate(e.candidate) : {};
                  if ('tcp' === a.protocol && (0 === a.port || 9 === a.port)) return Promise.resolve();
                  if (a.component && 1 !== a.component) return Promise.resolve();
                  if ((0 === n || (n > 0 && s.iceTransport !== this.transceivers[0].iceTransport)) && !o(s.iceTransport, a))
                    return Promise.reject(c('OperationError', 'Can not add ICE candidate'));
                  var l = e.candidate.trim();
                  0 === l.indexOf('a=') && (l = l.substr(2)),
                    ((t = r.splitSections(this.remoteDescription.sdp))[n + 1] += 'a=' + (a.type ? l : 'end-of-candidates') + '\r\n'),
                    (this.remoteDescription.sdp = t.join(''));
                } else
                  for (
                    var u = 0;
                    u < this.transceivers.length &&
                    (this.transceivers[u].isDatachannel ||
                      (this.transceivers[u].iceTransport.addRemoteCandidate({}),
                      ((t = r.splitSections(this.remoteDescription.sdp))[u + 1] += 'a=end-of-candidates\r\n'),
                      (this.remoteDescription.sdp = t.join('')),
                      !this.usingBundle));
                    u++
                  );
                return Promise.resolve();
              }),
              (n.prototype.getStats = function () {
                var e = [];
                this.transceivers.forEach(function (t) {
                  ['rtpSender', 'rtpReceiver', 'iceGatherer', 'iceTransport', 'dtlsTransport'].forEach(function (n) {
                    t[n] && e.push(t[n].getStats());
                  });
                });
                return new Promise(function (t) {
                  var n = new Map();
                  Promise.all(e).then(function (e) {
                    e.forEach(function (e) {
                      Object.keys(e).forEach(function (t) {
                        e[t].type =
                          ((r = e[t]),
                          {
                            inboundrtp: 'inbound-rtp',
                            outboundrtp: 'outbound-rtp',
                            candidatepair: 'candidate-pair',
                            localcandidate: 'local-candidate',
                            remotecandidate: 'remote-candidate',
                          }[r.type] || r.type);
                        var r;
                        n.set(t, e[t]);
                      });
                    }),
                      t(n);
                  });
                });
              });
            var d = ['createOffer', 'createAnswer'];
            return (
              d.forEach(function (e) {
                var t = n.prototype[e];
                n.prototype[e] = function () {
                  var e = arguments;
                  return 'function' == typeof e[0] || 'function' == typeof e[1]
                    ? t.apply(this, [arguments[2]]).then(
                        function (t) {
                          'function' == typeof e[0] && e[0].apply(null, [t]);
                        },
                        function (t) {
                          'function' == typeof e[1] && e[1].apply(null, [t]);
                        }
                      )
                    : t.apply(this, arguments);
                };
              }),
              (d = ['setLocalDescription', 'setRemoteDescription', 'addIceCandidate']).forEach(function (e) {
                var t = n.prototype[e];
                n.prototype[e] = function () {
                  var e = arguments;
                  return 'function' == typeof e[1] || 'function' == typeof e[2]
                    ? t.apply(this, arguments).then(
                        function () {
                          'function' == typeof e[1] && e[1].apply(null);
                        },
                        function (t) {
                          'function' == typeof e[2] && e[2].apply(null, [t]);
                        }
                      )
                    : t.apply(this, arguments);
                };
              }),
              ['getStats'].forEach(function (e) {
                var t = n.prototype[e];
                n.prototype[e] = function () {
                  var e = arguments;
                  return 'function' == typeof e[1]
                    ? t.apply(this, arguments).then(function () {
                        'function' == typeof e[1] && e[1].apply(null);
                      })
                    : t.apply(this, arguments);
                };
              }),
              n
            );
          };
        },
        { sdp: 39 },
      ],
      35: [
        function (e, t, n) {
          var r = (t.exports = {
            v: [{ name: 'version', reg: /^(\d*)$/ }],
            o: [
              {
                name: 'origin',
                reg: /^(\S*) (\d*) (\d*) (\S*) IP(\d) (\S*)/,
                names: ['username', 'sessionId', 'sessionVersion', 'netType', 'ipVer', 'address'],
                format: '%s %s %d %s IP%d %s',
              },
            ],
            s: [{ name: 'name' }],
            i: [{ name: 'description' }],
            u: [{ name: 'uri' }],
            e: [{ name: 'email' }],
            p: [{ name: 'phone' }],
            z: [{ name: 'timezones' }],
            r: [{ name: 'repeats' }],
            t: [{ name: 'timing', reg: /^(\d*) (\d*)/, names: ['start', 'stop'], format: '%d %d' }],
            c: [{ name: 'connection', reg: /^IN IP(\d) (\S*)/, names: ['version', 'ip'], format: 'IN IP%d %s' }],
            b: [{ push: 'bandwidth', reg: /^(TIAS|AS|CT|RR|RS):(\d*)/, names: ['type', 'limit'], format: '%s:%s' }],
            m: [{ reg: /^(\w*) (\d*) ([\w\/]*)(?: (.*))?/, names: ['type', 'port', 'protocol', 'payloads'], format: '%s %d %s %s' }],
            a: [
              {
                push: 'rtp',
                reg: /^rtpmap:(\d*) ([\w\-\.]*)(?:\s*\/(\d*)(?:\s*\/(\S*))?)?/,
                names: ['payload', 'codec', 'rate', 'encoding'],
                format: function (e) {
                  return e.encoding ? 'rtpmap:%d %s/%s/%s' : e.rate ? 'rtpmap:%d %s/%s' : 'rtpmap:%d %s';
                },
              },
              { push: 'fmtp', reg: /^fmtp:(\d*) ([\S| ]*)/, names: ['payload', 'config'], format: 'fmtp:%d %s' },
              { name: 'control', reg: /^control:(.*)/, format: 'control:%s' },
              {
                name: 'rtcp',
                reg: /^rtcp:(\d*)(?: (\S*) IP(\d) (\S*))?/,
                names: ['port', 'netType', 'ipVer', 'address'],
                format: function (e) {
                  return null != e.address ? 'rtcp:%d %s IP%d %s' : 'rtcp:%d';
                },
              },
              { push: 'rtcpFbTrrInt', reg: /^rtcp-fb:(\*|\d*) trr-int (\d*)/, names: ['payload', 'value'], format: 'rtcp-fb:%d trr-int %d' },
              {
                push: 'rtcpFb',
                reg: /^rtcp-fb:(\*|\d*) ([\w-_]*)(?: ([\w-_]*))?/,
                names: ['payload', 'type', 'subtype'],
                format: function (e) {
                  return null != e.subtype ? 'rtcp-fb:%s %s %s' : 'rtcp-fb:%s %s';
                },
              },
              {
                push: 'ext',
                reg: /^extmap:(\d+)(?:\/(\w+))? (\S*)(?: (\S*))?/,
                names: ['value', 'direction', 'uri', 'config'],
                format: function (e) {
                  return 'extmap:%d' + (e.direction ? '/%s' : '%v') + ' %s' + (e.config ? ' %s' : '');
                },
              },
              {
                push: 'crypto',
                reg: /^crypto:(\d*) ([\w_]*) (\S*)(?: (\S*))?/,
                names: ['id', 'suite', 'config', 'sessionConfig'],
                format: function (e) {
                  return null != e.sessionConfig ? 'crypto:%d %s %s %s' : 'crypto:%d %s %s';
                },
              },
              { name: 'setup', reg: /^setup:(\w*)/, format: 'setup:%s' },
              { name: 'mid', reg: /^mid:([^\s]*)/, format: 'mid:%s' },
              { name: 'msid', reg: /^msid:(.*)/, format: 'msid:%s' },
              { name: 'ptime', reg: /^ptime:(\d*)/, format: 'ptime:%d' },
              { name: 'maxptime', reg: /^maxptime:(\d*)/, format: 'maxptime:%d' },
              { name: 'direction', reg: /^(sendrecv|recvonly|sendonly|inactive)/ },
              { name: 'icelite', reg: /^(ice-lite)/ },
              { name: 'iceUfrag', reg: /^ice-ufrag:(\S*)/, format: 'ice-ufrag:%s' },
              { name: 'icePwd', reg: /^ice-pwd:(\S*)/, format: 'ice-pwd:%s' },
              { name: 'fingerprint', reg: /^fingerprint:(\S*) (\S*)/, names: ['type', 'hash'], format: 'fingerprint:%s %s' },
              {
                push: 'candidates',
                reg: /^candidate:(\S*) (\d*) (\S*) (\d*) (\S*) (\d*) typ (\S*)(?: raddr (\S*) rport (\d*))?(?: tcptype (\S*))?(?: generation (\d*))?(?: network-id (\d*))?(?: network-cost (\d*))?/,
                names: [
                  'foundation',
                  'component',
                  'transport',
                  'priority',
                  'ip',
                  'port',
                  'type',
                  'raddr',
                  'rport',
                  'tcptype',
                  'generation',
                  'network-id',
                  'network-cost',
                ],
                format: function (e) {
                  var t = 'candidate:%s %d %s %d %s %d typ %s';
                  return (
                    (t += null != e.raddr ? ' raddr %s rport %d' : '%v%v'),
                    (t += null != e.tcptype ? ' tcptype %s' : '%v'),
                    null != e.generation && (t += ' generation %d'),
                    (t += null != e['network-id'] ? ' network-id %d' : '%v'),
                    (t += null != e['network-cost'] ? ' network-cost %d' : '%v')
                  );
                },
              },
              { name: 'endOfCandidates', reg: /^(end-of-candidates)/ },
              { name: 'remoteCandidates', reg: /^remote-candidates:(.*)/, format: 'remote-candidates:%s' },
              { name: 'iceOptions', reg: /^ice-options:(\S*)/, format: 'ice-options:%s' },
              {
                push: 'ssrcs',
                reg: /^ssrc:(\d*) ([\w_]*)(?::(.*))?/,
                names: ['id', 'attribute', 'value'],
                format: function (e) {
                  var t = 'ssrc:%d';
                  return null != e.attribute && ((t += ' %s'), null != e.value && (t += ':%s')), t;
                },
              },
              { push: 'ssrcGroups', reg: /^ssrc-group:([\x21\x23\x24\x25\x26\x27\x2A\x2B\x2D\x2E\w]*) (.*)/, names: ['semantics', 'ssrcs'], format: 'ssrc-group:%s %s' },
              { name: 'msidSemantic', reg: /^msid-semantic:\s?(\w*) (\S*)/, names: ['semantic', 'token'], format: 'msid-semantic: %s %s' },
              { push: 'groups', reg: /^group:(\w*) (.*)/, names: ['type', 'mids'], format: 'group:%s %s' },
              { name: 'rtcpMux', reg: /^(rtcp-mux)/ },
              { name: 'rtcpRsize', reg: /^(rtcp-rsize)/ },
              {
                name: 'sctpmap',
                reg: /^sctpmap:([\w_\/]*) (\S*)(?: (\S*))?/,
                names: ['sctpmapNumber', 'app', 'maxMessageSize'],
                format: function (e) {
                  return null != e.maxMessageSize ? 'sctpmap:%s %s %s' : 'sctpmap:%s %s';
                },
              },
              { name: 'xGoogleFlag', reg: /^x-google-flag:([^\s]*)/, format: 'x-google-flag:%s' },
              {
                push: 'rids',
                reg: /^rid:([\d\w]+) (\w+)(?: ([\S| ]*))?/,
                names: ['id', 'direction', 'params'],
                format: function (e) {
                  return e.params ? 'rid:%s %s %s' : 'rid:%s %s';
                },
              },
              {
                push: 'imageattrs',
                reg: new RegExp(
                  '^imageattr:(\\d+|\\*)[\\s\\t]+(send|recv)[\\s\\t]+(\\*|\\[\\S+\\](?:[\\s\\t]+\\[\\S+\\])*)(?:[\\s\\t]+(recv|send)[\\s\\t]+(\\*|\\[\\S+\\](?:[\\s\\t]+\\[\\S+\\])*))?'
                ),
                names: ['pt', 'dir1', 'attrs1', 'dir2', 'attrs2'],
                format: function (e) {
                  return 'imageattr:%s %s %s' + (e.dir2 ? ' %s %s' : '');
                },
              },
              {
                name: 'simulcast',
                reg: new RegExp('^simulcast:(send|recv) ([a-zA-Z0-9\\-_~;,]+)(?:\\s?(send|recv) ([a-zA-Z0-9\\-_~;,]+))?$'),
                names: ['dir1', 'list1', 'dir2', 'list2'],
                format: function (e) {
                  return 'simulcast:%s %s' + (e.dir2 ? ' %s %s' : '');
                },
              },
              { name: 'simulcast_03', reg: /^simulcast:[\s\t]+([\S+\s\t]+)$/, names: ['value'], format: 'simulcast: %s' },
              { name: 'framerate', reg: /^framerate:(\d+(?:$|\.\d+))/, format: 'framerate:%s' },
              { push: 'invalid', names: ['value'] },
            ],
          });
          Object.keys(r).forEach(function (e) {
            r[e].forEach(function (e) {
              e.reg || (e.reg = /(.*)/), e.format || (e.format = '%s');
            });
          });
        },
        {},
      ],
      36: [
        function (e, t, n) {
          var r = e('./parser'),
            i = e('./writer');
          (n.write = i),
            (n.parse = r.parse),
            (n.parseFmtpConfig = r.parseFmtpConfig),
            (n.parseParams = r.parseParams),
            (n.parsePayloads = r.parsePayloads),
            (n.parseRemoteCandidates = r.parseRemoteCandidates),
            (n.parseImageAttributes = r.parseImageAttributes),
            (n.parseSimulcastStreamList = r.parseSimulcastStreamList);
        },
        { './parser': 37, './writer': 38 },
      ],
      37: [
        function (e, t, n) {
          var r = function (e) {
              return String(Number(e)) === e ? Number(e) : e;
            },
            i = function (e, t, n) {
              var i = e.name && e.names;
              e.push && !t[e.push] ? (t[e.push] = []) : i && !t[e.name] && (t[e.name] = {});
              var s = e.push ? {} : i ? t[e.name] : t;
              !(function (e, t, n, i) {
                if (i && !n) t[i] = r(e[1]);
                else for (var s = 0; s < n.length; s += 1) null != e[s + 1] && (t[n[s]] = r(e[s + 1]));
              })(n.match(e.reg), s, e.names, e.name),
                e.push && t[e.push].push(s);
            },
            s = e('./grammar'),
            a = RegExp.prototype.test.bind(/^([a-z])=(.*)/);
          n.parse = function (e) {
            var t = {},
              n = [],
              r = t;
            return (
              e
                .split(/(\r\n|\r|\n)/)
                .filter(a)
                .forEach(function (e) {
                  var t = e[0],
                    a = e.slice(2);
                  'm' === t && (n.push({ rtp: [], fmtp: [] }), (r = n[n.length - 1]));
                  for (var o = 0; o < (s[t] || []).length; o += 1) {
                    var l = s[t][o];
                    if (l.reg.test(a)) return i(l, r, a);
                  }
                }),
              (t.media = n),
              t
            );
          };
          var o = function (e, t) {
            var n = t.split(/=(.+)/, 2);
            return 2 === n.length && (e[n[0]] = r(n[1])), e;
          };
          (n.parseParams = function (e) {
            return e.split(/\;\s?/).reduce(o, {});
          }),
            (n.parseFmtpConfig = n.parseParams),
            (n.parsePayloads = function (e) {
              return e.split(' ').map(Number);
            }),
            (n.parseRemoteCandidates = function (e) {
              for (var t = [], n = e.split(' ').map(r), i = 0; i < n.length; i += 3) t.push({ component: n[i], ip: n[i + 1], port: n[i + 2] });
              return t;
            }),
            (n.parseImageAttributes = function (e) {
              return e.split(' ').map(function (e) {
                return e
                  .substring(1, e.length - 1)
                  .split(',')
                  .reduce(o, {});
              });
            }),
            (n.parseSimulcastStreamList = function (e) {
              return e.split(';').map(function (e) {
                return e.split(',').map(function (e) {
                  var t,
                    n = !1;
                  return '~' !== e[0] ? (t = r(e)) : ((t = r(e.substring(1, e.length))), (n = !0)), { scid: t, paused: n };
                });
              });
            });
        },
        { './grammar': 35 },
      ],
      38: [
        function (e, t, n) {
          var r = e('./grammar'),
            i = /%[sdv%]/g,
            s = function (e, t, n) {
              var r = [e + '=' + (t.format instanceof Function ? t.format(t.push ? n : n[t.name]) : t.format)];
              if (t.names)
                for (var s = 0; s < t.names.length; s += 1) {
                  var a = t.names[s];
                  t.name ? r.push(n[t.name][a]) : r.push(n[t.names[s]]);
                }
              else r.push(n[t.name]);
              return function (e) {
                var t = 1,
                  n = arguments,
                  r = n.length;
                return e.replace(i, function (e) {
                  if (t >= r) return e;
                  var i = n[t];
                  switch (((t += 1), e)) {
                    case '%%':
                      return '%';
                    case '%s':
                      return String(i);
                    case '%d':
                      return Number(i);
                    case '%v':
                      return '';
                  }
                });
              }.apply(null, r);
            },
            a = ['v', 'o', 's', 'i', 'u', 'e', 'p', 'c', 'b', 't', 'r', 'z', 'a'],
            o = ['i', 'c', 'b', 'a'];
          t.exports = function (e, t) {
            (t = t || {}),
              null == e.version && (e.version = 0),
              null == e.name && (e.name = ' '),
              e.media.forEach(function (e) {
                null == e.payloads && (e.payloads = '');
              });
            var n = t.outerOrder || a,
              i = t.innerOrder || o,
              l = [];
            return (
              n.forEach(function (t) {
                r[t].forEach(function (n) {
                  n.name in e && null != e[n.name]
                    ? l.push(s(t, n, e))
                    : n.push in e &&
                      null != e[n.push] &&
                      e[n.push].forEach(function (e) {
                        l.push(s(t, n, e));
                      });
                });
              }),
              e.media.forEach(function (e) {
                l.push(s('m', r.m[0], e)),
                  i.forEach(function (t) {
                    r[t].forEach(function (n) {
                      n.name in e && null != e[n.name]
                        ? l.push(s(t, n, e))
                        : n.push in e &&
                          null != e[n.push] &&
                          e[n.push].forEach(function (e) {
                            l.push(s(t, n, e));
                          });
                    });
                  });
              }),
              l.join('\r\n') + '\r\n'
            );
          };
        },
        { './grammar': 35 },
      ],
      39: [
        function (e, t, n) {
          'use strict';
          var r = {};
          (r.generateIdentifier = function () {
            return Math.random().toString(36).substr(2, 10);
          }),
            (r.localCName = r.generateIdentifier()),
            (r.splitLines = function (e) {
              return e
                .trim()
                .split('\n')
                .map(function (e) {
                  return e.trim();
                });
            }),
            (r.splitSections = function (e) {
              return e.split('\nm=').map(function (e, t) {
                return (t > 0 ? 'm=' + e : e).trim() + '\r\n';
              });
            }),
            (r.matchPrefix = function (e, t) {
              return r.splitLines(e).filter(function (e) {
                return 0 === e.indexOf(t);
              });
            }),
            (r.parseCandidate = function (e) {
              for (
                var t,
                  n = {
                    foundation: (t = 0 === e.indexOf('a=candidate:') ? e.substring(12).split(' ') : e.substring(10).split(' '))[0],
                    component: parseInt(t[1], 10),
                    protocol: t[2].toLowerCase(),
                    priority: parseInt(t[3], 10),
                    ip: t[4],
                    port: parseInt(t[5], 10),
                    type: t[7],
                  },
                  r = 8;
                r < t.length;
                r += 2
              )
                switch (t[r]) {
                  case 'raddr':
                    n.relatedAddress = t[r + 1];
                    break;
                  case 'rport':
                    n.relatedPort = parseInt(t[r + 1], 10);
                    break;
                  case 'tcptype':
                    n.tcpType = t[r + 1];
                    break;
                  case 'ufrag':
                    (n.ufrag = t[r + 1]), (n.usernameFragment = t[r + 1]);
                    break;
                  default:
                    n[t[r]] = t[r + 1];
                }
              return n;
            }),
            (r.writeCandidate = function (e) {
              var t = [];
              t.push(e.foundation), t.push(e.component), t.push(e.protocol.toUpperCase()), t.push(e.priority), t.push(e.ip), t.push(e.port);
              var n = e.type;
              return (
                t.push('typ'),
                t.push(n),
                'host' !== n && e.relatedAddress && e.relatedPort && (t.push('raddr'), t.push(e.relatedAddress), t.push('rport'), t.push(e.relatedPort)),
                e.tcpType && 'tcp' === e.protocol.toLowerCase() && (t.push('tcptype'), t.push(e.tcpType)),
                e.ufrag && (t.push('ufrag'), t.push(e.ufrag)),
                'candidate:' + t.join(' ')
              );
            }),
            (r.parseIceOptions = function (e) {
              return e.substr(14).split(' ');
            }),
            (r.parseRtpMap = function (e) {
              var t = e.substr(9).split(' '),
                n = { payloadType: parseInt(t.shift(), 10) };
              return (t = t[0].split('/')), (n.name = t[0]), (n.clockRate = parseInt(t[1], 10)), (n.numChannels = 3 === t.length ? parseInt(t[2], 10) : 1), n;
            }),
            (r.writeRtpMap = function (e) {
              var t = e.payloadType;
              return (
                void 0 !== e.preferredPayloadType && (t = e.preferredPayloadType),
                'a=rtpmap:' + t + ' ' + e.name + '/' + e.clockRate + (1 !== e.numChannels ? '/' + e.numChannels : '') + '\r\n'
              );
            }),
            (r.parseExtmap = function (e) {
              var t = e.substr(9).split(' ');
              return { id: parseInt(t[0], 10), direction: t[0].indexOf('/') > 0 ? t[0].split('/')[1] : 'sendrecv', uri: t[1] };
            }),
            (r.writeExtmap = function (e) {
              return 'a=extmap:' + (e.id || e.preferredId) + (e.direction && 'sendrecv' !== e.direction ? '/' + e.direction : '') + ' ' + e.uri + '\r\n';
            }),
            (r.parseFmtp = function (e) {
              for (var t, n = {}, r = e.substr(e.indexOf(' ') + 1).split(';'), i = 0; i < r.length; i++) n[(t = r[i].trim().split('='))[0].trim()] = t[1];
              return n;
            }),
            (r.writeFmtp = function (e) {
              var t = '',
                n = e.payloadType;
              if ((void 0 !== e.preferredPayloadType && (n = e.preferredPayloadType), e.parameters && Object.keys(e.parameters).length)) {
                var r = [];
                Object.keys(e.parameters).forEach(function (t) {
                  r.push(t + '=' + e.parameters[t]);
                }),
                  (t += 'a=fmtp:' + n + ' ' + r.join(';') + '\r\n');
              }
              return t;
            }),
            (r.parseRtcpFb = function (e) {
              var t = e.substr(e.indexOf(' ') + 1).split(' ');
              return { type: t.shift(), parameter: t.join(' ') };
            }),
            (r.writeRtcpFb = function (e) {
              var t = '',
                n = e.payloadType;
              return (
                void 0 !== e.preferredPayloadType && (n = e.preferredPayloadType),
                e.rtcpFeedback &&
                  e.rtcpFeedback.length &&
                  e.rtcpFeedback.forEach(function (e) {
                    t += 'a=rtcp-fb:' + n + ' ' + e.type + (e.parameter && e.parameter.length ? ' ' + e.parameter : '') + '\r\n';
                  }),
                t
              );
            }),
            (r.parseSsrcMedia = function (e) {
              var t = e.indexOf(' '),
                n = { ssrc: parseInt(e.substr(7, t - 7), 10) },
                r = e.indexOf(':', t);
              return r > -1 ? ((n.attribute = e.substr(t + 1, r - t - 1)), (n.value = e.substr(r + 1))) : (n.attribute = e.substr(t + 1)), n;
            }),
            (r.getMid = function (e) {
              var t = r.matchPrefix(e, 'a=mid:')[0];
              if (t) return t.substr(6);
            }),
            (r.parseFingerprint = function (e) {
              var t = e.substr(14).split(' ');
              return { algorithm: t[0].toLowerCase(), value: t[1] };
            }),
            (r.getDtlsParameters = function (e, t) {
              return { role: 'auto', fingerprints: r.matchPrefix(e + t, 'a=fingerprint:').map(r.parseFingerprint) };
            }),
            (r.writeDtlsParameters = function (e, t) {
              var n = 'a=setup:' + t + '\r\n';
              return (
                e.fingerprints.forEach(function (e) {
                  n += 'a=fingerprint:' + e.algorithm + ' ' + e.value + '\r\n';
                }),
                n
              );
            }),
            (r.getIceParameters = function (e, t) {
              var n = r.splitLines(e);
              return {
                usernameFragment: (n = n.concat(r.splitLines(t)))
                  .filter(function (e) {
                    return 0 === e.indexOf('a=ice-ufrag:');
                  })[0]
                  .substr(12),
                password: n
                  .filter(function (e) {
                    return 0 === e.indexOf('a=ice-pwd:');
                  })[0]
                  .substr(10),
              };
            }),
            (r.writeIceParameters = function (e) {
              return 'a=ice-ufrag:' + e.usernameFragment + '\r\na=ice-pwd:' + e.password + '\r\n';
            }),
            (r.parseRtpParameters = function (e) {
              for (var t = { codecs: [], headerExtensions: [], fecMechanisms: [], rtcp: [] }, n = r.splitLines(e)[0].split(' '), i = 3; i < n.length; i++) {
                var s = n[i],
                  a = r.matchPrefix(e, 'a=rtpmap:' + s + ' ')[0];
                if (a) {
                  var o = r.parseRtpMap(a),
                    l = r.matchPrefix(e, 'a=fmtp:' + s + ' ');
                  switch (
                    ((o.parameters = l.length ? r.parseFmtp(l[0]) : {}),
                    (o.rtcpFeedback = r.matchPrefix(e, 'a=rtcp-fb:' + s + ' ').map(r.parseRtcpFb)),
                    t.codecs.push(o),
                    o.name.toUpperCase())
                  ) {
                    case 'RED':
                    case 'ULPFEC':
                      t.fecMechanisms.push(o.name.toUpperCase());
                  }
                }
              }
              return (
                r.matchPrefix(e, 'a=extmap:').forEach(function (e) {
                  t.headerExtensions.push(r.parseExtmap(e));
                }),
                t
              );
            }),
            (r.writeRtpDescription = function (e, t) {
              var n = '';
              (n += 'm=' + e + ' '),
                (n += t.codecs.length > 0 ? '9' : '0'),
                (n += ' UDP/TLS/RTP/SAVPF '),
                (n +=
                  t.codecs
                    .map(function (e) {
                      return void 0 !== e.preferredPayloadType ? e.preferredPayloadType : e.payloadType;
                    })
                    .join(' ') + '\r\n'),
                (n += 'c=IN IP4 0.0.0.0\r\n'),
                (n += 'a=rtcp:9 IN IP4 0.0.0.0\r\n'),
                t.codecs.forEach(function (e) {
                  (n += r.writeRtpMap(e)), (n += r.writeFmtp(e)), (n += r.writeRtcpFb(e));
                });
              var i = 0;
              return (
                t.codecs.forEach(function (e) {
                  e.maxptime > i && (i = e.maxptime);
                }),
                i > 0 && (n += 'a=maxptime:' + i + '\r\n'),
                (n += 'a=rtcp-mux\r\n'),
                t.headerExtensions.forEach(function (e) {
                  n += r.writeExtmap(e);
                }),
                n
              );
            }),
            (r.parseRtpEncodingParameters = function (e) {
              var t,
                n = [],
                i = r.parseRtpParameters(e),
                s = -1 !== i.fecMechanisms.indexOf('RED'),
                a = -1 !== i.fecMechanisms.indexOf('ULPFEC'),
                o = r
                  .matchPrefix(e, 'a=ssrc:')
                  .map(function (e) {
                    return r.parseSsrcMedia(e);
                  })
                  .filter(function (e) {
                    return 'cname' === e.attribute;
                  }),
                l = o.length > 0 && o[0].ssrc,
                u = r.matchPrefix(e, 'a=ssrc-group:FID').map(function (e) {
                  var t = e.split(' ');
                  return (
                    t.shift(),
                    t.map(function (e) {
                      return parseInt(e, 10);
                    })
                  );
                });
              u.length > 0 && u[0].length > 1 && u[0][0] === l && (t = u[0][1]),
                i.codecs.forEach(function (e) {
                  if ('RTX' === e.name.toUpperCase() && e.parameters.apt) {
                    var r = { ssrc: l, codecPayloadType: parseInt(e.parameters.apt, 10), rtx: { ssrc: t } };
                    n.push(r), s && (((r = JSON.parse(JSON.stringify(r))).fec = { ssrc: t, mechanism: a ? 'red+ulpfec' : 'red' }), n.push(r));
                  }
                }),
                0 === n.length && l && n.push({ ssrc: l });
              var c = r.matchPrefix(e, 'b=');
              return (
                c.length &&
                  ((c =
                    0 === c[0].indexOf('b=TIAS:')
                      ? parseInt(c[0].substr(7), 10)
                      : 0 === c[0].indexOf('b=AS:')
                      ? 1e3 * parseInt(c[0].substr(5), 10) * 0.95 - 16e3
                      : void 0),
                  n.forEach(function (e) {
                    e.maxBitrate = c;
                  })),
                n
              );
            }),
            (r.parseRtcpParameters = function (e) {
              var t = {},
                n = r
                  .matchPrefix(e, 'a=ssrc:')
                  .map(function (e) {
                    return r.parseSsrcMedia(e);
                  })
                  .filter(function (e) {
                    return 'cname' === e.attribute;
                  })[0];
              n && ((t.cname = n.value), (t.ssrc = n.ssrc));
              var i = r.matchPrefix(e, 'a=rtcp-rsize');
              (t.reducedSize = i.length > 0), (t.compound = 0 === i.length);
              var s = r.matchPrefix(e, 'a=rtcp-mux');
              return (t.mux = s.length > 0), t;
            }),
            (r.parseMsid = function (e) {
              var t,
                n = r.matchPrefix(e, 'a=msid:');
              if (1 === n.length) return { stream: (t = n[0].substr(7).split(' '))[0], track: t[1] };
              var i = r
                .matchPrefix(e, 'a=ssrc:')
                .map(function (e) {
                  return r.parseSsrcMedia(e);
                })
                .filter(function (e) {
                  return 'msid' === e.attribute;
                });
              return i.length > 0 ? { stream: (t = i[0].value.split(' '))[0], track: t[1] } : void 0;
            }),
            (r.generateSessionId = function () {
              return Math.random().toString().substr(2, 21);
            }),
            (r.writeSessionBoilerplate = function (e, t) {
              var n = void 0 !== t ? t : 2;
              return 'v=0\r\no=thisisadapterortc ' + (e || r.generateSessionId()) + ' ' + n + ' IN IP4 127.0.0.1\r\ns=-\r\nt=0 0\r\n';
            }),
            (r.writeMediaSection = function (e, t, n, i) {
              var s = r.writeRtpDescription(e.kind, t);
              if (
                ((s += r.writeIceParameters(e.iceGatherer.getLocalParameters())),
                (s += r.writeDtlsParameters(e.dtlsTransport.getLocalParameters(), 'offer' === n ? 'actpass' : 'active')),
                (s += 'a=mid:' + e.mid + '\r\n'),
                e.direction
                  ? (s += 'a=' + e.direction + '\r\n')
                  : e.rtpSender && e.rtpReceiver
                  ? (s += 'a=sendrecv\r\n')
                  : e.rtpSender
                  ? (s += 'a=sendonly\r\n')
                  : e.rtpReceiver
                  ? (s += 'a=recvonly\r\n')
                  : (s += 'a=inactive\r\n'),
                e.rtpSender)
              ) {
                var a = 'msid:' + i.id + ' ' + e.rtpSender.track.id + '\r\n';
                (s += 'a=' + a),
                  (s += 'a=ssrc:' + e.sendEncodingParameters[0].ssrc + ' ' + a),
                  e.sendEncodingParameters[0].rtx &&
                    ((s += 'a=ssrc:' + e.sendEncodingParameters[0].rtx.ssrc + ' ' + a),
                    (s += 'a=ssrc-group:FID ' + e.sendEncodingParameters[0].ssrc + ' ' + e.sendEncodingParameters[0].rtx.ssrc + '\r\n'));
              }
              return (
                (s += 'a=ssrc:' + e.sendEncodingParameters[0].ssrc + ' cname:' + r.localCName + '\r\n'),
                e.rtpSender && e.sendEncodingParameters[0].rtx && (s += 'a=ssrc:' + e.sendEncodingParameters[0].rtx.ssrc + ' cname:' + r.localCName + '\r\n'),
                s
              );
            }),
            (r.getDirection = function (e, t) {
              for (var n = r.splitLines(e), i = 0; i < n.length; i++)
                switch (n[i]) {
                  case 'a=sendrecv':
                  case 'a=sendonly':
                  case 'a=recvonly':
                  case 'a=inactive':
                    return n[i].substr(2);
                }
              return t ? r.getDirection(t) : 'sendrecv';
            }),
            (r.getKind = function (e) {
              return r.splitLines(e)[0].split(' ')[0].substr(2);
            }),
            (r.isRejected = function (e) {
              return '0' === e.split(' ', 2)[1];
            }),
            (r.parseMLine = function (e) {
              var t = r.splitLines(e)[0].split(' ');
              return { kind: t[0].substr(2), port: parseInt(t[1], 10), protocol: t[2], fmt: t.slice(3).join(' ') };
            }),
            'object' == typeof t && (t.exports = r);
        },
        {},
      ],
      40: [
        function (e, t, n) {
          (function (n) {
            'use strict';
            var r = e('./adapter_factory.js');
            t.exports = r({ window: n.window });
          }.call(this, 'undefined' != typeof global ? global : 'undefined' != typeof self ? self : 'undefined' != typeof window ? window : {}));
        },
        { './adapter_factory.js': 41 },
      ],
      41: [
        function (e, t, n) {
          'use strict';
          var r = e('./utils');
          t.exports = function (t, n) {
            var i = t && t.window,
              s = { shimChrome: !0, shimFirefox: !0, shimEdge: !0, shimSafari: !0 };
            for (var a in n) hasOwnProperty.call(n, a) && (s[a] = n[a]);
            var o = r.log,
              l = r.detectBrowser(i),
              u = { browserDetails: l, extractVersion: r.extractVersion, disableLog: r.disableLog, disableWarnings: r.disableWarnings },
              c = e('./chrome/chrome_shim') || null,
              d = e('./edge/edge_shim') || null,
              h = e('./firefox/firefox_shim') || null,
              f = e('./safari/safari_shim') || null,
              p = e('./common_shim') || null;
            switch (l.browser) {
              case 'chrome':
                if (!c || !c.shimPeerConnection || !s.shimChrome) return o('Chrome shim is not included in this adapter release.'), u;
                o('adapter.js shimming chrome.'),
                  (u.browserShim = c),
                  p.shimCreateObjectURL(i),
                  c.shimGetUserMedia(i),
                  c.shimMediaStream(i),
                  c.shimSourceObject(i),
                  c.shimPeerConnection(i),
                  c.shimOnTrack(i),
                  c.shimAddTrackRemoveTrack(i),
                  c.shimGetSendersWithDtmf(i),
                  p.shimRTCIceCandidate(i);
                break;
              case 'firefox':
                if (!h || !h.shimPeerConnection || !s.shimFirefox) return o('Firefox shim is not included in this adapter release.'), u;
                o('adapter.js shimming firefox.'),
                  (u.browserShim = h),
                  p.shimCreateObjectURL(i),
                  h.shimGetUserMedia(i),
                  h.shimSourceObject(i),
                  h.shimPeerConnection(i),
                  h.shimOnTrack(i),
                  h.shimRemoveStream(i),
                  p.shimRTCIceCandidate(i);
                break;
              case 'edge':
                if (!d || !d.shimPeerConnection || !s.shimEdge) return o('MS edge shim is not included in this adapter release.'), u;
                o('adapter.js shimming edge.'), (u.browserShim = d), p.shimCreateObjectURL(i), d.shimGetUserMedia(i), d.shimPeerConnection(i), d.shimReplaceTrack(i);
                break;
              case 'safari':
                if (!f || !s.shimSafari) return o('Safari shim is not included in this adapter release.'), u;
                o('adapter.js shimming safari.'),
                  (u.browserShim = f),
                  p.shimCreateObjectURL(i),
                  f.shimRTCIceServerUrls(i),
                  f.shimCallbacksAPI(i),
                  f.shimLocalStreamsAPI(i),
                  f.shimRemoteStreamsAPI(i),
                  f.shimTrackEventTransceiver(i),
                  f.shimGetUserMedia(i),
                  f.shimCreateOfferLegacy(i),
                  p.shimRTCIceCandidate(i);
                break;
              default:
                o('Unsupported browser!');
            }
            return u;
          };
        },
        { './chrome/chrome_shim': 42, './common_shim': 44, './edge/edge_shim': 45, './firefox/firefox_shim': 47, './safari/safari_shim': 49, './utils': 50 },
      ],
      42: [
        function (e, t, n) {
          'use strict';
          var r = e('../utils.js'),
            i = r.log,
            s = {
              shimMediaStream: function (e) {
                e.MediaStream = e.MediaStream || e.webkitMediaStream;
              },
              shimOnTrack: function (e) {
                if ('object' == typeof e && e.RTCPeerConnection && !('ontrack' in e.RTCPeerConnection.prototype)) {
                  Object.defineProperty(e.RTCPeerConnection.prototype, 'ontrack', {
                    get: function () {
                      return this._ontrack;
                    },
                    set: function (e) {
                      this._ontrack && this.removeEventListener('track', this._ontrack), this.addEventListener('track', (this._ontrack = e));
                    },
                  });
                  var t = e.RTCPeerConnection.prototype.setRemoteDescription;
                  e.RTCPeerConnection.prototype.setRemoteDescription = function () {
                    var n = this;
                    return (
                      n._ontrackpoly ||
                        ((n._ontrackpoly = function (t) {
                          t.stream.addEventListener('addtrack', function (r) {
                            var i;
                            i = e.RTCPeerConnection.prototype.getReceivers
                              ? n.getReceivers().find(function (e) {
                                  return e.track && e.track.id === r.track.id;
                                })
                              : { track: r.track };
                            var s = new Event('track');
                            (s.track = r.track), (s.receiver = i), (s.transceiver = { receiver: i }), (s.streams = [t.stream]), n.dispatchEvent(s);
                          }),
                            t.stream.getTracks().forEach(function (r) {
                              var i;
                              i = e.RTCPeerConnection.prototype.getReceivers
                                ? n.getReceivers().find(function (e) {
                                    return e.track && e.track.id === r.id;
                                  })
                                : { track: r };
                              var s = new Event('track');
                              (s.track = r), (s.receiver = i), (s.transceiver = { receiver: i }), (s.streams = [t.stream]), n.dispatchEvent(s);
                            });
                        }),
                        n.addEventListener('addstream', n._ontrackpoly)),
                      t.apply(n, arguments)
                    );
                  };
                }
              },
              shimGetSendersWithDtmf: function (e) {
                if (
                  'object' == typeof e &&
                  e.RTCPeerConnection &&
                  !('getSenders' in e.RTCPeerConnection.prototype) &&
                  'createDTMFSender' in e.RTCPeerConnection.prototype
                ) {
                  var t = function (e, t) {
                    return {
                      track: t,
                      get dtmf() {
                        return void 0 === this._dtmf && ('audio' === t.kind ? (this._dtmf = e.createDTMFSender(t)) : (this._dtmf = null)), this._dtmf;
                      },
                      _pc: e,
                    };
                  };
                  if (!e.RTCPeerConnection.prototype.getSenders) {
                    e.RTCPeerConnection.prototype.getSenders = function () {
                      return (this._senders = this._senders || []), this._senders.slice();
                    };
                    var n = e.RTCPeerConnection.prototype.addTrack;
                    e.RTCPeerConnection.prototype.addTrack = function (e, r) {
                      var i = n.apply(this, arguments);
                      return i || ((i = t(this, e)), this._senders.push(i)), i;
                    };
                    var r = e.RTCPeerConnection.prototype.removeTrack;
                    e.RTCPeerConnection.prototype.removeTrack = function (e) {
                      r.apply(this, arguments);
                      var t = this._senders.indexOf(e);
                      -1 !== t && this._senders.splice(t, 1);
                    };
                  }
                  var i = e.RTCPeerConnection.prototype.addStream;
                  e.RTCPeerConnection.prototype.addStream = function (e) {
                    var n = this;
                    (n._senders = n._senders || []),
                      i.apply(n, [e]),
                      e.getTracks().forEach(function (e) {
                        n._senders.push(t(n, e));
                      });
                  };
                  var s = e.RTCPeerConnection.prototype.removeStream;
                  e.RTCPeerConnection.prototype.removeStream = function (e) {
                    var t = this;
                    (t._senders = t._senders || []),
                      s.apply(t, [e]),
                      e.getTracks().forEach(function (e) {
                        var n = t._senders.find(function (t) {
                          return t.track === e;
                        });
                        n && t._senders.splice(t._senders.indexOf(n), 1);
                      });
                  };
                } else if (
                  'object' == typeof e &&
                  e.RTCPeerConnection &&
                  'getSenders' in e.RTCPeerConnection.prototype &&
                  'createDTMFSender' in e.RTCPeerConnection.prototype &&
                  e.RTCRtpSender &&
                  !('dtmf' in e.RTCRtpSender.prototype)
                ) {
                  var a = e.RTCPeerConnection.prototype.getSenders;
                  (e.RTCPeerConnection.prototype.getSenders = function () {
                    var e = this,
                      t = a.apply(e, []);
                    return (
                      t.forEach(function (t) {
                        t._pc = e;
                      }),
                      t
                    );
                  }),
                    Object.defineProperty(e.RTCRtpSender.prototype, 'dtmf', {
                      get: function () {
                        return (
                          void 0 === this._dtmf && ('audio' === this.track.kind ? (this._dtmf = this._pc.createDTMFSender(this.track)) : (this._dtmf = null)), this._dtmf
                        );
                      },
                    });
                }
              },
              shimSourceObject: function (e) {
                var t = e && e.URL;
                'object' == typeof e &&
                  (!e.HTMLMediaElement ||
                    'srcObject' in e.HTMLMediaElement.prototype ||
                    Object.defineProperty(e.HTMLMediaElement.prototype, 'srcObject', {
                      get: function () {
                        return this._srcObject;
                      },
                      set: function (e) {
                        var n = this;
                        (this._srcObject = e),
                          this.src && t.revokeObjectURL(this.src),
                          e
                            ? ((this.src = t.createObjectURL(e)),
                              e.addEventListener('addtrack', function () {
                                n.src && t.revokeObjectURL(n.src), (n.src = t.createObjectURL(e));
                              }),
                              e.addEventListener('removetrack', function () {
                                n.src && t.revokeObjectURL(n.src), (n.src = t.createObjectURL(e));
                              }))
                            : (this.src = '');
                      },
                    }));
              },
              shimAddTrackRemoveTrack: function (e) {
                var t = r.detectBrowser(e);
                if (!(e.RTCPeerConnection.prototype.addTrack && t.version >= 64)) {
                  var n = e.RTCPeerConnection.prototype.getLocalStreams;
                  e.RTCPeerConnection.prototype.getLocalStreams = function () {
                    var e = this,
                      t = n.apply(this);
                    return (
                      (e._reverseStreams = e._reverseStreams || {}),
                      t.map(function (t) {
                        return e._reverseStreams[t.id];
                      })
                    );
                  };
                  var i = e.RTCPeerConnection.prototype.addStream;
                  e.RTCPeerConnection.prototype.addStream = function (t) {
                    var n = this;
                    if (
                      ((n._streams = n._streams || {}),
                      (n._reverseStreams = n._reverseStreams || {}),
                      t.getTracks().forEach(function (e) {
                        if (
                          n.getSenders().find(function (t) {
                            return t.track === e;
                          })
                        )
                          throw new DOMException('Track already exists.', 'InvalidAccessError');
                      }),
                      !n._reverseStreams[t.id])
                    ) {
                      var r = new e.MediaStream(t.getTracks());
                      (n._streams[t.id] = r), (n._reverseStreams[r.id] = t), (t = r);
                    }
                    i.apply(n, [t]);
                  };
                  var s = e.RTCPeerConnection.prototype.removeStream;
                  (e.RTCPeerConnection.prototype.removeStream = function (e) {
                    (this._streams = this._streams || {}),
                      (this._reverseStreams = this._reverseStreams || {}),
                      s.apply(this, [this._streams[e.id] || e]),
                      delete this._reverseStreams[this._streams[e.id] ? this._streams[e.id].id : e.id],
                      delete this._streams[e.id];
                  }),
                    (e.RTCPeerConnection.prototype.addTrack = function (t, n) {
                      var r = this;
                      if ('closed' === r.signalingState) throw new DOMException("The RTCPeerConnection's signalingState is 'closed'.", 'InvalidStateError');
                      var i = [].slice.call(arguments, 1);
                      if (
                        1 !== i.length ||
                        !i[0].getTracks().find(function (e) {
                          return e === t;
                        })
                      )
                        throw new DOMException(
                          'The adapter.js addTrack polyfill only supports a single  stream which is associated with the specified track.',
                          'NotSupportedError'
                        );
                      if (
                        r.getSenders().find(function (e) {
                          return e.track === t;
                        })
                      )
                        throw new DOMException('Track already exists.', 'InvalidAccessError');
                      (r._streams = r._streams || {}), (r._reverseStreams = r._reverseStreams || {});
                      var s = r._streams[n.id];
                      if (s)
                        s.addTrack(t),
                          Promise.resolve().then(function () {
                            r.dispatchEvent(new Event('negotiationneeded'));
                          });
                      else {
                        var a = new e.MediaStream([t]);
                        (r._streams[n.id] = a), (r._reverseStreams[a.id] = n), r.addStream(a);
                      }
                      return r.getSenders().find(function (e) {
                        return e.track === t;
                      });
                    }),
                    ['createOffer', 'createAnswer'].forEach(function (t) {
                      var n = e.RTCPeerConnection.prototype[t];
                      e.RTCPeerConnection.prototype[t] = function () {
                        var e = this,
                          t = arguments;
                        return arguments.length && 'function' == typeof arguments[0]
                          ? n.apply(e, [
                              function (n) {
                                var r = l(e, n);
                                t[0].apply(null, [r]);
                              },
                              function (e) {
                                t[1] && t[1].apply(null, e);
                              },
                              arguments[2],
                            ])
                          : n.apply(e, arguments).then(function (t) {
                              return l(e, t);
                            });
                      };
                    });
                  var a = e.RTCPeerConnection.prototype.setLocalDescription;
                  e.RTCPeerConnection.prototype.setLocalDescription = function () {
                    return arguments.length && arguments[0].type
                      ? ((arguments[0] = (function (e, t) {
                          var n = t.sdp;
                          return (
                            Object.keys(e._reverseStreams || []).forEach(function (t) {
                              var r = e._reverseStreams[t],
                                i = e._streams[r.id];
                              n = n.replace(new RegExp(r.id, 'g'), i.id);
                            }),
                            new RTCSessionDescription({ type: t.type, sdp: n })
                          );
                        })(this, arguments[0])),
                        a.apply(this, arguments))
                      : a.apply(this, arguments);
                  };
                  var o = Object.getOwnPropertyDescriptor(e.RTCPeerConnection.prototype, 'localDescription');
                  Object.defineProperty(e.RTCPeerConnection.prototype, 'localDescription', {
                    get: function () {
                      var e = o.get.apply(this);
                      return '' === e.type ? e : l(this, e);
                    },
                  }),
                    (e.RTCPeerConnection.prototype.removeTrack = function (e) {
                      var t = this;
                      if ('closed' === t.signalingState) throw new DOMException("The RTCPeerConnection's signalingState is 'closed'.", 'InvalidStateError');
                      if (!e._pc) throw new DOMException('Argument 1 of RTCPeerConnection.removeTrack does not implement interface RTCRtpSender.', 'TypeError');
                      if (!(e._pc === t)) throw new DOMException('Sender was not created by this connection.', 'InvalidAccessError');
                      t._streams = t._streams || {};
                      var n;
                      Object.keys(t._streams).forEach(function (r) {
                        t._streams[r].getTracks().find(function (t) {
                          return e.track === t;
                        }) && (n = t._streams[r]);
                      }),
                        n &&
                          (1 === n.getTracks().length ? t.removeStream(t._reverseStreams[n.id]) : n.removeTrack(e.track),
                          t.dispatchEvent(new Event('negotiationneeded')));
                    });
                }
                function l(e, t) {
                  var n = t.sdp;
                  return (
                    Object.keys(e._reverseStreams || []).forEach(function (t) {
                      var r = e._reverseStreams[t],
                        i = e._streams[r.id];
                      n = n.replace(new RegExp(i.id, 'g'), r.id);
                    }),
                    new RTCSessionDescription({ type: t.type, sdp: n })
                  );
                }
              },
              shimPeerConnection: function (e) {
                var t = r.detectBrowser(e);
                if (e.RTCPeerConnection) {
                  var n = e.RTCPeerConnection;
                  (e.RTCPeerConnection = function (e, t) {
                    if (e && e.iceServers) {
                      for (var i = [], s = 0; s < e.iceServers.length; s++) {
                        var a = e.iceServers[s];
                        !a.hasOwnProperty('urls') && a.hasOwnProperty('url')
                          ? (r.deprecated('RTCIceServer.url', 'RTCIceServer.urls'), ((a = JSON.parse(JSON.stringify(a))).urls = a.url), i.push(a))
                          : i.push(e.iceServers[s]);
                      }
                      e.iceServers = i;
                    }
                    return new n(e, t);
                  }),
                    (e.RTCPeerConnection.prototype = n.prototype),
                    Object.defineProperty(e.RTCPeerConnection, 'generateCertificate', {
                      get: function () {
                        return n.generateCertificate;
                      },
                    });
                } else
                  (e.RTCPeerConnection = function (t, n) {
                    return i('PeerConnection'), t && t.iceTransportPolicy && (t.iceTransports = t.iceTransportPolicy), new e.webkitRTCPeerConnection(t, n);
                  }),
                    (e.RTCPeerConnection.prototype = e.webkitRTCPeerConnection.prototype),
                    e.webkitRTCPeerConnection.generateCertificate &&
                      Object.defineProperty(e.RTCPeerConnection, 'generateCertificate', {
                        get: function () {
                          return e.webkitRTCPeerConnection.generateCertificate;
                        },
                      });
                var s = e.RTCPeerConnection.prototype.getStats;
                (e.RTCPeerConnection.prototype.getStats = function (e, t, n) {
                  var r = this,
                    i = arguments;
                  if (arguments.length > 0 && 'function' == typeof e) return s.apply(this, arguments);
                  if (0 === s.length && (0 === arguments.length || 'function' != typeof arguments[0])) return s.apply(this, []);
                  var a = function (e) {
                      var t = {};
                      return (
                        e.result().forEach(function (e) {
                          var n = {
                            id: e.id,
                            timestamp: e.timestamp,
                            type: { localcandidate: 'local-candidate', remotecandidate: 'remote-candidate' }[e.type] || e.type,
                          };
                          e.names().forEach(function (t) {
                            n[t] = e.stat(t);
                          }),
                            (t[n.id] = n);
                        }),
                        t
                      );
                    },
                    o = function (e) {
                      return new Map(
                        Object.keys(e).map(function (t) {
                          return [t, e[t]];
                        })
                      );
                    };
                  if (arguments.length >= 2) {
                    return s.apply(this, [
                      function (e) {
                        i[1](o(a(e)));
                      },
                      arguments[0],
                    ]);
                  }
                  return new Promise(function (e, t) {
                    s.apply(r, [
                      function (t) {
                        e(o(a(t)));
                      },
                      t,
                    ]);
                  }).then(t, n);
                }),
                  t.version < 51 &&
                    ['setLocalDescription', 'setRemoteDescription', 'addIceCandidate'].forEach(function (t) {
                      var n = e.RTCPeerConnection.prototype[t];
                      e.RTCPeerConnection.prototype[t] = function () {
                        var e = arguments,
                          t = this,
                          r = new Promise(function (r, i) {
                            n.apply(t, [e[0], r, i]);
                          });
                        return e.length < 2
                          ? r
                          : r.then(
                              function () {
                                e[1].apply(null, []);
                              },
                              function (t) {
                                e.length >= 3 && e[2].apply(null, [t]);
                              }
                            );
                      };
                    }),
                  t.version < 52 &&
                    ['createOffer', 'createAnswer'].forEach(function (t) {
                      var n = e.RTCPeerConnection.prototype[t];
                      e.RTCPeerConnection.prototype[t] = function () {
                        var e = this;
                        if (arguments.length < 1 || (1 === arguments.length && 'object' == typeof arguments[0])) {
                          var t = 1 === arguments.length ? arguments[0] : void 0;
                          return new Promise(function (r, i) {
                            n.apply(e, [r, i, t]);
                          });
                        }
                        return n.apply(this, arguments);
                      };
                    }),
                  ['setLocalDescription', 'setRemoteDescription', 'addIceCandidate'].forEach(function (t) {
                    var n = e.RTCPeerConnection.prototype[t];
                    e.RTCPeerConnection.prototype[t] = function () {
                      return (arguments[0] = new ('addIceCandidate' === t ? e.RTCIceCandidate : e.RTCSessionDescription)(arguments[0])), n.apply(this, arguments);
                    };
                  });
                var a = e.RTCPeerConnection.prototype.addIceCandidate;
                e.RTCPeerConnection.prototype.addIceCandidate = function () {
                  return arguments[0] ? a.apply(this, arguments) : (arguments[1] && arguments[1].apply(null), Promise.resolve());
                };
              },
            };
          t.exports = {
            shimMediaStream: s.shimMediaStream,
            shimOnTrack: s.shimOnTrack,
            shimAddTrackRemoveTrack: s.shimAddTrackRemoveTrack,
            shimGetSendersWithDtmf: s.shimGetSendersWithDtmf,
            shimSourceObject: s.shimSourceObject,
            shimPeerConnection: s.shimPeerConnection,
            shimGetUserMedia: e('./getusermedia'),
          };
        },
        { '../utils.js': 50, './getusermedia': 43 },
      ],
      43: [
        function (e, t, n) {
          'use strict';
          var r = e('../utils.js'),
            i = r.log;
          t.exports = function (e) {
            var t = r.detectBrowser(e),
              n = e && e.navigator,
              s = function (e) {
                if ('object' != typeof e || e.mandatory || e.optional) return e;
                var t = {};
                return (
                  Object.keys(e).forEach(function (n) {
                    if ('require' !== n && 'advanced' !== n && 'mediaSource' !== n) {
                      var r = 'object' == typeof e[n] ? e[n] : { ideal: e[n] };
                      void 0 !== r.exact && 'number' == typeof r.exact && (r.min = r.max = r.exact);
                      var i = function (e, t) {
                        return e ? e + t.charAt(0).toUpperCase() + t.slice(1) : 'deviceId' === t ? 'sourceId' : t;
                      };
                      if (void 0 !== r.ideal) {
                        t.optional = t.optional || [];
                        var s = {};
                        'number' == typeof r.ideal
                          ? ((s[i('min', n)] = r.ideal), t.optional.push(s), ((s = {})[i('max', n)] = r.ideal), t.optional.push(s))
                          : ((s[i('', n)] = r.ideal), t.optional.push(s));
                      }
                      void 0 !== r.exact && 'number' != typeof r.exact
                        ? ((t.mandatory = t.mandatory || {}), (t.mandatory[i('', n)] = r.exact))
                        : ['min', 'max'].forEach(function (e) {
                            void 0 !== r[e] && ((t.mandatory = t.mandatory || {}), (t.mandatory[i(e, n)] = r[e]));
                          });
                    }
                  }),
                  e.advanced && (t.optional = (t.optional || []).concat(e.advanced)),
                  t
                );
              },
              a = function (e, r) {
                if (t.version >= 61) return r(e);
                if ((e = JSON.parse(JSON.stringify(e))) && 'object' == typeof e.audio) {
                  var a = function (e, t, n) {
                    t in e && !(n in e) && ((e[n] = e[t]), delete e[t]);
                  };
                  a((e = JSON.parse(JSON.stringify(e))).audio, 'autoGainControl', 'googAutoGainControl'),
                    a(e.audio, 'noiseSuppression', 'googNoiseSuppression'),
                    (e.audio = s(e.audio));
                }
                if (e && 'object' == typeof e.video) {
                  var o = e.video.facingMode;
                  o = o && ('object' == typeof o ? o : { ideal: o });
                  var l = t.version < 66;
                  if (
                    o &&
                    ('user' === o.exact || 'environment' === o.exact || 'user' === o.ideal || 'environment' === o.ideal) &&
                    (!n.mediaDevices.getSupportedConstraints || !n.mediaDevices.getSupportedConstraints().facingMode || l)
                  ) {
                    delete e.video.facingMode;
                    var u;
                    if (
                      ('environment' === o.exact || 'environment' === o.ideal ? (u = ['back', 'rear']) : ('user' !== o.exact && 'user' !== o.ideal) || (u = ['front']), u)
                    )
                      return n.mediaDevices.enumerateDevices().then(function (t) {
                        var n = (t = t.filter(function (e) {
                          return 'videoinput' === e.kind;
                        })).find(function (e) {
                          return u.some(function (t) {
                            return -1 !== e.label.toLowerCase().indexOf(t);
                          });
                        });
                        return (
                          !n && t.length && -1 !== u.indexOf('back') && (n = t[t.length - 1]),
                          n && (e.video.deviceId = o.exact ? { exact: n.deviceId } : { ideal: n.deviceId }),
                          (e.video = s(e.video)),
                          i('chrome: ' + JSON.stringify(e)),
                          r(e)
                        );
                      });
                  }
                  e.video = s(e.video);
                }
                return i('chrome: ' + JSON.stringify(e)), r(e);
              },
              o = function (e) {
                return {
                  name:
                    {
                      PermissionDeniedError: 'NotAllowedError',
                      InvalidStateError: 'NotReadableError',
                      DevicesNotFoundError: 'NotFoundError',
                      ConstraintNotSatisfiedError: 'OverconstrainedError',
                      TrackStartError: 'NotReadableError',
                      MediaDeviceFailedDueToShutdown: 'NotReadableError',
                      MediaDeviceKillSwitchOn: 'NotReadableError',
                    }[e.name] || e.name,
                  message: e.message,
                  constraint: e.constraintName,
                  toString: function () {
                    return this.name + (this.message && ': ') + this.message;
                  },
                };
              };
            n.getUserMedia = function (e, t, r) {
              a(e, function (e) {
                n.webkitGetUserMedia(e, t, function (e) {
                  r && r(o(e));
                });
              });
            };
            var l = function (e) {
              return new Promise(function (t, r) {
                n.getUserMedia(e, t, r);
              });
            };
            if (
              (n.mediaDevices ||
                (n.mediaDevices = {
                  getUserMedia: l,
                  enumerateDevices: function () {
                    return new Promise(function (t) {
                      var n = { audio: 'audioinput', video: 'videoinput' };
                      return e.MediaStreamTrack.getSources(function (e) {
                        t(
                          e.map(function (e) {
                            return { label: e.label, kind: n[e.kind], deviceId: e.id, groupId: '' };
                          })
                        );
                      });
                    });
                  },
                  getSupportedConstraints: function () {
                    return { deviceId: !0, echoCancellation: !0, facingMode: !0, frameRate: !0, height: !0, width: !0 };
                  },
                }),
              n.mediaDevices.getUserMedia)
            ) {
              var u = n.mediaDevices.getUserMedia.bind(n.mediaDevices);
              n.mediaDevices.getUserMedia = function (e) {
                return a(e, function (e) {
                  return u(e).then(
                    function (t) {
                      if ((e.audio && !t.getAudioTracks().length) || (e.video && !t.getVideoTracks().length))
                        throw (
                          (t.getTracks().forEach(function (e) {
                            e.stop();
                          }),
                          new DOMException('', 'NotFoundError'))
                        );
                      return t;
                    },
                    function (e) {
                      return Promise.reject(o(e));
                    }
                  );
                });
              };
            } else
              n.mediaDevices.getUserMedia = function (e) {
                return l(e);
              };
            void 0 === n.mediaDevices.addEventListener &&
              (n.mediaDevices.addEventListener = function () {
                i('Dummy mediaDevices.addEventListener called.');
              }),
              void 0 === n.mediaDevices.removeEventListener &&
                (n.mediaDevices.removeEventListener = function () {
                  i('Dummy mediaDevices.removeEventListener called.');
                });
          };
        },
        { '../utils.js': 50 },
      ],
      44: [
        function (e, t, n) {
          'use strict';
          var r = e('sdp'),
            i = e('./utils');
          t.exports = {
            shimRTCIceCandidate: function (e) {
              if (!(e.RTCIceCandidate && 'foundation' in e.RTCIceCandidate.prototype)) {
                var t = e.RTCIceCandidate;
                (e.RTCIceCandidate = function (e) {
                  'object' == typeof e && e.candidate && 0 === e.candidate.indexOf('a=') && ((e = JSON.parse(JSON.stringify(e))).candidate = e.candidate.substr(2));
                  var n = new t(e),
                    i = r.parseCandidate(e.candidate),
                    s = Object.assign(n, i);
                  return (
                    (s.toJSON = function () {
                      return { candidate: s.candidate, sdpMid: s.sdpMid, sdpMLineIndex: s.sdpMLineIndex, usernameFragment: s.usernameFragment };
                    }),
                    s
                  );
                }),
                  (function (e, t, n) {
                    if (e.RTCPeerConnection) {
                      var r = e.RTCPeerConnection.prototype,
                        i = r.addEventListener;
                      r.addEventListener = function (e, r) {
                        if (e !== t) return i.apply(this, arguments);
                        var s = function (e) {
                          r(n(e));
                        };
                        return (this._eventMap = this._eventMap || {}), (this._eventMap[r] = s), i.apply(this, [e, s]);
                      };
                      var s = r.removeEventListener;
                      (r.removeEventListener = function (e, n) {
                        if (e !== t || !this._eventMap || !this._eventMap[n]) return s.apply(this, arguments);
                        var r = this._eventMap[n];
                        return delete this._eventMap[n], s.apply(this, [e, r]);
                      }),
                        Object.defineProperty(r, 'on' + t, {
                          get: function () {
                            return this['_on' + t];
                          },
                          set: function (e) {
                            this['_on' + t] && (this.removeEventListener(t, this['_on' + t]), delete this['_on' + t]),
                              e && this.addEventListener(t, (this['_on' + t] = e));
                          },
                        });
                    }
                  })(e, 'icecandidate', function (t) {
                    return t.candidate && Object.defineProperty(t, 'candidate', { value: new e.RTCIceCandidate(t.candidate), writable: 'false' }), t;
                  });
              }
            },
            shimCreateObjectURL: function (e) {
              var t = e && e.URL;
              if ('object' == typeof e && e.HTMLMediaElement && 'srcObject' in e.HTMLMediaElement.prototype && t.createObjectURL && t.revokeObjectURL) {
                var n = t.createObjectURL.bind(t),
                  r = t.revokeObjectURL.bind(t),
                  s = new Map(),
                  a = 0;
                (t.createObjectURL = function (e) {
                  if ('getTracks' in e) {
                    var t = 'polyblob:' + ++a;
                    return s.set(t, e), i.deprecated('URL.createObjectURL(stream)', 'elem.srcObject = stream'), t;
                  }
                  return n(e);
                }),
                  (t.revokeObjectURL = function (e) {
                    r(e), s.delete(e);
                  });
                var o = Object.getOwnPropertyDescriptor(e.HTMLMediaElement.prototype, 'src');
                Object.defineProperty(e.HTMLMediaElement.prototype, 'src', {
                  get: function () {
                    return o.get.apply(this);
                  },
                  set: function (e) {
                    return (this.srcObject = s.get(e) || null), o.set.apply(this, [e]);
                  },
                });
                var l = e.HTMLMediaElement.prototype.setAttribute;
                e.HTMLMediaElement.prototype.setAttribute = function () {
                  return (
                    2 === arguments.length && 'src' === ('' + arguments[0]).toLowerCase() && (this.srcObject = s.get(arguments[1]) || null), l.apply(this, arguments)
                  );
                };
              }
            },
          };
        },
        { './utils': 50, sdp: 39 },
      ],
      45: [
        function (e, t, n) {
          'use strict';
          var r = e('../utils'),
            i = e('rtcpeerconnection-shim');
          t.exports = {
            shimGetUserMedia: e('./getusermedia'),
            shimPeerConnection: function (e) {
              var t = r.detectBrowser(e);
              if (
                e.RTCIceGatherer &&
                (e.RTCIceCandidate ||
                  (e.RTCIceCandidate = function (e) {
                    return e;
                  }),
                e.RTCSessionDescription ||
                  (e.RTCSessionDescription = function (e) {
                    return e;
                  }),
                t.version < 15025)
              ) {
                var n = Object.getOwnPropertyDescriptor(e.MediaStreamTrack.prototype, 'enabled');
                Object.defineProperty(e.MediaStreamTrack.prototype, 'enabled', {
                  set: function (e) {
                    n.set.call(this, e);
                    var t = new Event('enabled');
                    (t.enabled = e), this.dispatchEvent(t);
                  },
                });
              }
              !e.RTCRtpSender ||
                'dtmf' in e.RTCRtpSender.prototype ||
                Object.defineProperty(e.RTCRtpSender.prototype, 'dtmf', {
                  get: function () {
                    return (
                      void 0 === this._dtmf &&
                        ('audio' === this.track.kind ? (this._dtmf = new e.RTCDtmfSender(this)) : 'video' === this.track.kind && (this._dtmf = null)),
                      this._dtmf
                    );
                  },
                }),
                (e.RTCPeerConnection = i(e, t.version));
            },
            shimReplaceTrack: function (e) {
              !e.RTCRtpSender || 'replaceTrack' in e.RTCRtpSender.prototype || (e.RTCRtpSender.prototype.replaceTrack = e.RTCRtpSender.prototype.setTrack);
            },
          };
        },
        { '../utils': 50, './getusermedia': 46, 'rtcpeerconnection-shim': 34 },
      ],
      46: [
        function (e, t, n) {
          'use strict';
          t.exports = function (e) {
            var t = e && e.navigator,
              n = t.mediaDevices.getUserMedia.bind(t.mediaDevices);
            t.mediaDevices.getUserMedia = function (e) {
              return n(e).catch(function (e) {
                return Promise.reject({
                  name: { PermissionDeniedError: 'NotAllowedError' }[(t = e).name] || t.name,
                  message: t.message,
                  constraint: t.constraint,
                  toString: function () {
                    return this.name;
                  },
                });
                var t;
              });
            };
          };
        },
        {},
      ],
      47: [
        function (e, t, n) {
          'use strict';
          var r = e('../utils'),
            i = {
              shimOnTrack: function (e) {
                'object' != typeof e ||
                  !e.RTCPeerConnection ||
                  'ontrack' in e.RTCPeerConnection.prototype ||
                  Object.defineProperty(e.RTCPeerConnection.prototype, 'ontrack', {
                    get: function () {
                      return this._ontrack;
                    },
                    set: function (e) {
                      this._ontrack && (this.removeEventListener('track', this._ontrack), this.removeEventListener('addstream', this._ontrackpoly)),
                        this.addEventListener('track', (this._ontrack = e)),
                        this.addEventListener(
                          'addstream',
                          (this._ontrackpoly = function (e) {
                            e.stream.getTracks().forEach(
                              function (t) {
                                var n = new Event('track');
                                (n.track = t), (n.receiver = { track: t }), (n.transceiver = { receiver: n.receiver }), (n.streams = [e.stream]), this.dispatchEvent(n);
                              }.bind(this)
                            );
                          }.bind(this))
                        );
                    },
                  }),
                  'object' == typeof e &&
                    e.RTCTrackEvent &&
                    'receiver' in e.RTCTrackEvent.prototype &&
                    !('transceiver' in e.RTCTrackEvent.prototype) &&
                    Object.defineProperty(e.RTCTrackEvent.prototype, 'transceiver', {
                      get: function () {
                        return { receiver: this.receiver };
                      },
                    });
              },
              shimSourceObject: function (e) {
                'object' == typeof e &&
                  (!e.HTMLMediaElement ||
                    'srcObject' in e.HTMLMediaElement.prototype ||
                    Object.defineProperty(e.HTMLMediaElement.prototype, 'srcObject', {
                      get: function () {
                        return this.mozSrcObject;
                      },
                      set: function (e) {
                        this.mozSrcObject = e;
                      },
                    }));
              },
              shimPeerConnection: function (e) {
                var t = r.detectBrowser(e);
                if ('object' == typeof e && (e.RTCPeerConnection || e.mozRTCPeerConnection)) {
                  e.RTCPeerConnection ||
                    ((e.RTCPeerConnection = function (n, r) {
                      if (t.version < 38 && n && n.iceServers) {
                        for (var i = [], s = 0; s < n.iceServers.length; s++) {
                          var a = n.iceServers[s];
                          if (a.hasOwnProperty('urls'))
                            for (var o = 0; o < a.urls.length; o++) {
                              var l = { url: a.urls[o] };
                              0 === a.urls[o].indexOf('turn') && ((l.username = a.username), (l.credential = a.credential)), i.push(l);
                            }
                          else i.push(n.iceServers[s]);
                        }
                        n.iceServers = i;
                      }
                      return new e.mozRTCPeerConnection(n, r);
                    }),
                    (e.RTCPeerConnection.prototype = e.mozRTCPeerConnection.prototype),
                    e.mozRTCPeerConnection.generateCertificate &&
                      Object.defineProperty(e.RTCPeerConnection, 'generateCertificate', {
                        get: function () {
                          return e.mozRTCPeerConnection.generateCertificate;
                        },
                      }),
                    (e.RTCSessionDescription = e.mozRTCSessionDescription),
                    (e.RTCIceCandidate = e.mozRTCIceCandidate)),
                    ['setLocalDescription', 'setRemoteDescription', 'addIceCandidate'].forEach(function (t) {
                      var n = e.RTCPeerConnection.prototype[t];
                      e.RTCPeerConnection.prototype[t] = function () {
                        return (arguments[0] = new ('addIceCandidate' === t ? e.RTCIceCandidate : e.RTCSessionDescription)(arguments[0])), n.apply(this, arguments);
                      };
                    });
                  var n = e.RTCPeerConnection.prototype.addIceCandidate;
                  e.RTCPeerConnection.prototype.addIceCandidate = function () {
                    return arguments[0] ? n.apply(this, arguments) : (arguments[1] && arguments[1].apply(null), Promise.resolve());
                  };
                  var i = {
                      inboundrtp: 'inbound-rtp',
                      outboundrtp: 'outbound-rtp',
                      candidatepair: 'candidate-pair',
                      localcandidate: 'local-candidate',
                      remotecandidate: 'remote-candidate',
                    },
                    s = e.RTCPeerConnection.prototype.getStats;
                  e.RTCPeerConnection.prototype.getStats = function (e, n, r) {
                    return s
                      .apply(this, [e || null])
                      .then(function (e) {
                        if (
                          (t.version < 48 &&
                            (e = (function (e) {
                              var t = new Map();
                              return (
                                Object.keys(e).forEach(function (n) {
                                  t.set(n, e[n]), (t[n] = e[n]);
                                }),
                                t
                              );
                            })(e)),
                          t.version < 53 && !n)
                        )
                          try {
                            e.forEach(function (e) {
                              e.type = i[e.type] || e.type;
                            });
                          } catch (t) {
                            if ('TypeError' !== t.name) throw t;
                            e.forEach(function (t, n) {
                              e.set(n, Object.assign({}, t, { type: i[t.type] || t.type }));
                            });
                          }
                        return e;
                      })
                      .then(n, r);
                  };
                }
              },
              shimRemoveStream: function (e) {
                !e.RTCPeerConnection ||
                  'removeStream' in e.RTCPeerConnection.prototype ||
                  (e.RTCPeerConnection.prototype.removeStream = function (e) {
                    var t = this;
                    r.deprecated('removeStream', 'removeTrack'),
                      this.getSenders().forEach(function (n) {
                        n.track && -1 !== e.getTracks().indexOf(n.track) && t.removeTrack(n);
                      });
                  });
              },
            };
          t.exports = {
            shimOnTrack: i.shimOnTrack,
            shimSourceObject: i.shimSourceObject,
            shimPeerConnection: i.shimPeerConnection,
            shimRemoveStream: i.shimRemoveStream,
            shimGetUserMedia: e('./getusermedia'),
          };
        },
        { '../utils': 50, './getusermedia': 48 },
      ],
      48: [
        function (e, t, n) {
          'use strict';
          var r = e('../utils'),
            i = r.log;
          t.exports = function (e) {
            var t = r.detectBrowser(e),
              n = e && e.navigator,
              s = e && e.MediaStreamTrack,
              a = function (e) {
                return {
                  name:
                    { InternalError: 'NotReadableError', NotSupportedError: 'TypeError', PermissionDeniedError: 'NotAllowedError', SecurityError: 'NotAllowedError' }[
                      e.name
                    ] || e.name,
                  message:
                    { 'The operation is insecure.': 'The request is not allowed by the user agent or the platform in the current context.' }[e.message] || e.message,
                  constraint: e.constraint,
                  toString: function () {
                    return this.name + (this.message && ': ') + this.message;
                  },
                };
              },
              o = function (e, r, s) {
                var o = function (e) {
                  if ('object' != typeof e || e.require) return e;
                  var t = [];
                  return (
                    Object.keys(e).forEach(function (n) {
                      if ('require' !== n && 'advanced' !== n && 'mediaSource' !== n) {
                        var r = (e[n] = 'object' == typeof e[n] ? e[n] : { ideal: e[n] });
                        if (
                          ((void 0 === r.min && void 0 === r.max && void 0 === r.exact) || t.push(n),
                          void 0 !== r.exact && ('number' == typeof r.exact ? (r.min = r.max = r.exact) : (e[n] = r.exact), delete r.exact),
                          void 0 !== r.ideal)
                        ) {
                          e.advanced = e.advanced || [];
                          var i = {};
                          'number' == typeof r.ideal ? (i[n] = { min: r.ideal, max: r.ideal }) : (i[n] = r.ideal),
                            e.advanced.push(i),
                            delete r.ideal,
                            Object.keys(r).length || delete e[n];
                        }
                      }
                    }),
                    t.length && (e.require = t),
                    e
                  );
                };
                return (
                  (e = JSON.parse(JSON.stringify(e))),
                  t.version < 38 &&
                    (i('spec: ' + JSON.stringify(e)), e.audio && (e.audio = o(e.audio)), e.video && (e.video = o(e.video)), i('ff37: ' + JSON.stringify(e))),
                  n.mozGetUserMedia(e, r, function (e) {
                    s(a(e));
                  })
                );
              };
            if (
              (n.mediaDevices ||
                (n.mediaDevices = {
                  getUserMedia: function (e) {
                    return new Promise(function (t, n) {
                      o(e, t, n);
                    });
                  },
                  addEventListener: function () {},
                  removeEventListener: function () {},
                }),
              (n.mediaDevices.enumerateDevices =
                n.mediaDevices.enumerateDevices ||
                function () {
                  return new Promise(function (e) {
                    e([
                      { kind: 'audioinput', deviceId: 'default', label: '', groupId: '' },
                      { kind: 'videoinput', deviceId: 'default', label: '', groupId: '' },
                    ]);
                  });
                }),
              t.version < 41)
            ) {
              var l = n.mediaDevices.enumerateDevices.bind(n.mediaDevices);
              n.mediaDevices.enumerateDevices = function () {
                return l().then(void 0, function (e) {
                  if ('NotFoundError' === e.name) return [];
                  throw e;
                });
              };
            }
            if (t.version < 49) {
              var u = n.mediaDevices.getUserMedia.bind(n.mediaDevices);
              n.mediaDevices.getUserMedia = function (e) {
                return u(e).then(
                  function (t) {
                    if ((e.audio && !t.getAudioTracks().length) || (e.video && !t.getVideoTracks().length))
                      throw (
                        (t.getTracks().forEach(function (e) {
                          e.stop();
                        }),
                        new DOMException('The object can not be found here.', 'NotFoundError'))
                      );
                    return t;
                  },
                  function (e) {
                    return Promise.reject(a(e));
                  }
                );
              };
            }
            if (!(t.version > 55 && 'autoGainControl' in n.mediaDevices.getSupportedConstraints())) {
              var c = function (e, t, n) {
                  t in e && !(n in e) && ((e[n] = e[t]), delete e[t]);
                },
                d = n.mediaDevices.getUserMedia.bind(n.mediaDevices);
              if (
                ((n.mediaDevices.getUserMedia = function (e) {
                  return (
                    'object' == typeof e &&
                      'object' == typeof e.audio &&
                      ((e = JSON.parse(JSON.stringify(e))), c(e.audio, 'autoGainControl', 'mozAutoGainControl'), c(e.audio, 'noiseSuppression', 'mozNoiseSuppression')),
                    d(e)
                  );
                }),
                s && s.prototype.getSettings)
              ) {
                var h = s.prototype.getSettings;
                s.prototype.getSettings = function () {
                  var e = h.apply(this, arguments);
                  return c(e, 'mozAutoGainControl', 'autoGainControl'), c(e, 'mozNoiseSuppression', 'noiseSuppression'), e;
                };
              }
              if (s && s.prototype.applyConstraints) {
                var f = s.prototype.applyConstraints;
                s.prototype.applyConstraints = function (e) {
                  return (
                    'audio' === this.kind &&
                      'object' == typeof e &&
                      ((e = JSON.parse(JSON.stringify(e))), c(e, 'autoGainControl', 'mozAutoGainControl'), c(e, 'noiseSuppression', 'mozNoiseSuppression')),
                    f.apply(this, [e])
                  );
                };
              }
            }
            n.getUserMedia = function (e, i, s) {
              if (t.version < 44) return o(e, i, s);
              r.deprecated('navigator.getUserMedia', 'navigator.mediaDevices.getUserMedia'), n.mediaDevices.getUserMedia(e).then(i, s);
            };
          };
        },
        { '../utils': 50 },
      ],
      49: [
        function (e, t, n) {
          'use strict';
          var r = e('../utils'),
            i = {
              shimLocalStreamsAPI: function (e) {
                if ('object' == typeof e && e.RTCPeerConnection) {
                  if (
                    ('getLocalStreams' in e.RTCPeerConnection.prototype ||
                      (e.RTCPeerConnection.prototype.getLocalStreams = function () {
                        return this._localStreams || (this._localStreams = []), this._localStreams;
                      }),
                    'getStreamById' in e.RTCPeerConnection.prototype ||
                      (e.RTCPeerConnection.prototype.getStreamById = function (e) {
                        var t = null;
                        return (
                          this._localStreams &&
                            this._localStreams.forEach(function (n) {
                              n.id === e && (t = n);
                            }),
                          this._remoteStreams &&
                            this._remoteStreams.forEach(function (n) {
                              n.id === e && (t = n);
                            }),
                          t
                        );
                      }),
                    !('addStream' in e.RTCPeerConnection.prototype))
                  ) {
                    var t = e.RTCPeerConnection.prototype.addTrack;
                    (e.RTCPeerConnection.prototype.addStream = function (e) {
                      this._localStreams || (this._localStreams = []), -1 === this._localStreams.indexOf(e) && this._localStreams.push(e);
                      var n = this;
                      e.getTracks().forEach(function (r) {
                        t.call(n, r, e);
                      });
                    }),
                      (e.RTCPeerConnection.prototype.addTrack = function (e, n) {
                        return (
                          n && (this._localStreams ? -1 === this._localStreams.indexOf(n) && this._localStreams.push(n) : (this._localStreams = [n])), t.call(this, e, n)
                        );
                      });
                  }
                  'removeStream' in e.RTCPeerConnection.prototype ||
                    (e.RTCPeerConnection.prototype.removeStream = function (e) {
                      this._localStreams || (this._localStreams = []);
                      var t = this._localStreams.indexOf(e);
                      if (-1 !== t) {
                        this._localStreams.splice(t, 1);
                        var n = this,
                          r = e.getTracks();
                        this.getSenders().forEach(function (e) {
                          -1 !== r.indexOf(e.track) && n.removeTrack(e);
                        });
                      }
                    });
                }
              },
              shimRemoteStreamsAPI: function (e) {
                'object' == typeof e &&
                  e.RTCPeerConnection &&
                  ('getRemoteStreams' in e.RTCPeerConnection.prototype ||
                    (e.RTCPeerConnection.prototype.getRemoteStreams = function () {
                      return this._remoteStreams ? this._remoteStreams : [];
                    }),
                  'onaddstream' in e.RTCPeerConnection.prototype ||
                    Object.defineProperty(e.RTCPeerConnection.prototype, 'onaddstream', {
                      get: function () {
                        return this._onaddstream;
                      },
                      set: function (e) {
                        this._onaddstream && (this.removeEventListener('addstream', this._onaddstream), this.removeEventListener('track', this._onaddstreampoly)),
                          this.addEventListener('addstream', (this._onaddstream = e)),
                          this.addEventListener(
                            'track',
                            (this._onaddstreampoly = function (e) {
                              var t = e.streams[0];
                              if ((this._remoteStreams || (this._remoteStreams = []), !(this._remoteStreams.indexOf(t) >= 0))) {
                                this._remoteStreams.push(t);
                                var n = new Event('addstream');
                                (n.stream = e.streams[0]), this.dispatchEvent(n);
                              }
                            }.bind(this))
                          );
                      },
                    }));
              },
              shimCallbacksAPI: function (e) {
                if ('object' == typeof e && e.RTCPeerConnection) {
                  var t = e.RTCPeerConnection.prototype,
                    n = t.createOffer,
                    r = t.createAnswer,
                    i = t.setLocalDescription,
                    s = t.setRemoteDescription,
                    a = t.addIceCandidate;
                  (t.createOffer = function (e, t) {
                    var r = arguments.length >= 2 ? arguments[2] : arguments[0],
                      i = n.apply(this, [r]);
                    return t ? (i.then(e, t), Promise.resolve()) : i;
                  }),
                    (t.createAnswer = function (e, t) {
                      var n = arguments.length >= 2 ? arguments[2] : arguments[0],
                        i = r.apply(this, [n]);
                      return t ? (i.then(e, t), Promise.resolve()) : i;
                    });
                  var o = function (e, t, n) {
                    var r = i.apply(this, [e]);
                    return n ? (r.then(t, n), Promise.resolve()) : r;
                  };
                  (t.setLocalDescription = o),
                    (o = function (e, t, n) {
                      var r = s.apply(this, [e]);
                      return n ? (r.then(t, n), Promise.resolve()) : r;
                    }),
                    (t.setRemoteDescription = o),
                    (o = function (e, t, n) {
                      var r = a.apply(this, [e]);
                      return n ? (r.then(t, n), Promise.resolve()) : r;
                    }),
                    (t.addIceCandidate = o);
                }
              },
              shimGetUserMedia: function (e) {
                var t = e && e.navigator;
                t.getUserMedia ||
                  (t.webkitGetUserMedia
                    ? (t.getUserMedia = t.webkitGetUserMedia.bind(t))
                    : t.mediaDevices &&
                      t.mediaDevices.getUserMedia &&
                      (t.getUserMedia = function (e, n, r) {
                        t.mediaDevices.getUserMedia(e).then(n, r);
                      }.bind(t)));
              },
              shimRTCIceServerUrls: function (e) {
                var t = e.RTCPeerConnection;
                (e.RTCPeerConnection = function (e, n) {
                  if (e && e.iceServers) {
                    for (var i = [], s = 0; s < e.iceServers.length; s++) {
                      var a = e.iceServers[s];
                      !a.hasOwnProperty('urls') && a.hasOwnProperty('url')
                        ? (r.deprecated('RTCIceServer.url', 'RTCIceServer.urls'), ((a = JSON.parse(JSON.stringify(a))).urls = a.url), delete a.url, i.push(a))
                        : i.push(e.iceServers[s]);
                    }
                    e.iceServers = i;
                  }
                  return new t(e, n);
                }),
                  (e.RTCPeerConnection.prototype = t.prototype),
                  'generateCertificate' in e.RTCPeerConnection &&
                    Object.defineProperty(e.RTCPeerConnection, 'generateCertificate', {
                      get: function () {
                        return t.generateCertificate;
                      },
                    });
              },
              shimTrackEventTransceiver: function (e) {
                'object' == typeof e &&
                  e.RTCPeerConnection &&
                  'receiver' in e.RTCTrackEvent.prototype &&
                  !e.RTCTransceiver &&
                  Object.defineProperty(e.RTCTrackEvent.prototype, 'transceiver', {
                    get: function () {
                      return { receiver: this.receiver };
                    },
                  });
              },
              shimCreateOfferLegacy: function (e) {
                var t = e.RTCPeerConnection.prototype.createOffer;
                e.RTCPeerConnection.prototype.createOffer = function (e) {
                  if (e) {
                    var n = this.getTransceivers().find(function (e) {
                      return e.sender.track && 'audio' === e.sender.track.kind;
                    });
                    !1 === e.offerToReceiveAudio && n
                      ? 'sendrecv' === n.direction
                        ? n.setDirection('sendonly')
                        : 'recvonly' === n.direction && n.setDirection('inactive')
                      : !0 !== e.offerToReceiveAudio || n || this.addTransceiver('audio');
                    var r = this.getTransceivers().find(function (e) {
                      return e.sender.track && 'video' === e.sender.track.kind;
                    });
                    !1 === e.offerToReceiveVideo && r
                      ? 'sendrecv' === r.direction
                        ? r.setDirection('sendonly')
                        : 'recvonly' === r.direction && r.setDirection('inactive')
                      : !0 !== e.offerToReceiveVideo || r || this.addTransceiver('video');
                  }
                  return t.apply(this, arguments);
                };
              },
            };
          t.exports = {
            shimCallbacksAPI: i.shimCallbacksAPI,
            shimLocalStreamsAPI: i.shimLocalStreamsAPI,
            shimRemoteStreamsAPI: i.shimRemoteStreamsAPI,
            shimGetUserMedia: i.shimGetUserMedia,
            shimRTCIceServerUrls: i.shimRTCIceServerUrls,
            shimTrackEventTransceiver: i.shimTrackEventTransceiver,
            shimCreateOfferLegacy: i.shimCreateOfferLegacy,
          };
        },
        { '../utils': 50 },
      ],
      50: [
        function (e, t, n) {
          'use strict';
          var r = !0,
            i = !0,
            s = {
              disableLog: function (e) {
                return 'boolean' != typeof e
                  ? new Error('Argument type: ' + typeof e + '. Please use a boolean.')
                  : ((r = e), e ? 'adapter.js logging disabled' : 'adapter.js logging enabled');
              },
              disableWarnings: function (e) {
                return 'boolean' != typeof e
                  ? new Error('Argument type: ' + typeof e + '. Please use a boolean.')
                  : ((i = !e), 'adapter.js deprecation warnings ' + (e ? 'disabled' : 'enabled'));
              },
              log: function () {
                if ('object' == typeof window) {
                  if (r) return;
                  'undefined' != typeof console && 'function' == typeof console.log && console.log.apply(console, arguments);
                }
              },
              deprecated: function (e, t) {
                i && console.warn(e + ' is deprecated, please use ' + t + ' instead.');
              },
              extractVersion: function (e, t, n) {
                var r = e.match(t);
                return r && r.length >= n && parseInt(r[n], 10);
              },
              detectBrowser: function (e) {
                var t = e && e.navigator,
                  n = {};
                if (((n.browser = null), (n.version = null), void 0 === e || !e.navigator)) return (n.browser = 'Not a browser.'), n;
                if (t.mozGetUserMedia) (n.browser = 'firefox'), (n.version = this.extractVersion(t.userAgent, /Firefox\/(\d+)\./, 1));
                else if (t.webkitGetUserMedia)
                  if (e.webkitRTCPeerConnection) (n.browser = 'chrome'), (n.version = this.extractVersion(t.userAgent, /Chrom(e|ium)\/(\d+)\./, 2));
                  else {
                    if (!t.userAgent.match(/Version\/(\d+).(\d+)/)) return (n.browser = 'Unsupported webkit-based browser with GUM support but no WebRTC support.'), n;
                    (n.browser = 'safari'), (n.version = this.extractVersion(t.userAgent, /AppleWebKit\/(\d+)\./, 1));
                  }
                else if (t.mediaDevices && t.userAgent.match(/Edge\/(\d+).(\d+)$/))
                  (n.browser = 'edge'), (n.version = this.extractVersion(t.userAgent, /Edge\/(\d+).(\d+)$/, 2));
                else {
                  if (!t.mediaDevices || !t.userAgent.match(/AppleWebKit\/(\d+)\./)) return (n.browser = 'Not a supported browser.'), n;
                  (n.browser = 'safari'), (n.version = this.extractVersion(t.userAgent, /AppleWebKit\/(\d+)\./, 1));
                }
                return n;
              },
            };
          t.exports = {
            log: s.log,
            deprecated: s.deprecated,
            disableLog: s.disableLog,
            disableWarnings: s.disableWarnings,
            extractVersion: s.extractVersion,
            shimCreateObjectURL: s.shimCreateObjectURL,
            detectBrowser: s.detectBrowser.bind(s),
          };
        },
        {},
      ],
      51: [
        function (e, t, n) {
          t.exports = {
            name: 'jssip',
            title: 'JsSIP',
            description: 'the Javascript SIP library',
            version: '3.2.4',
            homepage: 'http://jssip.net',
            author: 'José Luis Millán <jmillan@aliax.net> (https://github.com/jmillan)',
            contributors: ['Iñaki Baz Castillo <ibc@aliax.net> (https://github.com/ibc)', 'Saúl Ibarra Corretgé <saghul@gmail.com> (https://github.com/saghul)'],
            main: 'lib-es5/JsSIP.js',
            keywords: ['sip', 'websocket', 'webrtc', 'node', 'browser', 'library'],
            license: 'MIT',
            repository: { type: 'git', url: 'https://github.com/versatica/JsSIP.git' },
            bugs: { url: 'https://github.com/versatica/JsSIP/issues' },
            dependencies: { debug: '^3.1.0', 'sdp-transform': '^2.3.1', 'webrtc-adapter': '^6.0.4' },
            devDependencies: {
              'ansi-colors': '^1.0.1',
              'babel-core': '^6.26.0',
              'babel-preset-env': '^1.6.1',
              browserify: '^14.5.0',
              eslint: '^4.15.0',
              'fancy-log': '^1.3.2',
              gulp: '^4.0.0',
              'gulp-babel': '^7.0.0',
              'gulp-eslint': '^4.0.1',
              'gulp-expect-file': '0.0.7',
              'gulp-header': '1.8.9',
              'gulp-nodeunit-runner': '^0.2.2',
              'gulp-plumber': '^1.2.0',
              'gulp-rename': '^1.2.2',
              'gulp-uglify': '^3.0.0',
              pegjs: '^0.7.0',
              'vinyl-buffer': '^1.0.1',
              'vinyl-source-stream': '^2.0.0',
            },
            scripts: { test: 'gulp test', prepublishOnly: 'gulp babel' },
          };
        },
        {},
      ],
    },
    {},
    [8]
  )(8);
});
