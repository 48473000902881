<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id" @click="ccc()"> ({{ id }})</small>
        {{ `Добавление документов  ${model.title ? "[" + model.title + "]" : ""}` }}
      </template>
      <a-form-model v-model="data" :model="model" :errors="errors" :config="{ dense: true }" @validate="validate($event)" />
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save :disabled="!loaded || data.filesAdd.length == 0" @click.once="submit()" />
        <v-btn @click.once="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    modelDoc: Object,
  },
  data() {
    return {
      loaded: false,
    };
  },
  computed: {
    model() {
      let model = JSON.parse(JSON.stringify(this.modelDoc));
      model.validator = ["req"];
      model.newOnly = true;
      return [model, { name: "filesAdd", default: [], hidden: true }];
    },
  },
  watch: {
    value() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) {
        }
      }
    },
  },
  async created() {
    this.loaded = !true;
    if (this.value) {
      await this.updateData(this.id);
      this.loaded = true;
      if (!this.id) {
      }
    }
  },
  methods: {
    ccc() {
    },
    async submit() {
      let data = {
        id: this.data.id,
        filesAdd: this.data.filesAdd,
        filesRemove: this.data.filesRemove,
      };
      if (!this.data.id) return false;
      if (!data.filesAdd && !data.filesRemove) return false;
      await this.post(data, this.api);
      return true;
    },
    afterSave(data, status) {
      this.$emit("input");
      // this.$router.push({name: "objects_view", params: {id: this.id}});
    },
  },
};
</script>
