/** @format */

export default {
  methods: {
    validator: async function (types, key, fields) {
      if (Array.isArray(types) && types.length > 0) {
        let field;
        let name;
        let value;
        if (key.indexOf('.') !== -1) {
          field = key.split('.')[0];
          name = key.split('.')[1];
          value = fields[field][name];
        } else value = fields.data?.[key] || fields[key];
        //const value = (field ? fields[field][name] : fields.data?.[key]) || fields[key];
        var ferror = '';
        for (var el of types) {
          if (el == 'chk' && (value === '' || value === null || value === undefined)) {
            ferror = ' Не определено.';
          } else if (el == 'req' && (value === '' || value === null || value === undefined)) {
            ferror = 'Это поле обязательно.';
          } else if (el == 'pass' && value && value.length < 6) {
            ferror = 'Пароль не менее 6 символов.';
          } else if (el == 'email' && value && value != '' && !this.validEmail(value)) {
            ferror = 'Укажите корректный адрес электронной почты.';
          } else if (el == 'tel' && value && value != '') {
            ferror = this.validTel(value, key);
          } else if (el == 'login' && !(fields['id'] > 0)) {
            if (await this.validLogin(value, '/signup/login_exists')) {
              ferror = 'Такой пользователь уже существует';
            }
          } else if (el == 'unique') {
            ferror = await this.isUnique(key, value, fields);
          } else if (el == 'repass' && value != fields['password']) {
            ferror = 'Пароли не совпадают.';
          } else if (el == 'reemail' && value != fields['email']) {
            ferror = 'Адреса почты не совпадают';
          } else if (el == 'max') {
            let max = this.model.find((el) => el.name == key)?.max;
            if (max !== undefined && value > max) {
              ferror = `Значение не может быть больше ${max}`;
            }
          } else if (el == 'min') {
            let min = this.model.find((el) => el.name == key)?.min;
            if (min !== undefined && value < min) {
              ferror = `Значение должно быть больше ${min}`;
            }
          } else if (el.includes('max#')) {
            let max = parseInt(el.split('#')[1]);
            if (max !== undefined && value > max) {
              ferror = `Значение не может быть больше ${max}`;
            }
          } else if (el.includes('min#')) {
            let min = parseFloat(el.split('#')[1]);
            if (min !== undefined && value < min) {
              ferror = `Значение должно быть больше ${min}`;
            }
          }
          if (ferror != '') {
            return ferror;
          }
        }
      }
      return '';
    },
    validEmail(email) {
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    async validLogin(login, api) {
      let response = await this.$axios.get(api, {
        params: { login: login },
      });
      return response.data.data;
    },
    validTel(tel, key) {
      let mask = this.model.find((el) => el.name == key)?.mask;
      let res = '';
      if (mask) {
        let m = new RegExp('^' + mask.replace(/\*/g, '\\d') + '$');
        // /^996\d{9}$/
        if (!m.test(tel)) {
          res = `Укажите номер в формате ${mask}`;
        }
      }
      return res;
    },
    async isUnique(key, value, fields) {
      let table =
        this.model.find((m) => {
          return m.name == key;
        })?.table || null;
      if (!table) {
        console.log('table', table);
        return 'Не удалось проверить на уникальность';
      }
      let id = fields?.id || 0;
      const resp = await this.$axios.post('accounting/records/report-sql', {
        q: `SELECT 1 from ${table} where id<>${id} and ${key}='${value}'`,
      });

      let status = resp.data.status == 'ok';
      if (!status) {
        return 'Не удалось проверить на уникальность';
      }
      if (resp.data.data.length) {
        return 'Значение должно быть уникальным';
      }
      return '';
    },
  },
};
