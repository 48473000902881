/** @format */

import Validate from './libs/validate';
import Validators from './libs/validators';
import FillForm from './libs/fillForm';
import SubmitForm from './libs/submitForm';
import Components from './libs/components';
import GetIndex from './libs/getIndex';
import GetIndexSql from './libs/getIndexSql';
import GetForm from './libs/getForm';
import StaticFullHeight from './libs/staticFullHeight';
import SaveEl from './libs/saveEl';
import RemoveEl from './libs/removeEl';
import ModelOptions from './libs/modelOptions';
import StatusChange from './libs/statusChange';
import AutoHeightBlock from './libs/autoHeightBlock';
import GenModel from './libs/genModel';
import GetAccess from './libs/getAccess';
import LibChecks from './libs/libChecks';
import LibObjectPlanning from './libs/libObjectPlanning';
import KeyDetect from './libs/keyDetect';
import PopupMenu from './libs/popupMenu';
import DoubleClickDetect from './libs/doubleClickDetect';
import OpenDocs from './libs/openDocs';
import Comments from './libs/comments';

export const genModel = GenModel;
export const getAccess = GetAccess;
export const libChecks = LibChecks;
export const libObjectPlanning = LibObjectPlanning;
export const keyDetect = KeyDetect;
export const doubleClickDetect = DoubleClickDetect;
export const statusChange = StatusChange;
export const autoHeightBlock = AutoHeightBlock;
export const validate = Validate;
export const validators = Validators;
export const fillForm = FillForm;
export const submitForm = SubmitForm;
export const components = Components;
export const getIndex = GetIndex;
export const getIndexSql = GetIndexSql;
export const getForm = GetForm;
export const staticFullHeight = StaticFullHeight;
export const removeEl = RemoveEl;
export const saveEl = SaveEl;
export const modelOptions = ModelOptions;
export const popupMenu = PopupMenu;
export const openDocs = OpenDocs;
export const comments = Comments;
