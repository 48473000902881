<template>
  <v-btn :color="color" @click="$emit('click')" class="mx-1" :disabled="disabled">
    <v-icon>far fa-save</v-icon>
    <btn-title v-if="$vuetify.breakpoint.smAndUp && title">{{ title }}</btn-title>
  </v-btn>
</template>

<script>
export default {
  props: {
    title: { type: String, default: "Сохранить" },
    disabled: { type: Boolean, default: false },
    color: { type: String, default: "primary" },
  },
};
</script>
