/** @format */

import Vue from 'vue';
import axios from 'axios';
import config from '../config/config';
import { notificationBus } from './notificationBus';

const token = localStorage.getItem('user-token');
if (token) {
  axios.defaults.headers.common['Authorization'] = token;
}

axios.defaults.baseURL = config.baseUrl;

axios.interceptors.response.use(undefined, function (err) {
  return new Promise(function (resolve, reject) {
    if (!err?.response?.status) {
      console.log('ОШИБКА соединения');
    } else if (err.response.status === 400) {
      notificationBus.$emit('showInfo', {
        type: 'error',
        text: 'Ошибка: ' + (err.response.data?.message || 'null'),
      });
      if (err.response.data?.message !== 'login error') {
        //console.log(err.response.data?.message);
        throw err.response.data;
      }
    } else if (err.response.status === 403) {
      if (err.response.config.url === '/signup/get_profile' || !true) {
        localStorage.removeItem('user-token');
        localStorage.removeItem('user-profile');
        window.location.href = '/';
      } else {
        alert('У вас нет доступа');
      }
    }
    throw err;
  });
});

Vue.prototype.$axios = axios;

export default axios;
