<template>
  <div>
    <v-dialog v-model="needUpgdade" persistent max-width="290">
      <v-card>
        <v-card-title class="text-h5"> Важные обновления </v-card-title>
        <v-card-text>
          Для дальнейшей работы в программе, необходимо очистить кэш браузера.
          {{ version.actual }}-{{ version.build }}
        </v-card-text>
        <v-card-actions v-if="$root.profile.role == 1000">
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="needUpgdade = false"> Эакрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      needUpgdade: false,
      versionInterval: null,
      actualVersion: null,
    };
  },
  created() {
    this.checkVersion();
    this.versionInterval = setInterval(async () => {
      this.checkVersion();
    }, 30 * 1000);
    console.log("version created");
  },
  computed: {
    version() {
      return { build: this.$root.config.version, actual: this.actualVersion };
    },
  },
  methods: {
    async checkVersion() {
      let resp = await this.$axios.get("/config/config.version");
      let ver = resp?.data?.data || null;
      if (!ver) {
        console.log("Can not check version");
        this.$root.$emit("show-info", { type: "error", text: "Ошибка соединения с сервером" });
        return;
      }
      if (this.version.build < ver) {
        this.actualVersion = ver;
        this.needUpgdade = true;
        if (this.versionInterval) {
          clearInterval(this.versionInterval);
          this.versionInterval = null;
        }
      }
    },
  },
};
</script>
