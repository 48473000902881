<template>
  <div class="py-1 pb-3">
    <v-card>
      <v-card-title class="py-2">
        {{ model.title }}
        <v-spacer></v-spacer>
        <div class="text-right pa-1">
          <v-btn v-if="!readonly" class="ma-1" @click="add()" color="primary"> Добавить </v-btn>
        </div>
      </v-card-title>

      <v-card-text>
        <div>
          <v-row>
            <v-simple-table style="width: 100%">
              <tbody>
                <tr v-for="(el, key) in items" :key="key">
                  <td class="text-left">
                    <a :href="$root.config.fileUrl + el.file" target="_blank">
                      {{ el.file_name || el.file }}
                    </a>
                  </td>
                  <td width="10px">
                    <div class="d-flex">
                      <v-btn v-if="fileAdmin" class="mx-2" fab small title="Настройки" @click.prevent="configFile(el)">
                        <v-icon> mdi-cog </v-icon>
                      </v-btn>
                      <v-btn v-if="!readonly" class="mx-2" fab dark small color="error" title="Удалить" @click.prevent="remove(key)">
                        <v-icon dark> mdi-close </v-icon>
                      </v-btn>
                    </div>
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
    <v-dialog v-model="dialogFile" hide-overlay persistent max-width="600">
      <v-card>
        <v-card-title>Загрузка файлов</v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-8">
          <v-file-input multiple outlined chips label="Выберите файл" type="file" v-model="uploadsFiles"></v-file-input>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="uploadsFiles ? false : true" :loading="fileLoading" color="primary" @click="upload()">Загрузить </v-btn>
          <v-btn @click="dialogFile = false">Закрыть</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <s-fileConfigDialog v-if="showFileAdminDialog" v-model="showFileAdminDialog" :id="editFile_id" />
  </div>
</template>

<script>
export default {
  props: {
    value: String | Array,
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
    config: { type: Object, default: {} },
  },
  data() {
    return {
      uploadsFiles: null,
      dialogFile: false,
      fileLoading: false,
      showFileAdminDialog: false,
      addFiles: [],
      delFiles: [],
      editFile_id: 0,
    };
  },
  computed: {
    group_id() {
      return 1;
    },
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    fileAdmin() {
      return this.$root.profile.role === 1000;
    },
    items: {
      get() {
        let d = this.values.filesData || [];
        let f = this.model.category || null;
        try {
          d = d.filter((el) => {
            if (this.model?.newOnly && el?.id) return false;
            return f == null || el.category_id == f;
          });
          //d = this.value;
          d.forEach((el) => {
            // if (el?.type !== "add")
            el.type = "old";
          });
        } catch (error) {
          d = [];
        }

        return d;
      },
      set(v) {
        this.$emit("input", v);
      },
    },
  },
  methods: {
    configFile(file) {
      if (!file.upload_id) return;
      this.editFile_id = file.upload_id;
      this.showFileAdminDialog = true;
    },
    remove(key) {
      let e = this.items;
      //e.splice(key, 1);
      e[key].type = "del";
      this.items = e;
    },
    add() {
      this.uploadsFiles = null;
      this.dialogFile = true;
      this.fileLoading = false;
    },
    async upload() {
      this.fileLoading = true;
      let promises = [];
      for (let file of this.uploadsFiles) {
        promises.push(this.uploadFile(file));
      }
      await Promise.all(promises);
      this.fileLoading = false;
      this.dialogFile = false;
    },
    uploadFile(file) {
      return new Promise((resolve, reject) => {
        let formData = new FormData();
        formData.append("file", file);
        formData.append("name", file.name);
        // file.uploadStatus = 0;
        this.$axios
          .post("/file/upload", formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
            // onUploadProgress: (progressEvent) => {
            //   file.uploadStatus = parseInt(
            //     (progressEvent.loaded / progressEvent.total) * 100
            //   );
            // },
          })
          .then((response) => {
            let data = response.data.data.file;
            let el = this.items;
            if (!Array.isArray(el)) el = [];
            let f = {
              file: data,
              file_name: file.name,
              category_id: this.model?.category || null,
              upload_id: response.data.data.id,
              group_id: this.group_id,
              type: "add",
            };
            this.addFiles.push(f);
            //el.push(f);
            el.splice(0, 0, f);
            this.items = el;
            resolve();
          })
          .catch(function (error) {
            console.log(error);
            // this.$root.$emit("show-info", {
            //   text: "Error: " + error,
            // });
            reject(error);
          });
      });
    },
  },
};
</script>
