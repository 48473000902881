/** @format */

export default {
  data() {
    return {
      popumMenuShow: false,
      popupMenuList: [],
      popupMenuPosition: null,
      itemId: null,
      itemValue: null,
      menuItems: [
        {
          name: 'object_name',
          title: 'Перейти в объект',
          action: () => {
            let routeData = this.$router.resolve({ name: 'objects_view', params: { id: this.itemId } });
            console.log('open', routeData);
            return window.open(routeData.href, '_blank');
            return this.$router.push({
              name: 'objects_view',
              params: { id: this.itemId },
            });
          },
        },
        {
          name: 'object_work',
          title: 'Перейти в объект (график)',
          action: () => {
            let routeData = this.$router.resolve({ name: 'objects_works', params: { id: this.itemId } });
            console.log('open', routeData);
            return window.open(routeData.href, '_blank');
          },
        },
        {
          name: 'object_id',
          title: 'Перейти в объект',
          action: () => {
            return this.$router.push({
              name: 'objects_view',
              params: { id: this.itemId, tab: 2 },
            });
          },
        },
        {
          name: 'task_id',
          title: 'Перейти в задачу',
          action: () => {
            return this.$router.push({
              name: 'tasks_view',
              params: { id: this.itemId },
            });
          },
        },
        {
          name: 'buying_id',
          title: 'Перейти в покупку',
          action: () => {
            return this.$router.push({
              name: 'tasks-goods_view',
              params: { id: this.itemId },
            });
          },
        },
        {
          name: 'link',
          title: 'Открыть ссылку',
          action: () => {
            window.open(this.itemValue);
          },
        },
      ],
      timer: null,
    };
  },

  created() {},
  beforeDestroy() {},
  methods: {
    doAction(name, row) {
      return 1;
    },
    showPopupMenu(name, row, event, obj = null) {
      this.$nextTick(() => {
        this.popumMenuShow = false;
      });
      //this.popumMenuShow = false;

      const menuItems = obj?.menuItems ? obj.menuItems : this.menuItems;
      // console.log('menuItems', menuItems, name, obj?.menuItems);
      if ('object_name,object_id,object_work'.split(',').includes(name) && row.object_id) {
        this.itemId = row.object_id;
        this.popupMenuList = menuItems.filter((el) => el.name == name);
        if (!this.popupMenuList.length) return;
        this.showMenu(event);
        return;
      }
      if (name == 'object_id' && row.object_id) {
        this.itemId = row.object_id;
        this.popupMenuList = menuItems.filter((el) => el.name == name);
        if (!this.popupMenuList.length) return;
        this.showMenu(event);
        return;
      }
      if (name == 'object' && row.id) {
        this.itemId = row.id;
        this.popupMenuList = menuItems.filter((el) => el.name == 'object_name');
        if (!this.popupMenuList.length) return;
        this.showMenu(event);
        return;
      }
      if (name == 'task_id') {
        if (obj && obj.task) this.itemId = obj.task.id;
        else this.itemId = row.id;
        this.popupMenuList = menuItems.filter((el) => el.name == name);
        if (!this.popupMenuList.length) return;
        this.showMenu(event);
        return;
      }
      if (name == 'buying_id') {
        if (obj && obj.task) this.itemId = obj.task.id;
        else this.itemId = row.id;
        this.popupMenuList = menuItems.filter((el) => el.name == name);
        if (!this.popupMenuList.length) return;
        this.showMenu(event);
        return;
      }
      if (['document_route', 'doc_id'].includes(name) && obj) {
        this.popupMenuList = [];
        console.log('popup document', obj);
        let id = obj?.field?.value || row[name] || 0;
        let routeName = obj?.field?.routeName;
        if (obj.field?.menuOptions) {
          if (obj.field.menuOptions.routeName.value) routeName = obj.field.menuOptions.routeName.value;
          if (obj.field.menuOptions.routeName.field) routeName = row?.[obj.field.menuOptions.routeName.field];
          if (obj.field.menuOptions.id.value) id = obj.field.menuOptions.id.value;
          if (obj.field.menuOptions.id.field) id = row?.[obj.field.menuOptions.id.field];
        }
        console.log('popup document', { routeName, id });

        if (routeName && id) {
          this.popupMenuList.push({
            name: 'document',
            title: 'Перейти в документ',
            action: () => {
              let routeData = this.$router.resolve({ name: routeName, params: { id } });
              console.log('open', routeData);
              return window.open(routeData.href, '_blank');
              return this.$router.push({
                name: obj.field.routeName,
                params: { id },
              });
            },
          });
          this.showMenu(event);

          return;
        }
      }

      if (row[name] && name == 'link') {
        this.popupMenuList = [];
        this.itemId = row.id;
        this.itemValue = row[name];
        let menu = menuItems.filter((el) => el.name == name);
        if (!menu.length) return;
        let links = this.itemValue.match(/\bhttp(s)?\:\/\/(\S+)\b/g);
        for (let i = 0; i < links.length; i++) {
          const link = links[i];
          let item = { name: 'link' + i, title: 'Открыть ссылку ' + (i + 1), action: () => window.open(link) };
          this.popupMenuList.push(item);
        }
        this.showMenu(event);

        return;
      }
      if (row[name]) {
        this.itemId = row.id;
        this.itemValue = row[name];
        this.popupMenuList = menuItems.filter((el) => el.name == name);
        if (!this.popupMenuList.length) return;
        this.showMenu(event);
      }
    },
    showMenu(e) {
      clearTimeout(this.timer);
      setTimeout(() => {
        this.popupMenuPosition = { x: e.clientX, y: e.clientY };
        this.popumMenuShow = true;
      }, 150);
      this.timer = setTimeout(() => {
        this.popumMenuShow = false;
      }, 2000);
    },
    openLink(text) {
      let links = text.match(/\bhttp(s)?\:\/\/(\S+)\b/g);
      for (const link of links) {
        /*         let a = document.createElement('a');
        a.href = link;
        a.target = '_blank';
        a.click(); */

        setTimeout(() => window.open(link), 500);
      }
    },
  },
};
