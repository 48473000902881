/** @format */

export default {
  appName: 'unrealty.pro',
  baseUrl: 'https://app.unrealty.pro/api',

  //baseUrl: 'https://81.163.31.148/api',
  websocetUrl: 'wss://app.unrealty.pro/data',
  videoUrl: 'https:/app.unrealty.pro/api/file/download/',
  imageUrl: 'https://app.unrealty.pro/api/image/download/',
  fileUrl: 'https://app.unrealty.pro/api/file/download/',
  recordUrl: 'https://tel.unrealty.pro/',
  sip_server: 'tel.unrealty.pro',
  sip_ws: 'wss://tel.unrealty.pro:7443/ws',

  mainMenu: [
    { auth: 0, name: 'Главная', icon1: 'fas fa-border-all', route: { name: 'index' } },
    { auth: 10001, name: 'Аренда', route: { name: 'rent' } },
    { auth: 10001, name: 'Продажа', route: { name: 'buy' } },
    { auth: 10001, name: 'Инфо', icon1: 'far fa-question-circle', route: { name: 'info' } },
    { auth: 10001, name: 'Предложения', icon1: 'far fa-question-circle', route: { name: 'offers' } },
    { auth: 10001, name: 'Избранное', icon1: 'fas fa-laptop-house', route: { name: 'objects' } },
    { auth: 1000, name: 'Мои предложения', icon1: 'fas fa-hand-holding-medical', route: { name: 'myOffers' } },
    {
      auth: 1000,
      name: 'Настройки',
      icon1: 'fas fa-cog',
      accessName: 'subSettings',
      sublist: [
        { auth: 10001, route: { name: 'dirs' }, name: 'Справочники', icon1: 'fas fa-book' },
        { auth: 1000, route: { name: 'users' }, name: 'Пользователи', icon1: 'fas fa-user-cog' },
        { auth: 1000, route: { name: 'billing' }, name: 'Billing', icon1: 'fas fa-user-cog' },
        { auth: 1000, route: { name: 'settings' }, name: 'Настройки', icon1: 'fas fa-cog' },
        { auth: 10001, route: { name: 'apiConfig' }, name: 'Настройка API', icon1: 'far fa-cog' },
        { auth: 10001, route: { name: 'info-admin' }, name: 'Инфо', ico1: 'far fa-question-circle' },
      ],
    },
  ],
  meta: {
    enum: {
      status_active: [
        { value: 1, text: 'Активно' },
        { value: 0, text: 'Не активно' },
      ],

      yesNo: [
        { value: 0, text: 'Нет' },
        { value: 1, text: 'Да' },
      ],
      offer_type: [
        { value: 1, text: 'Аренда' },
        { value: 2, text: 'Продажа' },
      ],
    },
  },
};
