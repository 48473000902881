/** @format */

import Vue from 'vue';
import App from '@/App.vue';
import vuetify from './libs/vuetify';
import router from './libs/router';

import store from './store/index';
import config from './config/config';
import PortalVue from 'portal-vue';
import ElementUI from 'element-ui';
import locale from 'element-ui/lib/locale/lang/en';
import VueFullscreen from 'vue-fullscreen';
import 'element-ui/lib/theme-chalk/index.css';
import './libs/registerComponents';
import './libs/axios';
import './libs/translations';
import './libs/date';
import './libs/array';
import './libs/string';
//import './registerServiceWorker';
import CKEditor from '@ckeditor/ckeditor5-vue2';
Vue.use(CKEditor);
Vue.config.productionTip = false;
Vue.use(ElementUI, { locale });
Vue.use(PortalVue);
Vue.use(VueFullscreen);

const askUserToUpdate = (reg) => {
  return Modal.confirm({
    onOk: async () => {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
        window.location.reload();
      });
      if (reg && reg.waiting) {
        reg.waiting.postMessage({ type: 'SKIP_WAITING' });
      }
    },
    onCancel: () => {
      Modal.destroyAll();
    },
    icon: null,
    title: 'Хорошие новости! ? ',
    content: 'Мы только что обновили версию приложения! Чтобы получить обновления, нажмите на кнопку ниже (страница перезагрузится)',
    cancelText: 'Не обновлять',
    okText: 'Обновить',
  });
};

const app = new Vue({
  vuetify,
  router,
  store,
  render: (h) => h(App),
  el: '#app',
  data() {
    return {
      leftMenuDrawer: null,
      dialogShow: false,
      lang: 'ru',
      title: '',
      websocketWorking: false,
      websocket: null,
      websocketData: {},
      windowSize: { contentHeight: 0, innerHeight: 0 },
      filename: '',
    };
  },
  computed: {
    version() {
      return this.$store.getters['config/getVersion'];
    },
    filters() {
      return this.$store.getters['config/getSessionFilter'];
    },
    config() {
      return config;
    },
    profile() {
      return this.$store.getters['auth/profile'];
    },

    typeClick() {
      return this.$store.getters['config/getTypeClick'];
    },
    dirs() {
      return this.$store.getters['config/getDirs1'];
    },
    roles() {
      return this.dirs.roles;
    },
  },
  created() {
    this.$vuetify.lang.current = this.lang;
  },
  mounted() {},
  methods: {
    log: function (info, type = '', level = '') {
      console.log(info);
    },

    async login(data) {
      try {
        await this.$store.dispatch('auth/login', {
          login: data.login,
          password: data.password,
        });
      } catch (error) {
        this.$emit('show-info', {
          type: 'error',
          text: error,
        });
      }
    },
    async logout() {
      await this.$store.dispatch('auth/logout');
      this.$router.push('/login');
    },

    setSessionFilter(n, v) {
      this.$store.commit('config/setSessionFilter', {
        name: n,
        value: v,
      });
    },
    getSessionFilter(n) {
      const f = this.filters?.[n] || null;
      return f;
    },
  },
});
