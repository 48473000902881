/** @format */
import { roles } from './libs';

const status = [
  { value: 0, text: 'Новый' },
  { value: 1, text: 'Активный' },
  { value: 10, text: 'Не активный' },
];
const readonly = true;
const hidden = true;
const profile = {
  title: 'Профиль',
  url: '/signup/set_profile',
  list: [
    { name: 'id', title: '#', type: 'id', sortable: true, width: 50 },
    { name: 'name', title: 'Имя', type: 'string', sortable: true, width: 200 },
    { name: 'login', title: 'Логин', type: 'string', sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', options: status, sortable: true, width: 200 },
    { name: 'role', title: 'Роль', type: 'select', options: roles, sortable: true, width: 200 },
    { name: 'sip_number', title: 'SIP номер', type: 'string', sortable: true, width: 100 },
    { name: 'activity', title: 'Активность', type: 'activity', width: 150 },
  ],
  form: [
    { name: 'name', title: 'Имя', type: 'string', validator: ['req'] },
    { name: 'login', title: 'Логин', type: 'string', validator: ['req', 'login'], readonly },
    { name: 'password', title: 'Пароль', type: 'password', validator: ['pass'], readonly },
    { name: 'company.company_name', title: 'Название компании', type: 'string' },
    { name: 'company.company_logo', title: 'Логотип', type: 'photo', video: false },
    { name: 'company.company_phone', title: 'Телефон компании', type: 'string' },
  ],
  roles,
  config: {
    default: {
      fields: 'name,login1,password1,company.company_name,company.company_logo,company.company_phone',
      fieldsRO: '',
      fieldsPost: 'name,company',
      fieldsForm: 'name,login,company.company_name,company.company_logo,company.company_phone',
    },
  },
};
export default profile;
