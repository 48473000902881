/** @format */

import Vue from 'vue';
import Vuetify from 'vuetify';
import ru from 'vuetify/src/locale/ru.ts';
import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify);

const theme = {
  primary: '#00679C',
  secondary: '#A8A754',
  third: '#595959',
};

export default new Vuetify({
  lang: {
    locales: { ru },
  },
  theme: {
    dark: false,
    themes: {
      dark: theme,
      light: theme,
    },
  },
});
