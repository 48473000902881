<template>
  <div>
    {{ model.title }}
    <ckeditor :editor="editor" :value="value" @input="onChange($event)" :config="editorOptions"></ckeditor>
  </div>
</template>

<script>
import ClassicEditor from "@/assets/ckeditor";
export default {
  components: {},
  props: {
    value: [String, Number],
    model: Object,
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      editorOptions: {
        toolbar: {
          items: [
            "undo",
            "redo",
            "heading",
            "|",
            "bold",
            "italic",
            "bulletedList",
            "numberedList",
            "|",
            "outdent",
            "indent",
            "|",
            "blockQuote",
            "insertTable",
            "alignment",
            "fontColor",
            "fontSize",
            "highlight",
            "showBlocks",
          ],
        },
        language: "ru",
        image: {
          toolbar: ["imageTextAlternative", "toggleImageCaption", "imageStyle:inline", "imageStyle:block", "imageStyle:side"],
        },
      },

      editor: ClassicEditor,
    };
  },
  methods: {
    onChange(e) {
      //console.log("input", e);
      this.$emit("input", e);
    },
  },
};
</script>

<style>
.ck-editor__editable {
  min-height: 300px;
}

.ck-source-editing-area {
  display: flex;
}
</style>
